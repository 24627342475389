<template>
    <div class="top-nav-container" ref="top-nav">
        <div class="top-tabs">
            <div v-for="tab in tabsToDisplay" v-bind:key="tab.id">
                <div class="tab-item-container" ref="tab-container">
                    <div class="tab-item link" :class="{ 'tab-active' : tab.routeDetails.includes(currentRouteDetail) }" @click="goToRoute(tab.routeDetails[0])">
                        <div>{{tab.name}}</div>
                        <div>[CLICK HERE]</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdButton } from 'vue-material/dist/components'

Vue.use(MdButton);

export default {
    name: 'TopNav',
    data() {
        return {
            tabsToDisplay: null
        }
    },
    props: {
        currentRouteDetail: {
            type: String
        }
    },
    watch: {
        currentRouteDetail(val) {
            if(val) {
                this.initBottomTabs();
            }
        }
    },
    methods: {
        initBottomTabs() {
            let tabWidth = 100;

            this.tabsToDisplay = [
                {
                    name: 'Round Report & Journal Entries',
                    routeDetails: ['round-report']
                },
                {
                    name: 'Journal Entries & Financial Statements',
                    routeDetails: ['income-statement', 'balance-sheet', 'cash-flow']
                }
            ];

            if(this.tabsToDisplay && this.tabsToDisplay.length > 0) {
                tabWidth = 100/this.tabsToDisplay.length;
            }

            if(this.currentRouteDetail === 'hasnt-started' || this.currentRouteDetail === 'calc-waiting' || this.currentRouteDetail === 'grade-waiting' || this.currentRouteDetail === 'game-over') {
                this.tabsToDisplay = [];
            } else {
                this.$nextTick(() => {
                    if(this.$refs['tab-container'] && this.$refs['tab-container'].length){
                        for(let el of this.$refs['tab-container']) {
                            el.style.width = tabWidth + "vw";
                        }
                    }
                });
            }
        },
        goToRoute(routeDetail) {
            if(this.$route.params.routeDetail !== routeDetail) {
                this.$router.push({
                    name: 'Game',
                    params: {
                        teamId: this.$route.params.teamId,
                        routeDetail: routeDetail
                    }
                });
            }
        }
    },
    created() {
        this.initBottomTabs();
    }
}
</script>

<style scoped>
    .top-nav-container {
        position: absolute;
        top: 64px;
        background-color: var(--nav-bar-color);
        height: 64px;
        width: 100%;
        z-index: 10;
    }

    .top-tabs {
        color: var(--text-secondary);
        display: flex;
        flex-wrap: nowrap;
    }

    .tab-item-container {
        height: 64px;
        line-height: 32px;
        overflow: hidden;
    }

    .tab-item {
        height: 100%;
    }

    .tab-active {
        background-color: var(--team-menu-color);
        cursor: default !important;
        color: black;
    }

    @media screen and (max-height: 500px){
        .top-nav-container {
            position: relative;
        }
    }
</style>
