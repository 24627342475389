<template>
    <div ref="main" :class="routeDetail === 'get-cash' || routeDetail === 'spend-cash' || routeDetail === 'go-to-market' ? 'game-accordion-view' : 'game'" v-if="currentCourse && getCurrentGameState && getCurrentRoundConfig && getCurrentAssets">
        <round-navigation
            :show-scoreboard-on-load="showScoreboardOnLoad"
            :is-menu-open.sync="isMenuOpen" v-on:countdown-ended="countdownEnded()"
            :selected-team.sync="selectedTeam"
            :active-round-id.sync="activeRoundId"
            :show-mobile-round-report-overlay.sync="showMobileRoundReportOverlay"
            :route-detail="routeDetail"
            :team-has-submitted-income-statement="teamHasSubmittedIncomeStatement"
            :team-has-submitted-balance-sheet="teamHasSubmittedBalanceSheet"
            :team-has-submitted-cash-flow="teamHasSubmittedCashFlow"
            :team-has-submitted-journal-entries="teamHasSubmittedJournalEntries">
        </round-navigation>
        <div v-if="(routeDetail === 'round-report' || routeDetail === 'income-statement' || routeDetail === 'balance-sheet' || routeDetail === 'cash-flow' || routeDetail === 'journal-entry-submissions') && !isMenuOpen">
            <!-- top nav will only show if showJournalEntryTAccounts is true because if it's false routeDetail for round-report will automatically redirect to income-statement and switching the top route then becomes pointless  -->
            <div v-if="showJournalEntryTAccounts">
                <top-nav :current-route-detail="routeDetail"></top-nav>
                <div class="top-nav-padding"></div>
            </div>
            <div v-else class="top-nav-padding-no-je"></div>
        </div>
        <AdminUserLogout></AdminUserLogout>
        <div v-if="currentDeadline && routeDetail !== 'grades' && routeDetail !== 'calc-waiting' && routeDetail !== 'get-cash' && routeDetail !== 'spend-cash' && routeDetail !== 'go-to-market' && routeDetail !== 'competitor-financials'">
          <Countdown :deadline.sync="currentDeadline" v-on:ended="countdownEnded()" :countdown-id="'fast-game-countdown'"></Countdown>
        </div>
        <div v-if="!routeDetail">
            <!-- determine default landing page based on current round -->
            <div v-if="!isProfessor">Main landing page</div>
            <div v-if="isProfessor">Professor landing page</div>
        </div>
        <game-round v-if="routeDetail === 'get-cash' || routeDetail === 'spend-cash' || routeDetail === 'go-to-market'"
                    :round-nav-height="roundNavHeight" :init-round-index="getCurrentGameState.roundIndex"
                    :route-detail="routeDetail"
                    :active-round-id="activeRoundId"></game-round>
        <div v-if="routeDetail === 'competitor-financials' && getSalesReport">
            <competitor-financials :active-round-id="activeRoundId"></competitor-financials>
        </div>
        <!-- this shows round report and journal entry, any call to this route will be checked first to see if showJournalEntryTAccounts is true. if showJournalEntryTAccounts is false, routeDetail will be redirected to income-statement instead -->
        <div v-if="routeDetail === 'round-report'">
            <div class="md-layout" v-if="selectedTeam && activeRoundId">
                <div class="split-screen-round-report split-screen-self-scroll md-layout-item md-xlarge-size-33 md-large-size-33 md-medium-size-50" v-if="showRoundReport && getSalesReport">
                    <round-report :selected-team="selectedTeam" :active-round-id="activeRoundId"></round-report>
                </div>
                <div class="split-screen-self-scroll md-layout-item md-xlarge-size-66 md-large-size-66 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
                    <journal-entries-and-t-accounts :selected-team="selectedTeam" :active-round-id="activeRoundId"></journal-entries-and-t-accounts>
                </div>
            </div>
        </div>
        <div v-if="routeDetail === 'income-statement' || routeDetail === 'balance-sheet' || routeDetail === 'cash-flow' || routeDetail === 'journal-entry-submissions'">
            <md-button v-if="!showMobileRoundReportOverlay" class="md-icon-button show-round-report-btn" @click="showMobileRoundReportOverlay = true">
                <md-icon>menu</md-icon>
            </md-button>
            <md-button v-if="showMobileRoundReportOverlay" class="md-icon-button hide-round-report-btn" @click="showMobileRoundReportOverlay = false">
                <md-icon>close</md-icon>
            </md-button>
            <transition name="slide">
                <div class="mobile-round-report-overlay md-layout-item md-size-100" v-if="showRoundReport && getSalesReport && showMobileRoundReportOverlay && selectedTeam && activeRoundId">
                    <round-report :selected-team="selectedTeam" :active-round-id="activeRoundId"></round-report>
                </div>
            </transition>
            <div class="md-layout" v-if="selectedTeam && activeRoundId">
                <div class="split-screen-self-scroll split-screen-round-report md-layout-item md-small-size-100 md-xsmall-size-100" :class="[showJournalEntryTAccounts ? 'md-xlarge-size-50 md-large-size-50 md-medium-size-50' : 'md-xlarge-size-33 md-large-size-33 md-medium-size-50']">
                    <journal-entries-and-t-accounts v-if="showJournalEntryTAccounts" :selected-team="selectedTeam" :active-round-id="activeRoundId" :journalEntryFilter.sync="journalEntryFilter" :availableJournalEntryFilters.sync="availableJournalEntryFilters"></journal-entries-and-t-accounts>
                    <round-report v-else :selected-team="selectedTeam" :active-round-id="activeRoundId"></round-report>
                </div>
                <div v-if="routeDetail" class="split-screen-self-scroll md-layout-item md-small-size-100 md-xsmall-size-100" :class="[showJournalEntryTAccounts ? 'md-xlarge-size-50 md-large-size-50 md-medium-size-50' : 'md-xlarge-size-66 md-large-size-66 md-medium-size-50']">
                    <div v-if="routeDetail === 'income-statement'">
                        <income-statement :allow-submission-when-valid-game-config-and-game-state="true" :selected-team="selectedTeam" :active-round-id="activeRoundId" :team-has-submitted-income-statement.sync="teamHasSubmittedIncomeStatement" :journalEntryFilter.sync="journalEntryFilter" :is-split-view="true" :availableJournalEntryFilters.sync="availableJournalEntryFilters"></income-statement>
                    </div>
                    <div v-if="routeDetail === 'balance-sheet'">
                        <balance-sheet :allow-submission-when-valid-game-config-and-game-state="true" :selected-team="selectedTeam" :active-round-id="activeRoundId" :team-has-submitted-balance-sheet.sync="teamHasSubmittedBalanceSheet" :journalEntryFilter.sync="journalEntryFilter" :is-split-view="true" :availableJournalEntryFilters.sync="availableJournalEntryFilters"></balance-sheet>
                    </div>
                    <div v-if="selectedTeam && routeDetail === 'cash-flow'">
                        <cash-flow :allow-submission-when-valid-game-config-and-game-state="true" :selected-team="selectedTeam" :active-round-id="activeRoundId" :team-has-submitted-cash-flow.sync="teamHasSubmittedCashFlow" :journalEntryFilter.sync="journalEntryFilter" :is-split-view="true" :availableJournalEntryFilters.sync="availableJournalEntryFilters"></cash-flow>
                    </div>
                    <div v-if="routeDetail === 'journal-entry-submissions'">
                        <journal-entry-submissions :selected-team="selectedTeam" :active-round-id="activeRoundId" :team-has-submitted-journal-entries.sync="teamHasSubmittedJournalEntries" :is-split-view="true"></journal-entry-submissions>
                    </div>
                    <bottom-nav class="split-screen-bottom-nav" :current-route-detail="routeDetail" :active-round-id="activeRoundId" :game-state="getCurrentGameState" :screen-width-percent="showJournalEntryTAccounts || isMediumWidthScreen ? 50 : 66"></bottom-nav>
                    <bottom-nav class="full-screen-bottom-nav" :current-route-detail="routeDetail" :active-round-id="activeRoundId" :game-state="getCurrentGameState" :screen-width-percent="100"></bottom-nav>
                </div>
            </div>
        </div>
        <div v-if="routeDetail === 'hasnt-started'" class="push-down">The game has not begun yet</div>
        <div v-if="routeDetail === 'is-paused'" class="push-down">This game's markets are being repaired. Please check back soon</div>
        <div v-if="routeDetail === 'calc-waiting'" class="push-down">The system is calculating the market. Please wait.</div>
        <div v-if="routeDetail === 'grade-waiting'" class="push-down">The system is calculating the grades. Please wait.</div>
        <div v-if="routeDetail === 'grades'" class="push-down">
            <div>Your assignment has been graded. Please wait for the next round to begin</div>
            <Countdown :deadline="currentDeadline" :countdown-id="'next-round-waiting'"></Countdown>
            <div>until next round starts</div>
        </div>
        <div v-if="routeDetail === 'game-over'">The game has ended</div>
        <!-- Bottom tabs are handled inside statements for the statement routes -->
        <div v-if="selectedTeam && !isMenuOpen && routeDetail !== 'round-report' && routeDetail !== 'income-statement' && routeDetail !== 'balance-sheet' && routeDetail !== 'cash-flow' && routeDetail !== 'journal-entry-submissions' && routeDetail !== 'competitor-financials' && routeDetail !== 'is-paused'">
            <bottom-nav :current-route-detail="routeDetail" :active-round-id="activeRoundId" :game-state="getCurrentGameState" :screen-width-percent="100"></bottom-nav>
        </div>
        <md-dialog :md-active.sync="showAutoLogoutDialog" :md-click-outside-to-close="false">
            <md-dialog-title>Warning</md-dialog-title>
            <md-dialog-content>
                You will be logged out due to inactivity in
                <Countdown v-on:ended="logout()" :deadline="logoutTimestamp" :countdown-id="'logout-dialog'"></Countdown>
                Any unsaved work will be lost
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="btn-primary" @click="stayLoggedIn">Stay Logged In</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
    <div v-else>
        Please wait...
    </div>
</template>
<script>
    import {
        mapActions, mapGetters
    } from 'vuex'
    import GameRound from './GameRound.vue';
    import Countdown from './Countdown.vue';
    import RoundNavigation from './RoundNavigation.vue';
    import TopNav from './TopNav.vue';
    import IncomeStatement from './IncomeStatement.vue';
    import CashFlow from './CashFlow.vue';
    import BalanceSheet from './BalanceSheet.vue';
    import RoundReport from './RoundReport.vue';
    import CompetitorFinancials from './CompetitorFinancials.vue';
    import {GameRoundPhase} from "@bit/accountonomics.types-lib.api-types/apiTypes";
    import BottomNav from './BottomNav.vue';
    import AdminUserLogout from './AdminUserLogout.vue';
    import JournalEntriesAndTAccounts from './JournalEntriesAndTAccounts.vue';
    import { UserTypeEnum } from "@bit/accountonomics.types-lib.api-types/apiTypes";
    import JournalEntrySubmissions from './JournalEntrySubmissions.vue';

    export default {
        data() {
            return {
                attrs: {
                    currentRouteDetail: null,
                },
                isProfessor: false,
                selectedTeam: null,
                roundNavHeight: 64,
                activeRoundId: null,
                availableRoutes: null,
                redirectRoute: null,
                pollInterval: null,
                isMenuOpen: false,
                teamId: null,
                semesterId: null,
                showAutoLogoutDialog: false,
                timeoutFunc: null,
                showRoundReport: true,
                showMobileRoundReportOverlay: false,
                teamHasSubmittedIncomeStatement: false,
                teamHasSubmittedBalanceSheet: false,
                teamHasSubmittedCashFlow: false,
                teamHasSubmittedJournalEntries: false,
                showJournalEntryTAccounts: false,
                isMediumWidthScreen: false,
                journalEntryFilter: null,
                availableJournalEntryFilters: null
            }
        },
        name: 'Game',
        components: {
            Countdown,
            'game-round': GameRound,
            'round-navigation': RoundNavigation,
            'top-nav': TopNav,
            'income-statement': IncomeStatement,
            'cash-flow': CashFlow,
            'balance-sheet': BalanceSheet,
            'round-report': RoundReport,
            'competitor-financials': CompetitorFinancials,
            'bottom-nav': BottomNav,
            AdminUserLogout,
            'journal-entries-and-t-accounts': JournalEntriesAndTAccounts,
            'journal-entry-submissions' : JournalEntrySubmissions
        },
        props: {
            routeDetail: {
                type: String
            },
            showScoreboardOnLoad: {
                type: Boolean
            }
        },
        watch: {
            async selectedTeam(val) {
                if (val) {
                    if(this.activeRoundId) {
                        await this.fetchSalesReport({
                            teamId: val.id,
                            roundConfigId: this.activeRoundId
                        });
                    }

                    await this.initGame({
                        semesterId: this.semesterId,
                        teamId: val.id
                    });
                    await this.fetchCurrentAssets({
                        teamId: val.id
                    });
                }
            },
            async activeRoundId(val) {
                await this.fetchSalesReport({
                    teamId: this.$route.params.teamId,
                    roundConfigId: this.activeRoundId
                });
                await this.initGame({
                    semesterId: this.semesterId,
                    teamId: this.teamId
                });
                await this.fetchCurrentAssets({
                    teamId: this.teamId
                });
                this.setShowJournalEntryTAccount();
            },
            async currentGameState(newVal, oldVal) {
                if(newVal && oldVal) {
                    if(newVal.phase !== oldVal.phase) {
                        if(this.selectedTeam) {
                            await this.initGame({
                                semesterId: this.semesterId,
                                teamId: this.selectedTeam.id
                            });
                            await this.fetchCurrentAssets({
                                teamId: this.selectedTeam.id
                            });
                        }
                        await this.determineRouteIfNotSet();
                    }
                }
            },
            async routeDetail(newVal, oldVal) {
                await this.startPhasePoll();
            },
            getToken(val) {
                if(val) {
                    this.initLogoutCountdown();
                }
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentRoundConfig',
                'getCurrentAssets',
                'getSalesReport',
                'getCurrentCourse',
                'getNextDeadline',
                'getCurrentGameState',
                'getTokenExpTime',
                'getToken',
                'getUserType',
                'getRefreshToken'
            ]),
            currentCourse() {
                return this.$store.state.game.currentCourse;
            },
            currentDeadline() {
                return this.getNextDeadline;
            },
            logoutTimestamp: {
                get() {
                    let timeToMakeRequestMilliSeconds = 1200000; // 20 min
                    if(window.nerkleInfo.timeToMakeRequest) {
                        timeToMakeRequestMilliSeconds = window.nerkleInfo.timeToMakeRequest;
                    }
                    const tokenExpTime = parseInt(this.getTokenExpTime);
                    return (tokenExpTime + timeToMakeRequestMilliSeconds)/1000; // this needs to be in seconds to work with Countdown
                }
            }
        },
        methods: {
            ...mapActions([
                'initGame',
                'fetchCurrentAssets',
                'fetchCurrentGameState',
                'fetchSalesReport',
                'getStudentTeam',
                'refreshIdToken',
                'resetVuexState',
                'fetchCustomLabels'
            ]),
            async determineRouteIfNotSet() {
                const gameState = this.getCurrentGameState;
                if(gameState.phase === GameRoundPhase.CalcSheets || gameState.phase === GameRoundPhase.Graded) {
                    this.selectedTeam = await this.getStudentTeam({
                        teamId: this.teamId
                    });
                }
                const isPaused = this.selectedTeam.semester.isPaused;
                if(isPaused) {
                    if(this.routeDetail !== 'is-paused') {
                        this.$router.push({
                            name: 'Game',
                            params: {routeDetail: 'is-paused', teamId: this.$route.params.teamId}
                        });
                    }
                    this.availableRoutes = ['is-paused'];
                    return;
                }
                // if no routeDetail, redirect to the correct page based on current gameState
                if (!this.routeDetail) {
                    this.determineRoute();
                } else {
                    this.determineRouteRedirect();
                }
            },
            async countdownEnded() {
                await this.determineRouteIfNotSet();
            },
            determineRoute() {
                const gameState = this.getCurrentGameState;
                switch (gameState.phase) {
                    case GameRoundPhase.HasntStarted:
                        this.$router.push({
                            name: 'Game',
                            params: {routeDetail: 'hasnt-started', teamId: this.$route.params.teamId}
                        });
                        break;
                    case GameRoundPhase.InputSheet:
                        if(this.getUserType === UserTypeEnum.Student) {
                            this.$router.push({
                              name: 'Game',
                              params: {routeDetail: 'get-cash', teamId: this.$route.params.teamId}
                            });
                        }
                        // we redirect to income-statement if the routeDetail is round-report && showJournalEntryTAccounts is false
                        // this is because we want the view that income-statement shows (round report on the left, income statement on the right with the screen proportions)
                        // overall it is easier to render income-statement rather than adjust the view for round-report
                        if(this.getUserType === UserTypeEnum.Professor) {
                            const routeDetail = this.showJournalEntryTAccounts ? 'round-report' : 'income-statement';
                            this.$router.push({
                              name: 'Game',
                              params: {routeDetail, teamId: this.$route.params.teamId}
                            });
                        }
                        break;
                    case GameRoundPhase.CalcWaiting:
                        this.$router.push({
                            name: 'Game',
                            params: {routeDetail: 'calc-waiting', teamId: this.$route.params.teamId}
                        });
                        break;
                    case GameRoundPhase.CalcSheets:
                        this.$router.push({
                            name: 'Game',
                            params: {routeDetail: 'income-statement', teamId: this.$route.params.teamId}
                        });
                        break;
                    case GameRoundPhase.GradeWaiting:
                        this.$router.push({
                            name: 'Game',
                            params: {routeDetail: 'grade-waiting', teamId: this.$route.params.teamId}
                        });
                        break;
                    case GameRoundPhase.Graded:
                        this.$router.push({
                            name: 'Game',
                            params: {routeDetail: 'grades', teamId: this.$route.params.teamId}
                        });
                        break;
                    case GameRoundPhase.GameOver:
                        this.$router.push({
                            name: 'Game',
                            params: {routeDetail: 'game-over', teamId: this.$route.params.teamId}
                        });
                        break;
                    default:
                        break;
                }
            },
            determineRouteRedirect() {
                const gameState = this.getCurrentGameState;
                switch (gameState.phase) {
                    case GameRoundPhase.HasntStarted:
                        this.availableRoutes = ['hasnt-started'];
                        this.redirectRoute = 'hasnt-started';
                        break;
                    case GameRoundPhase.InputSheet:
                        if(this.getUserType === UserTypeEnum.Student) {
                            this.availableRoutes = [
                                'get-cash',
                                'spend-cash',
                                'go-to-market'
                            ];
                            this.redirectRoute = 'get-cash';
                        }
                        if(this.getUserType === UserTypeEnum.Professor) {
                            if(this.showJournalEntryTAccounts) {
                                this.availableRoutes = [
                                    'round-report'
                                ];
                                this.redirectRoute = 'round-report';
                            } else {
                                this.availableRoutes = [
                                    'income-statement'
                                ];
                                this.redirectRoute = 'income-statement';
                            }
                        }
                        break;
                    case GameRoundPhase.CalcWaiting:
                        this.availableRoutes = ['calc-waiting'];
                        this.redirectRoute = 'calc-waiting';
                        break;
                    case GameRoundPhase.CalcSheets:
                        this.availableRoutes = [
                            'income-statement',
                            'balance-sheet',
                            'cash-flow',
                            'round-report',
                            'journal-entry-submissions'
                        ];
                        this.redirectRoute = 'income-statement';
                        break;
                    case GameRoundPhase.GradeWaiting:
                        this.availableRoutes = ['grade-waiting'];
                        this.redirectRoute = 'grade-waiting';
                        break;
                    case GameRoundPhase.Graded:
                        this.availableRoutes = [
                            'grades'
                        ];
                        this.redirectRoute = 'grades';
                        break;
                    case GameRoundPhase.GameOver:
                        this.availableRoutes = ['game-over'];
                        this.redirectRoute = 'game-over';
                        break;
                    default:
                        break;
                }
                if(this.availableRoutes.indexOf(this.routeDetail) < 0) {
                    this.$router.push({
                        name: 'Game',
                        params: {routeDetail: this.redirectRoute, teamId: this.$route.params.teamId}
                    });
                }
            },
            logout() {
                localStorage.clear();
                sessionStorage.clear();
                this.resetVuexState();
                this.$router.push({
                    name: 'Login'
                });
            },
            setShowJournalEntryTAccount() {
                const gameConfig = this.selectedTeam.semester.gameConfig;
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                const currentRoundId = this.getCurrentGameState.roundId;
                const isCurrentActiveRound = currentRoundId === this.activeRoundId;
                const result = isCurrentActiveRound ?
                    gameConfig.roundFeatures.journalEntryTAccountConfig[roundIndex] === "OFF" ||
                    (gameConfig.roundFeatures.journalEntryTAccountConfig[roundIndex] === "SHOW_FOR_PAST_ROUNDS" && this.getUserType === UserTypeEnum.Student) :
                    gameConfig.roundFeatures.journalEntryTAccountConfig[roundIndex] === "OFF";
                this.showJournalEntryTAccounts = !result;
            },
            async startPhasePoll() {
                if(this.selectedTeam) {
                    if(this.selectedTeam.semester.useFlowMode === true) {
                        // no phase poll in flow mode
                        return;
                    }
                }

                if(this.pollInterval){
                    clearInterval(this.pollInterval);
                }
                const gameState = await this.fetchCurrentGameState({
                  semesterId: this.semesterId
                });
                if(gameState.phase === GameRoundPhase.CalcWaiting || gameState.phase === GameRoundPhase.GradeWaiting) {
                    this.pollInterval = setInterval(async () => {
                        const polledGameState = await this.fetchCurrentGameState({
                          semesterId: this.semesterId
                        });
                        if(polledGameState.phase !== GameRoundPhase.CalcWaiting && polledGameState.phase !== GameRoundPhase.GradeWaiting) {
                            clearInterval(this.pollInterval);
                            this.$router.go();
                        }
                    }, 3000); // check phase change every 3 seconds
                }
            },
            async init() {
                this.isProfessor = this.getUserType === UserTypeEnum.Professor;
                this.selectedTeam = await this.getStudentTeam({
                    teamId: this.teamId
                });
                await this.initGame({
                    semesterId: this.semesterId,
                    teamId: this.teamId
                });
                await this.fetchCurrentAssets({
                    semesterId: this.semesterId,
                    teamId: this.teamId
                });
                await this.determineRouteIfNotSet();
                await this.startPhasePoll();
                this.initLogoutCountdown();
                await this.fetchCustomLabels({ semesterId: this.semesterId });
            },
            initLogoutCountdown() {
                if(process.env.VUE_APP_ENV !== 'local') {
                    let secUntilShowLogoutDialog = 300; // 5 min
                    if(window.nerkleInfo.secUntilShowLogoutDialog) {
                        secUntilShowLogoutDialog = window.nerkleInfo.secUntilShowLogoutDialog;
                    }
                    const currentTimeSeconds = Date.now()/1000;
                    const milisToShowLogoutDialog = ((this.logoutTimestamp - currentTimeSeconds) - secUntilShowLogoutDialog) * 1000;

                    if(milisToShowLogoutDialog <= 0) {
                        this.showAutoLogoutDialog = true;
                    } else {
                        setTimeout(() => {
                            this.showAutoLogoutDialog = true;
                        }, milisToShowLogoutDialog);
                    }
                }
            },
            adjustUIForNewScreenWidth() {
                const bodyWidth = document.body.offsetWidth;
                this.isMediumWidthScreen =  bodyWidth > 960 && bodyWidth <= 1280;
            },
            async stayLoggedIn() {
                try {
                    await this.refreshIdToken({refreshToken: this.getRefreshToken});
                    this.showAutoLogoutDialog = false;
                } catch(err) {
                    this.logout();
                }
            }
        },
        async created() {
            window.addEventListener('resize', this.adjustUIForNewScreenWidth);
            this.teamId = this.$route.params.teamId;
            this.semesterId = this.$route.params.semesterId;
            await this.init();
            this.adjustUIForNewScreenWidth();
        },
        destroyed () {
          window.removeEventListener('resize', this.calculateAccordionItemHeight);
        }
    }
</script>
<style scoped>
    @import '../assets/global.css';

    .game {
        background-color: var(--main-bg-color);
    }

    .game-accordion-view {
        background-color: var(--main-bg-color);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 100vh;
        overflow: hidden;
    }

    .not-yet-label {
        margin-top: 40px;
        font-weight: bold;
    }

    .md-dialog {
        width: 400px;
        height: 200px;
    }

    .show-round-report-btn {
        margin-left: 0;
        margin-right: auto;
        display: block;
    }

    .hide-round-report-btn {
        margin-left: auto;
        margin-right: 0;
        display: block;
    }

    .mobile-round-report-overlay {
        position: absolute;
        background-color: var(--main-bg-color);
        z-index: 100;
    }

    .slide-leave-active,.slide-enter-active {
        transition: 1s;
    }

    .slide-enter {
        transform: translate(-100%, 0);
    }

    .slide-leave-to {
        transform: translate(-100%, 0);
    }

    .split-screen-self-scroll {
        overflow-y: scroll;
        height: 85vh;
    }

    .top-nav-padding {
        padding-bottom: 128px;
    }

    .top-nav-padding-no-je {
        padding-bottom: 64px;
    }

    .split-screen-bottom-nav {
        display: block;
    }

    .full-screen-bottom-nav {
        display: none;
    }

    .push-down {
      margin-top: 170px;
    }

    @media screen and (max-height: 500px){
        .top-nav-padding {
            padding-bottom: 64px;
        }
        .split-screen-self-scroll {
            height: 65vh;
        }
    }

    @media screen and (max-height: 650px){
        .game-accordion-view {
            overflow: scroll;
        }
        .split-screen-self-scroll {
            height: 75vh;
        }
    }

    @media screen and (max-width: 960px){
        .split-screen-round-report {
            display: none;
        }
        .split-screen-bottom-nav {
            display: none;
        }
        .full-screen-bottom-nav {
            display: block;
        }
    }

    @media screen and (min-width: 960px){
        .show-round-report-btn {
            display: none;
        }
        .hide-round-report-btn {
            display: none;
        }
        .mobile-round-report-overlay {
            display: none;
        }
    }

    @media screen and (min-width: 960px){
        .show-round-report-btn {
            display: none;
        }
        .hide-round-report-btn {
            display: none;
        }
        .mobile-round-report-overlay {
            display: none;
        }
    }
</style>
