import gql from 'graphql-tag';
import { apolloClient } from "../../main";
import { LoginCredentials, UserVuexState } from "@/types/types";
import { User, Team, Semester, SemesterSeason, StudentOnboardInput, UserTypeEnum } from "@bit/accountonomics.types-lib.api-types";
const state: UserVuexState = {
  team: undefined,
  professorTeams: undefined,
  userLoginCode: undefined,
  token: undefined,
  tokenExpTime: undefined,
  timeToShowLogoutModal: undefined,
  userType: undefined,
  userEmail: undefined,
  accessToken: undefined,
  refreshToken: undefined
};
const getters = {
  getUserType: (state: UserVuexState): UserTypeEnum | undefined => {
    if(!state.userType) {
      if(process.env.VUE_APP_ENV === 'local') {
        return process.env.VUE_APP_SELECTED_USER_TYPE as UserTypeEnum;
      } else {
        const hostname = window.location.hostname;
        if(hostname.includes("educator")) {
          return UserTypeEnum.Professor;
        } else if(hostname.includes("play")) {
          return UserTypeEnum.Student;
        } else if(hostname.includes("admin")) {
          return UserTypeEnum.Admin;
        }
      }
      return undefined;
    } else {
      return state.userType
    }
  },
  getUserEmail: (state: UserVuexState): string | undefined => {
    if(!state.userEmail){
      const localEmail = sessionStorage.getItem('accountonomics.email');
      if(localEmail){
        return localEmail;
      }
    }
    return state.userEmail
  },
  getTeam: (state: UserVuexState): Team | undefined => state.team,
  getProfessorTeams: (state: UserVuexState): Team[] | undefined => state.professorTeams,
  getUserLoginCode: (state: UserVuexState): string | null | undefined => {
    if(!state.userLoginCode){
      const localLoginCode = localStorage.getItem('accountonomics.loginCode');
      if(localLoginCode){
        return localLoginCode;
      }
    }
    return state.userLoginCode;
  },
  getToken: (state: UserVuexState): string | null | undefined => {
    if(!state.token){
      const localToken = sessionStorage.getItem('accountonomics.token');
      if(localToken){
        return localToken;
      }
    }
    return state.token;
  },
  getAccessToken: (state: UserVuexState): string | null | undefined => {
    if(!state.accessToken){
      const localToken = sessionStorage.getItem('accountonomics.accessToken');
      if(localToken){
        return localToken;
      }
    }
    return state.accessToken;
  },
  getTokenExpTime: (state: UserVuexState): string | null | undefined => {
    if(!state.tokenExpTime){
      const localTokenExpTime = sessionStorage.getItem('accountonomics.tokenExpTime');
      if(localTokenExpTime){
        return localTokenExpTime;
      }
    }
    return state.tokenExpTime;
  },
  getRefreshToken: (state: UserVuexState): string | null | undefined => {
    if(!state.refreshToken){
      const localRefreshToken = sessionStorage.getItem('accountonomics.refreshToken');
      if(localRefreshToken){
        return localRefreshToken;
      }
    }
    return state.refreshToken;
  }
};

const actions = {
  async submitOnboardStudentCode({ commit }: any, payload: string) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query SemesterByCode($semesterCode: String) {
            semesterByCode(semesterCode: $semesterCode) {
              id,
              name,
              year,
              season,
              startTime,
              endTime,
              numTeams,
              numStudentsPerTeam,
              onboardCode,
              useFlowMode,
              teams {
                id,
                name,
                students {
                  id,
                  email
                }
              }
            }
          }
        `,
        variables: {
          semesterCode: payload
        }
      });

      if(!response.data.semesterByCode) {
        throw("semester not found");
      }
      return response.data.semesterByCode;
    } catch(err) {
      throw("Error fetching semesterByCode: " + err);
    }
  },

  async submitStudentOnboard({ commit }: any, payload: StudentOnboardInput) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation StudentOnboard($studentInput: StudentOnboardInput!) {
            studentOnboard(input: $studentInput) {
              id
            }
          }
        `,
        variables: {
          studentInput: payload
        }
      });

      return response.data.studentOnboard;
    } catch(err) {
      throw(err);
    }
  },

  async getStudentTeam({ commit }: any, payload: any) {
    try {
      const teamResponse = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
            query Team($teamId: ID!) {
                team(teamId: $teamId) { 
                  id, 
                  name,
                  semester {
                    id,
                    name,
                    year,
                    teams {
                      id, 
                      name
                    },
                    season,
                    startTime,
                    endTime,
                    numTeams,
                    numStudentsPerTeam,
                    timezone,
                    useFlowMode,
                    onboardCode,
                    teamSalesReturns { teamId, roundConfigId, assignedSalesReturnsPercentage },
                    roundTimings { roundConfigId, electionsOpen, electionsDeadline, secondSubmissionDeadline, gradeViewingDeadline, hasCalculatedMarket, hasGraded },
                    gameConfig {
                        roundFeatures { advertising { cost, benefit }, salesReturns, incomeTax, borrowingCaps { debtRoundBorrowCap, bondRoundBorrowCap, totalPrincipalCap }, incomeStatementConfig { roundSetting, disabledFields }, balanceSheetConfig { roundSetting, disabledFields }, cashFlowConfig { roundSetting, disabledFields }, journalEntryTAccountConfig, requirePositiveCashBalanceBeforeElecting, accountsReceivable { outstandingPercent, badDebtPercent }, journalEntrySubmissionConfig { enabledJournalEntrySubmissions { journalEntryType, accountOptions } } },
                        junkLoans { rate, term },
                        stockConfig { teamStartingStockPrice, initialOfferingNumShares, totalSellableSharesPerTeam }
                    },
                    course {
                      id,
                      gameProducts { id, name }
                      rounds {
                        id,
                        buildings { id, typesConfig { buildingType, purchaseCost, equipmentCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod, miniId } },
                        equipments { id, typesConfig { equipmentType, purchaseCost, productionCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod, miniId } },
                        storeFurnishings { id, typesConfig { furnishingType, purchaseCost, productionCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod, miniId } },
                        lands { id, purchaseCost, buildingCapacity, miniId },
                        debts { id, borrowingRate, borrowingTerm, miniId },
                        products { id, gameProductId, productionCost, demandSlope, demandIntercept },
                        isStockTradingEnabled,
                        bonds {
                          realInterestRateFactor, 
                          bondOptions {
                            id,
                            miniId,
                            nominalAmount, 
                            nominalInterestRate, 
                            paymentDueQuarterEnd, 
                            numPaymentsPerYear, 
                            term 
                          } 
                        }
                      },
                      defaultGameConfig {
                        roundFeatures { advertising { cost, benefit }, salesReturns, incomeTax, borrowingCaps { debtRoundBorrowCap, bondRoundBorrowCap, totalPrincipalCap }, incomeStatementConfig { roundSetting, disabledFields }, balanceSheetConfig { roundSetting, disabledFields }, cashFlowConfig { roundSetting, disabledFields }, journalEntryTAccountConfig, requirePositiveCashBalanceBeforeElecting, accountsReceivable { outstandingPercent, badDebtPercent }, journalEntrySubmissionConfig { enabledJournalEntrySubmissions { journalEntryType, accountOptions } } },
                        junkLoans { rate, term },
                        stockConfig { teamStartingStockPrice, initialOfferingNumShares, totalSellableSharesPerTeam }
                      }
                    },
                    isPaused
                  }
                }
            }
        `,
        variables: {
          teamId: payload.teamId
        }
      });

      const team = teamResponse.data.team;
      commit('setLoggedInTeam', team);
      return team;
    } catch(err) {
      throw("No team found for student");
    }
  },

  async refreshIdToken({ dispatch }: any, payload: any) {
    const url = process.env.VUE_APP_LAMBDA_URL
    const data = {
      authType: 'REFRESH_TOKEN_AUTH',
      authParameters: {
        REFRESH_TOKEN: payload.refreshToken
      }
    };

    try {
      const response = await fetch(`${url}/authentication`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Method': 'POST'
        },
        body: JSON.stringify(data)
      });

      const responseJson = await response.json();          
      if(response.ok) {
        const token = responseJson.input["AuthenticationResult"]["IdToken"];
        const tokenExpSeconds = responseJson.input["AuthenticationResult"]["ExpiresIn"];
        const accessToken = responseJson.input["AuthenticationResult"]["AccessToken"];
        const currentTimestamp = Date.now();
        const tokenExpTime = currentTimestamp + (tokenExpSeconds * 1000);

        await dispatch('setUserToken', token);
        await dispatch('setUserTokenExpTime', tokenExpTime);
        await dispatch('setAccessToken', accessToken);

        return Promise.resolve(responseJson.input);
      } else {
        return Promise.reject(responseJson.message);
      }
    } catch(err) {
      return Promise.reject(err);
    }
  },

  async setTeam({ commit }: any, payload: any) {
    commit('setLoggedInTeam', payload.team);
  },

  async setAdminUserToken({ commit }: any, token: string) {
    token ? sessionStorage.setItem('accountonomics.adminUserToken', token.toString()) : sessionStorage.removeItem('accountonomics.adminUserToken');
  },

  async setUserToken({ commit }: any, token: string) {
    commit('setToken', token);
    token ? sessionStorage.setItem('accountonomics.token', token.toString()) : sessionStorage.removeItem('accountonomics.token');
  },

  async setAccessToken({ commit }: any, token: string) {
    commit('setAccessToken', token);
    token ? sessionStorage.setItem('accountonomics.accessToken', token.toString()) : sessionStorage.removeItem('accountonomics.accessToken');
  },

  async setUserRefreshToken({ commit }: any, token: string) {
    commit('setRefreshToken', token);
    token ? sessionStorage.setItem('accountonomics.refreshToken', token.toString()) : sessionStorage.removeItem('accountonomics.refreshToken');
  },

  async setUserTokenExpTime({ commit }: any, tokenExpTime: string) {
    commit('setTokenExpTime', tokenExpTime);
    tokenExpTime ? sessionStorage.setItem('accountonomics.tokenExpTime', tokenExpTime.toString()) : sessionStorage.removeItem('accountonomics.tokenExpTime');
  },

  async getCurrentLicense({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query GetCurrentLicense($userEmail: String!) {
            getCurrentLicense(userEmail: $userEmail) {
              id,
              key,
              price,
              revokedTimestamp,
              isBanned,
              purchaseNote,
              licenseType,
              email,
              studentPrice,
              school,
              licenseStart,
              licenseEnd,
              paid,
              autoRenew,
              numAllowedGames,
              user {
                id,
                firstname,
                lastname,
                email
              },
              createdAt
            }
          }
        `,
        variables: {
          userEmail: payload.email
        }
      });

      return response.data.getCurrentLicense;
    } catch(err) {
      throw(err);
    }
  },

  async configureUserType({ commit }: any, userType: UserTypeEnum) {
    commit('setUserType', userType);
  },
  async configureUserEmail({ commit }: any, currentEmail: string) {
    const userEmail: String | undefined = currentEmail;
    userEmail ? sessionStorage.setItem('accountonomics.email', userEmail.toString()) : sessionStorage.removeItem('accountonomics.email');
    commit('setUserEmail', userEmail);
  },
  async fetchUserByEmail({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query UserByEmail($email: String!) {
            userByEmail(email: $email) {
              id,
              settings {
                emailPreference
              }
            }
          }
        `,
        variables: {
          email: payload.email
        }
      });

      return response.data.userByEmail;
    } catch(err) {
      throw(err);
    }
  },
  async updateUserSettings({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateUserSettings($userSettings: UserSettingsInput!) {
            updateUserSettings(userSettings: $userSettings) {
              id,
              settings {
                emailPreference
              }
            }
          }
        `,
        variables: {
          userSettings: payload.userSettings
        }
      });

      return response.data.updateUserSettings;
    } catch(err) {
      throw(err);
    }
  }
};

const mutations = {
  setLoggedInTeam: (state: UserVuexState, team: Team) => { state.team = team },
  setProfessorTeams: (state: UserVuexState, teams: Team[]) => { state.professorTeams = teams },
  setUserLoginCode: (state: UserVuexState, loginCode: string) => { state.userLoginCode = loginCode },
  setToken: (state: UserVuexState, token: string) => { state.token = token },
  setAccessToken: (state: UserVuexState, token: string) => { state.accessToken = token },
  setRefreshToken: (state: UserVuexState, token: string) => { state.refreshToken = token },
  setTokenExpTime: (state: UserVuexState, tokenExpTime: string) => { state.tokenExpTime = tokenExpTime },
  setUserType: (state: UserVuexState, userType: UserTypeEnum) => { state.userType = userType },
  setUserEmail: (state: UserVuexState, userEmail: string) => { state.userEmail = userEmail },
  resetUserState(state: UserVuexState) {
    state.team = undefined;
    state.professorTeams = undefined;
    state.userLoginCode = undefined;
    state.token = undefined;
    state.tokenExpTime = undefined;
    state.userType = undefined;
    state.userEmail = undefined;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};