<template>
    <div>
        <GChart 
            type="BarChart"
            :data="data"
            :options="options"
        />
    </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

export default {
    name: 'BarGraph',
    components: {
        GChart
    },
    props: {
        data: {
            type: Array
        },
        options: {
            type: Object
        }
    }
}
</script>

<style scoped>
</style>