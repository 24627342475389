<template>
    <div>
        <div v-if="successMessage" class="alert-success">
            {{successMessage}}
            <span @click="successMessage = null"><md-icon class="fa fa-times-circle light link"></md-icon></span>
        </div>
        <div class="search-type" v-if="searched && searched.length > 0">
            <md-field md-clearable class="search-bar md-toolbar-section-end">
                <md-input placeholder="Search by name" v-model="search" @input="searchOnTable" />
            </md-field>
        </div>
        <div class="course-template-table">
            <md-button class="md-button button-theme md-icon-button md-dense md-raised md-primary custom-fab" @click="showImportCourseTemplateModal = true">
                <md-icon>add</md-icon>
            </md-button>
            <md-table v-model="searched" md-sort="name" md-card>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell class="course-template-table-cell" md-label="Name">
                        <div>{{ item.name }}</div>
                    </md-table-cell>
                    <md-table-cell class="course-template-table-cell" md-label="is Live">
                        <md-checkbox @change="toggleIsLive(item)" class="md-primary md-layout-item" v-model="item.isLive"></md-checkbox>
                    </md-table-cell>
                    <md-table-cell>
                        <md-button class="md-raised md-primary" @click="openSelectedCourseTemplate(item)">View</md-button>
                        <md-button class="md-raised md-accent" @click="showDeleteCourseTemplateModal = true, selectedCourseTemplate = item">Delete</md-button>
                        <md-button class="md-raised" @click="openCourseTemplateModalForEditing(item)">Edit</md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>

        <md-dialog class="modal" :md-active="showImportCourseTemplateModal">
            <md-dialog-title class="modal-title">
                <span v-if="!isEditing">Import New </span>
                <span v-else>Edit</span>
                Course Template
            </md-dialog-title>

            <div class="md-layout">
                <div class="md-layout-item"></div>
                <md-field class="md-layout-item md-size-80">
                    <label>Name</label>
                    <md-input v-model="name"></md-input>
                </md-field>
                <div class="md-layout-item"></div>
            </div>

            <div class="md-layout">
                <div class="md-layout-item"></div>
                <md-field class="md-layout-item md-size-80">
                    <label>Description</label>
                    <md-input v-model="description"></md-input>
                </md-field>
                <div class="md-layout-item"></div>
            </div>

            <md-field>
                <label>Upload Course Template JSON</label>
                <md-file @change="onFileChange($event)" accept="application/JSON" />
            </md-field>

            <md-dialog-actions>
                <md-button v-if="!isEditing && courseTemplateJSON && name && description" class="md-primary" @click="uploadCourseTemplate()">Upload New Template</md-button>
                <md-button v-if="isEditing && (courseTemplateJSON || name || description)" class="md-primary" @click="updateSelectedCourseTemplate(selectedCourseTemplate)">Save Course Template</md-button>
                <md-button class="md-accent" @click="closeImportCourseTemplateModal()">Cancel</md-button>
            </md-dialog-actions>
        </md-dialog>
        <md-dialog class="modal" v-if="selectedCourseTemplate" :md-active="showDeleteCourseTemplateModal">
            <md-dialog-title class="modal-title">Delete Course Template</md-dialog-title>

            <md-dialog-content>
                Are you sure you want to delete Course Template {{selectedCourseTemplate.name}}?
            </md-dialog-content>

            <md-dialog-actions>
                <md-button class="md-primary" @click="deleteSelectedCourseTemplate()">Delete</md-button>
                <md-button class="md-accent" @click="showDeleteCourseTemplateModal = false, selectedCourseTemplate = null">Cancel</md-button>
            </md-dialog-actions>
        </md-dialog>
        <span class="error" v-if="errorMessage">{{errorMessage}}</span>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import { MdTable } from 'vue-material/dist/components'
import { MdRipple } from 'vue-material/dist/components'
Vue.use(MdTable);
Vue.use(MdRipple);

export default {
    name: 'CourseTemplates',
    data() {
        return {
            showImportCourseTemplateModal: false,
            showDeleteCourseTemplateModal: false,
            errorMessage: null,
            courseTemplateJSON: null,
            courseTemplates: [],
            search: null,
            searched: [],
            selectedCourseTemplate: null,
            successMessage: null,
            name: null,
            description: null,
            isEditing: false
        }
    },
    props: {
        professorId: {
            type: String
        }
    },
    watch: {
        courseTemplates(val) {
            if(val) {
                this.searched = val;
            }
        }
    },
    methods: {
        ...mapActions([
            'importCourseTemplate',
            'fetchCourseTemplates',
            'deleteCourseTemplate',
            'fetchCourseTemplateAsInputString',
            'updateCourseTemplate'
        ]),
        async deleteSelectedCourseTemplate() {
            try {
                this.courseTemplates = await this.deleteCourseTemplate({
                    courseTemplateId: this.selectedCourseTemplate.id,
                    professorId: this.professorId
                });

                if(this.professorId) {
                    this.courseTemplates = this.courseTemplates.filter(courseTemplate => !courseTemplate.isSystemTemplate);
                }

                this.showDeleteCourseTemplateModal = false;
                this.successMessage = `Successfully deleted Course Template ${this.selectedCourseTemplate.name}`;
                this.selectedCourseTemplate = null;
            } catch(err) {
                if(err.graphQLErrors && err.graphQLErrors.length) {
					this.errorMessage = err.graphQLErrors[0].message;
				} else {
					this.errorMessage = "Something went wrong. Please try again";
				}
				return err;
            }
        },
        async openSelectedCourseTemplate(selectedCourseTemplate) {
            const courseTemplateInputString = await this.fetchCourseTemplateAsInputString({
                courseTemplateId: selectedCourseTemplate.id
            });

            sessionStorage.setItem('accountonomics.courseTemplate', courseTemplateInputString);

            let routeData = this.$router.resolve({
                name: "PlainTextCourseTemplate", 
                params: {
                    courseTemplate: selectedCourseTemplate
                }
            });
            window.open(routeData.href);
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.readFile(files[0]);
        },
        readFile(file) {
            let reader = new FileReader();
            reader.onload = e => {
                const courseTemplateJSON = JSON.parse(e.target.result);

                this.courseTemplateJSON = {
                    minTeams: courseTemplateJSON.minTeams,
                    maxTeams: courseTemplateJSON.maxTeams,
                    minStudentsPerTeam: courseTemplateJSON.minStudentsPerTeam,
                    maxStudentsPerTeam: courseTemplateJSON.maxStudentsPerTeam,
                    gameProducts: courseTemplateJSON.gameProducts,
                    rounds: courseTemplateJSON.rounds,
                    defaultGameConfig: courseTemplateJSON.defaultGameConfig
                };
            };
            reader.readAsText(file);
        },
        searchOnTable() {
            if(this.courseTemplates) {
                this.searched = this.courseTemplates.filter((item) => {
                    if(this.toLower(item.name).includes(this.toLower(this.search))) {
                        return true;
                    }
                    return false;
                });
            }
        },
        toLower(text) {
            return text.toString().toLowerCase();
        },
        async uploadCourseTemplate() {
            try {
                await this.importCourseTemplate({
                    courseTemplateJSON: this.courseTemplateJSON,
                    professorId: this.professorId,
                    name: this.name,
                    description: this.description
                });

                this.showImportCourseTemplateModal = false;
                this.courseTemplateJSON = null;
                this.successMessage = "Successfully created new Course Template";
                this.courseTemplates = await this.fetchCourseTemplates({
                    professorId: this.professorId
                });

                if(this.professorId) {
                    this.courseTemplates = this.courseTemplates.filter(courseTemplate => !courseTemplate.isSystemTemplate);
                }
            } catch(err) {
                if(err.graphQLErrors && err.graphQLErrors.length) {
					this.errorMessage = err.graphQLErrors[0].message;
				} else {
					this.errorMessage = "Something went wrong. Please try again";
				}
				return err;
            }
        },
        async toggleIsLive(courseTemplate) {
            // isLive has been changed on the template object, but the whole template gets passed through the normal update flow
            await this.updateSelectedCourseTemplate(courseTemplate);
        },
        openCourseTemplateModalForEditing(courseTemplate) {
            this.isEditing = true;
            this.name = courseTemplate.name;
            this.description = courseTemplate.description;
            this.showImportCourseTemplateModal = true;
            this.selectedCourseTemplate = courseTemplate;
        },
        closeImportCourseTemplateModal() {
            this.showImportCourseTemplateModal = false;
            this.name = null;
            this.description = null;
            this.courseTemplateJSON = null;
            this.isEditing = false;
        },
        async updateSelectedCourseTemplate(courseTemplate) {
            try {
                this.courseTemplates = await this.updateCourseTemplate({
                    courseTemplateId: courseTemplate.id,
                    isLive: courseTemplate.isLive,
                    courseTemplateJSON: this.courseTemplateJSON,
                    professorId: this.professorId,
                    name: this.name,
                    description: this.description
                });

                if(this.professorId) {
                    this.courseTemplates = this.courseTemplates.filter(courseTemplate => !courseTemplate.isSystemTemplate);
                }

                this.successMessage = `Successfully update Course Template ${courseTemplate.name}`;
                this.closeImportCourseTemplateModal();

                setTimeout(() => {
                    this.successMessage = null;
                }, 3000);
            } catch(err) {
                if(err.graphQLErrors && err.graphQLErrors.length) {
					this.errorMessage = err.graphQLErrors[0].message;
				} else {
					this.errorMessage = "Something went wrong. Please try again";
				}
				return err;
            }
        }
    },
    async created() {
        this.courseTemplates = await this.fetchCourseTemplates({
            professorId: this.professorId
        });

        if(this.professorId) {
            this.courseTemplates = this.courseTemplates.filter(courseTemplate => !courseTemplate.isSystemTemplate);
        }
    }
}
</script>

<style scoped>
    .course-template-table {
        position: relative;
    }
    .custom-fab {
        position: absolute;
        right: 0;
        top: -17px;
        z-index: 10;
    }
    .course-template-table-cell {
        text-align: left;
    }
    .search-type {
        text-align: left;
    }
    .search-bar {
        max-width: 500px;
    }
    .alert-success {
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: var(--btn-primary-color);
        color: white;
        display: inline-block;
        padding-left: 20px;
        padding-right: 20px;
    }
</style>