<template>
    <div class="md-layout">
        <div class="md-layout-item"></div>
        <div class="md-layout-item md-large-size-50 md-medium-size-50 md-small-size-70 md-xsmall-size-80 main-box">
            <div class="text-center">
                <h1>Reset Password</h1>
                <div class="reset-password-form-container">
                    <div class="md-layout">
                        <div class="md-layout-item"></div>
                        <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-80">
                        <label>Password</label>
                        <md-input v-model="password" type="password"></md-input>
                        <span v-if="inputError === 'password'" class="error form-error">Please enter a valid password: must contain at least 1 uppercase character, 1 lowercase character, 1 number and be at least 6 characters in length</span>
                        </md-field>
                        <div class="md-layout-item"></div>
                    </div>
                    <div :class="{ 'retype-password-container' : inputError === 'password' }">
                        <div class="md-layout">
                            <div class="md-layout-item"></div>
                            <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-80">
                            <label>Confirm Password</label>
                            <md-input v-model="retypePassword" type="password"></md-input>
                            <span v-if="inputError === 'passwordNotMatch'" class="error form-error">Passwords must match</span>
                            </md-field>
                            <div class="md-layout-item"></div>
                        </div>
                    </div>
                    <div class="btn-group">
                        <md-button :disabled="httpWait" @click="resetPassword()" class="md-raised btn-primary" :class="{ 'btn-disabled' : httpWait }">
                            Submit
                            <md-progress-spinner v-if="httpWait" class="btn-spin" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                        </md-button>
                    </div>
                    <span class="error">{{serverError}}</span>
                </div>
            </div>
        </div>
        <div class="md-layout-item"></div>
    </div>
</template>

<script>
export default {
    name: 'Confirm',
    data() {
        return {
			email: null,
            password: null,
            retypePassword: null,
			confirmationCode: null,
            inputError: "",
			serverError: null,
			url: process.env.VUE_APP_LAMBDA_URL,
            httpWait: false
        }
    },
    methods: {
        async resetPassword() {
            this.httpWait = true;
            if(this.validateForm()) {
                const data = {
                    email: this.email,
                    password: this.password,
                    confirmationCode: this.confirmationCode
                };

                try {
                    const response = await fetch(`${this.url}/resetPassword`, {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'no-cache',
                        headers: {
                                'Content-Type': 'application/json',
                                'Access-Control-Request-Method': 'POST'
                        },
                        body: JSON.stringify(data)
                    });

                    const responseJson = await response.json();
                    let notifyMessage = "Successfully reset password.";
                    let emailForLogin = this.email;

                    if(!response.ok) {
                        notifyMessage = "Could not reset password. Please try again"
                        if(responseJson.code === 'ExpiredCodeException') {
                            notifyMessage = "Confimation code expired. Please trying again"
                        }
                    }

                    this.$router.push({ 
                        name: 'Login', 
                        params: { 
                            notifyMessage: notifyMessage,
                            email: emailForLogin
                        } 
                    });
                    this.httpWait = false;
                } catch(err) {
                    this.serverError = "Something went wrong. Please try again";
                    this.httpWait = false;
                    return err;
                }
            }
            this.httpWait = false;
        },
        validateForm() {
            if(!this.validatePassword()) {
                this.inputError = "password";
                return false;
            } else {
                if(this.password !== this.retypePassword) {
                    this.inputError = "passwordNotMatch";
                    return false;
                }
                this.inputError = "";
                return true;
            }
        },
        validatePassword() {
            const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9!@#$&%*\-<>=?|:;~(){}[\\-`.+,/"]{6,}$/;

            if(this.password && re.test(this.password)) {
                return true;
            } else {
                this.inputError = "password";
                return false;
            }
        }
    },
    async created() {
		this.email = this.$route.query.email;
		this.confirmationCode = this.$route.query.code;
    }
}

</script>

<style scoped>
    h1 {
        padding: 1em;
    }

    .main-box {
        background-color: var(--main-box-color);
        box-shadow: 5px 5px 3px var(--box-shadow-color);
        margin: 1em;
        min-height: 280px;
    }

    .text-center {
        text-align: center;
    }
    
    .btn-group {
        padding: 1em;
    }
  
    .error {
        color: var(--warning-text-color);
    }
  
    .form-error {
        display: block!important;
        left: 0;
        height: 20px;
        position: absolute;
        bottom: -22px;
        font-size: 12px;
        transition: .3s cubic-bezier(.4,0,.2,1);
    }

    .retype-password-container {
        padding-top: 50px;
    }
</style>