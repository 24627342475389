<template>
  <div class="fixed-assets-ledger">
    <h4>Fixed Assets Ledger</h4>
    <div v-if="getCurrentAssets">
      <div class="current-assets-table">
        <div class="md-layout border-bottom">
          <div class="md-layout-item flex-table-cell-fix border-right">Type</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Asset ID #</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Historical Cost</div>
          <div class="md-layout-item flex-table-cell-fix border-right" v-if="!(hideUsefulLife === true)">Useful Life</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Salvage Value</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Round Placed in Service</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Remaining Useful Life (as of Last Day of the Year)</div>
        </div>
        <div v-if="currentLands">
          <div v-for="currentLand in currentLands" :key="currentLand.id" class="md-layout border-bottom">
            <div class="md-layout-item flex-table-cell-fix border-right">Land</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentLand.friendlyId}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentLand.originalPurchasePrice | toCurrency}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right" v-if="!(hideUsefulLife === true)">n/a</div>
            <div class="md-layout-item flex-table-cell-fix border-right">n/a</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentLand.originalRoundPurchasedNum}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right">n/a</div>
          </div>
        </div>
        <div v-if="currentBuildings">
          <div v-for="currentBuilding in currentBuildings" :key="currentBuilding.id" class="md-layout border-bottom">
            <div class="md-layout-item flex-table-cell-fix border-right">Building</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentBuilding.friendlyId}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentBuilding.originalPurchasePrice | toCurrency}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right" v-if="!(hideUsefulLife === true)">{{currentBuilding.usefulLife}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right">$0</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentBuilding.originalRoundPurchasedNum}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentBuilding.remainingUsefulLife}}</div>
          </div>
        </div>
        <div v-if="currentEquipments">
          <div v-for="currentEquipment in currentEquipments" :key="currentEquipment.id" class="md-layout border-bottom">
            <div class="md-layout-item flex-table-cell-fix border-right">Equipment</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentEquipment.friendlyId}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentEquipment.originalPurchasePrice | toCurrency}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right" v-if="!(hideUsefulLife === true)">{{currentEquipment.usefulLife}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right">$0</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentEquipment.originalRoundPurchasedNum}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentEquipment.remainingUsefulLife}}</div>
          </div>
        </div>
        <div v-if="currentFurnishings && isFurnishingsEnabled()">
          <div v-for="currentFurnishing in currentFurnishings" :key="currentFurnishing.id" class="md-layout border-bottom">
            <div class="md-layout-item flex-table-cell-fix border-right">Store Furnishing</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentFurnishing.friendlyId}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentFurnishing.originalPurchasePrice | toCurrency}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right" v-if="!(hideUsefulLife === true)">{{currentFurnishing.usefulLife}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right">$0</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentFurnishing.originalRoundPurchasedNum}}</div>
            <div class="md-layout-item flex-table-cell-fix border-right">{{currentFurnishing.remainingUsefulLife}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="product-cell">
        <div class="cell-detail">N/A</div>
      </div>
    </div>
    <h4>Outstanding Loans</h4>
    <div v-if="currentDebts">
      <div class="current-assets-table">
        <div class="md-layout border-bottom">
          <div class="md-layout-item flex-table-cell-fix border-right">Type</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Loan ID #</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Principal Balance</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Loan Term (Years)</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Loan Origination Date</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Remaining Loan Terms (Years)</div>
        </div>
        <div v-for="currentDebt in currentDebts" :key="currentDebt.id" class="md-layout border-bottom">
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentDebt.name !== 'Junk Loan' ? 'Regular' : currentDebt.name}}</div>
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentDebt.friendlyId}}</div>
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentDebt.outstandingPrincipal | toCurrency}}</div>
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentDebt.originalTerm}}</div>
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentDebt.originalRoundBorrowedNum}}</div>
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentDebt.originalTerm - currentDebt.originalRoundBorrowedNum}}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="product-cell">
        <div class="cell-detail">N/A</div>
      </div>
    </div>
    <h4>Bonds Payable</h4>
    <div v-if="currentBonds">
      <div class="current-assets-table">
        <div class="md-layout border-bottom">
          <div class="md-layout-item flex-table-cell-fix border-right">Bond ID</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Nominal Amount</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Quantity Issued</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Issue Round</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Term (Years)</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Nominal Interest Rate</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Market Rate at Issue</div>
          <div class="md-layout-item flex-table-cell-fix border-right">Unamortized Premium or Discount</div>
        </div>
        <div v-for="currentBond in currentBonds" :key="currentBond.id" class="md-layout border-bottom">
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentBond.friendlyId}}</div>
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentBond.nominalAmount | toCurrency}}</div>
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentBond.quantityIssued}}</div>
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentBond.roundIssued}}</div>
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentBond.term}}</div>
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentBond.nominalInterestRate}}</div>
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentBond.marketRate}}</div>
          <div class="md-layout-item flex-table-cell-fix border-right">{{currentBond.unamortizedPremiumOrDiscount | toCurrency}}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="product-cell">
        <div class="cell-detail">N/A</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "FixedAssetsLedger",
  data () {
    return {
      currentLands: null,
      currentBuildings: null,
      currentEquipments: null,
      currentFurnishings: null,
      currentDebts: null,
      currentBonds: null
    }
  },
  methods: {
    initCurrentAssetsTable() {
      this.currentLands = this.getCurrentAssets.lands.filter(land => {
        return land.isSold === false;
      }).sort((a, b) => a.originalRoundPurchasedNum - b.originalRoundPurchasedNum);

      this.currentBuildings = this.getCurrentAssets.buildings.filter(building => {
        return building.isSold === false;
      }).sort((a, b) => a.originalRoundPurchasedNum - b.originalRoundPurchasedNum);

      this.currentEquipments = this.getCurrentAssets.equipments.filter(equipment => {
        return equipment.isSold === false;
      }).sort((a, b) => a.originalRoundPurchasedNum - b.originalRoundPurchasedNum);

      this.currentFurnishings = this.getCurrentAssets.storeFurnishings.filter(furnishing => {
        return furnishing.isSold === false;
      }).sort((a, b) => a.originalRoundPurchasedNum - b.originalRoundPurchasedNum);

      this.currentDebts = this.getCurrentAssets.debts.filter(debt => {
        return debt.outstandingPrincipal !== 0;
      }).sort((a, b) => a.originalRoundBorrowedNum - b.originalRoundBorrowedNum);

      this.currentBonds = JSON.parse(JSON.stringify(this.getCurrentAssets)).bonds.sort((a, b) => a.roundIssued - b.roundIssued);
    },
    isFurnishingsEnabled() {
      const courseRounds = this.getCurrentCourse.rounds;
      return courseRounds.reduce((result, round) => {
        if (round.storeFurnishings.length > 0) {
          return true;
        }
        return result;
      }, false);
    },
  },
  watch: {
    getCurrentAssets(val) {
      if(val) {
        this.initCurrentAssetsTable();
      }
    }
  },
  props: {
    hideUsefulLife: {
      type: Boolean
    }
  },
  created() {
    this.initCurrentAssetsTable();
  },
  computed: {
    ...mapGetters([
      'getCurrentAssets',
      'getCurrentCourse',
    ]),
  }
}
</script>

<style scoped>

.border-right {
  border-right: 1px solid black;
}

.border-bottom {
  border-bottom: 1px solid black;
}

.current-assets-table {
  max-width: 700px;
  border-top: solid 1px black;
  border-left: solid 1px black;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
}

.flex-table-cell-fix {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}

</style>
