<template>
  <div class="md-layout">
    <div class="msBlock">
      <div class="wrapper" v-if="localAssets && localAssets.length > 0">
        <md-card v-for="asset in unsoldAssets" v-bind:key="asset.id" :class="{ 'selected-border' : isAssetLocallySold(asset.id) }">
          <div class="friendly-id">
            Asset ID: #{{asset.friendlyId}}
          </div>
          <div v-if="asset.expiringRoundId && asset.expiringRoundId === activeRoundId">
            <md-icon class="fa fa-exclamation-circle"></md-icon>
            This asset is expiring at the end of this round. After the round ends, this asset will be disposed. You can choose to sell now or use it to produce products for this round.
          </div>
          <md-card-header>
            <div class="md-title">{{asset.name}}</div>
          </md-card-header>
          <md-card-content>
            <div class="left-text">
              <div class="election-config-label">Original Purchase Price</div>
              <div class="election-config-value">{{asset.originalPurchasePrice | toCurrency}}</div>
            </div>
            <div class="left-text">
              <div class="election-config-label">Sale Price</div>
              <div class="election-config-value">{{asset.salePrice | toCurrency}}</div>
            </div>
            <div class="left-text">
              <div class="election-config-label">Total Capacity</div>
              <div class="election-config-value">{{asset.totalCapacity}} {{childAssetName}}</div>
            </div>
            <div class="left-text" v-if="assetName === 'Building' || assetName === 'Equipment'">
              <div class="election-config-label">Remaining Useful Life</div>
              <div class="election-config-value">{{asset.remainingUsefulLife}} Rounds</div>
            </div>
            <div>
              <md-button v-if="!isAssetLocallySold(asset.id)" @click="sellAsset(asset.id)" class="md-raised btn-primary">Sell</md-button>
              <md-button v-if="isAssetLocallySold(asset.id)" @click="cancelAssetSale(asset.id)" class="md-raised btn-warning">Cancel Sale</md-button>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { MdCheckbox } from 'vue-material/dist/components'
export default {
  name: 'MultiSelectAsset',
  data() {
    return {
      attrs: {
        currentSaleElections: [],
        localAssets: [],
        selectedAssetIds: []
      },
    }
  },
  computed: {
    currentSaleElections: {
      get() {
        return this.attrs.currentSaleElections;
      },
      set(value) {
        this.attrs.currentSaleElections = value;
        this.$emit(`update:sale-elections`, value);
      }
    },
    localAssets: {
      get() {
        return this.attrs.localAssets;
      },
      set(value) {
        this.attrs.localAssets = value;
        this.$emit(`input`, value);
      }
    },
    unsoldAssets: {
      get() {
        return this.localAssets.filter((asset) => !asset.isSold);
      }
    },
    selectedAssetIds: {
      get() {
        return this.attrs.selectedAssetIds;
      },
      set(value) {
        this.attrs.selectedAssetIds = value;
        this.$emit(`update:selected-assets`, value);
        this.$emit('assets-changed');
      }
    },
    assetIdName: {
      get() {
        if(this.assetName === 'Land') {
          return 'landAssetId';
        }
        if(this.assetName === 'Building') {
          return 'buildingAssetId';
        }
        if(this.assetName === 'Equipment') {
          return 'equipmentAssetId';
        }
        if(this.assetName === 'StoreFurnishing') {
          return 'furnishingAssetId';
        }
        return '';
      }
    },
    childAssetName: {
      get() {
        if(this.assetName === 'Land') {
          return 'Buildings';
        }
        if(this.assetName === 'Building') {
          return 'Equipments / Furnishings';
        }
        return '';
      }
    },
  },
  watch: {
    selectedAssetIds(newVal, oldVal) {
      if(this.localAssets) {
        this.currentSaleElections = newVal.map((selectedAssetId) => {
          const asset = this.localAssets.find((currentAsset) => currentAsset.id === selectedAssetId);
          let election = { salePrice: asset.salePrice, originalPurchasePrice: asset.originalPurchasePrice };
          if(this.assetName === 'Land') {
            election.landAssetId = asset.id;
          }
          if(this.assetName === 'Building') {
            election.buildingAssetId = asset.id;
          }
          if(this.assetName === 'Equipment') {
            election.equipmentAssetId = asset.id;
          }
          if(this.assetName === 'StoreFurnishing') {
            election.furnishingAssetId = asset.id;
          }
          return election;
        });

        this.$emit(`update:selected-assets`, this.attrs.selectedAssetIds);
        this.$emit('assets-changed');
      }
    },
  },
  created() {
    this.attrs.localAssets = this.value;
    this.attrs.selectedAssetIds = this.selectedAssets;
    this.initSaleElections(this.saleElections);
  },
  methods: {
    isAssetLocallySold(assetId) {
      return this.selectedAssetIds.indexOf(assetId) >= 0;
    },
    sellAsset(assetId) {
      if(this.selectedAssetIds.indexOf(assetId) === -1) {
        this.selectedAssetIds.push(assetId);
      }
    },
    cancelAssetSale(assetId) {
      const assetIndex = this.selectedAssetIds.indexOf(assetId);
      if(assetIndex >= 0) {
        this.selectedAssetIds.splice(assetIndex, 1);
      }
    },
    initSaleElections(elections) {
      this.currentSaleElections = elections;
      this.selectedAssetIds = this.currentSaleElections.map((saleElection) => saleElection[this.assetIdName]);
    }
  },
  props: {
    value: {
      type: Array
    },
    assetName: {
      type: String
    },
    saleElections: {
      type: Array
    },
    selectedAssets: {
      type: Array
    },
    activeRoundId: {
      type: String
    }
  }
}
Vue.use(MdCheckbox);
</script>

<style scoped>
  .md-layout-item {
    padding: 10px;
  }

  .wrapper {
    background-color: var(--main-box-color);;
  }

  .md-button {
    margin: 1.5em;
  }

  .md-card {
    width: 300px;
    margin: 20px;
    display: inline-block;
    vertical-align: top;
  }

  .disabled {
    background-color: rgba(245, 245, 245, 1);
    opacity: .4;
    pointer-events: none;
  }

  .msBlock {
    margin-left: auto;
    margin-right: auto;
    min-width: 98%;
    max-width: 98%;
    padding: 0;
  }

  .friendly-id {
      padding-right: 10px;
      text-align: right;
      padding-top: 5px;
  }

  .left-text {
    text-align: left;
  }

  .election-config-label {
    display: inline-block;
  }

  .election-config-value {
    display: inline-block;
    font-weight: bold;
    margin-left: 15px;
  }
</style>
