<template>
    <div class="game-countdown">
        <div v-if="!hideUi">
            <ul class="vuejs-countdown">
                <li v-if="days > 0 && !hideBigUnits">
                    <p class="digit">{{ days | twoDigits }}</p>
                    <p v-if="!hideLabels" class="text">{{ days > 1 ? 'days' : 'day' }}</p>
                </li>
                <li v-if="!hideBigUnits">
                    <p class="digit">{{ hours | twoDigits }}</p>
                    <p v-if="!hideLabels" class="text">{{ hours > 1 ? 'hours' : 'hour' }}</p>
                </li>
                <li>
                    <p class="digit">{{ minutes | twoDigits }}</p>
                    <p v-if="!hideLabels" class="text">min</p>
                </li>
                <li>
                    <p class="digit">{{ seconds | twoDigits }}</p>
                    <p v-if="!hideLabels" class="text">Sec</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { DateTime } from "luxon";
    export default {
        name: 'Countdown',
        props: {
            deadline: {
                type: Number
            },
            stop: {
                type: Boolean
            },
            hideUi: {
                type: Boolean
            },
            hideBigUnits: {
                type: Boolean
            },
            hideLabels: {
                type: Boolean
            },
            countdownId: {
                type: String
            }
        },
        data() {
            return {
                now: DateTime.local().toSeconds(),
                date: null,
                diff: 0,
                interval: null
            }
        },
        created() {
            this.startCountdown();
        },
        methods: {
          startCountdown() {
              if(this.deadline){
                  clearInterval(this.interval);
                  this.date = Math.trunc(this.deadline);
                  this.interval = setInterval(() => {
                      this.now = DateTime.local().toSeconds();
                  }, 1000);
              }
          }
        },
        computed: {
            seconds() {
                return Math.trunc(this.diff) % 60
            },
            minutes() {
                return Math.trunc(this.diff / 60) % 60
            },
            hours() {
                return Math.trunc(this.diff / 60 / 60) % 24
            },
            days() {
                return Math.trunc(this.diff / 60 / 60 / 24)
            },
        },
        watch: {
            now(value) {
                this.diff = this.date - this.now;
                if(this.diff <= 0) {
                    this.$emit('ended')
                }
                if(this.diff <= 0 || this.stop){
                    this.diff = 0;
                    // Remove interval
                    clearInterval(this.interval);
                }
            },
            deadline(newVal, oldVal) {
                this.startCountdown();
            }
        },
        filters: {
            twoDigits(value) {
                if ( value.toString().length <= 1 ) {
                    return '0'+value.toString()
                }
                return value.toString()
            }
        },
        destroyed() {
            clearInterval(this.interval);
        }
    }
</script>
<style>
    .vuejs-countdown {
        padding: 0;
        margin: 0;
    }
    .vuejs-countdown li {
        display: inline-block;
        margin: 0 8px;
        text-align: center;
        position: relative;
    }
    .vuejs-countdown li p {
        margin: 0;
    }
    .vuejs-countdown li:after {
        content: ":";
        position: absolute;
        top: 0;
        right: -11px;
        font-size: 14px;
    }
    .vuejs-countdown li:first-of-type {
        margin-left: 0;
    }
    .vuejs-countdown li:last-of-type {
        margin-right: 0;
    }
    .vuejs-countdown li:last-of-type:after {
        content: "";
    }
    .vuejs-countdown .digit {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.4;
        margin-bottom: 0;
    }
    .vuejs-countdown .text {
        text-transform: uppercase;
        margin-bottom: 0;
        font-size: 10px;
    }
</style>
