<template>
    <div class="journal-entry-table">
        <div>{{journalEntry.problemText}}</div>
        <div class="md-layout journal-entry-header">
            <div class="md-layout-item text-left">{{journalEntry.journalEntryTypeFriendlyName}}</div>
        </div>
        <div class="md-layout journal-entry-content">
            <div class="md-layout-item text-left md-size-15">Team Answers</div>
            <div class="md-layout-item text-left md-size-15">Correct Answers</div>
            <div class="md-layout-item md-size-30">Team Answers</div>
            <div class="md-layout-item md-size-30">Correct Answers</div>
        </div>
        <div class="md-layout journal-entry-content">
            <div class="md-layout-item text-left md-size-30 bold">Account</div>
            <div class="md-layout-item md-size-15 bold">Dr</div>
            <div class="md-layout-item md-size-15 bold">Cr</div>
            <div class="md-layout-item md-size-15 bold">Dr</div>
            <div class="md-layout-item md-size-15 bold">Cr</div>
            <div class="md-layout-item md-size-10 bold">Grade</div>
        </div>
        <div class="md-layout journal-entry-content" v-for="(debitOrCredit, index) in journalEntry.debitsOrCredits" :key="debitOrCredit.accountEnum">
            <div class="md-layout-item text-left md-size-30">
                <div class="md-layout">
                    <div class="md-layout-item" v-bind:class="{'credit-account': journalEntry.correctDebitsOrCredits[index].balanceType === 'CREDIT'}">{{debitOrCredit.accountFriendlyName}}</div>
                    <div class="md-layout-item" v-bind:class="{'incorrect-answer': journalEntry.incorrectDebitsOrCredits[index].props.includes('accountEnum'), 'correct-answer': !journalEntry.incorrectDebitsOrCredits[index].props.includes('accountEnum'),  'credit-account': debitOrCredit.balanceType === 'CREDIT'}">{{journalEntry.correctDebitsOrCredits[index].accountFriendlyName}}</div>
                </div>
            </div>
            <div class="md-layout-item md-size-15">
                <div v-if="debitOrCredit.balanceType === 'DEBIT'">{{debitOrCredit.value | toCurrency}}</div>
            </div>
            <div class="md-layout-item md-size-15">
                <div v-if="debitOrCredit.balanceType === 'CREDIT'">{{debitOrCredit.value | toCurrency}}</div>
            </div>
            <div class="md-layout-item md-size-15">
                <div v-if="journalEntry.correctDebitsOrCredits[index].balanceType === 'DEBIT'" :class="[journalEntry.incorrectDebitsOrCredits[index].props.includes('value') ? 'incorrect-answer' : 'correct-answer']">{{journalEntry.correctDebitsOrCredits[index].value | toCurrency}}</div>
            </div>
            <div class="md-layout-item md-size-15">
                <div v-if="journalEntry.correctDebitsOrCredits[index].balanceType === 'CREDIT'" :class="[journalEntry.incorrectDebitsOrCredits[index].props.includes('value') ? 'incorrect-answer' : 'correct-answer']">{{journalEntry.correctDebitsOrCredits[index].value | toCurrency}}</div>
            </div>
            <div v-if="index === 0" class="md-layout-item md-size-10">
                {{submissionGrade}}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'GradedJournalEntry',
    data() {
        return {
            submissionGrade: ""
        }
    },
    props: {
        journalEntry: {
            type: Object
        }
    },
    created() {
        const numTotal = this.journalEntry.correctDebitsOrCredits.length * 2;
        const numIncorrect = this.journalEntry.incorrectDebitsOrCredits.reduce((total, incorrectDebitOrCredit) => {
            total += incorrectDebitOrCredit.props.length;
            return total;
        }, 0);
        const numCorrect = numTotal - numIncorrect;
        this.submissionGrade = `${numCorrect}/${numTotal}`;
    }
}
</script>

<style scoped>
    .text-left {
        text-align: left;
    }

    .bold {
        font-weight: bold;
    }

    .incorrect-answer {
        color: var(--warning-text-color);
    }

    .correct-answer {
        color: var(--success-bg-color);
    }

    .journal-entry-table {
        border: solid 1px black;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--table-cell-color);
    }

    .journal-entry-header {
        background-color: var(--table-title-bg-color);
        color: white;
        padding-left: 10px;
        padding-right: 10px;
    }

    .journal-entry-content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .credit-account {
      padding-left: 50px;
    }
</style>
