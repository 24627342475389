import Vue from 'vue';
import Vuex from 'vuex';
import admin from './modules/admin'
import user from './modules/user'
import semester from './modules/semester';
import course from './modules/course';
import game from './modules/game';
import report from './modules/report';

Vue.use(Vuex);

const actions = {
  resetVuexState({ commit }: any) {
    commit('resetAdminState');
    commit('resetCourseState');
    commit('resetGameState');
    commit('resetReportState');
    commit('resetSemesterState');
    commit('resetUserState');
  },
};

export const store = new Vuex.Store({
  strict: true,
  modules: {
    admin,
    user,
    semester,
    course,
    game,
    report
  },
  actions
});
