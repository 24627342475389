<template>
    <div class="md-layout">
        <div class="landBlock" v-if="getCurrentRoundConfig.lands && getCurrentRoundConfig.lands.length > 0 && currentLandPurchaseElections">
            <div class="wrapper">
                <md-card v-for="(landConfig, landIndex) in getCurrentRoundConfig.lands" v-bind:key="landConfig.id" :class="{ 'selected-border' : isLandLocallyPurchased(landConfig.id) }">
                    <div class="mini-id">
                        Asset ID: #{{landConfig.miniId}}
                    </div>
                    <md-card-header>
                        <div class="md-title">Land Choice {{landIndex + 1}}</div>
                    </md-card-header>
                    <md-card-content>
                        <div class="left-text cost">
                            <div class="election-config-label">Cost:</div>
                            <div class="election-config-value">{{landConfig.purchaseCost | toCurrency}}</div>
                        </div>
                        <div class="left-text">
                            <div class="election-config-label">Capacity</div>
                            <div class="election-config-value">{{landConfig.buildingCapacity}} Buildings</div>
                        </div>
                        <div>
                            <md-button v-if="!isLandLocallyPurchased(landConfig.id)" @click="purchaseLand(landConfig.id)" class="md-raised btn-primary">Purchase</md-button>
                            <md-button v-if="isLandLocallyPurchased(landConfig.id)" @click="cancelLandSale(landConfig.id)" class="md-raised btn-warning">Cancel Purchase</md-button>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import {
    MdCard,
    MdField,
    MdIcon
} from 'vue-material/dist/components'
import { mapGetters } from 'vuex'
export default {
    name: 'Land',
    data() {
        return {
            attrs: {
                currentLandPurchaseElections: this.landPurchaseElections,
                purchasedLands: []
            }
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentRoundConfig'
        ]),
        currentLandPurchaseElections: {
            get() {
                return this.attrs.currentLandPurchaseElections;
            },
            set(value) {
                this.attrs.currentLandPurchaseElections = value;
                this.$emit(`update:land-purchase-elections`, value);
            },
        },
        purchasedLands: {
            get() {
                return this.attrs.purchasedLands;
            },
            set(value) {
                this.attrs.purchasedLands = value;
            },
        },
    },
    watch: {
        purchasedLands(newVal, oldVal) {
            this.currentLandPurchaseElections = newVal.map((landConfigId) => {
                const roundConfig = this.getCurrentRoundConfig;
                const landConfig = roundConfig.lands.find((_landConfig) => _landConfig.id === landConfigId);
                return {
                    landConfigId,
                    purchasePrice: landConfig.purchaseCost,
                    capacity: landConfig.buildingCapacity,
                };
            });
        },
    },
    props: {
        landPurchaseElections: {
            type: Array,
        },
    },
    methods: {
        isLandLocallyPurchased(landConfigId) {
            return this.purchasedLands.indexOf(landConfigId) >= 0;
        },
        purchaseLand(landConfigId) {
            if(this.purchasedLands.indexOf(landConfigId) === -1) {
                this.purchasedLands.push(landConfigId);
            }
        },
        cancelLandSale(landConfigId) {
            const landIndex = this.purchasedLands.indexOf(landConfigId);
            if(landIndex >= 0) {
                this.purchasedLands.splice(landIndex, 1);
            }
        }
    },
    created() {
        this.currentLandPurchaseElections = this.landPurchaseElections;
        if(!this.currentLandPurchaseElections) {
            this.currentLandPurchaseElections = [];
        }
        for(let landPurchaseElection of this.currentLandPurchaseElections){
            const landConfigId = landPurchaseElection.landConfigId;
            this.purchasedLands.push(landConfigId);
        }
    }
}

Vue.use(MdCard);
Vue.use(MdField);
Vue.use(MdIcon);
</script>

<style scoped>
    .md-layout-item {
        padding: 10px;
    }

    .wrapper {
        background-color: var(--main-box-color);
    }

    .inputWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .md-layout-item {
        padding: 10px;
    }

    .landBlock {
        margin-left: auto;
        margin-right: auto;
        min-width: 98%;
        max-width: 98%;
        padding: 0;
    }

    .md-card {
        width: 300px;
        margin: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .md-button {
        margin: 1.5em;
    }

    .left-text {
        text-align: left;
    }

    .election-config-label {
        display: inline-block;
    }

    .election-config-value {
        display: inline-block;
        font-weight: bold;
        margin-left: 15px;
    }

    .cost {
        font-weight: bold;
        font-size: 20px;
    }

    .ribbon-container {
        color: var(--inner-box-color);
    }
    .ribbon-container:nth-child(even) {
        margin-right: 4%;
    }
    @media (max-width: 500px) {
        .ribbon-container {
            width: 100%;
        }
        .ribbon-container:nth-child(even) {
            margin-right: 0%;
        }
    }
</style>
