<template>
    <div class="bottom-nav-container" ref="bottom-nav">
        <div class="bottom-tabs">
            <div v-for="tab in tabsToDisplay" v-bind:key="tab.id">
                <div class="tab-item-container" ref="tab-container">
                    <div class="tab-item link" :class="{ 'tab-active' : componentToShow === tab.componentName }" @click="showComponent(tab.componentName)">
                        {{tab.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdButton } from 'vue-material/dist/components'

Vue.use(MdButton);

export default {
    name: 'BottomNav',
    data() {
        return {
        }
    },
    props: {
        componentToShow: {
            type: String
        },
        tabsToDisplay: {
            type: Array
        }
    },
    watch: {
        componentToShow(val) {
            if(val) {
                this.initBottomTabs();
            }
        }
    },
    methods: {
        initBottomTabs() {
            const tabWidth = 100/this.tabsToDisplay.length;
            this.$nextTick(() => {
                if(this.$refs['tab-container'] && this.$refs['tab-container'].length){
                    for(let el of this.$refs['tab-container']) {
                        el.style.width = tabWidth + "vw";
                    }
                }
            });

        },
        showComponent(componentName) {
            this.$emit(`update:component-to-show`, componentName);
        }
    },
    created() {
        this.initBottomTabs();
    }
}
</script>

<style scoped>
    .bottom-nav-container {
        position: fixed;
        bottom: 0px;
        background-color: var(--nav-bar-color);
        height: 64px;
        width: 100%;
        z-index: 10;
    }

    .bottom-tabs {
        color: var(--text-secondary);
        display: flex;
        flex-wrap: wrap;
    }

    .tab-item-container {
        height: 64px;
        line-height: 64px;
        overflow: hidden;
    }

    .tab-item {
        height: 100%;
    }

    .tab-active {
        background-color: var(--team-menu-color);
        cursor: default !important;
        color: black;
    }
</style>