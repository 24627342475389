<template>
    <div class="md-layout">
        <div class="stockBlock">
            <div class="wrapper">
                <md-card :class="{ 'selected-border' : isStockLocallySold }">
                    <md-card-header>
                        <div class="md-title">Sell Common Stock</div>
                    </md-card-header>
                    <md-card-content>
                        <div class="total-cap-label">
                          Your company has been authorized to issue up to {{ totalSellableSharesPerTeam.toLocaleString('en', {useGrouping:true}) }} shares throughout the game.
                        </div>
                        <div v-if="!isStockLocallySold" class="inputWrapper md-gutter">
                            <md-field>
                                <label>Number of shares to sell this round</label>
                                <md-input 
                                    v-model="numStocksOffered" 
                                    @keypress="preventNegativeAndDecimal($event)"
                                    @blur="numStockEnteredBlur()"
                                    @click="selectNumOffered()"
                                    ref="numStocksOfferedInput" 
                                    type="text"
                                    pattern="\d*">
                                </md-input>
                            </md-field>
                        </div>
                        <div v-else class="left-text">
                            <div class="election-config-label">Shares to sell this round</div>
                            <div class="election-config-value">{{ numStocksOffered }}</div>
                        </div>
                        <div class="left-text">
                            <div class="election-config-label">Current Team Stock Price</div>
                            <div class="election-config-value">{{ beginningOfRoundStockPrice | toCurrency }}</div>
                        </div>
                        <div class="left-text">
                            <div class="election-config-label">Shares Issued and Outstanding</div>
                            <div class="election-config-value">{{ numSharesSold.toLocaleString('en', {useGrouping:true}) }}</div>
                        </div>
                        <div class="left-text">
                            <div class="election-config-label">Total Shares Authorized</div>
                            <div class="election-config-value">{{ totalSellableSharesPerTeam.toLocaleString('en', {useGrouping:true}) }}</div>
                        </div>
                        <div class="left-text" v-if="isStockLocallySold">
                            <div class="election-config-label">Proceeds this Round</div>
                            <div class="election-config-value">{{ proceeds | toCurrency }}</div>
                        </div>
                        <div>
                            <div class="warning" v-if="warning">{{warning}}</div>
                            <md-button v-if="!isStockLocallySold" @click="sellStock()" class="md-raised btn-primary">Sell</md-button>
                            <md-button v-if="isStockLocallySold" @click="cancelStockSale()" class="md-raised btn-warning">Cancel Stock Sale</md-button>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import {
    MdCard,
    MdField,
    MdIcon
} from 'vue-material/dist/components'
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'Stock',
    data() {
        return {
            attrs: {
                currentStockSaleElections: this.stockSaleElections,
            },
            currentStockPrice: null,
            initialOfferingNumShares: null,
            totalSellableSharesPerTeam: null,
            numSharesSold: null,
            numStocksOffered: 0,
            proceeds: 0,
            warning: null,
            beginningOfRoundStockPrice: null,
            previouslySoldStocks: 0
        }
    },
    computed: {
        ...mapGetters([
            'getTeam',
            'getCurrentCourse',
            'getCurrentAssets'
        ]),
        currentStockSaleElections: {
            get() {
                return this.attrs.currentStockSaleElections;
            },
            set(value) {
                this.attrs.currentStockSaleElections = value;
                this.$emit(`update:stock-sale-elections`, value);
            },
        },
        isStockLocallySold() {
            return this.currentStockSaleElections.length > 0;
        }
    },
    watch: {
        async activeRoundId() {
          await this.getStockInfo();
        }
    },
    methods: {
        ...mapActions([
            'fetchBeginningOfRoundStockPrice'
        ]),
        cancelStockSale() {
            const parsedNumStocksOffered = parseInt(this.numStocksOffered);
            this.proceeds = 0;
            this.numSharesSold -= parsedNumStocksOffered;
            this.currentStockSaleElections = [];
        },
        preventNegativeAndDecimal(event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if(keyCode < 48 || keyCode > 57) {
              event.preventDefault();
            }
        },
        selectNumOffered() {
            const input = this.$refs['numStocksOfferedInput'].$el;
            input.focus();
            input.select();
        },
        sellStock() {
            const parsedNumStocksOffered = parseInt(this.numStocksOffered);
            this.warning = null;

            if(parsedNumStocksOffered > (this.totalSellableSharesPerTeam - this.previouslySoldStocks)) {
                this.warning = "Stock shares amount to sell cannot exceed authorized amount of total stocks available sell";
                return;
            }
            if(parsedNumStocksOffered <= 0) {
                this.warning = "Stock shares amount to sell must be greater than 0";
                return;
            }
            this.proceeds = parsedNumStocksOffered * this.beginningOfRoundStockPrice;
            this.numSharesSold += parsedNumStocksOffered;

            this.currentStockSaleElections.push({
                quantityOffered: parseInt(this.numStocksOffered),
                stockPrice: this.beginningOfRoundStockPrice
            });
        },
        async getStockInfo() {
            const gameConfig = this.getTeam.semester.gameConfig;
            const courseRounds = this.getCurrentCourse.rounds;
            const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
            this.teamStartingStockPrice = gameConfig.stockConfig.teamStartingStockPrice;
            this.initialOfferingNumShares = gameConfig.stockConfig.initialOfferingNumShares;
            this.totalSellableSharesPerTeam = gameConfig.stockConfig.totalSellableSharesPerTeam;
            this.isStockTradingEnabled = courseRounds[roundIndex].isStockTradingEnabled;
            this.numSharesSold = roundIndex === 0 ? this.initialOfferingNumShares : 0;
            this.previouslySoldStocks = roundIndex === 0 ? this.initialOfferingNumShares : 0;

            this.beginningOfRoundStockPrice = await this.fetchBeginningOfRoundStockPrice({
                roundConfigId: this.activeRoundId,
                teamId: this.$route.params.teamId
            });

            const previousRoundsStockSales = this.getCurrentAssets.stocks;
            const totalPreviousSales = previousRoundsStockSales.reduce((result, stockSale) => {
                return result += stockSale.quantityOffered;
            }, 0);
            // these are the same when initialized.. but different once users start locally electing, numSharesSold gets mutated
            this.numSharesSold += totalPreviousSales;
            this.previouslySoldStocks += totalPreviousSales;
        },
        numStockEnteredBlur() {
            const parsedNumStocksOffered = parseInt(this.numStocksOffered);
            if(isNaN(parsedNumStocksOffered) || !Number.isInteger(parsedNumStocksOffered)) {
                this.numStocksOffered = 0;
                return;
            }
        }
    },
    props: {
        stockSaleElections: {
            type: Array
        },
        activeRoundId: {
            type: String
        }
    },
    async created() {
        await this.getStockInfo();
        if(this.stockSaleElections.length > 0) {
            this.numStocksOffered = this.stockSaleElections[0].quantityOffered;
        }
    }
}

Vue.use(MdCard);
Vue.use(MdField);
Vue.use(MdIcon);
</script>

<style scoped>
    .md-layout-item {
        padding: 10px;
    }

    .wrapper {
        background-color: var(--main-box-color);
    }

    .md-layout-item {
        padding: 10px;
    }

    .stockBlock {
        margin-left: auto;
        margin-right: auto;
        min-width: 98%;
        max-width: 98%;
        padding: 0;
    }

    .md-card {
        width: 300px;
        margin: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .md-button {
        margin: 1.5em;
    }

    .left-text {
        text-align: left;
    }

    .election-config-label {
        display: inline-block;
    }

    .election-config-value {
        display: inline-block;
        font-weight: bold;
        margin-left: 15px;
    }

    .warning {
        color: var(--btn-warning-color);
        margin-top: 15px;
    }

    .total-cap-label {
        margin-bottom: 16px;
    }
</style>
