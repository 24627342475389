<template>
    <div class="md-layout">
        <div class="equipmentBlock" v-if="getCurrentRoundConfig.equipments && getCurrentRoundConfig.equipments.length > 0 && currentEquipmentPurchaseElections">
            <div class="wrapper">
                <span v-for="equipmentConfig in getCurrentRoundConfig.equipments" v-bind:key="equipmentConfig.id">
                    <md-card v-for="(equipmentTypeConfig, typeIndex) in equipmentConfig.typesConfig" v-bind:key="equipmentTypeConfig.equipmentType" :class="{ 'selected-border' : isEquipmentLocallyPurchased(equipmentConfig.id, typeIndex) }">
                        <div class="mini-id">
                            Asset ID: #{{equipmentTypeConfig.miniId}}
                        </div>
                        <md-card-header>
                            <div class="md-title">Equipment Condition: {{equipmentTypeConfig.equipmentType}}</div>
                        </md-card-header>
                        <md-card-content>
                            <div class="left-text cost">
                                <div class="election-config-label">Cost:</div>
                                <div class="election-config-value">{{equipmentTypeConfig.purchaseCost | toCurrency}}</div>
                            </div>
                            <div class="left-text">
                                <div class="election-config-label">Useful Life</div>
                                <div class="election-config-value">{{equipmentTypeConfig.usefulLife}} rounds</div>
                            </div>
                            <div class="left-text">
                                <div class="election-config-label">Salvage Value</div>
                                <div class="election-config-value">$0</div>
                            </div>
                            <div class="left-text">
                                <div class="election-config-label">Production Capacity</div>
                                <div class="election-config-value">{{equipmentTypeConfig.productionCapacity}} units</div>
                            </div>
                            <md-field class="depreciation-field" v-if="!isEquipmentLocallyPurchased(equipmentConfig.id, typeIndex)">
                                <label>Depreciation Method</label>
                                <md-select v-model="selectedDepreciationMethods[equipmentConfig.id][typeIndex]">
                                    <md-option v-for="depreciationMethod in equipmentTypeConfig.depreciationMethods" v-bind:value="depreciationMethod" v-bind:key="depreciationMethod">
                                        {{ depreciationMethod }}
                                    </md-option>
                                </md-select>
                            </md-field>
                            <div v-if="isEquipmentLocallyPurchased(equipmentConfig.id, typeIndex)">
                                <div class="left-text">
                                    <div class="election-config-label">Depreciation Method</div>
                                    <div class="election-config-value">{{selectedDepreciationMethods[equipmentConfig.id][typeIndex]}}</div>
                                </div>
                            </div>
                            <div>
                                <md-button v-if="!isEquipmentLocallyPurchased(equipmentConfig.id, typeIndex)" @click="purchaseEquipment(equipmentConfig.id, typeIndex)" class="md-raised btn-primary">Purchase</md-button>
                                <md-button v-if="isEquipmentLocallyPurchased(equipmentConfig.id, typeIndex)" @click="cancelEquipmentSale(equipmentConfig.id, typeIndex)" class="md-raised btn-warning">Cancel Purchase</md-button>
                            </div>
                        </md-card-content>
                    </md-card>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import {
    MdCard,
    MdField,
    MdIcon
} from 'vue-material/dist/components'
import { mapGetters } from 'vuex'
export default {
    name: 'Equipment',
    data() {
        return {
            attrs: {
                currentEquipmentPurchaseElections: this.equipmentPurchaseElections,
                purchasedEquipments: []
            },
            selectedDepreciationMethods: {}
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentRoundConfig',
            'getEquipmentTypeOptions',
            'getCurrentRoundElections'
        ]),
        currentEquipmentPurchaseElections: {
            get() {
                return this.attrs.currentEquipmentPurchaseElections;
            },
            set(value) {
                this.attrs.currentEquipmentPurchaseElections = value;
                this.$emit(`update:equipment-purchase-elections`, value);
            },
        },
        purchasedEquipments: {
            get() {
                return this.attrs.purchasedEquipments;
            },
            set(value) {
                this.attrs.purchasedEquipments = value;
            },
        },
    },
    watch: {
        purchasedEquipments(newVal, oldVal) {
            this.currentEquipmentPurchaseElections = newVal.map((purchasedEquipment) => {
                const equipmentConfigId = purchasedEquipment.equipmentConfigId;
                const typeIndex = purchasedEquipment.typeIndex;

                const roundConfig = this.getCurrentRoundConfig;
                const equipmentConfig = roundConfig.equipments.find((_equipmentConfig) => _equipmentConfig.id === equipmentConfigId);
                const typeConfig = equipmentConfig.typesConfig[typeIndex];
                return {
                    equipmentConfigId,
                    type: typeConfig.equipmentType,
                    depreciationMethod: this.selectedDepreciationMethods[equipmentConfigId][typeIndex],
                    purchasePrice: typeConfig.purchaseCost,
                    capacity: typeConfig.productionCapacity,
                    usefulLife: typeConfig.usefulLife,
                };
            });
        },
    },
    props: {
        equipmentPurchaseElections: {
            type: Array,
        },
    },
    methods: {
        isEquipmentLocallyPurchased(equipmentConfigId, typeIndex) {
            return this.purchasedEquipments
                .map((purchasedEquipment) => `${purchasedEquipment.equipmentConfigId}|${purchasedEquipment.typeIndex}`)
                .indexOf(`${equipmentConfigId}|${typeIndex}`) >= 0;
        },
        purchaseEquipment(equipmentConfigId, typeIndex) {
            const purchasedEquipmentIds = this.purchasedEquipments
                .map((purchasedEquipment) => `${purchasedEquipment.equipmentConfigId}|${purchasedEquipment.typeIndex}`);

            if(purchasedEquipmentIds.indexOf(`${equipmentConfigId}|${typeIndex}`) === -1) {
                this.purchasedEquipments.push({equipmentConfigId, typeIndex});
            }
        },
        cancelEquipmentSale(equipmentConfigId, typeIndex) {
            const equipmentIndex = this.purchasedEquipments
                .map((purchasedEquipment) => `${purchasedEquipment.equipmentConfigId}|${purchasedEquipment.typeIndex}`)
                .indexOf(`${equipmentConfigId}|${typeIndex}`);

            if(equipmentIndex >= 0) {
                this.purchasedEquipments.splice(equipmentIndex, 1);
            }
        },
        initElections(elections) {
            this.currentEquipmentPurchaseElections = elections;
            if(!this.currentEquipmentPurchaseElections) {
                this.currentEquipmentPurchaseElections = [];
            }

            const roundConfig = this.getCurrentRoundConfig;
            for(let equipmentPurchaseElection of elections){
                const equipmentConfigId = equipmentPurchaseElection.equipmentConfigId;
                const equipmentConfig = roundConfig.equipments.find((equipmentCfg) => equipmentCfg.id === equipmentConfigId);
                const typeIndex = equipmentConfig.typesConfig.map((_type) => _type.equipmentType).indexOf(equipmentPurchaseElection.type)
                this.purchasedEquipments.push({equipmentConfigId, typeIndex});
                this.selectedDepreciationMethods[equipmentConfigId][typeIndex] = equipmentPurchaseElection.depreciationMethod;
            }
        }
    },
    created() {
        const roundConfig = this.getCurrentRoundConfig;
        this.selectedDepreciationMethods = {};
        for(let equipmentConfig of roundConfig.equipments) {
            this.selectedDepreciationMethods[equipmentConfig.id] = equipmentConfig.typesConfig.map((typeConfig) => typeConfig.defaultDepreciationMethod)
        }

        this.initElections(this.equipmentPurchaseElections);
    }
}

Vue.use(MdCard);
Vue.use(MdField);
Vue.use(MdIcon);
</script>

<style scoped>
    .md-layout-item {
        padding: 10px;
    }

    .wrapper {
        background-color: var(--main-box-color);
    }

    .inputWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .md-layout-item {
        padding: 10px;
    }

    .equipmentBlock {
        margin-left: auto;
        margin-right: auto;
        min-width: 98%;
        max-width: 98%;
        padding: 0;
    }

    .md-card {
        width: 300px;
        margin: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .md-button {
        margin: 1.5em;
    }

    .depreciation-field {
        margin-top: 30px;
    }

    .left-text {
        text-align: left;
    }

    .election-config-label {
        display: inline-block;
    }

    .election-config-value {
        display: inline-block;
        font-weight: bold;
        margin-left: 15px;
    }

    .cost {
        font-weight: bold;
        font-size: 20px;
    }
</style>
