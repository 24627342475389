<template>
  <div v-if="isPageReady" class="balance-sheet-container">
    <div v-if="allowSubmissionWhenValidGameConfigAndGameState && !gameStateDisabledLabel && gameStateDisabledLabel !== ''">
      <div class="no-sheet" v-if="(!currentBalanceSheet || !currentBalanceSheet.submittedBy) && showTeamAnswers">
        <span v-if="!isPastBalanceSheet">Team {{this.selectedTeam.name}} has not yet submitted a Balance Sheet for Round {{ this.getActiveRoundIndex() + 1 }}</span>
        <span v-if="isPastBalanceSheet">Team {{this.selectedTeam.name}} did not submit a Balance Sheet for Round {{ this.getActiveRoundIndex() + 1 }}</span>
      </div>
      <div class="countdown" v-if="!isPastBalanceSheet && shouldShowCountdown">
        <Countdown :deadline="currentDeadline" :countdown-id="'balance-sheet'" :hide-labels="true" :hide-big-units="true"></Countdown>
        <div class="remaining-label">remaining</div>
      </div>
      <div v-if="isPastBalanceSheet && pastBalanceSheetTotal && showTeamAnswers">
        <h1>{{pastBalanceSheetCorrect}}/{{pastBalanceSheetTotal}} PTS</h1>
      </div>
      <div class="selected-color-indicator-container" v-if="showCorrectAnswers && isSplitView">
        <span class="selected-color-indicator">____</span> = Filter Journal Entries by Accounts for Line Item
      </div>
      <div v-if="isEditable">
        <h1>Fill out sheet to be graded</h1>
        <h4>Assets, Liabilities, and Equity accounts should all be expressed as positive figures. Contra-accounts should be shown as negative.</h4>
        <div class="md-layout btn-group">
          <md-button v-if="!isEditing" @click="toggleIsEditing()" class="md-raised btn-rounded">
            EDIT
          </md-button>
          <md-button v-if="isEditing" @click="submitCurrentBalanceSheet()" class="md-raised btn-rounded save-btn">
            SAVE
          </md-button>
          <md-button v-if="isEditing" @click="cancelCurrentBalanceSheet()" class="md-raised btn-rounded cancel-btn">
            CANCEL
          </md-button>
          <div class="text-next-to-button">Don't forget to save your work often</div>
        </div>
      </div>
      <div v-if="isEditable" class="alert">
        <div v-if="showFormError && !dismissAlert" class="alert-error">
          {{errorMessage}}
          <span @click="clearAlert()"><md-icon class="fa fa-times-circle light"></md-icon></span>
        </div>
        <div v-if="currentBalanceSheet && currentBalanceSheet.submittedBy && !hasSuccessfullySubmitted" class="alert-success">
            <span>
              Team has submitted balance sheet
              <br>
              Submitted by {{currentBalanceSheet.submittedBy.firstname}} {{currentBalanceSheet.submittedBy.lastname}} at {{getFormattedDate(parseInt(currentBalanceSheet.createdAt)/1000, selectedTeam.semester.timezone)}}
            </span>
        </div>
        <div v-if="hasSuccessfullySubmitted" class="alert-success alert-success-pulse">
          <span v-if="hasSuccessfullySubmitted">You successfully submitted!</span>
        </div>
      </div>
    </div>
    <div class="main-box" v-if="!gameStateDisabledLabel && gameStateDisabledLabel !== ''">
      <div v-if="(allowSubmissionWhenValidGameConfigAndGameState && !isEditing) || !allowSubmissionWhenValidGameConfigAndGameState" class="read-only-table">
        <md-table>
          <md-table-toolbar class="table-title">
            <h1 class="md-title text-center title-text">
              <span v-if="isPastBalanceSheet">ROUND {{roundNum}}</span>
              BALANCE SHEET
            </h1>
          </md-table-toolbar>

          <md-table-toolbar>
            <h1 class="header-container md-title table-header">Assets</h1>
          </md-table-toolbar>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Current Assets</md-table-cell>
            <md-table-cell v-if="showTeamAnswers" v-bind:class="[{'correct-answers-cell': isPastBalanceSheet && currentBalanceSheet}, 'mobile-table-cell answers-container']">
              <span class="md-layout" v-if="!isPastBalanceSheet && allowSubmissionWhenValidGameConfigAndGameState && getUserType === UserTypeEnum.Student">*for negative values, prefix your input with either "-" or "("</span>
              <span v-if="currentBalanceSheet">
                <span v-if="getUserType === UserTypeEnum.Student">Your</span> Team's Answers
              </span>
            </md-table-cell>
            <md-table-cell v-bind:class="[{'correct-answers-cell': isPastBalanceSheet && currentBalanceSheet}, 'mobile-table-cell answers-container']"><span v-if="showTeamAnswers">Correct Answers</span></md-table-cell>
            <md-table-cell v-if="!showTeamAnswers"></md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('cash')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('cash')}, {'selected-cell': currentJournalEntryFilter === 'cash'}]" class="table-header category-label">
              Cash
              <div v-if="currentJournalEntryFilter === 'cash'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="!showTeamAnswers" class="category-value category-label"></md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.cash === null) && !disabledFields.includes('cash')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('cash')">{{currentBalanceSheet.cash | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerCash" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('cash') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('cash')}]">
                  {{getCorrectAnswer("cash") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isAccountsReceivableEnabled()" @click="toggleJournalEntryFilter('accountsReceivable')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters && currentAvailableJournalEntryFilters.includes('accountsReceivable')}, {'selected-cell': currentJournalEntryFilter === 'accountsReceivable'}]" class="table-header category-label">
              Accounts Receivable
              <div v-if="currentJournalEntryFilter === 'accountsReceivable'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="!showTeamAnswers" class="category-value category-label"></md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.accountsReceivable === null) && !disabledFields.includes('accountsReceivable')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('accountsReceivable')">{{currentBalanceSheet.accountsReceivable | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerAccountsReceivable" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('accountsReceivable') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('accountsReceivable')}]">
                  {{getCorrectAnswer("accountsReceivable") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="bottom-table-cell-border" @click="toggleJournalEntryFilter('inventory')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('inventory')}, {'selected-cell': currentJournalEntryFilter === 'inventory'}]" class="table-header category-label">
              Inventory
              <div v-if="currentJournalEntryFilter === 'inventory'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="!showTeamAnswers" class="category-value category-label"></md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.inventory === null) && !disabledFields.includes('inventory')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('inventory')">{{currentBalanceSheet.inventory | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerInventory" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('inventory') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers  || disabledFields.includes('inventory')}]">
                  {{getCorrectAnswer("inventory") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="non-input-header">
            <md-table-cell>PP & E</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row v-if="isEquipmentEnabledForGame" @click="toggleJournalEntryFilter('equipment')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('equipment')}, {'selected-cell': currentJournalEntryFilter === 'equipment'}]" class="table-header category-label">
              Equipment
              <div v-if="currentJournalEntryFilter === 'equipment'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value category-label">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.equipment === null) && !disabledFields.includes('equipment')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('equipment')">{{currentBalanceSheet.equipment | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell v-if="isEditable" class="category-value"></md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerEquipment" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('equipment') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('equipment')}]">
                  {{getCorrectAnswer("equipment") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="!showTeamAnswers && showCorrectAnswers"></md-table-cell>
          </md-table-row>

          <md-table-row v-if="isEquipmentEnabledForGame" @click="toggleJournalEntryFilter('equipmentDepreciation')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('equipmentDepreciation')}, {'selected-cell': currentJournalEntryFilter === 'equipmentDepreciation'}]" class="sub-class category-label">
              Accumulated Depreciation
              <div v-if="currentJournalEntryFilter === 'equipmentDepreciation'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value category-label">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.equipmentDepreciation === null) && !disabledFields.includes('equipmentDepreciation')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('equipmentDepreciation')" :class="{ 'underline' : !showCorrectAnswers }">{{currentBalanceSheet.equipmentDepreciation | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell v-if="isEditable" class="category-value">
              <div>
                <span v-if="!disabledFields.includes('equipmentDepreciation') && !showCorrectAnswers">{{(currentBalanceSheet.equipment + currentBalanceSheet.equipmentDepreciation) | toCurrency}}</span>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerEquipmentDepreciation" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('equipmentDepreciation') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('equipmentDepreciation')}]">
                  <span :class="{ 'underline' : !showTeamAnswers }">{{getCorrectAnswer("equipmentDepreciation") | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="!showTeamAnswers && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerEquipmentDepreciation" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('equipmentDepreciation') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('equipmentDepreciation')}]">
                  <span v-if="!showTeamAnswers">{{(getCorrectAnswer("equipment") + getCorrectAnswer("equipmentDepreciation")) | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('furnishings')" v-if="isFurnishingsEnabled()">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('furnishings')}, {'selected-cell': currentJournalEntryFilter === 'furnishings'}]" class="table-header category-label">
              Furnishings
              <div v-if="currentJournalEntryFilter === 'furnishings'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value category-label">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.furnishings === null) && !disabledFields.includes('furnishings')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('furnishings')">{{currentBalanceSheet.furnishings | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell v-if="isEditable" class="category-value"></md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerFurnishings" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('furnishings') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('furnishings')}]">
                  {{getCorrectAnswer("furnishings") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="!showTeamAnswers && showCorrectAnswers"></md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('furnishingsDepreciation')" v-if="isFurnishingsEnabled()">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('furnishingsDepreciation')}, {'selected-cell': currentJournalEntryFilter === 'furnishingsDepreciation'}]" class="sub-class category-label">
              Accumulated Depreciation
              <div v-if="currentJournalEntryFilter === 'furnishingsDepreciation'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value category-label">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.furnishingsDepreciation === null) && !disabledFields.includes('furnishingsDepreciation')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('furnishingsDepreciation')" :class="{ 'underline' : !showCorrectAnswers }">{{currentBalanceSheet.furnishingsDepreciation | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell v-if="isEditable" class="category-value">
              <div>
                <span v-if="!disabledFields.includes('furnishingsDepreciation') && !showCorrectAnswers">{{(currentBalanceSheet.furnishings + currentBalanceSheet.furnishingsDepreciation) | toCurrency}}</span>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerFurnishingsDepreciation" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('furnishingsDepreciation') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('furnishingsDepreciation')}]">
                  <span :class="{ 'underline' : !showTeamAnswers }">{{getCorrectAnswer("furnishingsDepreciation") | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="!showTeamAnswers && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerFurnishingsDepreciation" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('furnishingsDepreciation') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('furnishingsDepreciation')}]">
                  <span v-if="!showTeamAnswers">{{(getCorrectAnswer("furnishings") + getCorrectAnswer("furnishingsDepreciation")) | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('buildings')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('buildings')}, {'selected-cell': currentJournalEntryFilter === 'buildings'}]" class="table-header category-label">
              Buildings
              <div v-if="currentJournalEntryFilter === 'buildings'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value category-label">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.buildings === null) && !disabledFields.includes('buildings')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('buildings')">{{currentBalanceSheet.buildings | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell v-if="isEditable" class="category-value"></md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerBuildings" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('buildings') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('buildings')}]">
                  {{getCorrectAnswer("buildings") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="!showTeamAnswers && showCorrectAnswers"></md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('buildingsDepreciation')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('buildingsDepreciation')}, {'selected-cell': currentJournalEntryFilter === 'buildingsDepreciation'}]" class="sub-class category-label">
              Accumulated Depreciation
              <div v-if="currentJournalEntryFilter === 'buildingsDepreciation'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value category-label">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.buildingsDepreciation === null) && !disabledFields.includes('buildingsDepreciation')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('buildingsDepreciation')" :class="{ 'underline' : !showCorrectAnswers }">{{currentBalanceSheet.buildingsDepreciation | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell v-if="isEditable" class="category-value">
              <div>
                <span v-if="!disabledFields.includes('buildingsDepreciation') && !showCorrectAnswers">{{(currentBalanceSheet.buildings + currentBalanceSheet.buildingsDepreciation) | toCurrency}}</span>
              </div>
            </md-table-cell>
            <md-table-cell  class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerBuildingsDepreciation" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('buildingsDepreciation') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('buildingsDepreciation')}]">
                  <span :class="{ 'underline' : !showTeamAnswers }">{{getCorrectAnswer("buildingsDepreciation") | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell  class="correct-answers-cell" v-if="!showTeamAnswers && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerBuildingsDepreciation" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('buildingsDepreciation') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('buildingsDepreciation')}]">
                  <span v-if="!showTeamAnswers">{{(getCorrectAnswer("buildings") + getCorrectAnswer("buildingsDepreciation")) | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="bottom-table-cell-border" @click="toggleJournalEntryFilter('land')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('land')}, {'selected-cell': currentJournalEntryFilter === 'land'}]" class="table-header category-label">
              Land
              <div v-if="currentJournalEntryFilter === 'land'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="!showTeamAnswers" class="category-value category-label"></md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.land === null) && !disabledFields.includes('land')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('land')">{{currentBalanceSheet.land | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell  class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerLand" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('land') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('land')}]">
                  {{getCorrectAnswer("land") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="bottom-border">
            <md-table-cell class="category-label">Total Assets</md-table-cell>
            <md-table-cell v-if="!showTeamAnswers" class="category-value category-label"></md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.totalAssets === null) && !disabledFields.includes('totalAssets')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('totalAssets')">{{currentBalanceSheet.totalAssets | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerTotalAssets" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('totalAssets') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('totalAssets')}]">
                  {{getCorrectAnswer("totalAssets") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <div class="md-toolbar">
            <h1 class="header-container md-title table-header">Liabilities & Owners Equity</h1>
          </div>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Liabilities</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('loanPayable')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('loanPayable')}, {'selected-cell': currentJournalEntryFilter === 'loanPayable'}]" class="table-header category-label">
              Notes Payable
              <div v-if="currentJournalEntryFilter === 'loanPayable'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="!showTeamAnswers" class="category-value category-label"></md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.loanPayable === null) && !disabledFields.includes('loanPayable')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('loanPayable')">{{currentBalanceSheet.loanPayable | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell  class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerLoanPayable" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('loanPayable') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('loanPayable')}]">
                  {{getCorrectAnswer("loanPayable") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <slot v-if="areBondsSalesEnabled()">
            <md-table-row @click="toggleJournalEntryFilter('bondsPayable')">
              <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('bondsPayable')}, {'selected-cell': currentJournalEntryFilter === 'bondsPayable'}]" class="table-header category-label">
                Bonds Payable
                <div v-if="currentJournalEntryFilter === 'bondsPayable'" class="clear-filter">Clear Filter</div>
              </md-table-cell>
              <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value category-label">
                <div class="answers-container">
                  <div v-if="(!currentBalanceSheet || currentBalanceSheet.bondsPayable === null) && !disabledFields.includes('bondsPayable')">
                    <span>blank</span>
                  </div>
                  <div v-else>
                    <span v-if="!disabledFields.includes('bondsPayable')">{{currentBalanceSheet.bondsPayable | toCurrency}}</span>
                  </div>
                </div>
              </md-table-cell>
              <md-table-cell v-if="isEditable" class="category-value"></md-table-cell>
              <md-table-cell class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
                <div class="answers-container">
                  <div ref="correctAnswerBondsPayable" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('bondsPayable') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('bondsPayable')}]">
                    {{getCorrectAnswer("bondsPayable") | toCurrency}}
                  </div>
                </div>
              </md-table-cell>
              <md-table-cell class="correct-answers-cell" v-if="!showTeamAnswers && showCorrectAnswers"></md-table-cell>
            </md-table-row>

            <md-table-row @click="toggleJournalEntryFilter('bondsPremiumOrDiscount')">
              <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('bondsPremiumOrDiscount')}, {'selected-cell': currentJournalEntryFilter === 'bondsPremiumOrDiscount'}]" class="sub-class category-label">
                Unamortized Premium (Discount)
                <div v-if="currentJournalEntryFilter === 'bondsPremiumOrDiscount'" class="clear-filter">Clear Filter</div>
              </md-table-cell>
              <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value category-label">
                <div class="answers-container">
                  <div v-if="(!currentBalanceSheet || currentBalanceSheet.bondsPremiumOrDiscount === null) && !disabledFields.includes('bondsPremiumOrDiscount')">
                    <span>blank</span>
                  </div>
                  <div v-else>
                    <span v-if="!disabledFields.includes('bondsPremiumOrDiscount')" :class="{ 'underline' : !showCorrectAnswers }">{{currentBalanceSheet.bondsPremiumOrDiscount | toCurrency}}</span>
                  </div>
                </div>
              </md-table-cell>
              <md-table-cell v-if="isEditable" class="category-value">
                <div>
                  <span v-if="!disabledFields.includes('bondsPremiumOrDiscount') && !showCorrectAnswers">{{(currentBalanceSheet.bondsPayable + currentBalanceSheet.bondsPremiumOrDiscount) | toCurrency}}</span>
                </div>
              </md-table-cell>
              <md-table-cell class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
                <div class="answers-container">
                  <div ref="correctAnswerBondsPremiumOrDiscount" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('bondsPremiumOrDiscount') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('bondsPremiumOrDiscount')}]">
                    <span :class="{ 'underline' : !showTeamAnswers }">{{getCorrectAnswer("bondsPremiumOrDiscount") | toCurrency}}</span>
                  </div>
                </div>
              </md-table-cell>
              <md-table-cell class="correct-answers-cell" v-if="!showTeamAnswers && showCorrectAnswers">
                <div class="answers-container">
                  <div ref="correctAnswerBondsPremiumOrDiscount" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('bondsPremiumOrDiscount') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('bondsPremiumOrDiscount')}]">
                    <span v-if="!showTeamAnswers">{{(getCorrectAnswer("bondsPayable") + getCorrectAnswer("bondsPremiumOrDiscount")) | toCurrency}}</span>
                  </div>
                </div>
              </md-table-cell>
            </md-table-row>
          </slot>

          <md-table-row>
            <md-table-cell class="table-header category-label">Total Liabilities</md-table-cell>
            <md-table-cell v-if="!showTeamAnswers" class="category-value category-label"></md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.totalLiabilities === null) && !disabledFields.includes('totalLiabilities')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('totalLiabilities')">{{currentBalanceSheet.totalLiabilities | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell  class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerTotalLiabilities" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('totalLiabilities') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('totalLiabilities')}]">
                  {{getCorrectAnswer("totalLiabilities") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Owners Equity</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('commonStock')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('commonStock')}, {'selected-cell': currentJournalEntryFilter === 'commonStock'}]" class="table-header category-label">
              Common Stock
              <div v-if="currentJournalEntryFilter === 'commonStock'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="!showTeamAnswers" class="category-value category-label"></md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.commonStock === null) && !disabledFields.includes('commonStock')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('commonStock')">{{currentBalanceSheet.commonStock | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell  class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerCommonStock" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('commonStock') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('commonStock')}]">
                  {{getCorrectAnswer("commonStock") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('retainedEarnings')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('retainedEarnings')}, {'selected-cell': currentJournalEntryFilter === 'retainedEarnings'}]" class="table-header category-label">
              Retained Earnings
              <div v-if="currentJournalEntryFilter === 'retainedEarnings'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="!showTeamAnswers" class="category-value category-label"></md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.retainedEarnings === null) && !disabledFields.includes('retainedEarnings')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('retainedEarnings')">{{currentBalanceSheet.retainedEarnings | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell  class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerRetainedEarnings" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('retainedEarnings') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('retainedEarnings')}]">
                  {{getCorrectAnswer("retainedEarnings") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border">
            <md-table-cell class="category-label">Total Liabilities and Equity</md-table-cell>
            <md-table-cell v-if="!showTeamAnswers" class="category-value category-label"></md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentBalanceSheet || currentBalanceSheet.totalLiabilitiesAndEquity === null) && !disabledFields.includes('totalLiabilitiesAndEquity')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('totalLiabilitiesAndEquity')">{{currentBalanceSheet.totalLiabilitiesAndEquity | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell  class="correct-answers-cell" v-if="currentBalanceSheet && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerTotalLiabilitiesAndEquity" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('totalLiabilitiesAndEquity') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('totalLiabilitiesAndEquity')}]">
                  {{getCorrectAnswer("totalLiabilitiesAndEquity") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>

      <div v-if="allowSubmissionWhenValidGameConfigAndGameState && isEditing && !isPastBalanceSheet" class="input-table">
        <md-table>
          <md-table-toolbar class="table-title">
            <h1 class="md-title text-center title-text">
              BALANCE SHEET
            </h1>
          </md-table-toolbar>

          <md-table-toolbar>
            <h1 class="header-container md-title table-header">Assets</h1>
          </md-table-toolbar>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Current Assets</md-table-cell>
            <md-table-cell>
              <span class="md-layout">*for negative values, prefix your input with either "-" or "("</span>
              <span class="md-layout">$ = Field submission required for grading</span>
            </md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Cash</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('cash')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.cash">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isAccountsReceivableEnabled()">
            <md-table-cell class="table-header category-label">Accounts Receivable</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('accountsReceivable')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.accountsReceivable">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="bottom-table-cell-border">
            <md-table-cell class="table-header category-label">Inventory</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('inventory')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.inventory">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="non-input-header">
            <md-table-cell>PP & E</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row v-if="isEquipmentEnabledForGame">
            <md-table-cell class="table-header category-label">Equipment</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('equipment')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.equipment">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="sub-class category-label">Accumulated Depreciation</md-table-cell>
            <md-table-cell v-if="isEquipmentEnabledForGame">
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('equipmentDepreciation')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.equipmentDepreciation">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
            <md-table-cell v-if="isEquipmentEnabledForGame" class="text-center">
              <span v-if="!disabledFields.includes('equipmentDepreciation')">{{(currentBalanceSheet.equipment + currentBalanceSheet.equipmentDepreciation) | toCurrency}}</span>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Furnishings</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('furnishings')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.furnishings">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="sub-class category-label">Accumulated Depreciation</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('furnishingsDepreciation')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.furnishingsDepreciation">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
            <md-table-cell class="text-center">
              <span v-if="!disabledFields.includes('furnishingsDepreciation')">{{(currentBalanceSheet.furnishings + currentBalanceSheet.furnishingsDepreciation) | toCurrency}}</span>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Buildings</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('buildings')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.buildings">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="sub-class category-label">Accumulated Depreciation</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('buildingsDepreciation')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.buildingsDepreciation">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
            <md-table-cell class="text-center">
              <span v-if="!disabledFields.includes('buildingsDepreciation')">{{(currentBalanceSheet.buildings + currentBalanceSheet.buildingsDepreciation) | toCurrency}}</span>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="bottom-table-cell-border">
            <md-table-cell class="table-header category-label">Land</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('land')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.land">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="bottom-border">
            <md-table-cell class="category-label">Total Assets</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('totalAssets')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.totalAssets">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <md-table class="liabilities-table">
          <md-table-toolbar>
            <h1 class="header-container md-title table-header">Liabilities & Owners Equity</h1>
          </md-table-toolbar>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Liabilities</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Notes Payable</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('loanPayable')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.loanPayable">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <slot v-if="areBondsSalesEnabled()">
            <md-table-row>
              <md-table-cell class="table-header category-label">Bonds Payable</md-table-cell>
              <md-table-cell>
                <div class="currency-input-container">
                  <md-field>
                    <currency-input v-if="!disabledFields.includes('bondsPayable')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.bondsPayable">
                    </currency-input>
                    <div v-else>
                      <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                      <md-input disabled class="non-editable-input">
                      </md-input>
                    </div>
                  </md-field>
                </div>
              </md-table-cell>
              <md-table-cell></md-table-cell>
            </md-table-row>

            <md-table-row>
              <md-table-cell class="sub-class category-label">Unamortized Premium (Discount)</md-table-cell>
              <md-table-cell>
                <div class="currency-input-container">
                  <md-field>
                    <currency-input v-if="!disabledFields.includes('bondsPremiumOrDiscount')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.bondsPremiumOrDiscount">
                    </currency-input>
                    <div v-else>
                      <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                      <md-input disabled class="non-editable-input">
                      </md-input>
                    </div>
                  </md-field>
                </div>
              </md-table-cell>
              <md-table-cell class="text-center">
                <span v-if="!disabledFields.includes('bondsPremiumOrDiscount')">{{(currentBalanceSheet.bondsPayable + currentBalanceSheet.bondsPremiumOrDiscount) | toCurrency}}</span>
              </md-table-cell>
            </md-table-row>
          </slot>

          <md-table-row>
            <md-table-cell class="table-header category-label">Total Liabilities</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('totalLiabilities')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.totalLiabilities">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Owners Equity</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Common Stock</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('commonStock')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.commonStock">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Retained Earnings</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('retainedEarnings')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.retainedEarnings">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border">
            <md-table-cell class="category-label">Total Liabilities and Equity</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('totalLiabilitiesAndEquity')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="balanceSheetInput.totalLiabilitiesAndEquity">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
    <div v-else v-bind:class="{'game-state-disabled-label': gameStateDisabledLabel !== ''}">
      {{gameStateDisabledLabel}}
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import CurrencyInput from './CurrencyInput';
import Countdown from './Countdown';
import { MdContent } from 'vue-material/dist/components'
import { MdTable } from 'vue-material/dist/components'
import { MdCard } from 'vue-material/dist/components'
import { MdField } from 'vue-material/dist/components'
import { DateTime } from 'luxon'
import { mapGetters, mapActions } from 'vuex'
import {
  GameRoundPhase,
  RoundModuleDisplaySettingEnum,
  UserTypeEnum
} from "@bit/accountonomics.types-lib.api-types/apiTypes";

Vue.use(MdContent)
Vue.use(MdTable)
Vue.use(MdCard)
Vue.use(MdField)

export default {
  name: 'BalanceSheet',
  components: {
    Countdown,
    'currency-input': CurrencyInput
  },
  data() {
    return {
      attrs: {
        currentTeamHasSubmittedBalanceSheet: this.teamHasSubmittedBalanceSheet,
        currentJournalEntryFilter: this.journalEntryFilter,
        currentAvailableJournalEntryFilters: this.availableJournalEntryFilters
      },
      isEditing: false,
      currentBalanceSheet: {},
      balanceSheetInput: {
        roundConfigId: null,
        cash: null,
        inventory: null,
        equipment: null,
        equipmentDepreciation: null,
        furnishings: null,
        furnishingsDepreciation: null,
        buildings: null,
        buildingsDepreciation: null,
        land: null,
        totalAssets: null,
        loanPayable: null,
        totalLiabilities: null,
        commonStock: null,
        retainedEarnings: null,
        totalLiabilitiesAndEquity: null
      },
      dismissAlert: false,
      teamId: null,
      isPastBalanceSheet: false,
      incorrectAnswerFields: [],
      correctAnswers: null,
      roundNum: null,
      pastBalanceSheetCorrect: null,
      pastBalanceSheetTotal: null,
      currentGamePhase: null,
      showFormError: false,
      errorMessage: "Cannot submit. Check form and try again",
      hasSuccessfullySubmitted: false,
      isPageReady: false,
      disabledFields: [],
      gameStateDisabledLabel: null,
      showTeamAnswers: false,
      showCorrectAnswers: false,
      isEditable: false,
      UserTypeEnum
    }
  },
  props: {
    allowSubmissionWhenValidGameConfigAndGameState: {
      type: Boolean
    },
    activeRoundId: {
      type: String
    },
    selectedTeam: {
      type: Object
    },
    teamHasSubmittedBalanceSheet: {
      type: Boolean
    },
    journalEntryFilter: {
      type: String
    },
    isSplitView: {
      type: Boolean
    },
    availableJournalEntryFilters: {
      type: Array
    }
  },
  watch: {
    selectedTeam(val) {
      if(val) {
        this.initBalanceSheet();
      }
    },
    activeRoundId(val) {
      if(val) {
        this.initBalanceSheet();
      }
    },
    journalEntryFilter(val) {
      this.currentJournalEntryFilter = val;
    },
    availableJournalEntryFilters(val) {
      this.currentAvailableJournalEntryFilters = val;
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentBalanceSheet',
      'getCurrentRoundConfig',
      'getCurrentCourse',
      'getCurrentGameState',
      'getNextDeadline',
      'getFormattedDate',
      'getUserType'
    ]),
    currentDeadline() {
      return this.getNextDeadline;
    },
    currentTeamHasSubmittedBalanceSheet: {
      get() {
        return this.attrs.currentTeamHasSubmittedBalanceSheet;
      },
      set(value) {
        this.attrs.currentTeamHasSubmittedBalanceSheet = value;
        this.$emit(`update:team-has-submitted-balance-sheet`, value);
      }
    },
    currentJournalEntryFilter: {
      get() {
        return this.attrs.currentJournalEntryFilter;
      },
      set(value) {
        this.attrs.currentJournalEntryFilter = value;
        this.$emit(`update:journal-entry-filter`, value);
      }
    },
    currentAvailableJournalEntryFilters: {
      get() {
        return this.attrs.currentAvailableJournalEntryFilters;
      },
      set(value) {
        this.attrs.currentAvailableJournalEntryFilters = value;
        this.$emit(`update:available-journal-entry-filters-list`, value);
      }
    },
    shouldShowCountdown() {
      const nowSeconds = DateTime.local().toSeconds();
      const nextDeadlineSeconds = this.getNextDeadline;
      if(isNaN(nextDeadlineSeconds)) {
        return false;
      }
      // show countdown if time is less than 1 hour
      return nextDeadlineSeconds - nowSeconds <= 3599;
    },
    isEquipmentEnabledForGame() {
      const courseRounds = this.getCurrentCourse.rounds;
      for(let round of courseRounds) {
        if(round.equipments.length > 0) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    ...mapActions([
      'fetchBalanceSheet',
      'submitBalanceSheet',
      'fetchGradingReport'
    ]),
    checkGameStateAndConfig() {
      this.gameStateDisabledLabel = null;
      this.isEditable = false;
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      const bsConfig = gameConfig.roundFeatures.balanceSheetConfig[roundIndex].roundSetting;
      if(bsConfig === RoundModuleDisplaySettingEnum.Off) {
        const verb = this.isPastBalanceSheet ? 'was' : 'is';
        // setting gameStateDisabledLabel label to an empty string omits the entire UI (mainly used in competitor financials when nothing should show due to the round setting)
        this.gameStateDisabledLabel = this.allowSubmissionWhenValidGameConfigAndGameState ? `Balance Sheet submission ${verb} not required in Round ${roundIndex + 1}` : "";
        this.showTeamAnswers = false;
        this.showCorrectAnswers = false;
      } else if(bsConfig === RoundModuleDisplaySettingEnum.ShowForPastRounds) {
        if(this.getUserType === UserTypeEnum.Student && !this.isPastBalanceSheet) {
          this.gameStateDisabledLabel = `Balance Sheet submission is not required in Round ${roundIndex + 1}`
        }
        this.showTeamAnswers = false;
        this.showCorrectAnswers = true;
      } else if(bsConfig === RoundModuleDisplaySettingEnum.ShowForAllRounds) {
        this.showTeamAnswers = false;
        this.showCorrectAnswers = true;
      } else if(bsConfig === RoundModuleDisplaySettingEnum.Submit) {
        this.showTeamAnswers = (this.allowSubmissionWhenValidGameConfigAndGameState && this.getUserType !== UserTypeEnum.Student) || (this.allowSubmissionWhenValidGameConfigAndGameState && this.isPastBalanceSheet);
        this.isEditable = this.getUserType === UserTypeEnum.Student && !this.isPastBalanceSheet;
        this.showCorrectAnswers = this.getUserType !== UserTypeEnum.Student || this.isPastBalanceSheet;
      }
    },
    toggleIsEditing() {
      this.isEditing = !this.isEditing;
    },
    async submitCurrentBalanceSheet() {
      if(!this.balanceSheetInput["roundConfigId"]) {
        this.balanceSheetInput["roundConfigId"] = this.getCurrentRoundConfig.id;
      }
      this.balanceSheetInput.teamId = this.teamId;
      try {
        await this.submitBalanceSheet(this.balanceSheetInput);
        this.currentBalanceSheet = this.getCurrentBalanceSheet;
        for(let input in this.balanceSheetInput) {
          this.balanceSheetInput[input] = this.balanceSheetInput[input];
        }
        this.isEditing = false;
        this.dismissAlert = false;
        this.hasSuccessfullySubmitted = true;
        this.currentTeamHasSubmittedBalanceSheet = true;
      } catch(err) {
        this.hasSuccessfullySubmitted = false;
        if(err == 'Error: GraphQL error: The submission deadline has passed') {
          this.errorMessage = "The submission deadline has passed"
        }
        this.dismissAlert = false;
        this.showFormError = true;
        return err;
      }
    },
    clearBalanceSheetElections() {
      this.balanceSheetInput = {
        cash: null,
        inventory: null,
        equipment: null,
        equipmentDepreciation: null,
        furnishings: null,
        furnishingsDepreciation: null,
        buildings: null,
        buildingsDepreciation: null,
        land: null,
        totalAssets: null,
        loanPayable: null,
        totalLiabilities: null,
        commonStock: null,
        retainedEarnings: null,
        totalLiabilitiesAndEquity: null
      }
      this.isEditing = false;
    },
    clearAlert() {
      this.dismissAlert = true;
    },
    async initBalanceSheet() {
      const gameState = this.getCurrentGameState;
      this.currentGamePhase = gameState.phase;
      this.clearBalanceSheetElections();
      this.teamId = this.selectedTeam ? this.selectedTeam.id : this.$route.params.teamId;
      await this.fetchBalanceSheet({
        roundConfigId: this.activeRoundId,
        teamId: this.teamId
      });
      if(this.activeRoundId){
        if(this.activeRoundId !== this.getCurrentRoundConfig.id || this.currentGamePhase === GameRoundPhase.GameOver || this.currentGamePhase === GameRoundPhase.Graded) {
          this.isPastBalanceSheet = true;
          this.roundNum = this.getCurrentCourse.rounds.map((_round) => _round.id).indexOf(this.activeRoundId) + 1;
        } else {
          this.isPastBalanceSheet = false;
        }
      }
      this.currentBalanceSheet = this.getCurrentBalanceSheet;
      if(!this.isPastBalanceSheet) {
        for(let input in this.currentBalanceSheet) {
          if(this.balanceSheetInput.hasOwnProperty(input)) {
            this.balanceSheetInput[input] = this.currentBalanceSheet[input];
          }
        }
        this.balanceSheetInput["roundConfigId"] = this.getCurrentRoundConfig.id;
      } else {
          this.getPastReport();
      }
      if(this.currentBalanceSheet) {
        this.incorrectAnswerFields = !this.currentBalanceSheet.incorrectAnswers ? [] : this.currentBalanceSheet.incorrectAnswers.map((_incorrectAnswer) => _incorrectAnswer.fieldName);
      }
      if(!this.allowSubmissionWhenValidGameConfigAndGameState) {
        this.isEditing = false;
      }
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      this.disabledFields = gameConfig.roundFeatures.balanceSheetConfig[roundIndex].disabledFields;
      if(!this.disabledFields) {
        this.disabledFields = [];
      }
      if(this.getCurrentBalanceSheet && this.getCurrentBalanceSheet.submittedBy) {
        this.currentTeamHasSubmittedBalanceSheet = true;
      }
      this.checkGameStateAndConfig();
      this.currentJournalEntryFilter = null;
      if(!this.currentAvailableJournalEntryFilters) {
        this.currentAvailableJournalEntryFilters = [];
      }
      this.isPageReady = true;
    },
    getActiveRoundIndex() {
      const courseRounds = this.getCurrentCourse.rounds;
      return courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
    },
    isAdvertisingEnabled() {
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      if(roundIndex > -1) {
        const adConfig = gameConfig.roundFeatures.advertising[roundIndex];
        return adConfig.cost > 0 || adConfig.benefit > 0;
      }
    },
    isSalesReturnsEnabled() {
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      if(roundIndex > -1) {
        return !!gameConfig.roundFeatures.salesReturns[roundIndex];
      }
    },
    isFurnishingsEnabled() {
      const courseRounds = this.getCurrentCourse.rounds;
      return courseRounds.reduce((result, round) => {
        if (round.storeFurnishings.length > 0) {
          return true;
        }
        return result;
      }, false);
    },
    isIncomeTaxEnabled() {
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      if(roundIndex > -1) {
        const taxRate = gameConfig.roundFeatures.incomeTax[roundIndex];
        return taxRate > 0;
      }
      return false;
    },
    isAccountsReceivableEnabled() {
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const courseRounds = this.getCurrentCourse.rounds;
      const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
      const currentCourseRounds = courseRounds.slice(0, roundIndex + 1);

      for(let i = 0; i < currentCourseRounds.length; i++) {
        const outstandingPercent = gameConfig.roundFeatures.accountsReceivable[i].outstandingPercent;
        if(outstandingPercent > 0) {
          return true;
        }
      }
      return false;
    },
    areBondsSalesEnabled() {
      const courseRounds = this.getCurrentCourse.rounds;
      const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
      return (courseRounds[roundIndex].bonds.realInterestRateFactor > 0 && courseRounds[roundIndex].bonds.bondOptions.length > 0);
    },
    getCorrectAnswer(fieldName) {
      if(this.disabledFields.includes(fieldName)) {
        const answer = this.currentBalanceSheet.disabledFieldAnswers.find((answer) => answer.fieldName === fieldName);
        if(!answer) {
          return '';
        }
        return answer.correctAnswer;
      }

      if(this.incorrectAnswerFields.includes(fieldName)) {
        const answer = this.currentBalanceSheet.incorrectAnswers.find((answer) => answer.fieldName === fieldName);
        if(!answer) {
          return '';
        }
        return answer.correctAnswer;
      }

      const answer = this.currentBalanceSheet.correctAnswers.find((answer) => answer.fieldName === fieldName);
      if(answer) {
        return answer.correctAnswer;
      }

      return this.currentBalanceSheet[fieldName];
    },
    async getPastReport() {
      try {
        const gradeReport = await this.fetchGradingReport({
          roundConfigId: this.activeRoundId,
          teamId: this.teamId
        });
        this.pastBalanceSheetCorrect = gradeReport.balanceSheetNumCorrect;
        this.pastBalanceSheetTotal = gradeReport.balanceSheetTotal;
      } catch(error) {
        return error;
      }
    },
    cancelCurrentBalanceSheet() {
      if(this.currentBalanceSheet) {
        for(let input in this.currentBalanceSheet) {
          if(this.balanceSheetInput.hasOwnProperty(input)) {
            this.balanceSheetInput[input] = this.currentBalanceSheet[input];
          }
        }
      } else {
        this.clearBalanceSheetElections();
      }
      this.isEditing = false;
    },
    toggleJournalEntryFilter(filter) {
      if(this.showCorrectAnswers && this.isSplitView && this.currentAvailableJournalEntryFilters.includes(filter)) {
        if(this.currentJournalEntryFilter === filter) {
          this.currentJournalEntryFilter = null;
        } else {
          this.currentJournalEntryFilter = filter;
        }
      }
    }
  },
  created() {
    if(this.activeRoundId && this.selectedTeam) {
      this.initBalanceSheet();
    }
  }
}
</script>

<style scoped>
  h1 {
    padding: 1em;
  }
  .main-box {
    border: 1px solid black;
    margin: 1em;
    text-align: left;
  }
  .table-title {
    background-color: var(--table-title-bg-color) !important;
  }
  .title-text {
    font-weight: bold;
    color: white !important;
  }
  .text-center {
    text-align: center;
  }
  .btn-group {
    padding: 1em;
    display: table;
  }
  .table-header {
    font-weight: bold;
  }
  .sub-class {
    padding-left: 30px;
  }
  .category-label {
    border-right: 1px solid rgba(0,0,0,0.12);
  }
  .error {
    color: #ff1744;
  }
  .form-error {
    display: block!important;
    left: 0;
    height: 20px;
    position: absolute;
    bottom: -22px;
    font-size: 12px;
    transition: .3s cubic-bezier(.4,0,.2,1);
  }
  .liabilities-table {
    padding-top: 40px;
  }
  .btn-rounded {
    border-radius: 8px;
  }
  .save-btn {
    color: white !important;
    background-color: var(--btn-primary-color) !important;
  }
  .cancel-btn {
    color: #000000 !important;
    background-color: var(--btn-secondary-color) !important;
  }
  .alert {
    margin-left: 1em;
    margin-right: 1em;
  }
  .alert-error {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ff1744;
    color: white;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
  }
  .fa.fa-times-circle.light {
    color: white;
    cursor: pointer;
  }
  .balance-sheet-container {
    padding-bottom: 64px;
  }
  .incorrect-answer {
    color: var(--warning-text-color);
  }
  .correct-answer {
    color: var(--success-bg-color);
  }
  .category-value {
    text-align: center;
    max-width: 100px;
    min-width: 100px;
  }
  .md-field {
    min-height: 0px;
    margin: 0;
    padding-top: 0px;
  }
  .currency-input-container {
    float: right;
  }
  .header-container {
    max-width: 200px;
  }
  .header-container.md-title {
    overflow: inherit;
    text-overflow: inherit;
  }
  .answers-container {
    text-align: center;
  }
  .remaining-label {
    margin-top: -8px;
  }
  .correct-answers-cell {
    border-left: 1px solid rgba(0,0,0,0.12);
    font-weight: bold;
  }
  .bottom-border {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  @media screen and (max-width: 550px){
    .currency-input-container {
      float: none;
    }
  }
  @media screen and (max-width: 800px){
    .mobile-table-cell {
      width: 50%;
    }
  }
  .text-next-to-button {
    display: table-cell;
    vertical-align: middle;
  }
  .black-text {
    color: black;
  }
  .no-sheet {
    margin-top: 20px;
  }

  .game-state-disabled-label {
    margin-top: 150px;
    font-weight: bold;
    border: 1px solid;
    display: inline-block;
    padding: 15px;
  }

  .underline {
    text-decoration: underline;
  }

  .selectable-cell {
    margin: -5px;
    border: 2px solid var(--btn-primary-color) !important;
  }

  .selected-cell {
    background: rgba(67,176,249,0.12) !important;
  }

  .selected-cell:hover {
    background: rgba(67,176,249,0.12) !important;
  }

  .selected-color-indicator {
    color: var(--btn-primary-color);
  }

  .clear-filter {
    position: absolute;
    font-weight: bold;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--btn-primary-color);
  }
</style>
