<template>
    <div class="journal-entry-table">
        <div>{{journalEntry.problemText}}</div>
        <div class="md-layout journal-entry-header">
            <div class="md-layout-item text-left">{{journalEntry.journalEntryTypeFriendlyName}}</div>
        </div>
        <div class="md-layout journal-entry-content">
            <div class="md-layout-item md-size-10"></div>
            <div class="md-layout-item text-left md-size-48 bold">Account</div>
            <div class="md-layout-item md-size-15 bold">Dr</div>
            <div class="md-layout-item md-size-15 bold">Cr</div>
            <div class="md-layout-item md-size-12"></div>
        </div>
        <div class="md-layout journal-entry-content" v-for="(debitOrCredit, index) in journalEntry.debitsOrCredits" :key="debitOrCredit.accountEnum">
            <div class="md-layout-item md-size-10"></div>
            <div class="md-layout-item text-left md-size-48">
                <div v-if="!isEditing" v-bind:class="[{'credit-account': debitOrCredit.balanceType === 'CREDIT'}]">{{debitOrCredit.accountFriendlyName || 'Enter Account Name'}}</div>
                <div v-else class="custom-select-wrapper">
                    <select class="custom-select" name="basic-dropdown" v-model="journalEntryInput[index].accountEnum">
                    <option v-for="journalEntryName in selectableAccountNames" :key="journalEntryName.enum" :value="journalEntryName.enum">{{journalEntryName.friendlyName}}</option>
                    </select>
                </div>
            </div>
            <div class="md-layout-item md-size-15">
                <div v-if="!isEditing && debitOrCredit.balanceType === 'DEBIT'">{{debitOrCredit.value | toCurrency}}</div>
                <div v-if="isEditing">
                    <input class="debit-credit-input" :ref="'debitValue-' + index" :value="journalEntryInput[index].debitValue" @blur="sanitizeAndSetDebitOrCredit('DEBIT', index)" @keypress="formatCurrencyForInput($event, 'DEBIT', index)" type="text" pattern="\d*">
                </div>
            </div>
            <div class="md-layout-item md-size-15">
                <div v-if="!isEditing && debitOrCredit.balanceType === 'CREDIT'">{{debitOrCredit.value | toCurrency}}</div>
                <div v-if="isEditing">
                    <input class="debit-credit-input" :ref="'creditValue-' + index" :value="journalEntryInput[index].creditValue" @blur="sanitizeAndSetDebitOrCredit('CREDIT', index)" @keypress="formatCurrencyForInput($event, 'CREDIT', index)" type="text" pattern="\d*">
                </div>
            </div>
            <div class="md-layout-item md-size-12">
                <div v-if="index === journalEntry.debitsOrCredits.length - 1" class="edit-btn link" @click="toggleIsEditing()">
                    <span v-if="!isEditing">{{debitOrCredit.accountFriendlyName ? 'Edit Assignment' : 'View Assignment'}}</span>
                    <span v-else>Save</span>
                </div>
            </div>
        </div>
        <div v-if="inputError" class="error">{{inputError}}</div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'EditableJournalEntry',
    data() {
        return {
            isEditing: false,
            selectableAccountNames: [],
            journalEntryInput: [],
            inputError: ""
        }
    },
    props: {
        journalEntry: {
            type: Object
        },
        teamId: {
            type: String
        },
        activeRoundId: {
            type: String
        },
        successCb: {
            type: Function
        }
    },
    methods: {
        ...mapActions([
            'submitJournalEntry'
        ]),
        formatCurrencyForInput(event, balanceType, index) {
            let value;
            if(balanceType === "DEBIT") {
                const ref = this.$refs['debitValue-' + index][0];
                value = ref.value;
            }
            if(balanceType === "CREDIT") {
                const ref = this.$refs['creditValue-' + index][0];
                value = ref.value;
            }
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || value.indexOf('.') != -1)) {
                event.preventDefault();
            }
        },
        sanitizeAndSetDebitOrCredit(balanceType, index) {
            if(balanceType === "DEBIT") {
                const ref = this.$refs['debitValue-' + index][0];
                if(isNaN(parseFloat(ref.value))) {
                    ref.value = this.journalEntryInput[index].debitValue;
                } else {
                    if(ref.value && this.journalEntryInput[index].creditValue) {
                        this.journalEntryInput[index].creditValue = null
                    }
                    this.journalEntryInput[index].debitValue = ref.value;
                }
            }
            if(balanceType === "CREDIT") {
                const ref = this.$refs['creditValue-' + index][0];
                if(isNaN(parseFloat(ref.value))) {
                    ref.value = this.journalEntryInput[index].creditValue;
                } else {
                    if(ref.value && this.journalEntryInput[index].debitValue) {
                        this.journalEntryInput[index].debitValue = null
                    }
                    this.journalEntryInput[index].creditValue = ref.value;
                }
            }
        },
        validateJournalInput() {
            this.inputError = "";
            let totalDebits = 0;
            let totalCredits = 0;
            let selectedAccoutEnums = [];

            for(let i = 0; i < this.journalEntryInput.length; i++) {
                const journalEntry = this.journalEntryInput[i];

                if(journalEntry.debitValue && journalEntry.creditValue) {
                    this.inputError = "Both Debits and Credits entered";
                    return false;
                }
                if(!journalEntry.accountEnum) {
                    this.inputError = "Missing Account value";
                    return false;
                }
                if(selectedAccoutEnums.length > 0) {
                    if(selectedAccoutEnums.find(_enum => _enum === journalEntry.accountEnum)) {
                        this.inputError = `Account ${journalEntry.accountEnum} has already been selected`;
                        return false;
                    }
                }
                selectedAccoutEnums.push(journalEntry.accountEnum);
                if(!journalEntry.debitValue && !journalEntry.creditValue) {
                    this.inputError = "Missing Debit or Credit value";
                    return false;
                }
                if(journalEntry.debitValue) {
                    totalDebits += parseFloat(journalEntry.debitValue);
                }
                if(journalEntry.creditValue) {
                    totalCredits += parseFloat(journalEntry.creditValue);
                }
            }

            if(totalDebits.toFixed(2) !== totalCredits.toFixed(2)) {
                this.inputError = "Debits and Credits do not balance";
                return false;
            }

            return true;
        },
        async toggleIsEditing() {
            if(this.isEditing) {
                if(!this.validateJournalInput()) {
                    return;
                }

                const sortedDebits = this.journalEntryInput.filter((entry) => {
                    return entry.debitValue !== null;
                }).sort((a, b) => Math.abs(b.debitValue) - Math.abs(a.debitValue));

                const sortedCredits = this.journalEntryInput.filter((entry) => {
                    return entry.creditValue !== null;
                }).sort((a, b) => Math.abs(b.creditValue) - Math.abs(a.creditValue));

                const sortedJournalEntryInput = sortedDebits.concat(sortedCredits);

                const debitsOrCredits = sortedJournalEntryInput.map((_input) => {
                    let balanceType;
                    let value;
                    if(_input.debitValue !== null) {
                        balanceType = "DEBIT";
                        value = parseFloat(_input.debitValue);
                    }
                    if(_input.creditValue !== null) {
                        balanceType = "CREDIT";
                        value = parseFloat(_input.creditValue);
                    }

                    return {
                        accountEnum: _input.accountEnum,
                        balanceType,
                        value
                    }
                });

                const submittedJournalEntry = await this.submitJournalEntry({
                    teamId: this.teamId,
                    roundConfigId: this.activeRoundId,
                    journalEntryTypeEnum: this.journalEntry.journalEntryTypeEnum,
                    debitsOrCredits
                });

                if(submittedJournalEntry) {
                    if(this.successCb) {
                        await this.successCb();
                    }
                }
            }
            this.isEditing = !this.isEditing;
        }
    },
    created() {
        this.selectableAccountNames = [
            {
                friendlyName: "Select an Account",
                enum: null
            }
        ];

        const availableAccounts = JSON.parse(JSON.stringify(this.journalEntry.availableAccounts));
        this.selectableAccountNames = this.selectableAccountNames.concat(availableAccounts);

        this.journalEntryInput = this.journalEntry.debitsOrCredits.map((debitOrCredit) => {
            let debitValue = null;
            let creditValue = null;

            if(debitOrCredit.value) {
                if(debitOrCredit.balanceType === "DEBIT") {
                    debitValue = debitOrCredit.value;
                }
                if(debitOrCredit.balanceType === "CREDIT") {
                    creditValue = debitOrCredit.value;
                }
            }

            return {
                accountEnum: debitOrCredit.accountEnum,
                debitValue,
                creditValue
            }
        });
    }
}
</script>

<style scoped>
    .error {
        color: #ff1744;
    }

    .text-left {
        text-align: left;
    }

    .bold {
        font-weight: bold;
    }

    .journal-entry-table {
        border: solid 1px black;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--table-cell-color);
    }

    .journal-entry-header {
        background-color: var(--table-title-bg-color);
        color: white;
        padding-left: 10px;
        padding-right: 10px;
    }

    .journal-entry-content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .edit-btn {
        background-color: var(--btn-primary-color);
        max-width: 115px;
        margin-left: auto;
        margin-right: auto;
        color: white;
        border: 1px solid black;
        margin-bottom: 5px;
    }

    .debit-credit-input {
        max-width: 100%;
    }

    .custom-select {
        position: relative;
        display: flex;
        flex-direction: column;
        border: 1px solid black;
        width: 100%;
    }

    .custom-select select {
        padding-left: 10px;
        background-color: transparent;
        font-size: 18px;
        outline: none;
        appearance: none;
        cursor: pointer;
    }

    .custom-select-wrapper {
        position: relative;
        user-select: none;
        width: 100%;
    }

    .credit-account {
      padding-left: 50px;
    }
</style>
