<template>
    <div>
        <div class="container">
            <div class="md-layout">
                <div class="btn-container md-layout-item md-size-50">
                    <div class="btn-wrapper" @click="logout()">
                        <span class="link">Sign Out</span>
                    </div>
                </div>
                <div class="message-btn-container md-layout-item md-size-50">
                    <md-button @click="showSendMessageModal = true" class="md-raised btn-primary">Send Message</md-button>
                </div>
            </div>
            <div class="alert">
                <div v-if="alertMessage" class="alert-success">
                    {{alertMessage}}
                    <span @click="alertMessage = ''"><md-icon class="fa fa-times-circle light link"></md-icon></span>
                </div>
            </div>
            <div class="main-box">
                <h1 class="text-center">{{ titleName }}</h1>
                <div v-if="componentToShow === 'ProfessorLicenses'">
                    <ProfessorLicenses></ProfessorLicenses>
                </div>
                <div v-if="componentToShow === 'AdminLicenses'">
                    <AdminLicenses></AdminLicenses>
                </div>
                <div v-if="componentToShow === 'StudentLicenses'">
                    <StudentLicenses></StudentLicenses>
                </div>
                <div v-if="componentToShow === 'CourseTemplates'">
                    <CourseTemplates :professor-id="null"></CourseTemplates>
                </div>
            </div>
        </div>
        <AdminNav :tabs-to-display="tabsToDisplay" :component-to-show.sync="componentToShow"></AdminNav>
        <md-dialog class="send-message-modal" :md-active="showSendMessageModal">
            <md-dialog-title class="modal-title">Send Message</md-dialog-title>

            <MessageForm :is-admin="true" :semesters="getSemesterList" :success-cb="sendMessageSuccessCallback" :alert-message.sync="alertMessage"></MessageForm>
            <md-dialog-actions>
                <md-button class="md-accent raised" @click="showSendMessageModal = false">Close</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AdminNav from './AdminNav'
import ProfessorLicenses from './ProfessorLicenses'
import AdminLicenses from './AdminLicenses'
import StudentLicenses from './StudentLicenses'
import { UserTypeEnum } from "@bit/accountonomics.types-lib.api-types/apiTypes";
import MessageForm from './MessageForm';
import CourseTemplates from './CourseTemplates'

export default {
    name: 'AdminHome',
    components: {
        AdminNav,
        ProfessorLicenses,
        AdminLicenses,
        StudentLicenses,
        MessageForm,
        CourseTemplates
	},
    data() {
        return {
            tabsToDisplay: [{
                name: 'Admin Licenses',
                componentName: 'AdminLicenses'
            }, {
                name: 'Professor Licenses',
                componentName: 'ProfessorLicenses'
            }, {
                name: 'Student Licenses',
                componentName: 'StudentLicenses'
            }, {
                name: 'Course Templates',
                componentName: 'CourseTemplates'
            }],
            componentToShow: null,
            showSendMessageModal: false,
            alertMessage: ""
        }
    },
    computed: {
        ...mapGetters([
            'getSemesterList'
        ]),
        titleName() {
            const index = this.tabsToDisplay.findIndex(tab => tab.componentName === this.componentToShow);
            return this.tabsToDisplay[index].name;
        }
    },
    methods: {
        ...mapActions([
			'resetVuexState',
            'configureUserType',
            'configureUserEmail',
            'fetchSemesterList'
		]),
        sendMessageSuccessCallback() {
			this.showSendMessageModal = false;
		},
        logout() {
            localStorage.clear();
			sessionStorage.clear();
			this.resetVuexState();
			this.$router.push({ 
				name: 'Login'
			});
        },
    },
    async created() {
        this.componentToShow = "AdminLicenses";
        await this.configureUserType(UserTypeEnum.Admin);
        await this.configureUserEmail(null);
        await this.fetchSemesterList();
    }
}
</script>

<style scoped>
h1 {
    padding: 1em;
}
.container {
    margin: 1em;
}
.btn-wrapper {
    display: inline-block;
}
.btn-container {
    padding-top: 20px;
    padding-left: 20px;
    text-align: left;
}
.message-btn-container {
    padding-right: 20px;
    text-align: right;
}
.main-box {
    background-color: var(--main-box-color);
    margin: 1em;
}
.text-center {
    text-align: center;
}
.send-message-modal {
    width: 100%;
}
.alert {
    margin-left: 1em;
    margin-right: 1em;
}
.fa.fa-times-circle.light {
    color: white;
    cursor: pointer;
}
@media screen and (min-width: 1900px){
    .main-box {
        min-width: 1000px;
    }
}
</style>