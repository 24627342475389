<template>
    <div>
		<span class="error" v-if="serverError">{{serverError}}</span>
    </div>
</template>

<script>

export default {
    name: 'Confirm',
    data() {
        return {
			email: null,
			verificationCode: null,
			serverError: null,
			url: process.env.VUE_APP_LAMBDA_URL
        }
    },
    methods: {
      async confirmSignUp() {
        const data = {
          email: this.email,
          verificationCode: this.verificationCode
        };

        try {
          const response = await fetch(`${this.url}/confirm`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'POST'
            },
            body: JSON.stringify(data)
          });

          const responseJson = await response.json();
          let notifyMessage = "Successfully verified email.";
          let emailForLogin = this.email;

          if(!response.ok) {
            notifyMessage = responseJson.message;
            emailForLogin = null;

            if(responseJson.message !== "User cannot be confirmed. Current status is CONFIRMED") {
              notifyMessage = "Error verifing email. You may need to re-register";
            }
            if(responseJson.code === 'ExpiredCodeException') {
              return await this.resendConfirmationCode();
            }
            this.$router.push({
              name: 'NotifyMessage',
              params: {
                message: notifyMessage
              }
            });
          } else {
            this.$router.push({
              name: 'Login',
              params: {
                notifyMessage: notifyMessage,
                email: emailForLogin
              }
            });
          }
        } catch(err) {
          this.serverError = "Something went wrong. Please try again";
          return err;
        }
      },
      async resendConfirmationCode() {
        const data = {
          email: this.email
        };

        try {
          const response = await fetch(`${this.url}/resendConfirmationCode`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'POST'
            },
            body: JSON.stringify(data)
          });

          let notifyMessage = "Code has expired. Check your email for a new link";

          if(!response.ok) {
            notifyMessage = "Could not resend confirmation code. Please try again";
          }

          this.$router.push({
            name: 'NotifyMessage',
            params: {
              message: notifyMessage
            }
          });
        } catch(err) {
          this.serverError = "Something went wrong. Please try again";
          return err;
        }
      }
    },
    async created() {
      this.email = this.$route.query.email;
      this.verificationCode = this.$route.query.code;
      if(this.email && this.verificationCode) {
        await this.confirmSignUp();
      } else {
        this.$router.push({
          name: 'NotifyMessage',
          params: {
            message: "Error verifing email. You may need to re-register"
          }
        });
      }
    }
}

</script>

<style scoped>
.error {
	color: var(--warning-text-color);
}
</style>
