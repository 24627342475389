<template>
    <div>
        <h1>{{message}}</h1>
        <h3>You may now close this browser tab</h3>
    </div>
</template>

<script>
export default {
    name: 'NotifyMessage',
    props: {
        message: String
    }
}
</script>

<style scoped>
</style>