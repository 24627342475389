<template>
    <div>
        <div v-if="showFormSuccess && !dismissAlert" class="alert-success">
            Successfully created license!
            <span @click="clearAlert()"><md-icon class="fa fa-times-circle light link"></md-icon></span>
        </div>
        <h2>Licenses</h2>
        <LicenseTable class="table-padding" :action-button-callback="showCreateProfessorLicense" :license-type="UserTypeEnum.Professor" :show-search="true" :licenses="licenses" :success-cb="didCreateLicense"></LicenseTable>
        <md-dialog class="invite-professor-modal" :md-active="showCreateProfessorLicenseModal">
            <md-dialog-title class="modal-title">Create License</md-dialog-title>

            <LicenseForm :form-type="'create'" :success-cb="didCreateLicense" :license-type="UserTypeEnum.Professor" :non-editable-fields="nonEditableFields"></LicenseForm>
            <md-dialog-actions>
                <md-button class="md-accent" @click="showCreateProfessorLicenseModal = false">Close</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import LicenseTable from './LicenseTable'
import LicenseForm from './LicenseForm'
import { UserTypeEnum } from "@bit/accountonomics.types-lib.api-types/apiTypes";

export default {
    name: 'ProfessorLicenses',
    components: {
        LicenseTable,
        LicenseForm
	},
    data() {
        return {
            licenses: null,
            warningLicenses: null,
            showCreateProfessorLicenseModal: false,
            nonEditableFields: [],
            showFormSuccess: false,
            dismissAlert: false,
            UserTypeEnum
        }
    },
    methods: {
        ...mapActions([
            'fetchProfessorLicenses'
        ]),
        clearAlert() {
            this.showFormSuccess = false;
			this.dismissAlert = true;
		},
        closeDialog() {
            this.showCreateProfessorLicenseModal = false;
            this.showFormSuccess = true;
        },
        didCreateLicense() {
            this.dismissAlert = false;
            this.closeDialog();
            this.refreshLicenses();
        },
        async refreshLicenses() {
            const professorLicenseResponse = await this.fetchProfessorLicenses();
            if(professorLicenseResponse) {
                this.licenses = professorLicenseResponse;
            }
        },
        showCreateProfessorLicense() {
            this.showCreateProfessorLicenseModal = true;
        }
    },
    async created() {
        await this.refreshLicenses();
    }
}
</script>

<style scoped>
.table-padding {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 80px;
}
.invite-professor-modal {
    overflow-y: scroll;
    width: 70vw;
}
.modal-title {
    text-align: center;
}
.alert {
    text-align: center;
    margin-left: 1em;
    margin-right: 1em;
}
.alert-success {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: var(--btn-primary-color);
    color: white;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
}
</style>