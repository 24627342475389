<template>
    <div v-if="isPageReady" class="journal-entry-submission-container">
        <h1>Journal Entry Submissions</h1>
        <h2 v-if="isPastJournalEntrySubmissions">
            {{journalEntryNumCorrect}}/{{journalEntryTotal}} PTS
        </h2>
        <div v-if="!isPastJournalEntrySubmissions" class="alert">
            <h4>Enter debits and credits both as positive numbers.</h4>
            <div v-if="currentTeamHasSubmittedJournalEntries" class="alert-success">
                <span>Team has submitted all required journal entries</span>
            </div>
            <div v-if="hasSuccessfullySubmitted" class="alert-success alert-success-pulse">
              <span v-if="hasSuccessfullySubmitted">You successfully submitted!</span>
            </div>
        </div>
        <div class="main-box">
            <div v-for="journalEntrySubmission in journalEntrySubmissions" :key="journalEntrySubmission.journalEntryTypeEnum">
                <editable-journal-entry v-if="!isPastJournalEntrySubmissions" :journal-entry="journalEntrySubmission" :team-id="selectedTeam.id" :active-round-id="activeRoundId" :success-cb="submittedJournalEntryCb"></editable-journal-entry>
                <graded-journal-entry v-else :journal-entry="journalEntrySubmission" :team-id="selectedTeam.id" :active-round-id="activeRoundId" :success-cb="submittedJournalEntryCb"></graded-journal-entry>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import EditableJournalEntry from './EditableJournalEntry.vue';
import GradedJournalEntry from './GradedJournalEntry.vue';
import {GameRoundPhase} from "@bit/accountonomics.types-lib.api-types/apiTypes";

export default {
  components: { EditableJournalEntry, GradedJournalEntry },
    name: 'JournalEntrySubmissions',
    data() {
        return {
            attrs: {
                currentTeamHasSubmittedJournalEntries: this.teamHasSubmittedJournalEntries
            },
            journalEntrySubmissions: null,
            teamId: null,
            hasSuccessfullySubmitted: false,
            isPageReady: false,
            isPastJournalEntrySubmissions: false,
            journalEntryNumCorrect: null,
            journalEntryTotal: null
        }
    },
    props: {
        activeRoundId: {
            type: String
        },
        selectedTeam: {
            type: Object
        },
        teamHasSubmittedJournalEntries: {
            type: Boolean
        },
        isSplitView: {
            type: Boolean
        }
    },
    watch: {
        selectedTeam(val) {
            if(val) {
                this.initJournalEntrySubmissions();
            }
        },
        activeRoundId(val) {
            if(val) {
                this.initJournalEntrySubmissions();
            }
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentRoundConfig',
            'getCurrentGameState'
        ]),
        currentTeamHasSubmittedJournalEntries: {
            get() {
                return this.attrs.currentTeamHasSubmittedJournalEntries;
            },
            set(value) {
                this.attrs.currentTeamHasSubmittedJournalEntries = value;
                this.$emit(`update:team-has-submitted-journal-entries`, value);
            }
        }
    },
    methods: {
        ...mapActions([
            'fetchJournalEntrySubmissions',
            'fetchGradingReport'
        ]),
        submittedJournalEntryCb() {
            this.hasSuccessfullySubmitted = true;
            this.initJournalEntrySubmissions();
        },
        async getPastReport() {
            try {
                const gradeReport = await this.fetchGradingReport({
                    roundConfigId: this.activeRoundId,
                    teamId: this.teamId
                });
                this.journalEntryNumCorrect = gradeReport.journalEntryNumCorrect;
                this.journalEntryTotal = gradeReport.journalEntryTotal;
            } catch(error) {
                return error;
            }
        },
        async initJournalEntrySubmissions() {
            this.teamId = this.selectedTeam ? this.selectedTeam.id : this.$route.params.teamId;
            this.journalEntrySubmissions = await this.fetchJournalEntrySubmissions({
                roundConfigId: this.activeRoundId,
                teamId: this.teamId
            });

            const gameState = this.getCurrentGameState;
            const currentGamePhase = gameState.phase;

            if(this.activeRoundId !== this.getCurrentRoundConfig.id || currentGamePhase === GameRoundPhase.GameOver || currentGamePhase === GameRoundPhase.Graded) {
                this.isPastJournalEntrySubmissions = true;
                await this.getPastReport();
            } else {
                this.isPastJournalEntrySubmissions = false;
            }

            const teamSubmittedJournalEntries = this.journalEntrySubmissions.filter(entry => entry.submittedBy);
            if(this.journalEntrySubmissions.length === teamSubmittedJournalEntries.length) {
                this.currentTeamHasSubmittedJournalEntries = true;
            }

            this.isPageReady = true;
        }
    },
    created() {
        this.initJournalEntrySubmissions();
    }
}
</script>

<style scoped>
    .alert {
        margin-left: 1em;
        margin-right: 1em;
    }

    .alert-error {
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #ff1744;
        color: white;
        display: inline-block;
        padding-left: 20px;
        padding-right: 20px;
    }

    .fa.fa-times-circle.light {
        color: white;
        cursor: pointer;
    }

    .journal-entry-submissions-container {
        padding-bottom: 64px;
    }

    .main-box {
        margin: 1em;
        padding-bottom: 50px;
    }
</style>
