<template>
  <div class="md-layout">
    <div class="msDebtBlock">
      <div class="wrapper" v-if="localDebts.length > 0">
        <md-card v-bind:class="[{'debt-due': debt.repaymentRoundId === activeRoundId}, {'selected-border' : selectedDebtsIds.includes(debt.id)}]" v-for="debt in localDebts" v-bind:key="debt.id">
          <div class="mini-id">
            Loan ID: #{{debt.friendlyId}}
          </div>
          <div v-if="debt.repaymentRoundId === activeRoundId">
            <div>
              <md-icon class="fa fa-exclamation-circle"></md-icon>
              This debt is due at the end of this round. If left unsettled, the remaining balance owed will automatically be deducted from Cash.
            </div>
            <div>
              <b>Caution:</b> your team is at risk of accumulating a Junk Loan if this causes your cash to go negative.
            </div>
          </div>
          <md-card-header>
            <div class="md-title">{{debt.name}}</div>
          </md-card-header>
          <md-card-content>
            <div class="left-text">
              <div class="election-config-label">Original Principal</div>
              <div class="election-config-value">{{debt.originalPrincipal | toCurrency}}</div>
            </div>
            <div class="left-text">
              <div class="election-config-label">Term</div>
              <div class="election-config-value">{{debt.originalTerm}} rounds</div>
            </div>
            <div class="left-text">
              <div class="election-config-label">Rate</div>
              <div class="election-config-value">{{ (debt.originalRate * 100).toFixed(2)}}%</div>
            </div>
            <div class="left-text">
              <div class="election-config-label">Outstanding Principal</div>
              <div class="election-config-value">{{debt.outstandingPrincipal | toCurrency}}</div>
            </div>
            <div v-if="selectedDebtsIds.includes(debt.id)">
              <div class="left-text">
                <div class="election-config-label">Payment this round</div>
                <div class="election-config-value">{{debtRepaymentAmounts[debt.id] | toCurrency}}</div>
              </div>
            </div>
            <md-field class="repayment-field" v-if="!selectedDebtsIds.includes(debt.id)">
              <label>Payment this round</label>
              <currency-input :input-callback="generateInputCallback(debt.id)" :blur-callback="generateBlurCallback(debt.id)" :currency-to-format.sync="debtRepaymentAmounts[debt.id]">
              </currency-input>
            </md-field>
            <div class="warning" v-if="warnOverpaymentOfDebtIds.indexOf(debt.id) >= 0">Payment amount must be less than or equal to outstanding loan principal</div>
            <md-button v-if="dirtyDebtIds.includes(debt.id)" @click="selectMakePayment(debt.id)" class="md-raised btn-primary">Make a Payment</md-button>
            <md-button v-if="selectedDebtsIds.includes(debt.id)" @click="cancelMakePayment(debt.id)" class="md-raised btn-warning">Cancel Payment</md-button>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { MdCheckbox } from 'vue-material/dist/components'
import {
  mapGetters,
} from 'vuex';
import CurrencyInput from './CurrencyInput';
export default {
  name: 'MultiSelectDebtLiability',
  components: {
    'currency-input': CurrencyInput
  },
  data() {
    return {
      attrs: {
        currentDebtsRepayedElections: this.debtsRepayedElections,
        localDebts: []
      },
      warnOverpaymentOfDebtIds: [],
      selectedDebtsIds: [],
      dirtyDebtIds: [],
      debtRepaymentAmounts: {}
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentRoundConfig',
    ]),
    currentDebtsRepayedElections: {
      get() {
        return this.attrs.currentDebtsRepayedElections;
      },
      set(value) {
        this.attrs.currentDebtsRepayedElections = value;
        this.$emit(`update:debts-repayed-elections`, value);
      }
    },
    localDebts: {
      get() {
        return this.attrs.localDebts;
      },
      set(value) {
        this.attrs.localDebts = value;
        this.$emit(`input`, value);
      }
    }
  },
  created() {
    this.attrs.localDebts = this.value;
    this.initDebtRepayElections(this.debtsRepayedElections);
  },
  methods: {
    initDebtRepayElections(elections) {
      this.currentDebtsRepayedElections = elections;
      this.selectedDebtsIds = this.currentDebtsRepayedElections.map((election) => election.debtLiabilityId);
      for(let election of this.currentDebtsRepayedElections) {
        this.debtRepaymentAmounts[election.debtLiabilityId] = election.repaymentAmount;
      }
    },
    toggleMakePaymentButtonState(debtId) {
      if(this.debtRepaymentAmounts[debtId] && this.debtRepaymentAmounts[debtId] !== 0) {
        this.addDebtId(debtId, this.dirtyDebtIds);
      } else {
        this.removeDebtId(debtId, this.dirtyDebtIds);
      }
    },
    addDebtId(id, arr) {
      if(arr.indexOf(id) === -1) {
        arr.push(id);
      }
    },
    removeDebtId(id, arr) {
      const debtIndex = arr.indexOf(id);
      if(debtIndex >= 0) {
        arr.splice(debtIndex, 1);
      }
    },
    selectMakePayment(debtId) {
      const debtToPay = this.localDebts.find((currentDebt) => currentDebt.id === debtId);
      if(this.debtRepaymentAmounts[debtId] > debtToPay.outstandingPrincipal) {
          this.warnOverpaymentOfDebtIds.push(debtId);
          return;
      }

      this.addDebtId(debtId, this.selectedDebtsIds);

      this.currentDebtsRepayedElections = this.selectedDebtsIds.map((_debtId) => {
        const debt = this.localDebts.find((currentDebt) => currentDebt.id === _debtId);
        let election = { debtLiabilityId: debt.id, rate: debt.originalRate, term: debt.originalTerm, originalPrincipal: debt.originalPrincipal, repaymentAmount: this.debtRepaymentAmounts[debt.id] };
        return election;
      });

      this.removeDebtId(debtId, this.dirtyDebtIds);
    },
    cancelMakePayment(debtId) {
      this.currentDebtsRepayedElections = this.currentDebtsRepayedElections.filter((debt) => debt.debtConfigId != debtId);
      this.dirtyDebtIds.push(debtId);
      this.removeDebtId(debtId, this.selectedDebtsIds);
    },
    generateInputCallback(debtId) {
      return () => {
        this.toggleMakePaymentButtonState(debtId);
      }
    },
    generateBlurCallback(debtId) {
      return () => {
        const debtToPay = this.localDebts.find((currentDebt) => currentDebt.id === debtId);
        const warnIndex = this.warnOverpaymentOfDebtIds.indexOf(debtId)
        if(warnIndex >= 0){
          this.warnOverpaymentOfDebtIds.splice(warnIndex, 1);
        }

        if(this.debtRepaymentAmounts[debtId] > debtToPay.outstandingPrincipal) {
          this.warnOverpaymentOfDebtIds.push(debtId);
        }
      }
    }
  },
  watch: {
    selectedDebtsIds(newVal, oldVal) {
      this.currentDebtsRepayedElections = newVal.map((debtLiabilityId) => {
        const debtLiability = this.localDebts.find((_debt) => _debt.id === debtLiabilityId);
        return {
          debtLiabilityId,
          rate: debtLiability.originalRate,
          term: debtLiability.originalTerm,
          originalPrincipal: debtLiability.outstandingPrincipal,
          repaymentAmount: this.debtRepaymentAmounts[debtLiabilityId]
        };
      });
    },
  },
  props: {
    value: {
      type: Array
    },
    debtsRepayedElections: {
      type: Array
    },
    activeRoundId: {
      type: String
    }
  }
}
Vue.use(MdCheckbox);
</script>

<style scoped>
  .md-layout-item {
    padding: 10px;
  }

  .wrapper {
    background-color: var(--main-box-color);
  }

  .md-button {
    margin: 1.5em;
  }

  .md-card {
    width: 300px;
    margin: 20px;
    display: inline-block;
    vertical-align: top;
  }

  .msDebtBlock {
    margin-left: auto;
    margin-right: auto;
    min-width: 98%;
    max-width: 98%;
    padding: 0;
  }

  .debt-due {
    border: 1px solid red;
  }

  .repayment-field {
    margin-top: 30px;
  }

  .left-text {
    text-align: left;
  }

  .election-config-label {
    display: inline-block;
  }

  .election-config-value {
    display: inline-block;
    font-weight: bold;
    margin-left: 15px;
  }

  .warning {
    color: var(--btn-warning-color);
  }
</style>
