<template>
    <div class="ticker-container">
        <div class="ticker-wrapper">
            <div class="ticker" ref="ticker">
                <div v-for="(data, index) in displayableStockTickerData" :key="index" :class="[data.changeFromPreviousRound < 0 ? 'negative-change' : 'positive-change']" class="ticker-item">
                    {{ data.team.stockSymbol }} {{ data.stockPrice | toCurrency }} 
                    <md-icon v-if="data.changeFromPreviousRound < 0" class="stock-caret-down fa fa-caret-down"></md-icon> 
                    <md-icon v-else class="stock-caret-up fa fa-caret-up"></md-icon> 
                    {{ Math.abs(data.changeFromPreviousRound) | toCurrency }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "StockTicker",
    data() {
        return {
            displayableStockTickerData: null
        }
    },
    computed: {
        ...mapGetters([
            'getStockTickerData'
        ]),
    },
    props: {
        activeRoundId: {
            type: String
        }
    },
    watch: {
        async activeRoundId(newVal, oldVal) {
            if(newVal && newVal !== oldVal) {
                await this.init();
            }
        },
        displayableStockTickerData(newVal, oldVal) {
            if(newVal && newVal !== oldVal) {
                this.adjustAnimationSpeed();
            }
        }
    },
    methods: {
        ...mapActions([
            'fetchStockTickerData'
        ]),
        // this is to adjust for the ticker to be the SAME speed, because of variability in length of the element
        adjustAnimationSpeed() {
            if(this.$refs['ticker']) {
                const animationSpeed = this.displayableStockTickerData.length * 5;
                this.$refs['ticker'].style["animationDuration"] = animationSpeed + "s";
                this.$refs['ticker'].style["webkitAnimationDuration"] = animationSpeed + "s";
            }
        },
        async init() {
            await this.fetchStockTickerData({
                semesterId: this.$route.params.semesterId,
                roundConfigId: this.activeRoundId
            });

            this.displayableStockTickerData = [...Array(20).keys()].reduce((result, i) => {
                return result.concat(this.getStockTickerData);
            }, []);
        }
    },
    async created() {
        await this.init();
    }
}
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }

    .ticker-container {
        width: 100%;
        overflow-x: hidden !important;
    }

    .ticker-wrapper {
        width: 100%;
        height: 64px;
        background-color: var(--nav-bar-color);
        padding-left: 100%;
        box-sizing: content-box;
    }

    @-webkit-keyframes ticker {
        0% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            visibility: visible;
        }
        100% {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }
    }

    @keyframes ticker {
        0% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            visibility: visible;
        }
        100% {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }
    }

    .ticker {
        display: inline-block;
        height: 4rem;
        line-height: 4rem;
        white-space: nowrap;
        padding-right: 100%;
        box-sizing: content-box;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-name: ticker;
        animation-name: ticker;
    }

    .ticker-item {
        display: inline-block;
        padding: 0 2rem;
        font-size: 2rem;
    }

    .positive-change {
        color: var(--success-bg-color);
    }

    .negative-change {
        color: var(--warning-text-color);
    }

    .stock-caret-up {
        color: var(--success-bg-color) !important;
        line-height: 10px;
    }

    .stock-caret-down {
        color: var(--warning-text-color) !important;
        line-height: 10px;
    }
</style>