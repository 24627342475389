<template>
    <div class="game-round-container" v-if="activeRoundId">
        <current-assets ref="currentAssetsMenu"
                        :assets="localAssets"
                        :land-sale-elections="currentLandSaleElections"
                        :land-purchase-elections="currentLandPurchaseElections"
                        :building-sale-elections="currentBuildingSaleElections"
                        :building-purchase-elections="currentBuildingPurchaseElections"
                        :equipment-sale-elections="currentEquipmentSaleElections"
                        :equipment-purchase-elections="currentEquipmentPurchaseElections"
                        :store-furnishing-sale-elections="currentStoreFurnishingSaleElections"
                        :store-furnishing-purchase-elections="currentStoreFurnishingPurchaseElections"
                        :debts-borrowed-elections="currentDebtsBorrowedElections"
                        :debts-repayed-elections="currentDebtsRepayedElections"
                        :product-elections="currentProductElections"
                        :show-inventory="routeDetail === 'go-to-market'"
                        :loan-cap="loanCap"
                        :bond-cap="bondCap"
                        :is-valid.sync="isSubmissionAllowed"
                        :should-require-positive-cash-balance-before-electing="shouldRequirePositiveCashBalanceBeforeElecting"
                        :production-cost="productionCost"
                        :stock-sale-elections="currentStockSaleElections"
                        :bond-sale-elections="currentBondSaleElections"
                        :additional-stock-sale-benefit="additionalStockSaleBenefit"
                        :total-borrow-cap="totalBorrowCap"
                        :prior-loan-amount="priorLoanAmount"
                        :prior-bonds-sold-principal="priorBondsSoldPrincipal"
                        :is-equipment-enabled-for-game="isEquipmentEnabledForGame"
                        :is-store-furnishing-enabled-for-game="isStoreFurnishingEnabledForGame"
        ></current-assets>
        <div class="gameBoard" ref="gameBoard">
            <div class="elections-alert">
                <div v-if="showFormError && !dismissAlert" class="alert-error">
                    {{errorMessage}}
                    <span @click="clearAlert()"><md-icon class="fa fa-times-circle light"></md-icon></span>
                </div>
                <div v-if="teamHasSubmittedElections && !hasSuccessfullySubmitted" class="alert-success">
                    <span>
                        Team has submitted elections
                        <br>
                        Submitted by {{getCurrentRoundElections.submittedBy.firstname}} {{getCurrentRoundElections.submittedBy.lastname}} at {{getFormattedDate(parseInt(getCurrentRoundElections.createdAt)/1000, getTeam.semester.timezone)}}
                    </span>
                </div>
                <div v-if="!teamHasSubmittedElections && !hasSuccessfullySubmitted" class="alert-salmon">
                    <span>
                        Warning! Elections have not yet been submitted for this round
                    </span>
                </div>
                <div v-if="hasSuccessfullySubmitted" class="alert-success alert-success-pulse">
                    <span v-if="hasSuccessfullySubmitted">You successfully submitted!</span>
                </div>
            </div>
            <div class="elections-alert-mobile">
                <div v-if="showFormError">
                    <md-icon class="fas fa-exclamation-circle error"><md-tooltip class="tooltip" md-direction="top">{{errorMessage}}</md-tooltip></md-icon>
                </div>
                <div v-if="teamHasSubmittedElections">
                    <md-icon class="fa fa-check-circle success"><md-tooltip class="tooltip" md-direction="top">Team has submitted elections. Submitted by {{getCurrentRoundElections.submittedBy.firstname}} {{getCurrentRoundElections.submittedBy.lastname}} at {{getFormattedDate(parseInt(getCurrentRoundElections.createdAt)/1000, getTeam.semester.timezone)}}</md-tooltip></md-icon>
                </div>
                <div v-else>
                    <md-icon class="fas fa-exclamation-triangle salmon"><md-tooltip class="tooltip" md-direction="top">Warning! Elections have not yet been submitted for this round</md-tooltip></md-icon>
                </div>
            </div>
            <div ref="step1" v-if="routeDetail === 'get-cash'">
                <div class="countdown" v-if="shouldShowCountdown">
                    <Countdown :deadline="currentDeadline" :countdown-id="'get-cash'" :hide-labels="true" :hide-big-units="true"></Countdown>
                    <div class="remaining-label">remaining</div>
                </div>
                <div class="tab-header" ref="getCashHeader">
                    <div class="center-text welcome" v-if="getCurrentGameState.roundIndex === 0">Welcome to your new game with Account-o-nomics!</div>
                    <h1>Get Cash</h1>
                    <div class="preloaded-stock" v-if="getCurrentGameState.roundIndex === 0 && initialOfferingNumShares > 0">
                      <div>Your company has sold <b>{{initialOfferingNumShares}} shares of common stock</b> at the opening price of <b>{{teamStartingStockPrice | toCurrency}} per share</b></div>
                      <div>In future rounds, your company's stock price will fluctuate to reflect the value of your company</div>
                    </div>
                    <div>
                      Sell assets and take out loans below
                    </div>
                </div>
                <div class="inputSheetStep1">
                    <div class="accordion-wrapper" ref="accordion">
                        <section class="section-container" v-if="noDebt && (!localAssets || (localAssets.lands.length === 0 && localAssets.buildings.length === 0 && localAssets.equipments.length === 0))">
                          <div class="center-text" v-if="getCurrentGameState.roundIndex === 0">Your team doesn't own any assets to sell yet</div>
                          <div class="center-text" v-else>Your team doesn't currently own any assets to sell</div>
                        </section>
                        <section class="section-container" v-if="!noDebt">
                            <div class="md-layout">
                                <div class="md-layout-item accordion-item" @click="toggleAccordionItemGetCash('borrowLoan')">
                                    Borrow Loans
                                    <md-icon v-if="!accordionItemGetCash.borrowLoan.isActive" class="accordion-icon fa fa-caret-down"></md-icon>
                                    <md-icon v-if="accordionItemGetCash.borrowLoan.isActive" class="accordion-icon fa fa-caret-up"></md-icon>
                                </div>
                            </div>
                            <transition
                                name="slide"
                                @enter="startTransition"
                                @after-enter="endTransition"
                                @before-leave="startTransition"
                                @after-leave="endTransition">
                                <debt class="accordion-detail" ref="borrowLoan" v-if="accordionItemGetCash.borrowLoan.isActive" :debts-borrowed-elections.sync="currentDebtsBorrowedElections" :loan-cap="loanCap" :total-borrow-cap="totalBorrowCap" :prior-loan-amount="priorLoanAmount">
                                </debt>
                            </transition>
                        </section>
                        <section class="section-container" v-if="!noStock">
                            <div class="md-layout">
                                <div class="md-layout-item accordion-item" @click="toggleAccordionItemGetCash('sellStock')">
                                    Sell Common Stock
                                    <md-icon v-if="!accordionItemGetCash.sellStock.isActive" class="accordion-icon fa fa-caret-down"></md-icon>
                                    <md-icon v-if="accordionItemGetCash.sellStock.isActive" class="accordion-icon fa fa-caret-up"></md-icon>
                                </div>
                            </div>
                            <transition
                                name="slide"
                                @enter="startTransition"
                                @after-enter="endTransition"
                                @before-leave="startTransition"
                                @after-leave="endTransition">
                                <stock class="accordion-detail" ref="sellStock" v-if="accordionItemGetCash.sellStock.isActive" :stock-sale-elections.sync="currentStockSaleElections" :active-round-id="activeRoundId">
                                </stock>
                            </transition>
                        </section>
                        <section class="section-container" v-if="!noBonds">
                            <div class="md-layout">
                                <div class="md-layout-item accordion-item" @click="toggleAccordionItemGetCash('sellBonds')">
                                    Sell Bonds
                                    <md-icon v-if="!accordionItemGetCash.sellBonds.isActive" class="accordion-icon fa fa-caret-down"></md-icon>
                                    <md-icon v-if="accordionItemGetCash.sellBonds.isActive" class="accordion-icon fa fa-caret-up"></md-icon>
                                </div>
                            </div>
                            <transition
                                name="slide"
                                @enter="startTransition"
                                @after-enter="endTransition"
                                @before-leave="startTransition"
                                @after-leave="endTransition">
                                <bond class="accordion-detail" ref="sellBonds" v-if="accordionItemGetCash.sellBonds.isActive" :bond-sale-elections.sync="currentBondSaleElections" :active-round-id="activeRoundId" :bond-cap="bondCap" :total-borrow-cap="totalBorrowCap" :prior-bonds-sold-principal="priorBondsSoldPrincipal">
                                </bond>
                            </transition>
                        </section>
                        <section class="section-container" v-if="localAssets && localAssets.lands.length">
                            <div class="md-layout">
                                <div class="md-layout-item accordion-item" @click="toggleAccordionItemGetCash('sellLand')">
                                    Sell Land
                                    <md-icon v-if="!accordionItemGetCash.sellLand.isActive" class="accordion-icon fa fa-caret-down"></md-icon>
                                    <md-icon v-if="accordionItemGetCash.sellLand.isActive" class="accordion-icon fa fa-caret-up"></md-icon>
                                </div>
                            </div>
                            <transition
                                name="slide"
                                @enter="startTransition"
                                @after-enter="endTransition"
                                @before-leave="startTransition"
                                @after-leave="endTransition">
                                <ms-asset class="accordion-detail"
                                        ref="sellLand"
                                        v-if="accordionItemGetCash.sellLand.isActive"
                                        v-model="localAssets.lands"
                                        :sale-elections.sync="currentLandSaleElections"
                                        :selected-assets.sync="soldLandIds"
                                        assetName="Land"
                                        :active-round-id="activeRoundId">
                                </ms-asset>
                            </transition>
                        </section>
                        <section class="section-container" v-if="localAssets && localAssets.buildings.length">
                            <div class="md-layout">
                                <div class="md-layout-item accordion-item" @click="toggleAccordionItemGetCash('sellBuilding')">
                                    Sell Building
                                    <md-icon v-if="!accordionItemGetCash.sellBuilding.isActive" class="accordion-icon fa fa-caret-down"></md-icon>
                                    <md-icon v-if="accordionItemGetCash.sellBuilding.isActive" class="accordion-icon fa fa-caret-up"></md-icon>
                                    <md-icon v-if="buildingsExpiringNow && buildingsExpiringNow.length" class="fa fa-exclamation-circle"></md-icon>
                                </div>
                            </div>
                            <transition
                                name="slide"
                                @enter="startTransition"
                                @after-enter="endTransition"
                                @before-leave="startTransition"
                                @after-leave="endTransition">
                                <ms-asset class="accordion-detail"
                                        ref="sellBuilding"
                                        v-if="accordionItemGetCash.sellBuilding.isActive"
                                        v-model="localAssets.buildings"
                                        :sale-elections.sync="currentBuildingSaleElections"
                                        :selected-assets.sync="soldBuildingIds"
                                        assetName="Building"
                                        :active-round-id="activeRoundId">
                                </ms-asset>
                            </transition>
                        </section>
                        <section class="section-container" v-if="localAssets && localAssets.equipments.length">
                            <div class="md-layout">
                                <div class="md-layout-item accordion-item" @click="toggleAccordionItemGetCash('sellEquipment')">
                                    Sell Equipment
                                    <md-icon v-if="!accordionItemGetCash.sellEquipment.isActive" class="accordion-icon fa fa-caret-down"></md-icon>
                                    <md-icon v-if="accordionItemGetCash.sellEquipment.isActive" class="accordion-icon fa fa-caret-up"></md-icon>
                                    <md-icon v-if="equipmentsExpiringNow && equipmentsExpiringNow.length" class="fa fa-exclamation-circle"></md-icon>
                                </div>
                            </div>
                            <transition
                                name="slide"
                                @enter="startTransition"
                                @after-enter="endTransition"
                                @before-leave="startTransition"
                                @after-leave="endTransition">
                                <ms-asset class="accordion-detail"
                                        ref="sellEquipment"
                                        v-if="accordionItemGetCash.sellEquipment.isActive"
                                        v-model="localAssets.equipments"
                                        :sale-elections.sync="currentEquipmentSaleElections"
                                        :selected-assets.sync="soldEquipmentIds"
                                        assetName="Equipment"
                                        :active-round-id="activeRoundId">
                                </ms-asset>
                            </transition>
                        </section>
                        <section class="section-container" v-if="localAssets && localAssets.storeFurnishings.length">
                            <div class="md-layout">
                                <div class="md-layout-item accordion-item" @click="toggleAccordionItemGetCash('sellStoreFurnishing')">
                                    Sell Store Furnishing
                                    <md-icon v-if="!accordionItemGetCash.sellStoreFurnishing.isActive" class="accordion-icon fa fa-caret-down"></md-icon>
                                    <md-icon v-if="accordionItemGetCash.sellStoreFurnishing.isActive" class="accordion-icon fa fa-caret-up"></md-icon>
                                    <md-icon v-if="furnishingsExpiringNow && furnishingsExpiringNow.length" class="fa fa-exclamation-circle"></md-icon>
                                </div>
                            </div>
                            <transition
                                name="slide"
                                @enter="startTransition"
                                @after-enter="endTransition"
                                @before-leave="startTransition"
                                @after-leave="endTransition">
                                <ms-asset class="accordion-detail"
                                        ref="sellStoreFurnishing"
                                        v-if="accordionItemGetCash.sellStoreFurnishing.isActive"
                                        v-model="localAssets.storeFurnishings"
                                        :sale-elections.sync="currentStoreFurnishingSaleElections"
                                        :selected-assets.sync="soldStoreFurnishingIds"
                                        assetName="StoreFurnishing"
                                        :active-round-id="activeRoundId">
                                </ms-asset>
                            </transition>
                        </section>
                    </div>
                    <div class="btn-wrapper" ref="getCashSubmitButton">
                        <md-button @click="goToSpendCashStep()" class="md-raised btn-primary fixed-bottom">Next, Spend Cash</md-button>
                    </div>
                </div>
            </div>
            <div class="inputSheetStep2" ref="step2" v-if="routeDetail === 'spend-cash'">
                <div class="countdown" v-if="shouldShowCountdown">
                    <Countdown :deadline="currentDeadline" :countdown-id="'spend-cash'" :hide-labels="true" :hide-big-units="true"></Countdown>
                    <div class="remaining-label">remaining</div>
                </div>
                <div class="tab-header" ref="spendCashHeader">
                    <h1>Spend Cash</h1>
                    Purchase assets & pay loans below
                </div>
                <section v-if="!noLand">
                    <div class="md-layout">
                        <div class="md-layout-item accordion-item" @click="toggleAccordionItemSpendCash('buyLand')">
                            Buy Land
                            <md-icon v-if="!accordionItemSpendCash.buyLand.isActive" class="accordion-icon fa fa-caret-down"></md-icon>
                            <md-icon v-if="accordionItemSpendCash.buyLand.isActive" class="accordion-icon fa fa-caret-up"></md-icon>
                        </div>
                    </div>
                    <transition
                            name="slide"
                            @enter="startTransition"
                            @after-enter="endTransition"
                            @before-leave="startTransition"
                            @after-leave="endTransition">
                        <land class="accordion-detail" ref="buyLand" v-if="accordionItemSpendCash.buyLand.isActive" :land-purchase-elections.sync="currentLandPurchaseElections">
                        </land>
                    </transition>
                </section>
                <section v-if="!noBuilding">
                    <div class="md-layout">
                        <div class="md-layout-item accordion-item" @click="toggleAccordionItemSpendCash('buyBuilding')">
                            Buy Building
                            <md-icon v-if="!accordionItemSpendCash.buyBuilding.isActive" class="accordion-icon fa fa-caret-down"></md-icon>
                            <md-icon v-if="accordionItemSpendCash.buyBuilding.isActive" class="accordion-icon fa fa-caret-up"></md-icon>
                        </div>
                    </div>
                    <transition
                            name="slide"
                            @enter="startTransition"
                            @after-enter="endTransition"
                            @before-leave="startTransition"
                            @after-leave="endTransition">
                        <building ref="buyBuilding" class="accordion-detail" v-if="accordionItemSpendCash.buyBuilding.isActive" :building-purchase-elections.sync="currentBuildingPurchaseElections">
                        </building>
                    </transition>
                </section>
                <section v-if="!noEquipment">
                    <div class="md-layout">
                        <div class="md-layout-item accordion-item" @click="toggleAccordionItemSpendCash('buyEquipment')">
                            Buy Equipment
                            <md-icon v-if="!accordionItemSpendCash.buyEquipment.isActive" class="accordion-icon fa fa-caret-down"></md-icon>
                            <md-icon v-if="accordionItemSpendCash.buyEquipment.isActive" class="accordion-icon fa fa-caret-up"></md-icon>
                        </div>
                    </div>
                    <transition
                            name="slide"
                            @enter="startTransition"
                            @after-enter="endTransition"
                            @before-leave="startTransition"
                            @after-leave="endTransition">
                        <equipment ref="buyEquipment" class="accordion-detail" v-if="accordionItemSpendCash.buyEquipment.isActive" :equipment-purchase-elections.sync="currentEquipmentPurchaseElections">
                        </equipment>
                    </transition>
                </section>
                <section v-if="!noStoreFurnishing">
                    <div class="md-layout">
                        <div class="md-layout-item accordion-item" @click="toggleAccordionItemSpendCash('buyStoreFurnishing')">
                            Buy Store Furnishing
                            <md-icon v-if="!accordionItemSpendCash.buyStoreFurnishing.isActive" class="accordion-icon fa fa-caret-down"></md-icon>
                            <md-icon v-if="accordionItemSpendCash.buyStoreFurnishing.isActive" class="accordion-icon fa fa-caret-up"></md-icon>
                        </div>
                    </div>
                    <transition
                            name="slide"
                            @enter="startTransition"
                            @after-enter="endTransition"
                            @before-leave="startTransition"
                            @after-leave="endTransition">
                        <store-furnishing ref="buyStoreFurnishing" class="accordion-detail" v-if="accordionItemSpendCash.buyStoreFurnishing.isActive" :store-furnishing-purchase-elections.sync="currentStoreFurnishingPurchaseElections">
                        </store-furnishing>
                    </transition>
                </section>
                <section v-if="localAssets && localAssets.debts.length">
                    <div class="md-layout">
                        <div class="md-layout-item accordion-item" @click="toggleAccordionItemSpendCash('debtRepayment')">
                            Debt Repayment
                            <md-icon v-if="!accordionItemSpendCash.debtRepayment.isActive" class="accordion-icon fa fa-caret-down"></md-icon>
                            <md-icon v-if="accordionItemSpendCash.debtRepayment.isActive" class="accordion-icon fa fa-caret-up"></md-icon>
                            <md-icon v-if="debtsDueNow && debtsDueNow.length" class="fa fa-exclamation-circle"></md-icon>
                        </div>
                    </div>
                    <transition
                            name="slide"
                            @enter="startTransition"
                            @after-enter="endTransition"
                            @before-leave="startTransition"
                            @after-leave="endTransition">
                        <ms-debt-liability ref="debtRepayment"
                                        class="accordion-detail"
                                        v-if="accordionItemSpendCash.debtRepayment.isActive"
                                        v-model="localAssets.debts"
                                        :debts-repayed-elections.sync="currentDebtsRepayedElections"
                                        :active-round-id="activeRoundId">
                        </ms-debt-liability>
                    </transition>
                </section>
                <div class="btn-wrapper" ref="spendCashSubmitButton">
                    <md-button @click="goToMarketStep()" class="md-raised btn-primary">Next, Market</md-button>
                </div>
            </div>
            <div class="inputSheetStep3" v-if="routeDetail === 'go-to-market'">
                <div class="countdown" v-if="shouldShowCountdown">
                    <Countdown :deadline="currentDeadline" :countdown-id="'go-to-market'" :hide-labels="true" :hide-big-units="true"></Countdown>
                    <div class="remaining-label">remaining</div>
                </div>
                <div class="tab-header" ref="goToMarketHeader">
                    <h1>Go to Market</h1>
                    Get product ready for the market
                </div>
                <div class="tax-and-returns" ref="goToMarketInfo">
                  <div v-if="isSalesReturnsEnabled()">
                    <span class="md-body-1"><b>Sales Returns and Allowances This Round:</b> {{ (salesReturnsPercentage * 100).toFixed(2) }}%</span>
                  </div>
                  <div v-if="isIncomeTaxEnabled()">
                    <span class="md-body-1"><b>Income Tax Rate This Round:</b> {{ taxRate.toFixed(2) }}%</span>
                  </div>
                </div>
                <section v-if="!noProducts">
                    <div class="md-layout">
                        <div class="md-layout-item accordion-item" @click="toggleAccordionItemGoToMarket('productOfferings')">
                            Product Offerings
                            <md-icon v-if="!accordionItemGoToMarket.productOfferings.isActive" class="accordion-icon fa fa-caret-down"></md-icon>
                            <md-icon v-if="accordionItemGoToMarket.productOfferings.isActive" class="accordion-icon fa fa-caret-up"></md-icon>
                        </div>
                    </div>
                    <transition
                            name="slide"
                            @enter="startTransition"
                            @after-enter="endTransition"
                            @before-leave="startTransition"
                            @after-leave="endTransition">
                        <product ref="productOfferings" class="accordion-detail" v-if="activeRoundId && accordionItemGoToMarket.productOfferings.isActive"
                                 :product-elections.sync="currentProductElections" :is-advertising-enabled="isAdvertisingEnabled()"
                                 :advertising-config="getAdvertisingConfig()"
                                 :is-product-saved.sync="isProductSaved"
                                 :production-cost.sync="productionCost"
                                 :assets.sync="localAssets"
                        >
                        </product>
                    </transition>
                    <div v-show="!isSubmissionAllowed" class="validationError formError">You must meet all asset requirements before submitting</div>
                </section>
                <md-dialog-confirm md-confirm-text="Yes, Submit"
                                md-cancel-text="Pay Debt"
                                :md-active.sync="showOutstandingDebtModal"
                                md-title="Warning"
                                md-content="You currently have a debt due at the end of this round. If left unsettled, the remaining balance owed will automatically be deducted from Cash.<br><b>Caution:</b> Your team is at risk of accumulating a Junk Loan if this causes your cash to go negative.<br> Are you sure you want to submit?"
                                @md-cancel="goToUnpaidDebts()"
                                @md-confirm="submitFromDebtWarningModal()"
                ></md-dialog-confirm>
                <md-dialog-confirm md-confirm-text="Yes, Submit"
                                md-cancel-text="Cancel"
                                :md-active.sync="showProductSaveModal"
                                md-title="Warning"
                                md-content="You currently have unsaved products with values. Click Cancel to edit or choose to submit anyway."
                                @md-cancel="showProductSaveModal = false"
                                @md-confirm="submitFromProductWarningModal()"
                ></md-dialog-confirm>
                <div ref="allow-negative-cash" v-if="!shouldRequirePositiveCashBalanceBeforeElecting" class="allow-negative-cash-message">
                    This round, teams are permitted to produce more units than cash in their accounts. Beware, if your team doesn't recuperate expenditures from market sales numbers, it could result in expensive junk loans to offset your cash liability.
                </div>
                <div ref="goToMarketSubmitButton">
                    <md-button @click="submitInputSheet()" :disabled="!isSubmissionAllowed || httpWait" v-bind:class="[{'disabled': !isSubmissionAllowed || httpWait}, 'md-raised', 'btn-primary', 'submit-btn']">Finalize and Submit Round</md-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Building from './Building.vue';
import Equipment from './Equipment.vue';
import StoreFurnishing from './StoreFurnishing.vue';
import Stock from './Stock.vue';
import Bond from './Bond.vue';
import Land from './Land.vue';
import Debt from './Debt.vue';
import Product from './Product.vue';
import Countdown from './Countdown'
import MultiSelectAsset from './MultiSelectAsset.vue';
import MultiSelectDebtLiability from './MultiSelectDebtLiability.vue';
import { MdChips, MdButton, MdDialogConfirm, MdTooltip } from 'vue-material/dist/components';
import CurrentAssets from "@/components/CurrentAssets";
import { DateTime } from "luxon";
export default {
    data() {
        const result = {
            attrs: {
                currentElections: null,
                pastAssets: null,
                isSubmissionAllowed: false
            },
            httpWait: false,
            localAssets: null,
            showOutstandingDebtModal: false,
            showProductSaveModal: false,
            roundIndex: this.initRoundIndex,
            soldLandIds: [],
            soldBuildingIds: [],
            soldEquipmentIds: [],
            currentBuildingPurchaseElections: [],
            currentBuildingSaleElections: [],
            currentEquipmentPurchaseElections: [],
            currentEquipmentSaleElections: [],
            currentStoreFurnishingPurchaseElections: [],
            currentStoreFurnishingSaleElections: [],
            currentLandPurchaseElections: [],
            currentLandSaleElections: [],
            currentStockSaleElections: [],
            currentBondSaleElections: [],
            currentDebtsBorrowedElections: [],
            currentDebtsRepayedElections: [],
            currentProductElections: [],
            showFormError: false,
            currentEquipmentCount: 0,
            accordionItemGetCash: {
                borrowLoan: {
                    index: 0,
                    isActive: true
                },
                sellStock: {
                    index: 1,
                    isActive: false
                },
                sellBonds: {
                    index: 2,
                    isActive: false
                },
                sellLand: {
                    index: 3,
                    isActive: false
                },
                sellBuilding: {
                    index: 4,
                    isActive: false
                },
                sellEquipment: {
                    index: 5,
                    isActive: false
                },
                sellStoreFurnishing: {
                    index: 6,
                    isActive: false
                }
            },
            accordionItemSpendCash: {
                buyLand: {
                    index: 0,
                    isActive: true
                },
                buyBuilding: {
                    index: 1,
                    isActive: false
                },
                buyEquipment: {
                    index: 2,
                    isActive: false
                },
                buyStoreFurnishing: {
                    index: 3,
                    isActive: false
                },
                debtRepayment: {
                    index: 4,
                    isActive: false
                }
            },
            accordionItemGoToMarket: {
                productOfferings: {
                    index: 0,
                    isActive: true
                }
            },
            componentToResize: null,
            pageHeight: 0,
            availableScreenHeight: 0,
            additionalElementsHeight: 0,
            availableComponentHeight: 0,
            dismissAlert: false,
            debtsDueNow: null,
            ignoreWarning: false,
            buildingsExpiringNow: null,
            equipmentsExpiringNow: null,
            furnishingsExpiringNow: null,
            isProductSaved: true,
            currentGamePhase: null,
            errorMessage: "Cannot submit. Check form and try again",
            teamHasSubmittedElections: false,
            hasSuccessfullySubmitted: false,
            productionCost: 0,
            shouldShowInitialStockSalesMessaging: false,
            teamStartingStockPrice: null,
            initialOfferingNumShares: 0,
            totalSellableSharesPerTeam: 0,
            additionalStockSaleBenefit: 0,
            priorLoanAmount: 0,
            priorBondsSoldPrincipal: 0
        };
        return result
    },
    name: 'GameRound',
    components: {
        CurrentAssets,
        Countdown,
        'building': Building,
        'equipment': Equipment,
        'store-furnishing': StoreFurnishing,
        'stock': Stock,
        'land': Land,
        'debt': Debt,
        'product': Product,
        'ms-asset': MultiSelectAsset,
        'ms-debt-liability': MultiSelectDebtLiability,
        'bond': Bond
    },
    computed: {
        ...mapGetters([
            'getCurrentRoundConfig',
            'getCurrentRoundElections',
            'getCurrentAssets',
            'getTeam',
            'getCurrentCourse',
            'getCurrentGameState',
            'getNextDeadline',
            'getFormattedDate'
        ]),
        isSubmissionAllowed: {
            get() {
                return this.attrs.isSubmissionAllowed;
            },
            set(value) {
                this.attrs.isSubmissionAllowed = value;
            }
        },
        noBuilding() {
            const roundConfig = this.getCurrentRoundConfig;
            if(!roundConfig) {
                return true;
            }
            return roundConfig.buildings.length === 0
        },
        noLand() {
            const roundConfig = this.getCurrentRoundConfig;
            if(!roundConfig) {
                return true;
            }
            return roundConfig.lands.length === 0
        },
        noDebt() {
            const roundConfig = this.getCurrentRoundConfig;
            const gameConfig = this.getTeam.semester.gameConfig;
            const courseRounds = this.getCurrentCourse.rounds;
            const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
            const borrowingCap = gameConfig.roundFeatures.borrowingCaps[roundIndex];
            if(!roundConfig) {
                return true;
            }
            const isDebtsEnabled = ((roundConfig.debts.length > 0) && (borrowingCap.debtRoundBorrowCap === -1 ||  borrowingCap.debtRoundBorrowCap > 0) && (borrowingCap.totalPrincipalCap === -1 ||  borrowingCap.totalPrincipalCap > 0))
            return !isDebtsEnabled;
        },
        noEquipment() {
            const roundConfig = this.getCurrentRoundConfig;
            if(!roundConfig) {
                return true;
            }
            return roundConfig.equipments.length === 0
        },
        noStoreFurnishing() {
            const roundConfig = this.getCurrentRoundConfig;
            if(!roundConfig) {
                return true;
            }
            return roundConfig.storeFurnishings.length === 0
        },
        noProducts() {
            const roundConfig = this.getCurrentRoundConfig;
            if(!roundConfig) {
                return true;
            }
            return roundConfig.products.length === 0
        },
        salesReturnsPercentage() {
            const team = this.getTeam;
            const salesReturnsForTeam = team.semester.teamSalesReturns[this.roundIndex].find((roundSalesReturn) => roundSalesReturn.teamId === team.id);
            return salesReturnsForTeam.assignedSalesReturnsPercentage;
        },
        taxRate() {
            const gameConfig = this.getTeam.semester.gameConfig;
            const courseRounds = this.getCurrentCourse.rounds;
            const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
            return gameConfig.roundFeatures.incomeTax[roundIndex];
        },
        shouldRequirePositiveCashBalanceBeforeElecting() {
            const gameConfig = this.getTeam.semester.gameConfig;
            const courseRounds = this.getCurrentCourse.rounds;
            const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
            return gameConfig.roundFeatures.requirePositiveCashBalanceBeforeElecting[roundIndex];
        },
        currentElections: {
            get() {
                return this.attrs.currentElections;
            },
            set(value) {
                this.attrs.currentElections = value;
            },
        },
        pastAssets: {
            get() {
                return this.attrs.pastAssets;
            },
            set(value) {
                this.attrs.pastAssets = value;
            }
        },
        loanCap: {
            get() {
                const gameConfig = this.getTeam.semester.gameConfig;
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                return gameConfig.roundFeatures.borrowingCaps[roundIndex].debtRoundBorrowCap;
            }
        },
        bondCap: {
            get() {
                const gameConfig = this.getTeam.semester.gameConfig;
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                return gameConfig.roundFeatures.borrowingCaps[roundIndex].bondRoundBorrowCap;
            }
        },
        totalBorrowCap: {
            get() {
                const gameConfig = this.getTeam.semester.gameConfig;
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                return gameConfig.roundFeatures.borrowingCaps[roundIndex].totalPrincipalCap;
            }
        },
        currentDeadline() {
            return this.getNextDeadline;
        },
        shouldShowCountdown() {
            const nowSeconds = DateTime.local().toSeconds();
            const nextDeadlineSeconds = this.getNextDeadline;
            if(isNaN(nextDeadlineSeconds)) {
                return false;
            }
            // show countdown if time is less than 1 hour
            return nextDeadlineSeconds - nowSeconds <= 3599;
        },
        noStock() {
            // const gameConfig = this.getTeam.semester.gameConfig;
            const courseRounds = this.getCurrentCourse.rounds;
            const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
            const isStockTradingEnabled = courseRounds[roundIndex].isStockTradingEnabled;
            return !isStockTradingEnabled;
        },
        noBonds() {
            const roundConfig = this.getCurrentRoundConfig;
            if(!roundConfig) {
              return true;
            }
            const gameConfig = this.getTeam.semester.gameConfig;
            const courseRounds = this.getCurrentCourse.rounds;
            const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
            const borrowingCap = gameConfig.roundFeatures.borrowingCaps[roundIndex];
            return roundConfig.bonds.length === 0 || borrowingCap.bondRoundBorrowCap === 0 || borrowingCap.totalPrincipalCap === 0;
        },
        isEquipmentEnabledForGame() {
            const courseRounds = this.getCurrentCourse.rounds;
            for(let round of courseRounds) {
                if(round.equipments.length > 0) {
                    return true;
                }
            }
            return false;
        },
        isStoreFurnishingEnabledForGame() {
            const courseRounds = this.getCurrentCourse.rounds;
            for(let round of courseRounds) {
                if(round.storeFurnishings.length > 0) {
                    return true;
                }
            }
            return false;
        }
    },
    created() {
        this.initLocalAssets();
        setTimeout(() => {
            this.calculateAccordionItemHeight();
        }, 1000)
    },
    destroyed () {
        window.removeEventListener('resize', this.calculateAccordionItemHeight);
    },
    watch: {
        roundNavHeight(val) {
            if(val) {
                this.initAccordionItemHeight();
            }
        },
        routeDetail(val) {
            if(val === 'get-cash' || val === 'spend-cash' || val === 'go-to-market') {
                this.componentToResize = null;
                this.resetAccordion();
                this.initAccordionItemHeight();
                this.hasSuccessfullySubmitted = false;
                this.calculateAccordionItemHeight();
            }
        },
        componentToResize(val) {
            if(val) {
                this.calculateAccordionItemHeight();
            }
        },
        async activeRoundId(val) {
            if(val) {
                this.debtsDueNow = this.localAssets.debts.filter((debt) => {
                    return debt.repaymentRoundId === val;
                });
                this.buildingsExpiringNow = this.localAssets.buildings.filter((building) => {
                    return building.expiringRoundId === val;
                });
                this.equipmentsExpiringNow = this.localAssets.equipments.filter((equipment) => {
                    return equipment.expiringRoundId === val;
                });
                this.furnishingsExpiringNow = this.localAssets.storeFurnishings.filter((furnishing) => {
                    return furnishing.expiringRoundId === val;
                });

                this.getStockConfig();
            }
        },
        getCurrentAssets(val) {
            if(val) {
                this.initLocalAssets();
            }
        }
    },
    methods: {
        ...mapActions([
            'submitRoundElections',
            'fetchBeginningOfRoundStockPrice'
        ]),
        initLocalAssets() {
            // Clone the object (make it not a reference to VueX store)
            this.localAssets = JSON.parse(JSON.stringify(this.getCurrentAssets));
            const activeBuildings = this.localAssets.buildings.filter(building => {
                return building.isSold === false;
            });
            const activeLands = this.localAssets.lands.filter(land => {
                return land.isSold === false;
            });
            const activeEquipments = this.localAssets.equipments.filter(equipment => {
                return equipment.isSold === false;
            });
            const activeStoreFurnishings = this.localAssets.storeFurnishings.filter(furnishing => {
                return furnishing.isSold === false;
            });
            const activeDebts = this.localAssets.debts.filter(debt => {
                return debt.outstandingPrincipal !== 0;
            });
            this.localAssets.buildings = activeBuildings;
            this.localAssets.lands = activeLands;
            this.localAssets.equipments = activeEquipments;
            this.localAssets.storeFurnishings = activeStoreFurnishings;
            this.localAssets.debts = activeDebts;
            this.pastAssets = JSON.parse(JSON.stringify(this.getCurrentAssets));
            this.priorLoanAmount = this.pastAssets.debts.reduce((result, debtElection) => {
                return debtElection.originalPrincipal + result;
            }, 0);
            this.priorBondsSoldPrincipal = this.pastAssets.bonds.reduce((result, bondElection) => {
                result += bondElection.principal;
                return result;
            }, 0);
            this.initCurrentElections(this.getCurrentRoundElections);
            window.addEventListener('resize', this.calculateAccordionItemHeight);
            this.debtsDueNow = this.localAssets.debts.filter((debt) => {
                return debt.repaymentRoundId === this.activeRoundId;
            });
            this.buildingsExpiringNow = this.localAssets.buildings.filter((building) => {
                return building.expiringRoundId === this.activeRoundId;
            });
            this.equipmentsExpiringNow = this.localAssets.equipments.filter((equipment) => {
                return equipment.expiringRoundId === this.activeRoundId;
            });
            this.furnishingsExpiringNow = this.localAssets.storeFurnishings.filter((furnishing) => {
                return furnishing.expiringRoundId === this.activeRoundId;
            });
            const gameState = this.getCurrentGameState;
            this.currentGamePhase = gameState.phase;
            this.initAccordionItemHeight();
            this.getStockConfig();
        },
        initCurrentElections(election) {
            this.currentElections = election;
            if(this.currentElections) {
                this.teamHasSubmittedElections = true;
                this.currentBuildingPurchaseElections = this.currentElections.buildingPurchases;
                this.currentBuildingSaleElections = this.currentElections.buildingSales;
                this.currentEquipmentPurchaseElections = this.currentElections.equipmentPurchases;
                this.currentEquipmentSaleElections = this.currentElections.equipmentSales;
                this.currentStoreFurnishingPurchaseElections = this.currentElections.storeFurnishingPurchases;
                this.currentStoreFurnishingSaleElections = this.currentElections.storeFurnishingSales;
                this.currentLandPurchaseElections = this.currentElections.landPurchases;
                this.currentLandSaleElections = this.currentElections.landSales;
                this.currentDebtsBorrowedElections = this.currentElections.debtsBorrowed;
                this.currentDebtsRepayedElections = this.currentElections.debtsRepayed;
                this.currentProductElections = this.currentElections.productElections;
                this.currentStockSaleElections = this.currentElections.stockSales;
                this.currentBondSaleElections = this.currentElections.bondSales;
            }
        },
        getStockConfig() {
            const gameConfig = this.getTeam.semester.gameConfig;
            const courseRounds = this.getCurrentCourse.rounds;
            const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
            this.teamStartingStockPrice = gameConfig.stockConfig.teamStartingStockPrice;
            this.initialOfferingNumShares = gameConfig.stockConfig.initialOfferingNumShares;
            this.shouldShowInitialStockSalesMessaging = roundIndex === 0 && (this.teamStartingStockPrice * this.initialOfferingNumShares) > 0;
            if(roundIndex === 0) {
                this.additionalStockSaleBenefit = this.teamStartingStockPrice * this.initialOfferingNumShares;
            }
        },
        async submitInputSheet() {
            const roundConfig = this.getCurrentRoundConfig;
            const input = {
                teamId: this.$route.params.teamId,
                roundConfigId: roundConfig.id,
                buildingPurchases: this.currentBuildingPurchaseElections,
                buildingSales: this.currentBuildingSaleElections,
                equipmentPurchases: this.currentEquipmentPurchaseElections,
                equipmentSales: this.currentEquipmentSaleElections,
                storeFurnishingPurchases: this.currentStoreFurnishingPurchaseElections,
                storeFurnishingSales: this.currentStoreFurnishingSaleElections,
                landPurchases: this.currentLandPurchaseElections,
                landSales: this.currentLandSaleElections,
                debtsBorrowed: this.currentDebtsBorrowedElections.map((debtBorrowed) => {
                    debtBorrowed.borrowingAmount = parseFloat(debtBorrowed.borrowingAmount);
                    return debtBorrowed;
                }),
                debtsRepayed: this.currentDebtsRepayedElections.map((debtRepayed) => {
                    debtRepayed.repaymentAmount = parseFloat(debtRepayed.repaymentAmount);
                    return debtRepayed;
                }),
                productElections: this.currentProductElections.map((productElection) => {
                    productElection.quantityOffered = parseInt(productElection.quantityOffered);
                    productElection.offerPrice = parseFloat(productElection.offerPrice);
                    productElection.numUnitsToAdvertise = parseInt(productElection.numUnitsToAdvertise);
                    return productElection;
                }),
                stockSales: this.currentStockSaleElections,
                bondSales: this.currentBondSaleElections
            };
            if((this.debtsDueNow && !this.ignoreWarning) || (!this.isProductSaved && !this.ignoreWarning)) {
                if(!this.isProductSaved) {
                    this.showProductSaveModal = true;
                }
                if(!input.debtsRepayed) {
                    this.showOutstandingDebtModal = true;
                } else {
                    for(let debt of this.debtsDueNow) {
                        if(!input.debtsRepayed.some((_debt) => {
                            return _debt.debtLiabilityId === debt.id && _debt.repaymentAmount === debt.outstandingPrincipal;
                        })) {
                            this.showOutstandingDebtModal = true;
                            break;
                        }
                    }
                }
            }
            if(!this.showOutstandingDebtModal && !this.showProductSaveModal) {
                this.httpWait = true;
                try{
                    await this.submitRoundElections(input);
                    this.hasSuccessfullySubmitted = true;
                    this.dismissAlert = false;
                    this.showFormError = false;
                    this.ignoreWarning = false;
                } catch (err) {
                    if(err == 'Error: GraphQL error: The market is closed for elections') {
                        this.errorMessage = "The market is closed for elections";
                    }
                    this.dismissAlert = false;
                    this.showFormError = true;
                }
            }
            this.httpWait = false;
            this.calculateAccordionItemHeight();
        },
        submitFromDebtWarningModal() {
            this.showOutstandingDebtModal = false;
            if(this.showProductSaveModal) {
                return;
            } else {
                this.ignoreWarning = true;
                this.submitInputSheet();
            }
        },
        submitFromProductWarningModal() {
            this.showProductSaveModal = false;
            if(this.showOutstandingDebtModal) {
                return;
            } else {
                this.ignoreWarning = true;
                this.submitInputSheet();
            }
        },
        async goToUnpaidDebts() {
            await this.$router.push({
                name: 'Game',
                params: {
                    teamId: this.$route.params.teamId,
                    routeDetail: "spend-cash"
                }
            });
            Vue.nextTick(() => {
                this.toggleAccordionItemSpendCash("debtRepayment");
            })
        },
        backToStep1() {
            this.step1Assets = null;
        },
        toggleAccordionItemGetCash(componentToDisplay) {
            let componentToClose = null;
            for(let item in this.accordionItemGetCash) {
                if(this.accordionItemGetCash[item].isActive === true) {
                    componentToClose = item;
                }
            }
            if(componentToDisplay === componentToClose) {
                return;
            } else {
                this.accordionItemGetCash[componentToClose].isActive = false;
                this.accordionItemGetCash[componentToDisplay].isActive = true;
            }
            this.componentToResize = componentToDisplay;
            this.initAccordionItemHeight();
        },
        toggleAccordionItemSpendCash(componentToDisplay) {
            let componentToClose = null;
            for(let item in this.accordionItemSpendCash) {
                if(this.accordionItemSpendCash[item].isActive === true) {
                    componentToClose = item;
                }
            }
            if(componentToDisplay === componentToClose) {
                return;
            } else {
                if(componentToClose){
                    this.accordionItemSpendCash[componentToClose].isActive = false;
                }
                this.accordionItemSpendCash[componentToDisplay].isActive = true;
            }
            this.componentToResize = componentToDisplay;
            this.initAccordionItemHeight();
        },
        toggleAccordionItemGoToMarket(componentToDisplay) {
            let componentToClose = null;
            for(let item in this.accordionItemGoToMarket) {
                if(this.accordionItemGoToMarket[item].isActive === true) {
                    componentToClose = item;
                }
            }
            if(componentToDisplay === componentToClose) {
                return;
            } else {
                this.accordionItemGoToMarket[componentToClose].isActive = false;
                this.accordionItemGoToMarket[componentToDisplay].isActive = true;
            }
            this.componentToResize = componentToDisplay;
            this.initAccordionItemHeight();
        },
        startTransition(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        endTransition(el) {
            el.style.height = ''
        },
        updateActivatedGetCashSection() {
            this.accordionItemGetCash.borrowLoan.isActive = false;
            this.accordionItemGetCash.sellStock.isActive = false;
            this.accordionItemGetCash.sellBonds.isActive = false;
            this.accordionItemGetCash.sellLand.isActive = false;
            this.accordionItemGetCash.sellBuilding.isActive = false;
            this.accordionItemGetCash.sellEquipment.isActive = false;
            this.accordionItemGetCash.sellStoreFurnishing.isActive = false;
            if(this.componentToResize){
                this.accordionItemGetCash[this.componentToResize].isActive = true;
            }
        },
        updateActivateSpendCashSection() {
            this.accordionItemSpendCash.buyLand.isActive = false;
            this.accordionItemSpendCash.buyBuilding.isActive = false;
            this.accordionItemSpendCash.buyEquipment.isActive = false;
            this.accordionItemSpendCash.buyStoreFurnishing.isActive = false;
            this.accordionItemSpendCash.debtRepayment.isActive = false;
            if(this.componentToResize) {
                this.accordionItemSpendCash[this.componentToResize].isActive = true;
            }
        },
        initAccordionItemHeight() {
            if(this.routeDetail === 'get-cash') {
                if(!this.componentToResize && this.activeRoundId) {
                    if(!this.noDebt) {
                        this.componentToResize = 'borrowLoan';
                    } else if(!this.noStock) {
                        this.componentToResize = 'sellStock';
                    } else if(!this.noBonds) {
                        this.componentToResize = 'sellBonds';
                    } else if(this.localAssets && this.localAssets.lands.length) {
                        this.componentToResize = 'sellLand'
                    } else if(this.localAssets && this.localAssets.buildings.length) {
                        this.componentToResize = 'sellBuilding'
                    } else if(this.localAssets && this.localAssets.storeFurnishings.length) {
                        this.componentToResize = 'sellStoreFurnishing'
                    } else if(this.localAssets && this.localAssets.equipments.length) {
                        this.componentToResize = 'sellEquipment'
                    }
                    this.updateActivatedGetCashSection();
                }
                // this will only run if the routeDetail is unchanged
                if(this.$refs['getCashHeader']) {
                    this.additionalElementsHeight = this.$refs['getCashHeader'].offsetHeight + this.$refs['getCashSubmitButton'].offsetHeight + 144; // 144 is the number of accordion components * their individual height (36)
                }
                // inital stock sales messaging wont always exist, add to additionalElementsHeight if it does
                if(this.$refs['initialStockSales']) {
                    this.additionalElementsHeight = this.additionalElementsHeight + this.$refs['initialStockSales'].offsetHeight; // 144 is the number of accordion components * their individual height (36)
                }
            } else if(this.routeDetail === 'spend-cash') {
                if(!this.componentToResize) {
                    if(!this.noLand) {
                        this.componentToResize = 'buyLand'
                    } else if(!this.noBuilding) {
                        this.componentToResize = 'buyBuilding'
                    } else if(!this.noEquipment) {
                        this.componentToResize = 'buyEquipment'
                    } else if(!this.noStoreFurnishing) {
                        this.componentToResize = 'buyStoreFurnishing'
                    } else if(this.localAssets && this.localAssets.debts.length) {
                        this.componentToResize = 'debtRepayment'
                    }
                    this.updateActivateSpendCashSection();
                }
                if(this.$refs['spendCashHeader']){
                    this.additionalElementsHeight = this.$refs['spendCashHeader'].offsetHeight + this.$refs['spendCashSubmitButton'].offsetHeight + 144; // 144 is the number of accordion components * their individual height (36)
                }
            } else {
                if(!this.componentToResize) {
                    this.componentToResize = 'productOfferings'
                }
                if(this.$refs['goToMarketHeader']) {
                    this.additionalElementsHeight = this.$refs['goToMarketHeader'].offsetHeight + this.$refs['goToMarketInfo'].offsetHeight + this.$refs['goToMarketSubmitButton'].offsetHeight + 36; // 144 is the number of accordion components * their individual height (36)
                }
            }
        },
        calculateAccordionItemHeight() {
            this.pageHeight = document.body.offsetHeight;
            if(this.pageHeight > 650) {
                this.availableScreenHeight = this.pageHeight - this.roundNavHeight - 64; // 64 is the height of bottom nav bar
                Vue.nextTick(() => {
                    if(!this.$refs['gameBoard']){
                        return;
                    }

                    const numSections = document.getElementsByTagName("section").length - 1; // subtract one section... (cuz it just looks better). Who knows why, padding stuff maybe
                    if(this.routeDetail === 'get-cash') {
                        this.additionalElementsHeight = this.$refs['getCashHeader'].offsetHeight + this.$refs['getCashSubmitButton'].offsetHeight + (36 * numSections); // numSections is the number of accordion components * their individual height (36)
                        if(this.$refs['initialStockSales']) {
                            this.additionalElementsHeight = this.additionalElementsHeight + this.$refs['initialStockSales'].offsetHeight;
                        }
                    } else if(this.routeDetail === 'spend-cash') {
                        this.additionalElementsHeight = this.$refs['spendCashHeader'].offsetHeight + this.$refs['spendCashSubmitButton'].offsetHeight + (36 * numSections); // numSections is the number of accordion components * their individual height (36)
                    } else {
                        const alertHeight = this.showFormError || this.hasSuccessfullySubmitted || this.teamHasSubmittedElections ? 44 : 0;
                        const requireCashHeight = this.shouldRequirePositiveCashBalanceBeforeElecting ? 0 : this.$refs['allow-negative-cash'].offsetHeight;
                        this.additionalElementsHeight = this.$refs['goToMarketHeader'].offsetHeight + this.$refs['goToMarketInfo'].offsetHeight + this.$refs['goToMarketSubmitButton'].offsetHeight + alertHeight + requireCashHeight + 36; // one section header's individual height (36)
                    }
                    this.availableComponentHeight = this.availableScreenHeight - this.additionalElementsHeight - this.$refs['currentAssetsMenu'].$el.offsetHeight;
                    this.$refs['gameBoard'].style.maxHeight = this.availableScreenHeight + "px";
                    if(this.componentToResize && this.$refs[this.componentToResize]){
                        this.$refs[this.componentToResize].$el.style.maxHeight = this.availableComponentHeight + "px";
                    }
                });
            }
        },
        clearAlert() {
            this.dismissAlert = true;
        },
        resetAccordion() {
            this.accordionItemGetCash = {
                borrowLoan: {
                    index: 0,
                    isActive: true
                },
                sellStock: {
                    index: 1,
                    isActive: false
                },
                sellBonds: {
                    index: 2,
                    isActive: false
                },
                sellLand: {
                    index: 3,
                    isActive: false
                },
                sellBuilding: {
                    index: 4,
                    isActive: false
                },
                sellEquipment: {
                    index: 5,
                    isActive: false
                },
                sellStoreFurnishing: {
                    index: 6,
                    isActive: false
                }
            };
            this.accordionItemSpendCash = {
                buyLand: {
                    index: 0,
                    isActive: true
                },
                buyBuilding: {
                    index: 1,
                    isActive: false
                },
                buyEquipment: {
                    index: 2,
                    isActive: false
                },
                buyStoreFurnishing: {
                    index: 3,
                    isActive: false
                },
                debtRepayment: {
                    index: 4,
                    isActive: false
                }
            };
            this.accordionItemGoToMarket = {
                productOfferings: {
                    index: 0,
                    isActive: true
                }
            }
        },
        isSalesReturnsEnabled() {
            const gameConfig = this.getTeam.semester.gameConfig;
            const courseRounds = this.getCurrentCourse.rounds;
            const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
            return !!gameConfig.roundFeatures.salesReturns[roundIndex];
        },
        isIncomeTaxEnabled() {
            const gameConfig = this.getTeam.semester.gameConfig;
            const courseRounds = this.getCurrentCourse.rounds;
            const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
            const taxConfig = gameConfig.roundFeatures.incomeTax[roundIndex];
            return taxConfig > 0;
        },
        isAdvertisingEnabled() {
            const gameConfig = this.getTeam.semester.gameConfig;
            const courseRounds = this.getCurrentCourse.rounds;
            const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
            const adConfig = gameConfig.roundFeatures.advertising[roundIndex];
            return adConfig.cost > 0 || adConfig.benefit > 0;
        },
        getAdvertisingConfig() {
            const gameConfig = this.getTeam.semester.gameConfig;
            const courseRounds = this.getCurrentCourse.rounds;
            const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
            return gameConfig.roundFeatures.advertising[roundIndex];
        },
        goToSpendCashStep() {
            this.$router.push({
                name: 'Game',
                params: {routeDetail: 'spend-cash', teamId: this.$route.params.teamId}
            });
        },
        goToMarketStep() {
            this.$router.push({
                name: 'Game',
                params: {routeDetail: 'go-to-market', teamId: this.$route.params.teamId}
            });
        }
    },
    props: {
        initRoundIndex: {
            type: Number,
        },
        routeDetail: {
            type: String
        },
        roundNavHeight: {
            type: Number
        },
        activeRoundId: {
            type: String
        }
    }
}
Vue.use(MdChips);
Vue.use(MdButton);
Vue.use(MdDialogConfirm);
Vue.use(MdTooltip);
</script>
<style scoped>
    .welcome {
      margin-top: 10px;
    }
    .md-layout-item {
        padding: .5em;
    }
    .formError {
        color: var(--btn-warning-color);
    }
    .formSuccess {
        color: green;
    }
    .preloaded-stock {
        margin-bottom: 15px;
        text-align: center;
    }
    .tax-and-returns {
        text-align: center;
        margin-bottom: 10px;
    }
    .gameBoard {
        margin: 1em;
        position: relative;
    }
    .accordion-item {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        min-width: 98%;
        max-width: 98%;
        cursor: pointer;
        border-bottom: 1px solid black;
    }
    .tab-header {
        padding: 15px;
    }
    .accordion-icon {
        position: absolute;
        right: 0.25rem;
    }
    .slide-enter-active, .slide-leave-active {
        will-change: height;
        -webkit-transition: height 0.3s ease;
        transition: height 0.3s ease;
    }
    .slide-enter, .slide-leave-to {
        height: 0 !important;
    }
    .accordion-detail {
        overflow-y: scroll;
    }
    .alert {
        margin-left: 1em;
        margin-right: 1em;
    }
    .alert-error {
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #ff1744;
        color: white;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: auto;
        margin-right: auto;
        max-width: 700px;
    }
    .fa.fa-times-circle.light {
        color: white;
        cursor: pointer;
    }
    .game-round-container {
        padding-bottom: 64px;
    }

    .disabled {
        opacity: 0.7;
    }

    .validationError {
        background-color: var(--main-box-color);
        margin-left: auto;
        margin-right: auto;
        min-width: 98%;
        max-width: 98%;
        padding: 0;
    }

    .center-text {
        text-align: center;
        font-weight: bold;
    }

    .countdown {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: -13px;
    }

    .remaining-label {
        margin-top: -8px;
    }

    .allow-negative-cash-message {
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .elections-alert {
        position: absolute;
        width: 33%;
        margin-left: 1em;
        max-width: 800px;
    }

    .elections-alert-mobile {
        display: none;
    }

    .md-icon.error {
        color: var(--warning-text-color);
    }

    .md-icon.success {
        color: var(--success-bg-color);
    }

    .md-icon.salmon {
        color: var(--salmon-dark);
    }

    @media screen and (max-width: 1015px) {
        .elections-alert {
            display: none;
        }
        .elections-alert-mobile {
            position: absolute;
            display: block;
            width: 65px;
        }
    }
</style>
