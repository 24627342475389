import gql from 'graphql-tag';
import { apolloClient } from "../../main";
import { AdminVuexState } from "@/types/types";
const state: AdminVuexState = {
};
const getters = {
};

const actions = {
  async adminCreateOrUpdateLicense({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation AdminCreateOrUpdateLicense($email: String!, $licenseType: String!, $licenseInput: LicenseInput) {
            adminCreateOrUpdateLicense(email: $email, licenseType: $licenseType, licenseInput: $licenseInput) {
              id
            }
          }
        `,
        variables: {
          email: payload.email,
          licenseType: payload.licenseType,
          licenseInput: payload.licenseInput
        }
      });

      return response.data.adminCreateOrUpdateLicense;
    } catch(err) {
      throw(err);
    }
  },
  async fetchAdminLicenses({ commit }: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query AdminLicenses {
            adminLicenses {
              email,
              currentLicense {
                id,
                key,
                price,
                revokedTimestamp,
                isBanned,
                purchaseNote,
                licenseType,
                email,
                studentPrice,
                school,
                licenseStart,
                licenseEnd,
                paid,
                autoRenew,
                numAllowedGames,
                user {
                  id,
                  firstname,
                  lastname,
                  email
                },
                warningNote,
                createdAt
              },
              licenseStatus,
              pendingLicense {
                id,
                key,
                price,
                revokedTimestamp,
                isBanned,
                purchaseNote,
                licenseType,
                email,
                studentPrice,
                school,
                licenseStart,
                licenseEnd,
                paid,
                autoRenew,
                numAllowedGames,
                user {
                  id,
                  firstname,
                  lastname,
                  email
                },
                warningNote,
                createdAt
              },
              warningNote,
              durationOfWarning
            }
          }
        `
      });

      return response.data.adminLicenses;
    } catch(err) {
      throw(err);
    }
  },
  async fetchProfessorLicenses({ commit }: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query ProfessorLicenses {
            professorLicenses {
              email,
              currentLicense {
                id,
                key,
                price,
                revokedTimestamp,
                isBanned,
                purchaseNote,
                licenseType,
                email,
                studentPrice,
                school,
                licenseStart,
                licenseEnd,
                paid,
                autoRenew,
                numAllowedGames,
                user {
                  id,
                  firstname,
                  lastname,
                  email
                },
                warningNote,
                createdAt
              },
              licenseStatus,
              pendingLicense {
                id,
                key,
                price,
                revokedTimestamp,
                isBanned,
                purchaseNote,
                licenseType,
                email,
                studentPrice,
                school,
                licenseStart,
                licenseEnd,
                paid,
                autoRenew,
                numAllowedGames,
                user {
                  id,
                  firstname,
                  lastname,
                  email
                },
                warningNote,
                createdAt
              },
              warningNote,
              durationOfWarning
            }
          }
        `
      });

      return response.data.professorLicenses;
    } catch(err) {
      throw(err);
    }
  },
  async fetchStudentLicenses({ commit }: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query StudentLicenses {
            studentLicenses {
              email,
              currentLicense {
                id,
                key,
                price,
                revokedTimestamp,
                isBanned,
                purchaseNote,
                licenseType,
                email,
                studentPrice,
                school,
                licenseStart,
                licenseEnd,
                paid,
                autoRenew,
                numAllowedGames,
                user {
                  id,
                  firstname,
                  lastname,
                  email
                },
                warningNote,
                createdAt,
                semesterId
              },
              licenseStatus,
              pendingLicense {
                id,
                key,
                price,
                revokedTimestamp,
                isBanned,
                purchaseNote,
                licenseType,
                email,
                studentPrice,
                school,
                licenseStart,
                licenseEnd,
                paid,
                autoRenew,
                numAllowedGames,
                user {
                  id,
                  firstname,
                  lastname,
                  email
                },
                warningNote,
                createdAt
              },
              warningNote,
              durationOfWarning
            }
          }
        `
      });

      return response.data.studentLicenses;
    } catch(err) {
      throw(err);
    }
  },
  async validateAdminLicense({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query ValidateAdminLicense($userEmail: String!) {
            validateAdminLicense(userEmail: $userEmail) {
              id,
              key,
              price,
              revokedTimestamp,
              isBanned,
              purchaseNote,
              licenseType,
              email,
              studentPrice,
              school,
              licenseStart,
              licenseEnd,
              paid,
              autoRenew,
              numAllowedGames,
              user {
                id,
                firstname,
                lastname,
                email
              },
              warningNote,
              createdAt
            },
          }
        `,
        variables: {
          userEmail: payload.userEmail
        }
      });

      return response.data.validateAdminLicense;
    } catch(err) {
      throw(err);
    }
  },
  async autoScheduleRoundTimings({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query AutoScheduleRoundTimings($semesterId: ID, $courseId: ID, $roundToBeginGeneratingNewTimings: Int!, $passedInRoundTiming: RoundTimingInput!, $timezone: String!) {
            autoScheduleRoundTimings(semesterId: $semesterId, courseId: $courseId, roundToBeginGeneratingNewTimings: $roundToBeginGeneratingNewTimings, passedInRoundTiming: $passedInRoundTiming, timezone: $timezone) {
              roundConfigId,
              electionsOpen,
              electionsDeadline,
              secondSubmissionDeadline,
              gradeViewingDeadline,
              hasCalculatedMarket,
              hasGraded
            }
          }
        `,
        variables: {
          semesterId: payload.semesterId,
          courseId: payload.courseId,
          roundToBeginGeneratingNewTimings: payload.roundToBeginGeneratingNewTimings,
          passedInRoundTiming: payload.passedInRoundTiming,
          timezone: payload.timezone
        }
      });

      return response.data.autoScheduleRoundTimings;
    } catch(err) {
      throw(err);
    }
  },
  async validateRoundTimings({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query ValidateRoundTimings($semesterId: ID, $courseId: ID, $roundTimings: [RoundTimingInput]!, $timezone: String!) {
            validateRoundTimings(semesterId: $semesterId, courseId: $courseId, roundTimings: $roundTimings, timezone: $timezone) {
              roundConfigId,
              electionsOpen,
              electionsDeadline,
              secondSubmissionDeadline,
              gradeViewingDeadline,
              hasCalculatedMarket,
              hasGraded
            }
          }
        `,
        variables: {
          semesterId: payload.semesterId,
          courseId: payload.courseId,
          roundTimings: payload.roundTimings,
          timezone: payload.timezone
        }
      });

      return response.data.validateRoundTimings;
    } catch(err) {
      throw(err);
    }
  },
  async updateSemesterRoundTimings({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: gql`
          mutation UpdateSemesterRoundTimings($semesterId: ID!, $roundTimings: [RoundTimingInput]!) {
            updateSemesterRoundTimings(semesterId: $semesterId, roundTimings: $roundTimings) {
              id
            }
          }
        `,
        variables: {
          semesterId: payload.semesterId,
          roundTimings: payload.roundTimings
        }
      });

      return response.data.updateSemesterRoundTimings;
    } catch(err) {
      throw(err);
    }
  }
};

const mutations = {
  resetAdminState(state: AdminVuexState) {
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
