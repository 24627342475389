<template>
    <div>
        <h2>Licenses</h2>
        <LicenseTable class="table-padding" :license-type="UserTypeEnum.Student" :show-search="true" :licenses.sync="licenses" :refresh-licenses="refreshLicenses"></LicenseTable>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import LicenseTable from './LicenseTable'
import { UserTypeEnum } from "@bit/accountonomics.types-lib.api-types/apiTypes";

export default {
    name: 'StudentLicenses',
    components: {
        LicenseTable
	},
    data() {
        return {
            licenses: null,
            UserTypeEnum
        }
    },
    methods: {
        ...mapActions([
            'fetchStudentLicenses'
        ]),
        async refreshLicenses() {
            this.licenses = await this.fetchStudentLicenses();
        }
    },
    async created() {
        const studentLicenseResponse = await this.fetchStudentLicenses();
        this.licenses = studentLicenseResponse.filter((license) => {
            if(!license.warningNote) {
                return license;
            }
        });
        this.licenses.map((license) => {
            if(!license.user) {
                const user = {
                    firstname: "",
                    lastname: "",
                    email: ""
                }
                license.user = user;
            }
        });
    }
}
</script>

<style scoped>
.table-padding {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 80px;
}
</style>