<template>
    <div>
        <div class="no-data" v-if="!ledgerEntries || ledgerEntries.length === 0">
            no data
        </div>
        <div v-if="ledgerEntries || ledgerEntries.length > 0" class="ledger-table">
            <md-table v-model="searched" md-sort="name" md-card>
                <md-table-toolbar>
                    <md-field md-clearable class="search-bar md-toolbar-section-end">
                        <md-input placeholder="Search..." v-model="search" @input="searchOnTable" />
                    </md-field>
                </md-table-toolbar>

                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell class="ledger-table-cell" md-label="Round Number">{{ item.roundNum }}</md-table-cell>
                    <md-table-cell class="ledger-table-cell" md-label="Team Name">{{ item.teamName }}</md-table-cell>
                    <md-table-cell class="ledger-table-cell" md-label="Amount">{{ item.amount | toCurrency }}</md-table-cell>
                    <md-table-cell class="ledger-table-cell" md-label="Account">{{ item.account }}</md-table-cell>
                </md-table-row>
            </md-table>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdTable } from 'vue-material/dist/components'
import { MdRipple } from 'vue-material/dist/components'
Vue.use(MdTable);
Vue.use(MdRipple);
export default {
    name: 'GeneralLedgerTable',
    data() {
        return {
            search: null,
            searched: []
        }
    },
    props: {
        ledgerEntries: {
            type: Array
        }
    },
    watch: {
        ledgerEntries(val) {
            if(val) {
                this.searched = val;
            }
        }
    },
    methods: {
        searchByName(items, term) {
            if(term) {
                return items.filter(item =>
                    Object.keys(item).some(property => this.toLower(item[property]).includes(this.toLower(term))));
            }
            return items;
        },
        searchOnTable() {
            if(this.ledgerEntries) {
                this.searched = this.searchByName(this.ledgerEntries, this.search);
            }
        },
        toLower(text) {
            return text.toString().toLowerCase();
        }
    },
    created() {
        this.searched = this.ledgerEntries;
    }
}
</script>

<style scoped>
.ledger-table {
    position: relative;
}
.ledger-table-cell {
    text-align: left;
}
.search-type {
    text-align: left;
}
.search-bar {
    max-width: 500px;
}
.no-data {
    text-align: center;
}
</style>