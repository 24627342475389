<template>
    <div>
        <pre class="text-left">{{courseTemplate | pretty}}</pre>
    </div>
</template>

<script>
export default {
    name: 'PlainTextCourseTemplate',
    data() {
        return {
            courseTemplate: null
        }
    },
    async created() {
        this.courseTemplate = sessionStorage.getItem('accountonomics.courseTemplate');
    }
}
</script>

<style scoped>
.text-left {
    text-align: left;
}
</style>