<template>
	<div v-if="isPageReady" class="container">
		<AdminUserLogout></AdminUserLogout>
		<div v-if="getUserType === UserTypeEnum.Professor">
			<div v-if="!showCreateSemester && !showManageRoundTimings && !showCreateCourseTemplate">
				<div class="btn-container">
					<div class="btn-wrapper" @click="logout()">
						<span class="link">Sign Out</span>
					</div>
				</div>
				<div class="main-box">
					<div class="md-layout">
						<div class="md-layout-item"></div>
						<div class="md-layout-item">
							<h1 class="text-center">Scheduled Semesters</h1>
						</div>
						<div class="md-layout-item">
							<md-button v-if="!adminUserToken" @click="showSendMessageModal = true" class="md-raised btn-primary">Send Message</md-button>
							<md-button v-if="adminUserToken" class="md-raised" disabled>Send Message</md-button>
						</div>
					</div>
					<div v-if="currentLicense">
						<span v-if="getNumNonArchivedSemesters() < currentLicense.numAllowedGames">you are using {{getNumNonArchivedSemesters()}} / {{currentLicense.numAllowedGames}} games</span>
						<span v-else>you have reached your license limit of {{currentLicense.numAllowedGames}} allowed games.  You will be required to archive old game(s) in order to create a new game</span>
					</div>
					<div class="alert">
						<div v-if="showFormSuccess && !dismissAlert" class="alert-success">
							{{alertMessage}}
							<span @click="clearAlert()"><md-icon class="fa fa-times-circle light link"></md-icon></span>
						</div>
					</div>
					<md-dialog-confirm
						v-if="selectedSemester"
						md-confirm-text="Yes"
						md-cancel-text="No"
						v-model="selectedSemester"
						:md-active.sync="showConfirmModal"
						md-title="Warning"
						:md-content="`Are you sure you want to permanently delete game ${selectedSemester.name}?`"
						@md-cancel="showConfirmModal = false"
						@md-confirm="deleteSemester(selectedSemester)"
					></md-dialog-confirm>
          <md-dialog class="modal" :md-active="showConfirmMoveGameModal">
            <md-dialog-title class="modal-title">Are you sure you want to move this game forward?</md-dialog-title>
            <md-dialog-content>
              <md-button @click="doMoveGame()" class="md-raised btn-primary">YES</md-button>
              <md-button class="md-raised btn-warning" @click="showConfirmMoveGameModal = false; moveGameSemester = null">Cancel</md-button>
            </md-dialog-content>
            <md-dialog-content v-if="showMoveSuccess">
              <div class="success">SUCCESS!</div>
            </md-dialog-content>
            <md-dialog-content v-if="showMoveError">
              <div class="error">ERROR!</div>
            </md-dialog-content>
          </md-dialog>
					<div class="switch-container">
						<md-switch v-model="showArchivedGames" class="md-primary">Show Archived Games</md-switch>
					</div>
					<md-card v-for="semester in semesters" v-bind:key="semester.id">
						<div>
							<div class="md-layout toggle-semester-link">
								<div class="left-text md-layout-item link toggle-semester-link-item" @click="toggleArchiveSemester(semester, true)">Archive</div>
							</div>
							<md-card-header>
								<div class="md-title">{{semester.name}}</div>
							</md-card-header>
							<md-card-content>
								<div class="left-text md-layout">
									<div class="md-layout-item">Start Date</div>
									<div class="config-value md-layout-item">
										<a v-if="getUserType === UserTypeEnum.Professor" class="link" @click="goToManageRoundTimings(semester)">{{convertToLocalDate(semester.startTime)}}</a>
										<span v-else>{{convertToLocalDate(semester.startTime)}}</span>
									</div>
								</div>
								<div class="left-text md-layout">
									<div class="md-layout-item">End Date</div>
									<div class="config-value md-layout-item">
										<a v-if="getUserType === UserTypeEnum.Professor" class="link" @click="goToManageRoundTimings(semester)">{{convertToLocalDate(semester.endTime)}}</a>
										<span v-else>{{convertToLocalDate(semester.endTime)}}</span>
									</div>
								</div>
								<div class="left-text md-layout">
									<div class="md-layout-item">Number of Enrolled Students</div>
									<div class="config-value md-layout-item">{{numEnrolledStudents(semester)}} / {{semester.numTeams * semester.numStudentsPerTeam}}</div>
								</div>
								<div v-if="semester.currentGamePhase !== 'GAME_OVER' && semester.currentGamePhase !== 'HASNT_STARTED'" class="left-text md-layout">
									<div class="md-layout-item">Current Game Round</div>
									<div class="config-value md-layout-item">{{semester.roundNum}}</div>
								</div>
                <div class="left-text md-layout">
                  <div class="md-layout-item">Current Game Phase</div>
                  <div class="config-value md-layout-item">
                    <div>{{printGamePhase(semester.currentGamePhase)}}</div>
                    <div v-if="semester.useFlowMode === true && (semester.currentGamePhase === GameRoundPhase.InputSheet || semester.currentGamePhase === GameRoundPhase.CalcSheets)" class="move-game-link link" @click="moveGameClicked(semester)">move game</div>
                  </div>
                </div>
								<div v-if="semester.currentGamePhase !== 'GAME_OVER'">
									<div class="left-text md-layout">
										<div class="md-layout-item">Elections Due</div>
										<div v-if="!semester.useFlowMode" class="config-value md-layout-item">{{convertToLocalDateDetailed(semester.electionsDue, semester.timezone)}}</div>
										<div v-else class="config-value md-layout-item">Flow Mode</div>
									</div>
									<div class="left-text md-layout">
										<div class="md-layout-item">Financials Due</div>
										<div v-if="!semester.useFlowMode" class="config-value md-layout-item">{{convertToLocalDateDetailed(semester.financialsDue, semester.timezone)}}</div>
										<div v-else class="config-value md-layout-item">Flow Mode</div>
									</div>
								</div>
								<div v-if="adminUserToken" class="left-text md-layout">
									<div class="md-layout-item">Semester Playground Id</div>
									<div class="config-value md-layout-item">{{semester.id}}</div>
								</div>
								<div class="left-text md-layout">
									<div class="md-layout-item">Student Onboard Code</div>
									<div class="config-value md-layout-item">{{semester.onboardCode}}</div>
								</div>
								<div v-if="adminUserToken" class="left-text md-layout">
									<div class="md-layout-item">Semester is Paused</div>
									<md-checkbox @change="toggleIsPaused(semester.id)" class="semester-checkbox md-primary md-layout-item" v-model="semester.isPaused"></md-checkbox>
								</div>
								<div>
									<md-button @click="professorSelectSemester(semester)" class="md-raised btn-primary">View Game</md-button>
								</div>
								<div v-if="adminUserToken">
									<a @click="viewCourseTemplate(semester)" class="link">View Course Template</a>
								</div>
								<div v-if="semesterToModifyRoundTimings && semesterToModifyRoundTimings.id === semester.id" class="alert-success">
									Successfully Changed Round Timings
								</div>
							</md-card-content>
						</div>
					</md-card>
					<div v-if="showArchivedGames">
						<h1 class="text-center">Archived Games</h1>
						<md-card v-for="archivedSemester in archivedSemesters" v-bind:key="archivedSemester.id">
							<div>
								<div class="md-layout toggle-semester-link">
									<div class="left-text md-layout-item link toggle-semester-link-item" @click="toggleArchiveSemester(archivedSemester, false)">Un-Archive</div>
									<div class="right-text md-layout-item link toggle-semester-link-item" @click="bringUpConfirmModal(archivedSemester)">Delete</div>
								</div>
								<md-card-header>
									<div class="md-title">{{archivedSemester.name}}</div>
								</md-card-header>
								<md-card-content>
									<div class="left-text md-layout">
										<div class="md-layout-item">Start Date</div>
										<div class="config-value md-layout-item">{{convertToLocalDate(archivedSemester.startTime)}}</div>
									</div>
									<div class="left-text md-layout">
										<div class="md-layout-item">End Date</div>
										<div class="config-value md-layout-item">{{convertToLocalDate(archivedSemester.endTime)}}</div>
									</div>
									<div v-if="adminUserToken" class="left-text md-layout">
										<div class="md-layout-item">Semester Playground Id</div>
										<div class="config-value md-layout-item">{{archivedSemester.id}}</div>
									</div>
									<div>
										<md-button @click="professorSelectSemester(archivedSemester)" class="md-raised btn-primary">View Game</md-button>
									</div>
								</md-card-content>
							</div>
						</md-card>
					</div>
				</div>
				<md-button v-if="getNumNonArchivedSemesters() < currentLicense.numAllowedGames" @click="toggleShowCreateSemester()" class="md-raised btn-primary outer-btn">Create Game</md-button>
				<div v-else>
					<md-button disabled class="md-raised outer-btn">Create Game</md-button>
					<div>you have reached your license limit of {{currentLicense.numAllowedGames}} allowed games.  You will be required to archive old game(s) in order to create a new game</div>
				</div>
        <md-button v-if="adminUserToken" @click="toggleShowCreateCourseTemplate()" class="md-raised btn-primary outer-btn">Create Course Template</md-button>
			</div>
			<div v-if="showCreateSemester">
				<div class="btn-container">
					<div class="btn-wrapper" @click="toggleShowCreateSemester()">
						<md-icon class="fa fa-angle-left link"></md-icon>
						<span class="link">Back</span>
					</div>
				</div>
				<div v-if="currentLicense && currentLicense.revokedTimestamp === null">
					<NewSemester v-if="getNumNonArchivedSemesters() < currentLicense.numAllowedGames" :show-create-semester.sync="showCreateSemester" :show-form-success.sync="showFormSuccess" :alert-message.sync="alertMessage" :dismiss-alert.sync="dismissAlert" :professor-id="currentLicense.user.id"></NewSemester>
					<span v-else>you have reached your license limit of {{currentLicense.numAllowedGames}} allowed games.  You will be required to archive old game(s) in order to create a new game</span>
				</div>
				<div v-else>
					Your current license is no longer valid. You will need to purchase a new license in order to create new games
					<ExistingUserLicenseOnboard :email="getUserEmail" :success-cb="refreshSemesterPage"></ExistingUserLicenseOnboard>
				</div>
			</div>
			<div v-if="showCreateCourseTemplate">
				<div class="btn-container">
					<div class="btn-wrapper" @click="toggleShowCreateCourseTemplate()">
						<md-icon class="fa fa-angle-left link"></md-icon>
						<span class="link">Back</span>
					</div>
				</div>
				<CourseTemplates :professor-id="currentLicense.user.id"></CourseTemplates>
			</div>
			<div v-if="showManageRoundTimings">
				<div class="btn-container">
					<div class="btn-wrapper" @click="showManageRoundTimings = false, semesterToModifyRoundTimings = null">
						<md-icon class="fa fa-angle-left link"></md-icon>
						<span class="link">Back</span>
					</div>
				</div>
				<ManageRoundTimings :semester="semesterToModifyRoundTimings" :is-admin="adminUserToken !== null" :round-timings-in-seconds.sync="roundTimingsInSeconds" :success-cb="updateRoundTimings"></ManageRoundTimings>
			</div>
			<md-dialog class="modal" :md-active="showSendMessageModal">
				<md-dialog-title class="modal-title">Send Message</md-dialog-title>

				<MessageForm :is-admin="false" :semesters="semesters" :success-cb="sendMessageSuccessCallback" :alert-message.sync="alertMessage"></MessageForm>
				<md-dialog-actions>
					<md-button class="md-accent raised" @click="showSendMessageModal = false">Close</md-button>
				</md-dialog-actions>
			</md-dialog>
		</div>
		<div v-if="getUserType === UserTypeEnum.Student">
			<div v-if="!showStudentOnboard">
				<div class="btn-container">
					<div class="btn-wrapper" @click="logout()">
					<span class="link">Sign Out</span>
					</div>
				</div>
				<div class="main-box">
					<div class="md-layout">
						<div class="md-layout-item"></div>
						<div class="md-layout-item">
							<h1 class="text-center">My Courses</h1>
              <div v-if="semesters && semesters.length === 0">You got no games! Join a game to continue</div>
						</div>
						<div class="md-layout-item">
							<div class="title-actions-container">
								<span><md-button @click="showStudentOnboard = true" class="md-raised btn-primary">Join a Game</md-button></span>
								<span @click="showStudentSettingsModal = true"><md-icon class="fas fa-cog light link title-icon"></md-icon></span>
							</div>
						</div>
					</div>
					<div class="alert">
						<div v-if="showFormSuccess && !dismissAlert" class="alert-success">
							{{alertMessage}}
							<span @click="clearAlert()"><md-icon class="fa fa-times-circle light link"></md-icon></span>
						</div>
					</div>
					<md-card md-with-hover v-for="semester in semesters" v-bind:key="semester.id">
						<md-card-header>
							<div class="md-title">{{semester.name}}</div>
						</md-card-header>
						<md-card-content>
							<div class="left-text md-layout">
								<div class="md-layout-item">Start Date</div>
								<div class="config-value md-layout-item">{{convertToLocalDate(semester.startTime)}}</div>
							</div>
							<div class="left-text md-layout">
								<div class="md-layout-item">End Date</div>
								<div class="config-value md-layout-item">{{convertToLocalDate(semester.endTime)}}</div>
							</div>
							<div v-if="semester.currentGamePhase !== 'GAME_OVER' && semester.currentGamePhase !== 'HASNT_STARTED'" class="left-text md-layout">
								<div class="md-layout-item">Current Game Round</div>
								<div class="config-value md-layout-item">{{semester.roundNum}}</div>
							</div>
							<div class="left-text md-layout">
								<div class="md-layout-item">Current Game Phase</div>
								<div class="config-value md-layout-item">{{printGamePhase(semester.currentGamePhase)}}</div>
							</div>
							<div v-if="semester.currentGamePhase && semester.currentGamePhase !== 'GAME_OVER'">
								<div class="left-text md-layout">
									<div class="md-layout-item">Elections Due</div>
									<div v-if="!semester.useFlowMode" class="config-value md-layout-item">{{convertToLocalDateDetailed(semester.electionsDue, semester.timezone)}}</div>
									<div v-else class="config-value md-layout-item">Flow Mode</div>
								</div>
								<div class="left-text md-layout">
									<div class="md-layout-item">Financials Due</div>
									<div v-if="!semester.useFlowMode" class="config-value md-layout-item">{{convertToLocalDateDetailed(semester.financialsDue, semester.timezone)}}</div>
									<div v-else class="config-value md-layout-item">Flow Mode</div>
								</div>
							</div>
							<div v-for="team in semester.teams" :key="team.id">
								<md-button @click="studentSelectSemester(semester, team)" class="md-raised btn-primary">View {{team.name}}</md-button>
							</div>
						</md-card-content>
					</md-card>
				</div>
				<md-dialog class="modal" :md-active="showStudentSettingsModal">
					<md-dialog-title class="modal-title">Settings</md-dialog-title>

					<md-dialog-content>
						<h3>Communication Settings</h3>
						<div class="md-layout md-gutter">
							<div class="md-layout-item md-size-25">
								<md-radio class="md-primary" v-for="communicationPreferenceOption in communicationPreferenceOptions" :key="communicationPreferenceOption.property" v-model="selectedCommunicationPreferenceOption" :value="communicationPreferenceOption">{{communicationPreferenceOption.text}}</md-radio>
							</div>
						</div>
					</md-dialog-content>

					<md-dialog-actions>
						<md-button class="md-primary raised" @click="saveStudentSettings()">Save</md-button>
						<md-button class="md-accent raised" @click="showStudentSettingsModal = false">Close</md-button>
					</md-dialog-actions>
				</md-dialog>
			</div>
			<div v-else>
				<div class="btn-container">
					<div class="btn-wrapper" @click="showStudentOnboard = false">
						<md-icon class="fa fa-angle-left link"></md-icon>
						<span class="link">Back</span>
					</div>
				</div>
				<Onboard :selected-email="getUserEmail" :success-cb="onboardSuccessCallback"></Onboard>
			</div>
		</div>
	</div>
</template>
<script>
import Vue from 'vue'
import { MdButton } from 'vue-material/dist/components'
import { MdCard } from 'vue-material/dist/components'
import { MdSwitch } from 'vue-material/dist/components'
import NewSemester from './NewSemester'
import ExistingUserLicenseOnboard from './ExistingUserLicenseOnboard'
import AdminUserLogout from './AdminUserLogout'
import ManageRoundTimings from './ManageRoundTimings'
import { mapGetters, mapActions } from 'vuex'
import { GameRoundPhase, UserTypeEnum } from "@bit/accountonomics.types-lib.api-types/apiTypes";
import MessageForm from './MessageForm';
import CourseTemplates from './CourseTemplates'
import Onboard from './Onboard'

Vue.use(MdButton)
Vue.use(MdCard)
Vue.use(MdSwitch)

export default {
	name: 'Semester',
	components: {
		NewSemester,
		ExistingUserLicenseOnboard,
		AdminUserLogout,
		ManageRoundTimings,
		MessageForm,
		CourseTemplates,
		Onboard
	},
	data() {
		return {
      GameRoundPhase: GameRoundPhase,
			showCreateSemester: false,
			showStudentOnboard: false,
      moveGameSemester: null,
      showMoveSuccess: false,
      showMoveError: false,
      showConfirmMoveGameModal: false,
			selectedSemester: null,
			selectedTeam: null,
			showFormSuccess: false,
			dismissAlert: true,
			showArchivedGames: false,
			semesters: null,
			archivedSemesters: null,
			showConfirmModal: false,
			currentLicense: null,
			isPageReady: false,
			UserTypeEnum,
			adminUserToken: null,
			showManageRoundTimings: false,
			semesterToModifyRoundTimings: null,
			roundTimingsInSeconds: null,
			showSendMessageModal: false,
			alertMessage: "",
			showStudentSettingsModal: false,
			communicationPreferenceOptions: [
                {
                    property: "ALL",
                    text: "All"
                },
                {
                    property: "CRITICAL",
                    text: "Critical"
                },
                {
                    property: "NONE",
                    text: "None"
                }
            ],
			selectedCommunicationPreferenceOption: null,
			showCreateCourseTemplate: false
		}
	},
	computed: {
		...mapGetters([
			'getSemesterList',
			'getUserEmail',
			'getUserType',
			'getFormattedDate'
		])
	},
	watch: {
		async getSemesterList(val) {
			if(val) {
				this.updateSemesterListsView(val);
			}
		}
	},
	methods: {
		...mapActions([
			'fetchSemesterList',
			'resetVuexState',
			'editSemester',
			'getStudentTeam',
			'setTeam',
			'getCurrentLicense',
			'updateSemesterRoundTimings',
			'toggleSemesterIsPaused',
			'fetchUserByEmail',
			'updateUserSettings',
			'fetchCourseTemplateFromCourse',
      'moveFlowModeGameForward'
		]),
    async moveGameClicked(semester) {
      this.moveGameSemester = semester;
      this.showConfirmMoveGameModal = true;
    },
    async doMoveGame() {
      const result = await this.moveFlowModeGameForward({ semesterId: this.moveGameSemester.id});
      if(result === 'success') {
        this.showMoveSuccess = true;
        setTimeout(() => {
          this.moveGameSemester = null;
          this.showConfirmMoveGameModal = false;
          this.showMoveSuccess = false;
          this.showMoveError = false;
        }, 2000)
      } else {
        this.showMoveError = true;
        setTimeout(() => {
          this.moveGameSemester = null;
          this.showConfirmMoveGameModal = false;
          this.showMoveSuccess = false;
          this.showMoveError = false;
        }, 2000)
      }
    },
		async viewCourseTemplate(semester) {
			const courseId = semester.course.id;
			const courseTemplateInputString = await this.fetchCourseTemplateFromCourse({
                courseId
            });

            sessionStorage.setItem('accountonomics.courseTemplate', courseTemplateInputString);

            let routeData = this.$router.resolve({
                name: "PlainTextCourseTemplate"
            });
            window.open(routeData.href);
		},
		async saveStudentSettings() {
			const userSettings = {
				emailPreference: this.selectedCommunicationPreferenceOption.property
			};

			const user = await this.updateUserSettings({ userSettings });
			const userEmailPreference = user.settings.emailPreference;
			this.selectedCommunicationPreferenceOption = this.communicationPreferenceOptions.find(preferenceOption => preferenceOption.property === userEmailPreference);
			this.showFormSuccess = true;
			this.dismissAlert = false;
			this.alertMessage = "Successfully updated user settings!";
			this.showStudentSettingsModal = false;
		},
		toggleShowCreateSemester(value) {
			if(value) {
				this.showCreateSemester = value;
			} else {
				this.showCreateSemester = !this.showCreateSemester;
			}
		},
		toggleShowCreateCourseTemplate(value) {
			if(value) {
				this.showCreateCourseTemplate = value;
			} else {
				this.showCreateCourseTemplate = !this.showCreateCourseTemplate;
			}
		},
		convertToLocalDate(timestamp) {
			return new Date(timestamp * 1000).toDateString();
		},
		convertToLocalDateDetailed(timestamp, timezone) {
			return this.getFormattedDate(timestamp, timezone);
		},
		numEnrolledStudents(semester) {
			return semester.teams
				.map((team) => team.students.length)
				.reduce((total, numStudents) => total += numStudents, 0);
		},
		async professorSelectSemester(semester) {
			if(semester.teams && semester.teams.length) {
				this.selectedTeam = semester.teams[0];
				await this.setTeam({ team: this.selectedTeam });
				this.$router.push({
					name: 'GameIndex',
					params: {
						teamId: this.selectedTeam.id,
						semesterId: semester.id,
						showScoreboardOnLoad: false
					}
				});
			} else {
				return;
			}
		},
		async studentSelectSemester(semester, team) {
			try {
				await this.getStudentTeam({
					teamId: team.id
				});
				if(team) {
					this.$router.push({
						name: 'GameIndex',
						params: {
							teamId: team.id,
							semesterId: semester.id,
							showScoreboardOnLoad: semester.roundNum > 1 || (semester.currentGamePhase !== GameRoundPhase.HasntStarted && semester.currentGamePhase !== GameRoundPhase.InputSheet && semester.currentGamePhase !== GameRoundPhase.CalcWaiting)
						}
					});
				}
			} catch(err) {
				if(err) {
					this.serverError = "Problem fetching student team";
				}
				return err;
			}
		},
		async toggleArchiveSemester(semester, toggleArchive) {
			try {
				await this.editSemester({
					id: semester.id,
					archive: toggleArchive
				});
				await this.fetchSemesterList();
			} catch(err) {
				if(err.graphQLErrors && err.graphQLErrors.length) {
					this.serverError = err.graphQLErrors[0].message;
				} else {
					this.serverError = "Something went wrong. Please try again"
				}
				return err;
			}
		},
		bringUpConfirmModal(semester) {
			this.showConfirmModal = true;
			this.selectedSemester = semester;
		},
		getNumNonArchivedSemesters() {
			if(this.getSemesterList && this.getSemesterList.length) {
				const nonArchivedSemesters = this.getSemesterList.filter(semester => semester.isArchived !== true);
				return nonArchivedSemesters.length;
			} else {
				return 0;
			}
		},
		async deleteSemester(semester) {
			try {
				await this.editSemester({
					id: semester.id,
					delete: true
				});
				await this.fetchSemesterList();
			} catch(err) {
				if(err.graphQLErrors && err.graphQLErrors.length) {
					this.serverError = err.graphQLErrors[0].message;
				} else {
					this.serverError = "Something went wrong. Please try again"
				}
				return err;
			}
		},
		clearAlert() {
			this.dismissAlert = true;
			this.alertMessage = "";
		},
		logout() {
			localStorage.clear();
			sessionStorage.clear();
			this.resetVuexState();
			this.$router.push({
				name: 'Login'
			});
		},
		printGamePhase(currentGamePhase) {
			switch (currentGamePhase) {
				case GameRoundPhase.HasntStarted:
					return "Game Hasn't Started";
				case GameRoundPhase.InputSheet:
					return "Input Sheet";
				case GameRoundPhase.CalcWaiting:
					return "Calc Waiting";
				case GameRoundPhase.CalcSheets:
					return "Calc Sheets";
				case GameRoundPhase.GradeWaiting:
					return "Grade Waiting"
				case GameRoundPhase.Graded:
					return "Graded";
				case GameRoundPhase.GameOver:
					return "Game Over";
				default:
					break;
			}
		},
		async toggleIsPaused(semesterId) {
			await this.toggleSemesterIsPaused({
				semesterId
			});
			await this.fetchSemesterList();
			this.updateSemesterListsView(this.getSemesterList);
		},
		async updateRoundTimings() {
			await this.updateSemesterRoundTimings({
				semesterId: this.semesterToModifyRoundTimings.id,
				roundTimings: this.roundTimingsInSeconds
			});
			this.showManageRoundTimings = false;
			await this.init();
		},
		updateSemesterListsView(semesterList) {
			if(!semesterList) {
				semesterList = [];
			}
			this.semesters = semesterList.map((semester) => {
				if(semester.isActive && !semester.isArchived) {
					return JSON.parse(JSON.stringify(semester));
				}
			}).filter((_semester) => {
				return _semester != null;
			}).sort((a, b) => a.startTime - b.startTime);
			this.archivedSemesters = semesterList.map((semester) => {
				if(semester.isActive && semester.isArchived) {
					return semester;
				}
			}).filter((_semester) => {
				return _semester != null;
			}).sort((a, b) => a.startTime - b.startTime);
		},
		goToManageRoundTimings(semester) {
			this.semesterToModifyRoundTimings = semester;
			this.showManageRoundTimings = true;
		},
		sendMessageSuccessCallback() {
			this.showSendMessageModal = false;
			this.showFormSuccess = true;
			this.dismissAlert = false;
		},
		async onboardSuccessCallback() {
			this.alertMessage = "You successfully joined";
			this.showStudentOnboard = false;
			this.showFormSuccess = true;
			this.dismissAlert = false;
			await this.init();
		},
		async init() {
			await this.fetchSemesterList();
			this.updateSemesterListsView(this.getSemesterList);
			if(this.getUserType === UserTypeEnum.Professor) {
				this.currentLicense = await this.getCurrentLicense({
					email: this.getUserEmail
				});
			}
			if(this.getUserType === UserTypeEnum.Student) {
				const user = await this.fetchUserByEmail({ email: this.getUserEmail });
				const userEmailPreference = user.settings.emailPreference;
				this.selectedCommunicationPreferenceOption = this.communicationPreferenceOptions.find(preferenceOption => preferenceOption.property === userEmailPreference);
			}
			this.adminUserToken = sessionStorage.getItem('accountonomics.adminUserToken');
			this.isPageReady = true;
		},
		async refreshSemesterPage() {
			await this.init();
			this.toggleShowCreateSemester();
		}
	},
	async created() {
		await this.init();
	}
}
</script>

<style scoped>
	.container {
		margin: 1em;
	}
	h1 {
		padding: 1em;
	}
	.main-box {
		background-color: var(--main-box-color);
		margin: 1em;
	}
	.text-center {
		text-align: center;
	}
	.btn-group {
		padding-bottom: 1em;
	}
	.md-card {
		width: 300px;
		margin: 20px;
		display: inline-block;
		vertical-align: top;
	}
	.outer-btn {
		margin-left: 1em;
	}
	.selected {
		border: blue 2px solid;
	}
	.btn-wrapper {
		display: inline-block;
	}
	.btn-container {
		padding-top: 20px;
		padding-left: 20px;
		text-align: left;
	}
	.alert {
		margin-left: 1em;
		margin-right: 1em;
	}
	.left-text {
		text-align: left;
	}
	.config-value {
		font-weight: bold;
	}
	.right-text {
		text-align: right;
	}
	.config-value {
		font-weight: bold;
	}
	.right-text {
		text-align: right;
	}
	.toggle-semester-link, .move-game-link {
		padding-top: 5px;
		padding-right: 5px;
		padding-left: 5px;
		text-decoration: underline;
	}
	.toggle-semester-link-item:hover {
		color: steelblue;
	}
  .move-game-link, move-game-link:hover {
    color: var(--btn-primary-color);
  }
	.semester-checkbox {
		margin: 0;
	}
	.modal {
		width: 100%;
	}
	.title-icon {
		line-height: 2;
		margin-left: 40px;
	}
	.settings-label {
		line-height: 4;
	}
	.title-actions-container {
		margin: 2em;
	}
  .success {
    color: var(--btn-primary-color)
  }
  .error {
    color: red;
  }
</style>
