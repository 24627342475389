<template>
    <div class="md-layout">
        <div class="md-layout-item">
            <div v-if="showBackButton" class="btn-container">
                <div class="btn-wrapper" @click="goToLogin()">
                    <md-icon class="fa fa-angle-left link"></md-icon>
                    <span class="link">Back</span>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-large-size-50 md-medium-size-50 md-small-size-70 md-xsmall-size-80 main-box">
            <h1 class="text-center">Register<span v-if="getUserType === UserTypeEnum.Student"> for Course</span></h1>
            <div v-if="getUserType === UserTypeEnum.Student">
                <div v-if="!studentOnboardSemester">
                    <div class="md-layout">
                        <div class="md-layout-item"></div>
                        <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-50 md-xsmall-size-80">
                            <label>Course Code</label>
                            <md-input v-model="semesterCode" type="text"></md-input>
                        </md-field>
                        <div class="md-layout-item"></div>
                    </div>
                    <div class="btn-group">
                        <md-button :disabled="httpWait" @click="submitOnboardCode()" class="md-raised btn-primary" :class="{ 'btn-disabled' : httpWait }">
                            Submit
                            <md-progress-spinner v-if="httpWait" class="btn-spin" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                        </md-button>
                    </div>
                </div>
            </div>
            <div v-if="studentOnboardSemester || (getUserType === UserTypeEnum.Professor && this.professorLicense)">
                <div class="student-onboard-form-container">
                    <div v-if="!selectedEmail" class="md-layout">
                        <div class="md-layout-item"></div>
                            <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                                <label>First Name</label>
                                <md-input v-model="firstName"></md-input>
                                <span v-if="inputError === 'firstName'" class="error form-error">Please enter first name</span>
                            </md-field>
                        <div class="md-layout-item"></div>
                    </div>
                    <div v-if="!selectedEmail" class="md-layout">
                        <div class="md-layout-item"></div>
                            <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                                <label>Last Name</label>
                                <md-input v-model="lastName"></md-input>
                                <span v-if="inputError === 'lastName'" class="error form-error">Please enter last name</span>
                            </md-field>
                        <div class="md-layout-item"></div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item"></div>
                            <div v-if="selectedEmail" class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                                Email: {{selectedEmail}}
                                <span v-if="inputError === 'email'" class="error form-error">Please enter a valid email</span>
                                <span v-if="inputError === 'emailDup'" class="error form-error">Email is already registered</span>
                            </div>
                            <md-field v-else class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                                <label>Email</label>
                                <md-input v-model="email"></md-input>
                                <span v-if="inputError === 'email'" class="error form-error">Please enter a valid email</span>
                                <span v-if="inputError === 'emailDup'" class="error form-error">Email is already registered</span>
                            </md-field>
                        <div class="md-layout-item"></div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item"></div>
                        <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-80">
                            <label>Password</label>
                            <md-input v-model="password" type="password"></md-input>
                            <span v-if="inputError === 'password'" class="error form-error">Please enter a valid password: must contain at least 1 uppercase character, 1 lowercase character, 1 number and be at least 6 characters in length</span>
                        </md-field>
                        <div class="md-layout-item"></div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item"></div>
                        <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-80">
                            <label>Confirm Password</label>
                            <md-input v-model="retypePassword" type="password"></md-input>
                            <span v-if="inputError === 'passwordNotMatch'" class="error form-error">Passwords must match</span>
                        </md-field>
                        <div class="md-layout-item"></div>
                    </div>
                    <div v-if="getUserType === UserTypeEnum.Professor" class="md-layout">
                        <div class="md-layout-item"></div>
                            <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                                <label>School</label>
                                <md-input v-model="professorLicense.school"></md-input>
                            </md-field>
                        <div class="md-layout-item"></div>
                    </div>
                    <div v-if="getUserType === UserTypeEnum.Student">
                        <div v-if="studentLicensePrice > 0">
                            <div class="license-container" v-if="!showNewLicenseForm">
                                <h3>Enter License Code</h3>
                                <div v-if="getUserType === UserTypeEnum.Student">Don't have a license? <span class="link license-toggle" @click="toggleLicenseForm()">Purchase License</span></div>
                                <div class="md-layout">
                                    <div class="md-layout-item"></div>
                                    <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                                        <label>License Code</label>
                                        <md-input v-model="licenseCode"></md-input>
                                    </md-field>
                                    <div class="md-layout-item"></div>
                                </div>
                            </div>
                            <div class="license-container" v-if="showNewLicenseForm">
                                <h3>Purchase License</h3>
                                <div>Already have a license? <span class="link license-toggle" @click="toggleLicenseForm()">Enter License</span></div>
                                <div class="student-price">Price: {{studentLicensePrice | toCurrency}}</div>
                                <div class="md-layout">
                                    <div class="md-layout-item"></div>
                                    <StripeCardElement :amount="calculatePaymentAmount()" class="md-layout-item md-large-size-75 md-medium-size-75 md-small-size-75 md-xsmall-size-100" ref="cardForm"></StripeCardElement>
                                    <div class="md-layout-item"></div>
                                </div>
                            </div>
                        </div>
                        <span v-if="inputError === 'license'" class="error">Enter a license code or purchase one</span>
                        <h3>Select Team</h3>
                        <span v-if="inputError === 'team'" class="error">Please select a team</span>
                        <div class="md-layout">
                            <div class="md-layout-item">
                                <md-chip
                                    v-bind:class="{ highlightSelected: selectedTeam && selectedTeam.id === team.id, disabled: team.students.length >= studentOnboardSemester.numStudentsPerTeam}"
                                    class="team-select-chip"
                                    @click="selectTeam(team)"
                                    md-with-hover v-for="team in studentOnboardSemester.teams" v-bind:key="team.id">
                                    {{team.name}}
                                </md-chip>
                            </div>
                        </div>
                        <div v-if="selectedTeam && !selectedTeam.colorCode">
                            <h3>Select Team Color</h3>
                            <div class="row">
                                <div :class="[{'selected-color': color.isSelected}, {'not-selectable': !color.isSelectable}, 'color-box']" :style="{ 'background-color': color.hexCode }"  v-for="color in selectableColors" :key="color.hexCode" @click="selectColor(color)">
                                    <div v-if="!color.isSelectable" class="x">X</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="form-container" v-if="professorLicense">
                            <h3>License Info</h3>
                            <div>Your one time license fee: {{professorLicense.price | toCurrency}}</div>
                            <div>Each student will pay: {{professorLicense.studentPrice | toCurrency}}</div>
                            <div>You will be allowed {{professorLicense.numAllowedGames}} games</div>
                            <div>Your license will end on {{getFormattedDate(professorLicense.licenseEnd)}}</div>
                        </div>
                        <div v-if="professorLicense && professorLicense.price > 0" class="md-layout">
                            <div class="md-layout-item"></div>
                            <StripeCardElement :amount="calculatePaymentAmount()" class="md-layout-item md-large-size-75 md-medium-size-75 md-small-size-75 md-xsmall-size-100" ref="cardForm"></StripeCardElement>
                            <div class="md-layout-item"></div>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item"></div>
                        <md-button :disabled="httpWait" @click="register()" class="md-raised btn-primary" :class="{ 'btn-disabled' : httpWait }">
                            {{ getCheckoutText() }}
                            <md-progress-spinner v-if="httpWait" class="btn-spin" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                        </md-button>
                        <div class="md-layout-item"></div>
                    </div>
                    <span class="error" v-if="serverError">{{serverError}}</span>
                    <span class="error" v-if="showForgotPasswordLink">
                        Incorrect password provided for your existing student account.  If you don't remember the password, please reset it with <a><span class='link' @click="initiateForgotPassword()">this link</span></a>
                    </span>
                </div>
            </div>
            <div v-else>
                <span class="error" v-if="serverError">{{serverError}}</span>
            </div>
        </div>
        <div class="md-layout-item"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { MdField } from 'vue-material/dist/components'
import { MdButton } from 'vue-material/dist/components'
import StripeCardElement from './StripeCardElement'
import { UserTypeEnum } from "@bit/accountonomics.types-lib.api-types/apiTypes";
Vue.use(MdField);
Vue.use(MdButton);
    export default {
        name: "Onboard",
        components: {
            StripeCardElement
        },
        data() {
            return {
                semesterCode: "",
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                retypePassword: null,
                selectedTeam: null,
                inputError: "",
                serverError: null,
                selectableColors: null,
                selectedColor: null,
                studentIdList: [],
                studentOnboardSemester: undefined,
                studentLicensePrice: null, // find this by getting professor semester license student price
                professorLicenseKey: null,
                showNewLicenseForm: true,
                licenseCode: "",
                cognitoUserExists: false,
                professorLicense: null,
                url: process.env.VUE_APP_LAMBDA_URL,
                UserTypeEnum,
                httpWait: false,
                showForgotPasswordLink: false,
                paymentMade: false
            }
        },
        computed: {
            ...mapGetters([
                'getUserType',
                'getFormattedDate'
            ])
        },
        props: {
            selectedEmail: String,
            successCb: Function,
            showBackButton: Boolean
        },
        methods: {
            ...mapActions([
                'setUserToken',
                'setUserRefreshToken',
                'setUserTokenExpTime',
                'setAccessToken',
                'configureUserEmail'
            ]),
            goToLogin() {
                this.$router.push({
                    name: 'Login'
                });
            },
            selectTeam(selectedTeam) {
                if(!this.selectedTeam || this.selectedTeam !== selectedTeam) {
                    this.selectedTeam = selectedTeam;
                } else {
                    this.selectedTeam = "";
                }
            },
            async submitOnboardCode() {
                this.httpWait = true;
                const data = {
                    semesterCode: this.semesterCode
                };
                try {
                    const response = await fetch(`${this.url}/onboardCode`, {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Request-Method': 'POST'
                        },
                        body: JSON.stringify(data)
                    });
                    const responseJson = await response.json();
                    if(response.ok) {
                        this.serverError = null;
                        this.studentOnboardSemester = responseJson;
                        this.studentLicensePrice = responseJson.studentPrice;
                        this.professorLicenseKey = responseJson.professorLicenseKey;
                        const usedColors = responseJson.teams.map((team) => team.colorCode);
                        this.selectableColors = responseJson.teamColors.map((color) => {
                            const isSelected = false;
                            const isSelectable = !usedColors.includes(color.hexCode);
                            return {
                                hexCode: color.hexCode,
                                isSelected,
                                isSelectable
                            }
                        });
                        this.httpWait = false;
                        return this.studentOnboardSemester;
                    } else {
                        this.serverError = "Invalid code entered. Please try again";
                        this.httpWait = false;
                        return this.serverError;
                    }
                } catch(err) {
                    this.serverError = "Something went wrong. Please try again";
                    this.httpWait = false;
                    return err;
                }
            },
            getCheckoutText() {
                const paymentAmount = this.calculatePaymentAmount();
                if(paymentAmount && paymentAmount > 0) {
                    return `Pay ${this.$options.filters.toCurrency(paymentAmount)}`;
                }
                return "Submit";
            },
            calculatePaymentAmount() {
                if(this.professorLicense && this.professorLicense.price && this.professorLicense.price > 0 && this.getUserType === UserTypeEnum.Professor) {
                    return this.professorLicense.price;
                } else if(this.studentLicensePrice && this.studentLicensePrice > 0 && this.getUserType === UserTypeEnum.Student) {
                    return this.studentLicensePrice;
                }
                return null;
            },
            async initiateForgotPassword() {
                if(this.validateEmail(this.email)) {
                    const data = {
                        email: this.email.toLowerCase(),
                        groupName: this.getUserType
                    };
                    try {
                        const response = await fetch(`${this.url}/forgotPassword`, {
                            method: 'POST',
                            mode: 'cors',
                            cache: 'no-cache',
                            headers: {
                                'Content-Type': 'application/json',
                                'Access-Control-Request-Method': 'POST'
                            },
                            body: JSON.stringify(data)
                        });
                        if(response.ok) {
                            this.clearOnboardForm();
                            this.httpWait = false;
                            const message = "A message has been sent to your email to reset your password";
                            this.$router.push({
                                name: 'NotifyMessage',
                                params: {
                                    message
                                }
                            });
                        } else {
                            this.serverError = "Could not initiate reset password";
                            return this.serverError;
                        }
                    } catch(err) {
                        this.serverError = "Something went wrong. Please try again";
                        return err;
                    }
                } else {
                    this.serverError = "Forgot password requires a valid email";
                }
            },
            async loginExistingUser() {
                const data = {
                    authParameters: {
                        USERNAME: this.email.toLowerCase(),
                        PASSWORD: this.password
                    },
                    groupName: this.getUserType
                };
                try {
                    const response = await fetch(`${this.url}/authentication`, {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Request-Method': 'POST'
                        },
                        body: JSON.stringify(data)
                    });
                    const responseJson = await response.json();
                    if(response.ok) {
                        const token = responseJson.input["AuthenticationResult"]["IdToken"];
                        const accessToken = responseJson.input["AuthenticationResult"]["AccessToken"];
                        const tokenExpSeconds = responseJson.input["AuthenticationResult"]["ExpiresIn"];
                        const refreshToken = responseJson.input["AuthenticationResult"]["RefreshToken"];
                        const currentTimestamp = Date.now();
                        const tokenExpTime = currentTimestamp + (tokenExpSeconds * 1000);
                        await this.setUserToken(token);
                        await this.setAccessToken(accessToken);
                        await this.configureUserEmail(this.email.toLowerCase());
                        await this.setUserTokenExpTime(tokenExpTime);
                        await this.setUserRefreshToken(refreshToken);
                        this.cognitoUserExists = true;
                        return await this.onboard();
                    } else {
                        if(responseJson.message === "Incorrect username or password.") {
                            this.showForgotPasswordLink = true;
                            this.httpWait = false;
                            return;
                        } else {
                            this.serverError = "Something went wrong. Please try again";
                        }
                        this.httpWait = false;
                        return this.serverError;
                    }
                } catch(err) {
                    this.serverError = "Something went wrong. Please try again";
                    this.httpWait = false;
                    return err;
                }
            },
            async onboard() {
                const data = {
                    user: {
                        email: this.email.toLowerCase(),
                        firstname: this.firstName,
                        lastname: this.lastName
                    },
                    licenseKey: this.licenseCode,
                    userType: this.getUserType
                };
                if(this.getUserType === UserTypeEnum.Student) {
                    data.teamId = this.selectedTeam.id;
                    data.selectedColor = this.selectedColor;
                    data.professorLicenseKey = this.professorLicenseKey;
                }
                if(this.getUserType === UserTypeEnum.Professor) {
                    data.school = this.professorLicense.school;
                }
                try {
                    const response = await fetch(`${this.url}/onboard`, {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Request-Method': 'POST'
                        },
                        body: JSON.stringify(data)
                    });
                    const responseJson = await response.json();
                    if(response.ok) {
                        if(this.cognitoUserExists) {
                            this.httpWait = false;
                            if(this.successCb) {
                                this.successCb();
                            }
                        } else {
                            const message = "Thanks for registering. Please check your email to confirm your account.";
                            this.$router.push({
                                name: 'NotifyMessage',
                                params: {
                                    message
                                }
                            });
                        }
                        this.clearOnboardForm();
                        this.httpWait = false;
                    } else {
                        this.cognitoUserExists = false;
                        this.serverError = "Something went wrong. Please try again";
                        if(responseJson.message === "The chosen color was taken by another player. Please choose another" || responseJson.message === "Team is full. Please choose another") {
                            this.serverError = responseJson.message;
                            await this.submitOnboardCode();
                        }
                        if(responseJson.message === "Student already exists on a team in the semester") {
                            this.serverError = "You are already enrolled for this semester";
                        }
                        if(responseJson.message === "Invalid license" || responseJson.message === "user license is revoked") {
                            this.serverError = "Invalid license. Please try again. If you were sent a confirmation email and attempt to verify, you will not be able to sign in. A new valid confirmation email will be sent after a new license is purchased or a valid license is supplied";
                        }
                        if(responseJson.message === "user license is revoked, no repurchase") {
                            this.serverError = "This license has been revoked and flagged. Please contact Accountonomics support for more details.";
                        }
                        this.httpWait = false;
                        return this.serverError;
                    }
                } catch(err) {
                    this.cognitoUserExists = false;
                    this.serverError = "Something went wrong. Please try again";
                    this.httpWait = false;
                    return err;
                }
            },
            async register() {
                this.httpWait = true;
                if(!this.licenseCode && this.calculatePaymentAmount() > 0 && !this.paymentMade) {
                    const paymentResult = await this.$refs.cardForm.confirmPaymentIntent();
                    if(paymentResult.error) {
                      this.serverError = paymentResult.error.message;
                      this.httpWait = false;
                      return paymentResult.error;
                    }
                    this.paymentMade = true;
                }
                if(this.validateForm()) {
                    if(process.env.VUE_APP_ENV === 'local') {
                        return await this.onboard();
                    }

                    const data = {
                        email: this.email.toLowerCase(),
                        password: this.password,
                        groupName: this.getUserType
                    };
                    try {
                        const response = await fetch(`${this.url}/register`, {
                            method: 'POST',
                            mode: 'cors',
                            cache: 'no-cache',
                            headers: {
                                'Content-Type': 'application/json',
                                'Access-Control-Request-Method': 'POST'
                            },
                            body: JSON.stringify(data)
                        });
                        const responseJson = await response.json();
                        if(!response.ok) {
                            this.serverError = "Something went wrong. Please try again";
                            this.httpWait = false;
                            return this.serverError;
                        } else {
                            if(responseJson.message === "Cognito user already exists.") {
                                return await this.loginExistingUser();
                            }
                            return await this.onboard();
                        }
                    } catch(err) {
                        this.serverError = "Something went wrong. Please try again";
                        this.httpWait = false;
                        return err;
                    }
                }
                this.httpWait = false;
            },
            selectColor(newSelectedColor) {
                if(newSelectedColor.isSelectable) {
                    const selectedIndex = this.selectableColors.findIndex(color => color.isSelected === true);
                    const newSelectedIndex = this.selectableColors.findIndex(color => color.hexCode === newSelectedColor.hexCode);
                    let previousSelectedColor;
                    if(selectedIndex >= 0) {
                        previousSelectedColor = this.selectableColors[selectedIndex];
                    }

                    if(previousSelectedColor && newSelectedColor.hexCode !== previousSelectedColor.hexCode) {
                        previousSelectedColor.isSelected = false;
                    }
                    this.selectableColors[newSelectedIndex].isSelected = true;
                    this.selectedColor = newSelectedColor;
                }
            },
            toggleLicenseForm() {
                this.showNewLicenseForm = !this.showNewLicenseForm;
                this.licenseCode = "";
            },
            validateForm() {
                if(!this.firstName && !this.selectedEmail) {
                    this.inputError = "firstName";
                    return false;
                } else if(!this.lastName && !this.selectedEmail) {
                    this.inputError = "lastName";
                    return false;
                } else if(!this.validateEmail(this.email)) {
                    return false;
                } else if(!this.validatePassword()) {
                    return false;
                } else if(this.password !== this.retypePassword) {
                    this.inputError = "passwordNotMatch";
                    return false;
                } else if(this.getUserType === UserTypeEnum.Student) {
                    if(!this.selectedTeam) {
                        this.inputError = "team";
                        return false;
                    } else if(!this.licenseCode && this.studentPrice > 0) {
                        this.inputError = "license";
                        return false;
                    } else {
                        this.inputError = "";
                        return true;
                    }
                } else {
                    this.inputError = "";
                    return true;
                }
            },
            validateEmail(email) {
                let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if(email && re.test(String(email).toLowerCase())) {
                    // dup email will need to exist for cognito to know whether its register or sign in
                    // for(let studentEmail of this.courseStudentEmailList) {
                    //     if(studentEmail === email) {
                    //         this.inputError = "emailDup";
                    //         return false;
                    //     }
                    // }
                    return true;
                } else {
                    this.inputError = "email";
                    return false;
                }
            },
            validatePassword() {
                const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9!@#$&%*\-<>=?|:;~(){}[\\-`.+,/"]{6,}$/;

                if(this.password && re.test(this.password)) {
                    return true;
                } else {
                    this.inputError = "password";
                    return false;
                }
            },
            clearOnboardForm() {
                this.semesterCode = "";
                this.firstName = "";
                this.lastName = "";
                this.email = "";
                this.selectedTeam = null;
                this.inputError = "";
                this.serverError = null;
                this.licenseCode = "";
                this.password = "";
                this.cognitoUserExists = false;
                this.showForgotPasswordLink = false;
                this.retypePassword = null;
            }
        },
        async created() {
            if(this.getUserType === UserTypeEnum.Student && this.selectedEmail) {
                this.email = this.selectedEmail;
            }

            if(this.getUserType === UserTypeEnum.Professor) {
                try {
                    const response = await fetch(`${this.url}/currentPricing`, {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Request-Method': 'POST'
                        }
                    });
                    const responseJson = await response.json();
                    if(response.ok) {
                        this.professorLicense = {
                            school: "",
                            price: responseJson.professorPrice,
                            studentPrice: responseJson.studentPrice,
                            numAllowedGames: responseJson.numAllowedGames,
                            licenseStart: Date.now()/1000,
                            licenseEnd: (Date.now()/1000) + responseJson.licenseDuration
                        }
                        return this.professorLicense;
                    } else {
                        this.serverError = "No current license is configured";
                        return this.serverError;
                    }
                } catch(err) {
                    this.serverError = "Something went wrong. Please try again";
                    return err;
                }
            }
        }
    }
</script>

<style scoped>
h1 {
    padding: 1em;
}
.main-box {
    background-color: var(--main-box-color);
    box-shadow: 5px 5px 3px var(--box-shadow-color);
    margin: 1em;
}
.text-center {
    text-align: center;
}
.student-onboard-form-container {
    padding: 1em;
}
.btn-group {
    padding-bottom: 1em;
}
.highlightSelected {
    background-color: var(--team-selected-bg-color) !important;
}
.error {
    color: #ff1744;
}
.form-error {
    display: block!important;
    left: 0;
    height: 20px;
    position: absolute;
    bottom: -22px;
    font-size: 12px;
    transition: .3s cubic-bezier(.4,0,.2,1);
}
.disabled {
    background-color: rgba(245, 245, 245, 1);
    opacity: .4;
    pointer-events: none;
}
.team-select-chip {
    margin-bottom: 20px;
    background-color: var(--team-chip-color);
    color: #ffffff;
    cursor: pointer;
}
.license-toggle {
    color: var(--onboard-message-color);
}
@media screen and (min-width: 1900px){
    .main-box {
        min-width: 1000px;
    }
}
.license-container {
    padding-top: 50px;
}
.student-price {
    font-weight: bold;
    padding-top: 8px;
}
.color-box {
    height: 40px;
    cursor: pointer;
    flex: 0 0 40px;
    text-align: center;
    margin: 5px;
}
.selected-color {
    border: 2px solid white;
    outline: 2px solid black;
}
.not-selectable {
    opacity: 0.3;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center
}
.x {
    height: 40px;
    line-height: 45px;
    font-size: 60px;
}
.row {
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.notify-message {
    color: var(--onboard-message-color);
}
.btn-wrapper {
    display: inline-block;
}
.btn-container {
    padding-top: 20px;
    padding-left: 20px;
    text-align: left;
}
</style>
