<template>
  <div v-if="isPageReady" class="cash-flow-container">
    <div v-if="allowSubmissionWhenValidGameConfigAndGameState && !gameStateDisabledLabel && gameStateDisabledLabel !== ''">
      <div class="no-sheet" v-if="(!currentCashFlowStatement || !currentCashFlowStatement.submittedBy) && showTeamAnswers">
        <span v-if="!isPastCashFlow">Team {{this.selectedTeam.name}} has not yet submitted a Statement of Cash Flow for Round {{ this.getActiveRoundIndex() + 1 }}</span>
        <span v-if="isPastCashFlow">Team {{this.selectedTeam.name}} did not submit a Statement of Cash Flow for Round {{ this.getActiveRoundIndex() + 1 }}</span>
      </div>
      <div class="countdown" v-if="!isPastCashFlow && shouldShowCountdown">
        <Countdown :deadline="currentDeadline" :countdown-id="'cash-flow'" :hide-labels="true" :hide-big-units="true"></Countdown>
        <div class="remaining-label">remaining</div>
      </div>
      <div v-if="isPastCashFlow && pastCashFlowTotal && showTeamAnswers">
        <h1>{{pastCashFlowCorrect}}/{{pastCashFlowTotal}} PTS</h1>
      </div>
      <div class="selected-color-indicator-container" v-if="showCorrectAnswers && isSplitView">
        <span class="selected-color-indicator">____</span> = Filter Journal Entries by Accounts for Line Item
      </div>
      <div v-if="isEditable">
        <h1>Fill out sheet to be graded</h1>
        <h4>Inflows should be expressed as positive and Outflows as negative.</h4>
        <div class="md-layout btn-group">
          <md-button v-if="!isEditing" @click="toggleIsEditing()" class="md-raised btn-rounded">
            EDIT
          </md-button>
          <md-button v-if="isEditing" @click="submitCurrentCashFlowStatement()" class="md-raised btn-rounded save-btn">
            SAVE
          </md-button>
          <md-button v-if="isEditing" @click="cancelCurrentCashFlowStatement()" class="md-raised btn-rounded cancel-btn">
            CANCEL
          </md-button>
          <div class="text-next-to-button">Don't forget to save your work often</div>
        </div>
      </div>
      <div v-if="isEditable" class="alert">
        <div v-if="showFormError && !dismissAlert" class="alert-error">
          {{errorMessage}}
          <span @click="clearAlert()"><md-icon class="fa fa-times-circle light"></md-icon></span>
        </div>
        <div v-if="currentCashFlowStatement && currentCashFlowStatement.submittedBy && !hasSuccessfullySubmitted" class="alert-success">
          <span>
            Team has submitted cash flow statement
            <br>
            Submitted by {{currentCashFlowStatement.submittedBy.firstname}} {{currentCashFlowStatement.submittedBy.lastname}} at {{getFormattedDate(parseInt(currentCashFlowStatement.createdAt)/1000, selectedTeam.semester.timezone)}}
          </span>
        </div>
        <div v-if="hasSuccessfullySubmitted" class="alert-success alert-success-pulse">
          <span v-if="hasSuccessfullySubmitted">You successfully submitted!</span>
        </div>
      </div>
    </div>
    <div class="main-box" v-if="!gameStateDisabledLabel && gameStateDisabledLabel !== ''">
      <div v-if="(allowSubmissionWhenValidGameConfigAndGameState && !isEditing) || !allowSubmissionWhenValidGameConfigAndGameState" class="read-only-table">
        <md-table>
          <md-table-toolbar class="table-title">
            <h1 class="md-title text-center title-text">
              <span v-if="isPastCashFlow">ROUND {{roundNum}}</span>
              STATEMENT OF CASH FLOW
            </h1>
          </md-table-toolbar>

          <md-table-toolbar>
            <h1 class="header-container md-title table-header">Cash Flow from Operating Activities</h1>
          </md-table-toolbar>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Inflows</md-table-cell>
            <md-table-cell v-if="showTeamAnswers" v-bind:class="[{'correct-answers-cell': isPastCashFlow && currentCashFlowStatement}, 'mobile-table-cell answers-container']">
              <span class="md-layout" v-if="!isPastCashFlow && allowSubmissionWhenValidGameConfigAndGameState && getUserType === UserTypeEnum.Student">*for negative values, prefix your input with either "-" or "("</span>
              <span v-if="currentCashFlowStatement">
                <span v-if="getUserType === UserTypeEnum.Student">Your</span> Team's Answers
              </span>
            </md-table-cell>
            <md-table-cell v-bind:class="[{'correct-answers-cell': isPastCashFlow && currentCashFlowStatement}, 'mobile-table-cell answers-container']"><span v-if="showTeamAnswers">Correct Answers</span></md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('operatingActivitiesSales')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('operatingActivitiesSales')}, {'selected-cell': currentJournalEntryFilter === 'operatingActivitiesSales'}]" class="category-label">
              <div class="table-header">Cash Collected From Customers</div>
              <div v-if="currentJournalEntryFilter === 'operatingActivitiesSales'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.operatingActivitiesSales === null) && !disabledFields.includes('operatingActivitiesSales')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('operatingActivitiesSales')">{{currentCashFlowStatement.operatingActivitiesSales | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerOperatingActivitiesSales" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('operatingActivitiesSales') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('operatingActivitiesSales')}]">
                  {{getCorrectAnswer("operatingActivitiesSales") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Outflows</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell v-if="showTeamAnswers"></md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('operatingActivitiesPurchasedInventory')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('operatingActivitiesPurchasedInventory')}, {'selected-cell': currentJournalEntryFilter === 'operatingActivitiesPurchasedInventory'}]" class="category-label">
              <div class="table-header">Cash Paid to Suppliers</div>
              <div v-if="currentJournalEntryFilter === 'operatingActivitiesPurchasedInventory'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.operatingActivitiesPurchasedInventory === null) && !disabledFields.includes('operatingActivitiesPurchasedInventory')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('operatingActivitiesPurchasedInventory')">{{currentCashFlowStatement.operatingActivitiesPurchasedInventory | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerOperatingActivitiesPurchasedInventory" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('operatingActivitiesPurchasedInventory') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('operatingActivitiesPurchasedInventory')}]">
                  {{getCorrectAnswer("operatingActivitiesPurchasedInventory") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isAdvertisingEnabled()" @click="toggleJournalEntryFilter('operatingActivitiesPaidAdvertising')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('operatingActivitiesPaidAdvertising')}, {'selected-cell': currentJournalEntryFilter === 'operatingActivitiesPaidAdvertising'}]" class="table-header category-label">
              {{getCustomLabelValue("paidAdvertising")}}
              <div v-if="currentJournalEntryFilter === 'operatingActivitiesPaidAdvertising'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.operatingActivitiesPaidAdvertising === null) && !disabledFields.includes('operatingActivitiesPaidAdvertising')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('operatingActivitiesPaidAdvertising')">{{currentCashFlowStatement.operatingActivitiesPaidAdvertising | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerOperatingActivitiesPaidAdvertising" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('operatingActivitiesPaidAdvertising') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('operatingActivitiesPaidAdvertising')}]">
                  {{getCorrectAnswer("operatingActivitiesPaidAdvertising") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isSalesReturnsEnabled()" @click="toggleJournalEntryFilter('operatingActivitiesSalesReturns')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('operatingActivitiesSalesReturns')}, {'selected-cell': currentJournalEntryFilter === 'operatingActivitiesSalesReturns'}]" class="table-header category-label">
              Sales Returns
              <div v-if="currentJournalEntryFilter === 'operatingActivitiesSalesReturns'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.operatingActivitiesSalesReturns === null) && !disabledFields.includes('operatingActivitiesSalesReturns')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('operatingActivitiesSalesReturns')">{{currentCashFlowStatement.operatingActivitiesSalesReturns | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerOperatingActivitiesSalesReturns" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('operatingActivitiesSalesReturns') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('operatingActivitiesSalesReturns')}]">
                  {{getCorrectAnswer("operatingActivitiesSalesReturns") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isIncomeTaxEnabled()" @click="toggleJournalEntryFilter('operatingActivitiesIncomeTaxesPaid')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('operatingActivitiesIncomeTaxesPaid')}, {'selected-cell': currentJournalEntryFilter === 'operatingActivitiesIncomeTaxesPaid'}]" class="table-header category-label">
              Income Taxes Paid
              <div v-if="currentJournalEntryFilter === 'operatingActivitiesIncomeTaxesPaid'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.operatingActivitiesIncomeTaxesPaid === null) && !disabledFields.includes('operatingActivitiesIncomeTaxesPaid')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('operatingActivitiesIncomeTaxesPaid')">{{currentCashFlowStatement.operatingActivitiesIncomeTaxesPaid | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerOperatingActivitiesIncomeTaxesPaid" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('operatingActivitiesIncomeTaxesPaid') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('operatingActivitiesIncomeTaxesPaid')}]">
                  {{getCorrectAnswer("operatingActivitiesIncomeTaxesPaid") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('operatingActivitiesInterestPaid')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('operatingActivitiesInterestPaid')}, {'selected-cell': currentJournalEntryFilter === 'operatingActivitiesInterestPaid'}]" class="table-header category-label">
              Interest Paid
              <div v-if="currentJournalEntryFilter === 'operatingActivitiesInterestPaid'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.operatingActivitiesInterestPaid === null) && !disabledFields.includes('operatingActivitiesInterestPaid')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('operatingActivitiesInterestPaid')">{{currentCashFlowStatement.operatingActivitiesInterestPaid | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerOperatingActivitiesInterestPaid" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('operatingActivitiesInterestPaid') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('operatingActivitiesInterestPaid')}]">
                  {{getCorrectAnswer("operatingActivitiesInterestPaid") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border bottom-border">
            <md-table-cell class="table-header category-label">Total</md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.operatingActivitiesTotal === null) && !disabledFields.includes('operatingActivitiesTotal')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('operatingActivitiesTotal')">{{currentCashFlowStatement.operatingActivitiesTotal | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerOperatingActivitiesTotal" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('operatingActivitiesTotal') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('operatingActivitiesTotal')}]">
                  {{getCorrectAnswer("operatingActivitiesTotal") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <div class="md-toolbar">
            <h1 class="md-title text-center"></h1>
          </div>

          <div class="md-toolbar">
            <h1 class="header-container md-title table-header">Cash Flow from Investing Activities</h1>
          </div>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Inflows</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell v-if="showTeamAnswers"></md-table-cell>
          </md-table-row>

          <md-table-row v-if="isEquipmentEnabledForGame" @click="toggleJournalEntryFilter('investingActivitiesInflowEquipment')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('investingActivitiesInflowEquipment')}, {'selected-cell': currentJournalEntryFilter === 'investingActivitiesInflowEquipment'}]" class="category-label">
              <div class="table-header">Equipment</div>
              <div v-if="currentJournalEntryFilter === 'investingActivitiesInflowEquipment'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.investingActivitiesInflowEquipment === null) && !disabledFields.includes('investingActivitiesInflowEquipment')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('investingActivitiesInflowEquipment')">{{currentCashFlowStatement.investingActivitiesInflowEquipment | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerInvestingActivitiesInflowEquipment" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('investingActivitiesInflowEquipment') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('investingActivitiesInflowEquipment')}]">
                  {{getCorrectAnswer("investingActivitiesInflowEquipment") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('investingActivitiesInflowFurnishings')" v-if="isFurnishingsEnabled()">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('investingActivitiesInflowFurnishings')}, {'selected-cell': currentJournalEntryFilter === 'investingActivitiesInflowFurnishings'}]" class="category-label">
              <div class="table-header">Furnishings</div>
              <div v-if="currentJournalEntryFilter === 'investingActivitiesInflowFurnishings'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.investingActivitiesInflowFurnishings === null) && !disabledFields.includes('investingActivitiesInflowFurnishings')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('investingActivitiesInflowFurnishings')">{{currentCashFlowStatement.investingActivitiesInflowFurnishings | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerInvestingActivitiesInflowFurnishings" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('investingActivitiesInflowFurnishings') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('investingActivitiesInflowFurnishings')}]">
                  {{getCorrectAnswer("investingActivitiesInflowFurnishings") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('investingActivitiesInflowBuildings')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('investingActivitiesInflowBuildings')}, {'selected-cell': currentJournalEntryFilter === 'investingActivitiesInflowBuildings'}]" class="table-header category-label">
              Buildings
              <div v-if="currentJournalEntryFilter === 'investingActivitiesInflowBuildings'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.investingActivitiesInflowBuildings === null) && !disabledFields.includes('investingActivitiesInflowBuildings')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('investingActivitiesInflowBuildings')">{{currentCashFlowStatement.investingActivitiesInflowBuildings | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerInvestingActivitiesInflowBuildings" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('investingActivitiesInflowBuildings') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('investingActivitiesInflowBuildings')}]">
                  {{getCorrectAnswer("investingActivitiesInflowBuildings") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('investingActivitiesInflowLand')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('investingActivitiesInflowLand')}, {'selected-cell': currentJournalEntryFilter === 'investingActivitiesInflowLand'}]" class="table-header category-label">
              Land
              <div v-if="currentJournalEntryFilter === 'investingActivitiesInflowLand'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.investingActivitiesInflowLand === null) && !disabledFields.includes('investingActivitiesInflowLand')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('investingActivitiesInflowLand')">{{currentCashFlowStatement.investingActivitiesInflowLand | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerInvestingActivitiesInflowLand" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('investingActivitiesInflowLand') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('investingActivitiesInflowLand')}]">
                  {{getCorrectAnswer("investingActivitiesInflowLand") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Outflows</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell v-if="showTeamAnswers"></md-table-cell>
          </md-table-row>

          <md-table-row v-if="isEquipmentEnabledForGame" @click="toggleJournalEntryFilter('investingActivitiesOutflowEquipment')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('investingActivitiesOutflowEquipment')}, {'selected-cell': currentJournalEntryFilter === 'investingActivitiesOutflowEquipment'}]" class="category-label">
              <div class="table-header">Equipment</div>
              <div v-if="currentJournalEntryFilter === 'investingActivitiesOutflowEquipment'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.investingActivitiesOutflowEquipment === null) && !disabledFields.includes('investingActivitiesOutflowEquipment')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('investingActivitiesOutflowEquipment')">{{currentCashFlowStatement.investingActivitiesOutflowEquipment | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerInvestingActivitiesOutflowEquipment" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('investingActivitiesOutflowEquipment') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('investingActivitiesOutflowEquipment')}]">
                  {{getCorrectAnswer("investingActivitiesOutflowEquipment") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('investingActivitiesOutflowFurnishings')" v-if="isFurnishingsEnabled()">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('investingActivitiesOutflowFurnishings')}, {'selected-cell': currentJournalEntryFilter === 'investingActivitiesOutflowFurnishings'}]" class="category-label">
              <div class="table-header">Furnishings</div>
              <div v-if="currentJournalEntryFilter === 'investingActivitiesOutflowFurnishings'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.investingActivitiesOutflowFurnishings === null) && !disabledFields.includes('investingActivitiesOutflowFurnishings')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('investingActivitiesOutflowFurnishings')">{{currentCashFlowStatement.investingActivitiesOutflowFurnishings | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerInvestingActivitiesOutflowFurnishings" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('investingActivitiesOutflowFurnishings') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('investingActivitiesOutflowFurnishings')}]">
                  {{getCorrectAnswer("investingActivitiesOutflowFurnishings") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('investingActivitiesOutflowBuildings')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('investingActivitiesOutflowBuildings')}, {'selected-cell': currentJournalEntryFilter === 'investingActivitiesOutflowBuildings'}]" class="table-header category-label">
              Buildings
              <div v-if="currentJournalEntryFilter === 'investingActivitiesOutflowBuildings'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.investingActivitiesOutflowBuildings === null) && !disabledFields.includes('investingActivitiesOutflowBuildings')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('investingActivitiesOutflowBuildings')">{{currentCashFlowStatement.investingActivitiesOutflowBuildings | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerInvestingActivitiesOutflowBuildings" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('investingActivitiesOutflowBuildings') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('investingActivitiesOutflowBuildings')}]">
                  {{getCorrectAnswer("investingActivitiesOutflowBuildings") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('investingActivitiesOutflowLand')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('investingActivitiesOutflowLand')}, {'selected-cell': currentJournalEntryFilter === 'investingActivitiesOutflowLand'}]" class="table-header category-label">
              Land
              <div v-if="currentJournalEntryFilter === 'investingActivitiesOutflowLand'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.investingActivitiesOutflowLand === null) && !disabledFields.includes('investingActivitiesOutflowLand')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('investingActivitiesOutflowLand')">{{currentCashFlowStatement.investingActivitiesOutflowLand | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerInvestingActivitiesOutflowLand" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('investingActivitiesOutflowLand') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('investingActivitiesOutflowLand')}]">
                  {{getCorrectAnswer("investingActivitiesOutflowLand") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border bottom-border">
            <md-table-cell class="table-header category-label">Total</md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.investingActivitiesTotal === null) && !disabledFields.includes('investingActivitiesTotal')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('investingActivitiesTotal')">{{currentCashFlowStatement.investingActivitiesTotal | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerInvestingActivitiesTotal" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('investingActivitiesTotal') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('investingActivitiesTotal')}]">
                  {{getCorrectAnswer("investingActivitiesTotal") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <div class="md-toolbar">
            <h1 class="md-title text-center"></h1>
          </div>

          <div class="md-toolbar">
            <h1 class="header-container md-title table-header">Cash Flow from Financing Activities</h1>
          </div>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Inflows</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell v-if="showTeamAnswers"></md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('financingActivitiesCapital')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('financingActivitiesCapital')}, {'selected-cell': currentJournalEntryFilter === 'financingActivitiesCapital'}]" class="category-label">
              <div class="table-header">Capital Contribution</div>
              <div v-if="currentJournalEntryFilter === 'financingActivitiesCapital'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.financingActivitiesCapital === null) && !disabledFields.includes('financingActivitiesCapital')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('financingActivitiesCapital')">{{currentCashFlowStatement.financingActivitiesCapital | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerFinancingActivitiesCapital" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('financingActivitiesCapital') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('financingActivitiesCapital')}]">
                  {{getCorrectAnswer("financingActivitiesCapital") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="areBondsSalesEnabled()" @click="toggleJournalEntryFilter('bondBorrowing')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('bondBorrowing')}, {'selected-cell': currentJournalEntryFilter === 'bondBorrowing'}]" class="table-header category-label">
              Bond Borrowing
              <div v-if="currentJournalEntryFilter === 'bondBorrowing'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.bondBorrowing === null) && !disabledFields.includes('bondBorrowing')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('bondBorrowing')">{{currentCashFlowStatement.bondBorrowing | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell  class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerBondBorrowing" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('bondBorrowing') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('bondBorrowing')}]">
                  {{getCorrectAnswer("bondBorrowing") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('financingActivitiesLoanBorrow')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('financingActivitiesLoanBorrow')}, {'selected-cell': currentJournalEntryFilter === 'financingActivitiesLoanBorrow'}]" class="table-header category-label">
              Loan Borrowing
              <div v-if="currentJournalEntryFilter === 'financingActivitiesLoanBorrow'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.financingActivitiesLoanBorrow === null) && !disabledFields.includes('financingActivitiesLoanBorrow')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('financingActivitiesLoanBorrow')">{{currentCashFlowStatement.financingActivitiesLoanBorrow | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerFinancingActivitiesLoanBorrow" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('financingActivitiesLoanBorrow') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('financingActivitiesLoanBorrow')}]">
                  {{getCorrectAnswer("financingActivitiesLoanBorrow") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Outflows</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell v-if="showTeamAnswers"></md-table-cell>
          </md-table-row>

          <md-table-row v-if="areBondsSalesEnabled()" @click="toggleJournalEntryFilter('bondRepayment')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('bondRepayment')}, {'selected-cell': currentJournalEntryFilter === 'bondRepayment'}]" class="table-header category-label">
              Bond Repayment
              <div v-if="currentJournalEntryFilter === 'bondRepayment'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.bondRepayment === null) && !disabledFields.includes('bondRepayment')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('bondRepayment')">{{currentCashFlowStatement.bondRepayment | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell  class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerBondRepayment" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('bondRepayment') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('bondRepayment')}]">
                  {{getCorrectAnswer("bondRepayment") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('financingActivitiesLoanRepay')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters.includes('financingActivitiesLoanRepay')}, {'selected-cell': currentJournalEntryFilter === 'financingActivitiesLoanRepay'}]" class="category-label">
              <div class="table-header">Loan Repayment</div>
              <div v-if="currentJournalEntryFilter === 'financingActivitiesLoanRepay'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.financingActivitiesLoanRepay === null) && !disabledFields.includes('financingActivitiesLoanRepay')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('financingActivitiesLoanRepay')">{{currentCashFlowStatement.financingActivitiesLoanRepay | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerFinancingActivitiesLoanRepay" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('financingActivitiesLoanRepay') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('financingActivitiesLoanRepay')}]">
                  {{getCorrectAnswer("financingActivitiesLoanRepay") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border bottom-border">
            <md-table-cell class="table-header category-label">Total</md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.financingActivitiesLoanRepay === null) && !disabledFields.includes('financingActivitiesLoanRepay')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('financingActivitiesTotal')">{{currentCashFlowStatement.financingActivitiesLoanRepay | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentCashFlowStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerFinancingActivitiesTotal" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('financingActivitiesTotal') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('financingActivitiesTotal')}]">
                  {{getCorrectAnswer("financingActivitiesTotal") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <md-table class="summary-table">
          <md-table-row>
            <md-table-cell class="table-header category-label">Net Increase (Decrease) in Cash</md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.netCash === null) && !disabledFields.includes('netCash')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('netCash')">{{currentCashFlowStatement.netCash | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell v-if="currentCashFlowStatement && showCorrectAnswers" class="summary-table-value correct-answers-cell">
              <div class="answers-container">
                <div ref="correctAnswerNetCash" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('netCash') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('netCash')}]">
                  {{getCorrectAnswer("netCash") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Beginning of Round</md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.beginningOfRound === null) && !disabledFields.includes('beginningOfRound')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('beginningOfRound')">{{currentCashFlowStatement.beginningOfRound | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell v-if="currentCashFlowStatement && showCorrectAnswers" class="md-table-cell summary-table-value correct-answers-cell">
              <div class="answers-container">
                <div ref="correctAnswerBeginningOfRound" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('beginningOfRound') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('beginningOfRound')}]">
                  {{getCorrectAnswer("beginningOfRound") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">End of Round</md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentCashFlowStatement || currentCashFlowStatement.endOfRound === null) && !disabledFields.includes('endOfRound')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('endOfRound')">{{currentCashFlowStatement.endOfRound | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell v-if="currentCashFlowStatement && showCorrectAnswers" class="md-table-cell summary-table-value correct-answers-cell">
              <div class="answers-container">
                <div ref="correctAnswerEndOfRound" v-bind:class="[incorrectAnswerFields && incorrectAnswerFields.includes('endOfRound') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('endOfRound')}]">
                  {{getCorrectAnswer("endOfRound") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>

      <div v-if="allowSubmissionWhenValidGameConfigAndGameState && isEditing && !isPastCashFlow" class="input-table">
        <md-table>
          <md-table-toolbar class="table-title title-text">
            <h1 class="md-title text-center title-text">STATEMENT OF CASH FLOW</h1>
          </md-table-toolbar>

          <md-table-toolbar>
            <h1 class="header-container md-title table-header">Cash Flow from Operating Activities</h1>
          </md-table-toolbar>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Inflows</md-table-cell>
            <md-table-cell>
              <span class="md-layout mobile-table-cell">*for negative values, prefix your input with either "-" or "("</span>
              <span class="md-layout mobile-table-cell">$ = Field submission required for grading</span>
            </md-table-cell>
          </md-table-row>

          <md-table-row >
            <md-table-cell class="category-label">
              <div class="table-header">Cash Collected From Customers</div>
            </md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('operatingActivitiesSales')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.operatingActivitiesSales">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Outflows</md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Cash Paid to Suppliers</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('operatingActivitiesPurchasedInventory')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.operatingActivitiesPurchasedInventory">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isAdvertisingEnabled()">
            <md-table-cell class="table-header category-label">{{getCustomLabelValue("paidAdvertising")}}</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('operatingActivitiesPaidAdvertising')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.operatingActivitiesPaidAdvertising">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isSalesReturnsEnabled()">
            <md-table-cell class="table-header category-label">Sales Returns</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('operatingActivitiesSalesReturns')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.operatingActivitiesSalesReturns">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isIncomeTaxEnabled()">
            <md-table-cell class="table-header category-label">Income Taxes Paid</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('operatingActivitiesIncomeTaxesPaid')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.operatingActivitiesIncomeTaxesPaid">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Interest Paid</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('operatingActivitiesInterestPaid')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.operatingActivitiesInterestPaid">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border bottom-border">
            <md-table-cell class="table-header category-label">Total</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('operatingActivitiesTotal')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.operatingActivitiesTotal">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <md-table class="investing-table">
          <md-table-toolbar>
            <h1 class="md-title text-center"></h1>
          </md-table-toolbar>

          <md-table-toolbar>
            <h1 class="header-container md-title table-header">Cash Flow from Investing Activities</h1>
          </md-table-toolbar>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Inflows</md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row v-if="isEquipmentEnabledForGame">
            <md-table-cell class="table-header category-label">Equipment</md-table-cell>
            <md-table-cell class="category-label">
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('investingActivitiesInflowEquipment')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.investingActivitiesInflowEquipment">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isFurnishingsEnabled()">
            <md-table-cell class="table-header category-label">Furnishings</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('investingActivitiesInflowFurnishings')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.investingActivitiesInflowFurnishings">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Buildings</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('investingActivitiesInflowBuildings')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.investingActivitiesInflowBuildings">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Land</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('investingActivitiesInflowLand')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.investingActivitiesInflowLand">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Outflows</md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row v-if="isEquipmentEnabledForGame">
            <md-table-cell class="table-header category-label">Equipment</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('investingActivitiesOutflowEquipment')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.investingActivitiesOutflowEquipment">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isFurnishingsEnabled()">
            <md-table-cell class="table-header category-label">Furnishings</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('investingActivitiesOutflowFurnishings')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.investingActivitiesOutflowFurnishings">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Buildings</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('investingActivitiesOutflowBuildings')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.investingActivitiesOutflowBuildings">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Land</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('investingActivitiesOutflowLand')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.investingActivitiesOutflowLand">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border bottom-border">
            <md-table-cell class="table-header category-label">Total</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('investingActivitiesTotal')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.investingActivitiesTotal">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <md-table class="financing-table">
          <md-table-toolbar>
            <h1 class="md-title text-center"></h1>
          </md-table-toolbar>

          <md-table-toolbar>
            <h1 class="header-container md-title table-header">Cash Flow from Financing Activities</h1>
          </md-table-toolbar>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Inflows</md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Capital Contribution</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('financingActivitiesCapital')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.financingActivitiesCapital">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="areBondsSalesEnabled()">
            <md-table-cell class="table-header category-label">Bond Borrowing</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('bondBorrowing')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.bondBorrowing">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Loan Borrowing</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('financingActivitiesLoanBorrow')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.financingActivitiesLoanBorrow">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border non-input-header">
            <md-table-cell>Outflows</md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row v-if="areBondsSalesEnabled()">
            <md-table-cell class="table-header category-label">Bond Repayment</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('bondRepayment')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.bondRepayment">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="bottom-border">
            <md-table-cell class="table-header category-label">Loan Repayment</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('financingActivitiesLoanRepayment')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.financingActivitiesLoanRepayment">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-table-cell-border bottom-border">
            <md-table-cell class="table-header category-label">Total</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('financingActivitiesTotal')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.financingActivitiesTotal">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <md-table class="summary-table">
          <md-table-row>
            <md-table-cell class="table-header category-label">Net Increase (Decrease) in Cash</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('netCash')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.netCash">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Cash, Beginning of Round</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('beginningOfRound')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.beginningOfRound">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Cash, End of Round</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('endOfRound')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="cashFlowInput.endOfRound">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
    <div v-else v-bind:class="{'game-state-disabled-label': gameStateDisabledLabel !== ''}">
      {{gameStateDisabledLabel}}
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import CurrencyInput from './CurrencyInput';
import Countdown from './Countdown';
import { MdContent } from 'vue-material/dist/components'
import { MdTable } from 'vue-material/dist/components'
import { MdCard } from 'vue-material/dist/components'
import { MdField } from 'vue-material/dist/components'
import { DateTime } from 'luxon'
import { mapGetters, mapActions } from 'vuex'
import {
  GameRoundPhase,
  RoundModuleDisplaySettingEnum,
  UserTypeEnum
} from "@bit/accountonomics.types-lib.api-types/apiTypes";

Vue.use(MdContent)
Vue.use(MdTable)
Vue.use(MdCard)
Vue.use(MdField)

export default {
  name: 'CashFlow',
  components: {
    Countdown,
    'currency-input': CurrencyInput
  },
  data() {
    return {
      attrs: {
        currentTeamHasSubmittedCashFlow: this.teamHasSubmittedCashFlow,
        currentJournalEntryFilter: this.journalEntryFilter,
        currentAvailableJournalEntryFilters: this.availableJournalEntryFilters
      },
      isEditing: false,
      currentCashFlowStatement: {},
      cashFlowInput: {
        roundConfigId: null,
        operatingActivitiesSales: null,
        operatingActivitiesPurchasedInventory: null,
        operatingActivitiesPaidAdvertising: null,
        operatingActivitiesSalesReturns: null,
        operatingActivitiesIncomeTaxesPaid: null,
        operatingActivitiesInterestPaid: null,
        operatingActivitiesTotal: null,
        investingActivitiesInflowEquipment: null,
        investingActivitiesInflowFurnishings: null,
        investingActivitiesInflowBuildings: null,
        investingActivitiesInflowLand: null,
        investingActivitiesOutflowEquipment: null,
        investingActivitiesOutflowFurnishings: null,
        investingActivitiesOutflowBuildings: null,
        investingActivitiesOutflowLand: null,
        investingActivitiesTotal: null,
        financingActivitiesCapital: null,
        financingActivitiesLoanBorrow: null,
        financingActivitiesLoanRepay: null,
        financingActivitiesTotal: null,
        netCash: null,
        beginningOfRound: null,
        endOfRound: null
      },
      dismissAlert: false,
      teamId: null,
      isPastCashFlow: false,
      incorrectAnswerFields: [],
      correctAnswers: null,
      roundNum: null,
      pastCashFlowCorrect: null,
      pastCashFlowTotal: null,
      currentGamePhase: null,
      showFormError: false,
      errorMessage: "Cannot submit. Check form and try again",
      hasSuccessfullySubmitted: false,
      isPageReady: false,
      disabledFields: [],
      gameStateDisabledLabel: null,
      showTeamAnswers: false,
      showCorrectAnswers: false,
      isEditable: false,
      UserTypeEnum
    }
  },
  props: {
    allowSubmissionWhenValidGameConfigAndGameState: {
      type: Boolean
    },
    activeRoundId: {
      type: String
    },
    selectedTeam: {
      type: Object
    },
    teamHasSubmittedCashFlow: {
      type: Boolean
    },
    journalEntryFilter: {
      type: String
    },
    isSplitView: {
      type: Boolean
    },
    availableJournalEntryFilters: {
      type: Array
    }
  },
  watch: {
    selectedTeam(val) {
      if(val) {
        this.initCashFlowStatement();
      }
    },
    activeRoundId(val) {
      if(val) {
        this.initCashFlowStatement();
      }
    },
    journalEntryFilter(val) {
      this.currentJournalEntryFilter = val;
    },
    availableJournalEntryFilters(val) {
      this.currentAvailableJournalEntryFilters = val;
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentCashFlowStatement',
      'getCurrentRoundConfig',
      'getCurrentCourse',
      'getCurrentGameState',
      'getNextDeadline',
      'getFormattedDate',
      'getUserType',
      'getGameLabels'
      ]),
    currentDeadline() {
      return this.getNextDeadline;
    },
    currentTeamHasSubmittedCashFlow: {
      get() {
        return this.attrs.currentTeamHasSubmittedCashFlow;
      },
      set(value) {
        this.attrs.currentTeamHasSubmittedCashFlow = value;
        this.$emit(`update:team-has-submitted-cash-flow`, value);
      }
    },
    currentJournalEntryFilter: {
      get() {
        return this.attrs.currentJournalEntryFilter;
      },
      set(value) {
        this.attrs.currentJournalEntryFilter = value;
        this.$emit(`update:journal-entry-filter`, value);
      }
    },
    currentAvailableJournalEntryFilters: {
      get() {
        return this.attrs.currentAvailableJournalEntryFilters;
      },
      set(value) {
        this.attrs.currentAvailableJournalEntryFilters = value;
        this.$emit(`update:available-journal-entry-filters-list`, value);
      }
    },
    shouldShowCountdown() {
      const nowSeconds = DateTime.local().toSeconds();
      const nextDeadlineSeconds = this.getNextDeadline;
      if(isNaN(nextDeadlineSeconds)) {
        return false;
      }
      // show countdown if time is less than 1 hour
      return nextDeadlineSeconds - nowSeconds <= 3599;
    },
    isEquipmentEnabledForGame() {
      const courseRounds = this.getCurrentCourse.rounds;
      for(let round of courseRounds) {
        if(round.equipments.length > 0) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    ...mapActions([
      'fetchCashFlowStatement',
      'submitCashFlowStatement',
      'fetchGradingReport'
    ]),
    getCustomLabelValue(key) {
      if(this.getGameLabels) {
        const found = this.getGameLabels.find(label => label.key === key);
        return found ? found.value : "";
      }
      return "";
    },
    checkGameStateAndConfig() {
      this.gameStateDisabledLabel = null;
      this.isEditable = false;
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      const cfConfig = gameConfig.roundFeatures.cashFlowConfig[roundIndex].roundSetting;
      if(cfConfig === RoundModuleDisplaySettingEnum.Off) {
        const verb = this.isPastCashFlow ? 'was' : 'is';
        // setting gameStateDisabledLabel label to an empty string omits the entire UI (mainly used in competitor financials when nothing should show due to the round setting)
        this.gameStateDisabledLabel = this.allowSubmissionWhenValidGameConfigAndGameState ? `Statement of Cash Flow submission ${verb} not required in Round ${roundIndex + 1}` : "";
        this.showTeamAnswers = false;
        this.showCorrectAnswers = false;
      } else if(cfConfig === RoundModuleDisplaySettingEnum.ShowForPastRounds) {
        if(this.getUserType === UserTypeEnum.Student && !this.isPastCashFlow) {
          this.gameStateDisabledLabel = `Statement of Cash Flow submission is not required in Round ${roundIndex + 1}`
        }
        this.showTeamAnswers = false;
        this.showCorrectAnswers = true;
      } else if(cfConfig === RoundModuleDisplaySettingEnum.ShowForAllRounds) {
        this.showTeamAnswers = false;
        this.showCorrectAnswers = true;
      } else if(cfConfig === RoundModuleDisplaySettingEnum.Submit) {
        this.showTeamAnswers = (this.allowSubmissionWhenValidGameConfigAndGameState && this.getUserType !== UserTypeEnum.Student) || (this.allowSubmissionWhenValidGameConfigAndGameState && this.isPastCashFlow);
        this.isEditable = this.getUserType === UserTypeEnum.Student && !this.isPastCashFlow;
        this.showCorrectAnswers = this.getUserType !== UserTypeEnum.Student || this.isPastCashFlow;
      }
    },
    toggleIsEditing() {
      this.isEditing = !this.isEditing;
    },
    async submitCurrentCashFlowStatement() {
      if(!this.cashFlowInput["roundConfigId"]) {
        this.cashFlowInput["roundConfigId"] = this.getCurrentRoundConfig.id;
      }
      this.cashFlowInput.teamId = this.teamId;
      try {
        await this.submitCashFlowStatement(this.cashFlowInput);
        this.currentCashFlowStatement = this.getCurrentCashFlowStatement;
        for(let input in this.cashFlowInput) {
          this.cashFlowInput[input] = this.cashFlowInput[input];
        }
        this.isEditing = false;
        this.dismissAlert = false;
        this.hasSuccessfullySubmitted = true;
        this.currentTeamHasSubmittedCashFlow = true;
      } catch(err) {
        this.hasSuccessfullySubmitted = false;
        if(err == 'Error: GraphQL error: The submission deadline has passed') {
          this.errorMessage = "The submission deadline has passed"
        }
        this.dismissAlert = false;
        this.showFormError = true;
        return err;
      }
    },
    clearCashFlowElections() {
      this.cashFlowInput = {
        operatingActivitiesSales: null,
        operatingActivitiesPurchasedInventory: null,
        operatingActivitiesPaidAdvertising: null,
        operatingActivitiesSalesReturns: null,
        operatingActivitiesIncomeTaxesPaid: null,
        operatingActivitiesInterestPaid: null,
        operatingActivitiesTotal: null,
        investingActivitiesInflowEquipment: null,
        investingActivitiesInflowFurnishings: null,
        investingActivitiesInflowBuildings: null,
        investingActivitiesInflowLand: null,
        investingActivitiesOutflowEquipment: null,
        investingActivitiesOutflowFurnishings: null,
        investingActivitiesOutflowBuildings: null,
        investingActivitiesOutflowLand: null,
        investingActivitiesTotal: null,
        financingActivitiesCapital: null,
        financingActivitiesLoanBorrow: null,
        financingActivitiesLoanRepay: null,
        financingActivitiesTotal: null,
        netCash: null,
        beginningOfRound: null,
        endOfRound: null
      }
      this.isEditing = false;
    },
    clearAlert() {
      this.dismissAlert = true;
    },
    async initCashFlowStatement() {
      const gameState = this.getCurrentGameState;
      this.currentGamePhase = gameState.phase;
      this.clearCashFlowElections();
      this.teamId = this.selectedTeam ? this.selectedTeam.id : this.$route.params.teamId;
      await this.fetchCashFlowStatement({
        roundConfigId: this.activeRoundId,
        teamId: this.teamId
      });
      if(this.activeRoundId) {
        if(this.activeRoundId !== this.getCurrentRoundConfig.id || this.currentGamePhase === GameRoundPhase.GameOver || this.currentGamePhase === GameRoundPhase.Graded) {
          this.isPastCashFlow = true;
          this.roundNum = this.getCurrentCourse.rounds.map((_round) => _round.id).indexOf(this.activeRoundId) + 1;
        } else {
          this.isPastCashFlow = false;
        }
      }
      this.currentCashFlowStatement = this.getCurrentCashFlowStatement;
      if(!this.isPastCashFlow) {
        for(let input in this.currentCashFlowStatement) {
          if(this.cashFlowInput.hasOwnProperty(input)) {
            this.cashFlowInput[input] = this.currentCashFlowStatement[input];
          }
        }
        this.cashFlowInput["roundConfigId"] = this.getCurrentRoundConfig.id;
      } else {
        this.getPastReport();
      }
      if(this.currentCashFlowStatement.incorrectAnswers){
        this.incorrectAnswerFields = this.currentCashFlowStatement.incorrectAnswers.map((_incorrectAnswer) => _incorrectAnswer.fieldName);
      }
      if(!this.allowSubmissionWhenValidGameConfigAndGameState) {
        this.isEditing = false;
      }
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      this.disabledFields = gameConfig.roundFeatures.cashFlowConfig[roundIndex].disabledFields;
      if(!this.disabledFields) {
        this.disabledFields = [];
      }
      if(this.getCurrentCashFlowStatement && this.getCurrentCashFlowStatement.submittedBy) {
        this.currentTeamHasSubmittedCashFlow = true;
      }
      this.checkGameStateAndConfig();
      this.currentJournalEntryFilter = null;
      if(!this.currentAvailableJournalEntryFilters) {
        this.currentAvailableJournalEntryFilters = [];
      }
      this.isPageReady = true;
    },
    getActiveRoundIndex() {
      const courseRounds = this.getCurrentCourse.rounds;
      return courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
    },
    isAdvertisingEnabled() {
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      if(roundIndex > -1) {
        const adConfig = gameConfig.roundFeatures.advertising[roundIndex];
        return adConfig.cost > 0 || adConfig.benefit > 0;
      }
    },
    isSalesReturnsEnabled() {
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      if(roundIndex > -1) {
        return !!gameConfig.roundFeatures.salesReturns[roundIndex];
      }
    },
    isIncomeTaxEnabled() {
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      if(roundIndex > -1) {
        const taxRate = gameConfig.roundFeatures.incomeTax[roundIndex];
        return taxRate > 0;
      }
      return false;
    },
    areBondsSalesEnabled() {
      const courseRounds = this.getCurrentCourse.rounds;
      const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
      return (courseRounds[roundIndex].bonds.realInterestRateFactor > 0 && courseRounds[roundIndex].bonds.bondOptions.length > 0);
    },
    isFurnishingsEnabled() {
      const courseRounds = this.getCurrentCourse.rounds;
      return courseRounds.reduce((result, round) => {
        if (round.storeFurnishings.length > 0) {
          return true;
        }
        return result;
      }, false);
    },
    getCorrectAnswer(fieldName) {
      if(this.disabledFields.includes(fieldName)) {
        const answer = this.currentCashFlowStatement.disabledFieldAnswers.find((answer) => answer.fieldName === fieldName);
        if(!answer) {
          return '';
        }
        return answer.correctAnswer;
      }

      if(this.incorrectAnswerFields.includes(fieldName)) {
        const answer = this.currentCashFlowStatement.incorrectAnswers.find((answer) => answer.fieldName === fieldName);
        if(!answer) {
          return '';
        }
        return answer.correctAnswer;
      }

      const answer = this.currentCashFlowStatement.correctAnswers.find((answer) => answer.fieldName === fieldName);
      if(answer) {
        return answer.correctAnswer;
      }

      return this.currentCashFlowStatement[fieldName];
    },
    async getPastReport() {
      try {
        const gradeReport = await this.fetchGradingReport({
          roundConfigId: this.activeRoundId,
          teamId: this.teamId
        });
        this.pastCashFlowCorrect = gradeReport.cashFlowNumCorrect;
        this.pastCashFlowTotal = gradeReport.cashFlowTotal;
      } catch(error) {
        return error;
      }
    },
    cancelCurrentCashFlowStatement() {
      if(this.currentCashFlowStatement) {
        for(let input in this.currentCashFlowStatement) {
          if(this.cashFlowInput.hasOwnProperty(input)) {
            this.cashFlowInput[input] = this.currentCashFlowStatement[input];
          }
        }
      } else {
        this.clearCashFlowElections();
      }
      this.isEditing = false;
    },
    toggleJournalEntryFilter(filter) {
      if(this.showCorrectAnswers && this.isSplitView && this.currentAvailableJournalEntryFilters.includes(filter)) {
        if(this.currentJournalEntryFilter === filter) {
          this.currentJournalEntryFilter = null;
        } else {
          this.currentJournalEntryFilter = filter;
        }
      }
    }
  },
  async created() {
    if(this.activeRoundId && this.selectedTeam) {
      this.initCashFlowStatement();
    }
  }
}
</script>

<style scoped>
  h1 {
    padding: 1em;
  }
  .main-box {
    border: 1px solid black;
    margin: 1em;
    text-align: left;
  }
  .table-title {
    background-color: var(--table-title-bg-color) !important;
  }
  .title-text {
    font-weight: bold;
    color: white !important;
  }
  .text-center {
    text-align: center;
  }
  .btn-group {
    padding: 1em;
    display: table;
  }
  .table-header {
    font-weight: bold;
  }
  .sub-class {
    padding-left: 30px;
  }
  .category-label {
    border-right: 1px solid rgba(0,0,0,0.12);
  }
  .error {
    color: #ff1744;
  }
  .form-error {
    display: block!important;
    left: 0;
    height: 20px;
    position: absolute;
    bottom: -22px;
    font-size: 12px;
    transition: .3s cubic-bezier(.4,0,.2,1);
  }
  .category-title {
    padding-bottom: 20px;
  }
  .text-right {
    text-align: right;
  }
  .non-table-input-container {
    max-height: 60px;
    max-width: 182px;
    margin-right: 22px;
  }
  .btn-rounded {
    border-radius: 8px;
  }
  .save-btn {
    color: white !important;
    background-color: var(--btn-primary-color) !important;
  }
  .cancel-btn {
    color: #000000 !important;
    background-color: var(--btn-secondary-color) !important;
  }
  .alert {
    margin-left: 1em;
    margin-right: 1em;
  }
  .alert-error {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ff1744;
    color: white;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
  }
  .fa.fa-times-circle.light {
    color: white;
    cursor: pointer;
  }
  .cash-flow-container {
    padding-bottom: 64px;
  }
  .incorrect-answer {
    color: var(--warning-text-color);
  }
  .correct-answer {
    color: var(--success-bg-color);
  }
  .category-value {
    text-align: center;
    max-width: 100px;
    min-width: 100px;
  }
  .non-table-category-value {
    display: inline-block;
    min-height: 27px;
    max-height: 27px;
    max-width: 100px;
    min-width: 100px;
    line-height: 2;
    text-align: center;
  }
  .net-cash-value {
    max-height: 70px;
    min-height: 70px;
  }
  .non-table-spacing {
    padding: 6px 0px 0px 24px;
  }
  .md-field {
    min-height: 0px;
    margin: 0;
    padding-top: 0px;
  }
  .currency-input-container {
    float: right;
  }
  .header-container {
    max-width: 200px;
  }
  .header-container.md-title {
    overflow: inherit;
    text-overflow: inherit;
  }
  .answers-container {
    text-align: center;
  }
  .table-value-padding {
    padding-right: 24px;
  }
  .summary-table {
    padding-top: 30px;
    background-color: var(--table-cell-color) !important;
  }
  .summary-table-value {
    width: 33.5%;
  }
  .remaining-label {
    margin-top: -8px;
  }
  .correct-answers-cell {
    border-left: 1px solid rgba(0,0,0,0.12);
    font-weight: bold;
  }
  .bottom-border {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .net-cash-label {
    font-size: 22px;
  }
  @media screen and (max-width: 550px){
    .currency-input-container {
      float: none;
    }
  }
  @media screen and (max-width: 800px){
    .mobile-table-cell {
      width: 50%;
    }
    .summary-table-value {
      width: 50%;
    }
  }
  .text-next-to-button {
    display: table-cell;
    vertical-align: middle;
  }
  .black-text {
    color: black;
  }
  .no-sheet {
    margin-top: 20px;
  }

  .game-state-disabled-label {
    margin-top: 150px;
    font-weight: bold;
    border: 1px solid;
    display: inline-block;
    padding: 15px;
  }

  .selectable-cell {
    margin: -5px;
    border: 2px solid var(--btn-primary-color) !important;
  }

  .selected-cell {
    background: rgba(67,176,249,0.12) !important;
  }

  .selected-cell:hover {
    background: rgba(67,176,249,0.12) !important;
  }

  .selected-color-indicator {
    color: var(--btn-primary-color);
  }

  .clear-filter {
    position: absolute;
    font-weight: bold;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--btn-primary-color);
  }
</style>
