<template>
  <div>
      Account-o-nomics is currently under maintenance, please check back later.
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
