<template>
    <div class="md-layout">
        <div class="storeFurnishingBlock" v-if="getCurrentRoundConfig.storeFurnishings && getCurrentRoundConfig.storeFurnishings.length > 0 && currentStoreFurnishingPurchaseElections">
            <div class="wrapper">
                <span v-for="storeFurnishingConfig in getCurrentRoundConfig.storeFurnishings" v-bind:key="storeFurnishingConfig.id">
                    <md-card v-for="(storeFurnishingTypeConfig, typeIndex) in storeFurnishingConfig.typesConfig" v-bind:key="storeFurnishingTypeConfig.storeFurnishingType" :class="{ 'selected-border' : isStoreFurnishingLocallyPurchased(storeFurnishingConfig.id, typeIndex) }">
                        <div class="mini-id">
                            Asset ID: #{{storeFurnishingTypeConfig.miniId}}
                        </div>
                        <md-card-header>
                            <div class="md-title">Store Furnishing Condition: {{storeFurnishingTypeConfig.furnishingType}}</div>
                        </md-card-header>
                        <md-card-content>
                            <div class="left-text cost">
                                <div class="election-config-label">Cost:</div>
                                <div class="election-config-value">{{storeFurnishingTypeConfig.purchaseCost | toCurrency}}</div>
                            </div>
                            <div class="left-text">
                                <div class="election-config-label">Useful Life</div>
                                <div class="election-config-value">{{storeFurnishingTypeConfig.usefulLife}} rounds</div>
                            </div>
                            <div class="left-text">
                                <div class="election-config-label">Salvage Value</div>
                                <div class="election-config-value">$0</div>
                            </div>
                            <div class="left-text">
                                <div class="election-config-label">Production Capacity</div>
                                <div class="election-config-value">{{storeFurnishingTypeConfig.productionCapacity}} units</div>
                            </div>
                            <md-field class="depreciation-field" v-if="!isStoreFurnishingLocallyPurchased(storeFurnishingConfig.id, typeIndex)">
                                <label>Depreciation Method</label>
                                <md-select v-model="selectedDepreciationMethods[storeFurnishingConfig.id][typeIndex]">
                                    <md-option v-for="depreciationMethod in storeFurnishingTypeConfig.depreciationMethods" v-bind:value="depreciationMethod" v-bind:key="depreciationMethod">
                                        {{ depreciationMethod }}
                                    </md-option>
                                </md-select>
                            </md-field>
                            <div v-if="isStoreFurnishingLocallyPurchased(storeFurnishingConfig.id, typeIndex)">
                                <div class="left-text">
                                    <div class="election-config-label">Depreciation Method</div>
                                    <div class="election-config-value">{{selectedDepreciationMethods[storeFurnishingConfig.id][typeIndex]}}</div>
                                </div>
                            </div>
                            <div>
                                <md-button v-if="!isStoreFurnishingLocallyPurchased(storeFurnishingConfig.id, typeIndex)" @click="purchaseStoreFurnishing(storeFurnishingConfig.id, typeIndex)" class="md-raised btn-primary">Purchase</md-button>
                                <md-button v-if="isStoreFurnishingLocallyPurchased(storeFurnishingConfig.id, typeIndex)" @click="cancelStoreFurnishingSale(storeFurnishingConfig.id, typeIndex)" class="md-raised btn-warning">Cancel Purchase</md-button>
                            </div>
                        </md-card-content>
                    </md-card>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import {
    MdCard,
    MdField,
    MdIcon
} from 'vue-material/dist/components'
import { mapGetters } from 'vuex'
export default {
    name: 'StoreFurnishing',
    data() {
        return {
            attrs: {
                currentStoreFurnishingPurchaseElections: this.storeFurnishingPurchaseElections,
                purchasedStoreFurnishings: []
            },
            selectedDepreciationMethods: {}
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentRoundConfig',
            'getStoreFurnishingTypeOptions',
            'getCurrentRoundElections'
        ]),
        currentStoreFurnishingPurchaseElections: {
            get() {
                return this.attrs.currentStoreFurnishingPurchaseElections;
            },
            set(value) {
                this.attrs.currentStoreFurnishingPurchaseElections = value;
                this.$emit(`update:store-furnishing-purchase-elections`, value);
            },
        },
        purchasedStoreFurnishings: {
            get() {
                return this.attrs.purchasedStoreFurnishings;
            },
            set(value) {
                this.attrs.purchasedStoreFurnishings = value;
            },
        },
    },
    watch: {
        purchasedStoreFurnishings(newVal, oldVal) {
            this.currentStoreFurnishingPurchaseElections = newVal.map((purchasedStoreFurnishing) => {
                const furnishingConfigId = purchasedStoreFurnishing.furnishingConfigId;
                const typeIndex = purchasedStoreFurnishing.typeIndex;

                const roundConfig = this.getCurrentRoundConfig;
                const furnishingConfig = roundConfig.storeFurnishings.find((_furnishingConfig) => _furnishingConfig.id === furnishingConfigId);
                const typeConfig = furnishingConfig.typesConfig[typeIndex];
                return {
                    furnishingConfigId,
                    type: typeConfig.furnishingType,
                    depreciationMethod: this.selectedDepreciationMethods[furnishingConfigId][typeIndex],
                    purchasePrice: typeConfig.purchaseCost,
                    capacity: typeConfig.productionCapacity,
                    usefulLife: typeConfig.usefulLife,
                };
            });
        },
    },
    props: {
        storeFurnishingPurchaseElections: {
            type: Array
        }
    },
    methods: {
        isStoreFurnishingLocallyPurchased(furnishingConfigId, typeIndex) {
            return this.purchasedStoreFurnishings
                .map((purchasedStoreFurnishing) => `${purchasedStoreFurnishing.furnishingConfigId}|${purchasedStoreFurnishing.typeIndex}`)
                .indexOf(`${furnishingConfigId}|${typeIndex}`) >= 0;
        },
        purchaseStoreFurnishing(furnishingConfigId, typeIndex) {
            const purchasedStoreFurnishingIds = this.purchasedStoreFurnishings
                .map((purchasedStoreFurnishing) => `${purchasedStoreFurnishing.furnishingConfigId}|${purchasedStoreFurnishing.typeIndex}`);

            if(purchasedStoreFurnishingIds.indexOf(`${furnishingConfigId}|${typeIndex}`) === -1) {
                this.purchasedStoreFurnishings.push({furnishingConfigId, typeIndex});
            }
        },
        cancelStoreFurnishingSale(furnishingConfigId, typeIndex) {
            const furnishingIndex = this.purchasedStoreFurnishings
                .map((purchasedStoreFurnishing) => `${purchasedStoreFurnishing.furnishingConfigId}|${purchasedStoreFurnishing.typeIndex}`)
                .indexOf(`${furnishingConfigId}|${typeIndex}`);

            if(furnishingIndex >= 0) {
                this.purchasedStoreFurnishings.splice(furnishingIndex, 1);
            }
        },
        initElections(elections) {
            this.currentStoreFurnishingPurchaseElections = elections;
            if(!this.currentStoreFurnishingPurchaseElections) {
                this.currentStoreFurnishingPurchaseElections = [];
            }

            const roundConfig = this.getCurrentRoundConfig;
            for(let storeFurnishingPurchaseElection of elections){
                const furnishingConfigId = storeFurnishingPurchaseElection.furnishingConfigId;
                const furnishingConfig = roundConfig.storeFurnishings.find((furnishingCfg) => furnishingCfg.id === furnishingConfigId);
                const typeIndex = furnishingConfig.typesConfig.map((_type) => _type.furnishingType).indexOf(storeFurnishingPurchaseElection.type)
                this.purchasedStoreFurnishings.push({furnishingConfigId, typeIndex});
                this.selectedDepreciationMethods[furnishingConfigId][typeIndex] = storeFurnishingPurchaseElection.depreciationMethod;
            }
        }
    },
    created() {
        const roundConfig = this.getCurrentRoundConfig;
        this.selectedDepreciationMethods = {};
        for(let furnishingConfig of roundConfig.storeFurnishings) {
            this.selectedDepreciationMethods[furnishingConfig.id] = furnishingConfig.typesConfig.map((typeConfig) => typeConfig.defaultDepreciationMethod)
        }

        this.initElections(this.storeFurnishingPurchaseElections);
    }
}

Vue.use(MdCard);
Vue.use(MdField);
Vue.use(MdIcon);
</script>

<style scoped>
    .md-layout-item {
        padding: 10px;
    }

    .wrapper {
        background-color: var(--main-box-color);
    }

    .inputWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .md-layout-item {
        padding: 10px;
    }

    .storeFurnishingBlock {
        margin-left: auto;
        margin-right: auto;
        min-width: 98%;
        max-width: 98%;
        padding: 0;
    }

    .md-card {
        width: 300px;
        margin: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .md-button {
        margin: 1.5em;
    }

    .depreciation-field {
        margin-top: 30px;
    }

    .left-text {
        text-align: left;
    }

    .election-config-label {
        display: inline-block;
    }

    .election-config-value {
        display: inline-block;
        font-weight: bold;
        margin-left: 15px;
    }

    .cost {
        font-weight: bold;
        font-size: 20px;
    }
</style>