import gql from 'graphql-tag';
import { apolloClient } from '../../main';
import { GameVuexState, ScoreboardData } from "@/types/types";
import { DateTime } from "luxon";
import { BuildingTypeDynamicConfig, EquipmentTypeDynamicConfig, Course, CurrentAssets, RoundConfig, RoundElection, RoundElectionInput, User, GameState, SalesReportEntry, GameProduct, ScoreboardRound, GameRoundPhase, Team, Semester, UserTypeEnum } from "@bit/accountonomics.types-lib.api-types";
import { isPlainObject, isArray } from 'lodash';

export function cloneWithoutTypename<T>(obj: T): Omit<T, '__typename'> {
    if (!isPlainObject(obj)) {
        return obj;
    }
    let result: any = {};
    for (let key in obj) {
        if (key === '__typename') {
            continue;
        }
        let value = (obj as any)[key];
        if (isPlainObject(value)) {
            result[key] = cloneWithoutTypename(value);
        } else if (isArray(value)) {
            result[key] = value.map(cloneWithoutTypename);
        } else {
            result[key] = value;
        }
    }
    return result;
}

const state: GameVuexState = {
    currentCourse: undefined,
    currentGameState: undefined,
    roundElections: undefined,
    currentAssets: undefined,
    salesReport: undefined,
    scoreboardData: undefined,
    stockTickerData: undefined,
    isCurrentActiveRound: false
};

const getters = {
    getAppClientVersion: (state: GameVuexState): string | undefined => {
        return process.env.VERSION;
    },
    getCurrentGameState: (state: GameVuexState): GameState | undefined => {
        return state.currentGameState;
    },
    getCurrentCourse: (state: GameVuexState): Course | undefined => {
        return state.currentCourse;
    },
    getNextDeadline: (state: GameVuexState, getters: any, rootState: any, rootGetters: any): number | undefined => {
        const gameState = getters.getCurrentGameState;
        const team = rootGetters.getTeam;
        if(!gameState || !team || team.semester.useFlowMode) {
            return undefined;
        }

        const roundTimings = JSON.parse(JSON.stringify(team.semester.roundTimings));
        const roundIndex = gameState.roundIndex >= 0 ? gameState.roundIndex : 0;
        const currentRoundTiming = roundTimings[roundIndex];
        switch (gameState.phase) {
            case GameRoundPhase.HasntStarted:
                return currentRoundTiming.electionsOpen;
            case GameRoundPhase.InputSheet:
                return currentRoundTiming.electionsDeadline;
            case GameRoundPhase.CalcSheets:
                return currentRoundTiming.secondSubmissionDeadline;
            case GameRoundPhase.Graded:
            case GameRoundPhase.GameOver:
                return currentRoundTiming.gradeViewingDeadline;
            default:
                return undefined;
        }
    },
    getCurrentRoundConfig: (state: GameVuexState): RoundConfig | null => {
        if(!state.currentGameState) {
            return null;
        }
        const roundIndex = state.currentGameState.roundIndex >= 0 ? state.currentGameState.roundIndex : 0;
        if(state.currentCourse && state.currentCourse.rounds) {
            return state.currentCourse.rounds[roundIndex];
        }
        return null;
    },
    getBuildingTypeOptions: (state: GameVuexState, getters: any) => (buildingIndex: number, selectedType: string): BuildingTypeDynamicConfig[] | null => {
        const currentRound = getters.getCurrentRoundConfig;
        if(!currentRound.buildings) {
            return null;
        }
        const buildingPurchaseConfig = currentRound.buildings[buildingIndex];
        if(!buildingPurchaseConfig || !buildingPurchaseConfig.typesConfig) {
            return null;
        }
        return buildingPurchaseConfig.typesConfig.find((typeConfig: BuildingTypeDynamicConfig ) => {
            if(!typeConfig){
                return false;
            }
            return typeConfig.buildingType === selectedType;
        });
    },
    getEquipmentTypeOptions: (state: GameVuexState, getters: any) => (equipmentIndex: number, selectedType: string): EquipmentTypeDynamicConfig[] | null => {
        const currentRound = getters.getCurrentRoundConfig;
        if(!currentRound.equipments) {
            return null;
        }
        const equipmentPurchaseConfig = currentRound.equipments[equipmentIndex];
        if(!equipmentPurchaseConfig || !equipmentPurchaseConfig.typesConfig) {
            return null;
        }
        return equipmentPurchaseConfig.typesConfig.find((typeConfig: EquipmentTypeDynamicConfig ) => {
            if(!typeConfig){
                return false;
            }
            return typeConfig.equipmentType === selectedType;
        });
    },
    // TODO use StoreFurnishingTypeDynamicConfig after new bit gen
    getStoreFurnishingsTypeOptions: (state: GameVuexState, getters: any) => (furnishingIndex: number, selectedType: string): any[] | null => {
        const currentRound = getters.getCurrentRoundConfig;
        if(!currentRound.storeFurnishings) {
            return null;
        }
        const furnishingsPurchaseConfig = currentRound.storeFurnishings[furnishingIndex];
        if(!furnishingsPurchaseConfig || !furnishingsPurchaseConfig.typesConfig) {
            return null;
        }
        return furnishingsPurchaseConfig.typesConfig.find((typeConfig: any ) => {
            if(!typeConfig){
                return false;
            }
            return typeConfig.furnishingsType === selectedType;
        });
    },
    getCurrentRoundElections: (state: GameVuexState): RoundElection | null => {
        if(!state.currentGameState || !state.currentCourse || !state.currentCourse.rounds) {
            return null;
        }
        const currentRoundIndex = state.currentGameState.roundIndex;
        const currentRound = state.currentCourse.rounds[currentRoundIndex];
        if(currentRound && state.roundElections) {
            return state.roundElections;
        }
        return null;
    },
    getCurrentAssets: (state: GameVuexState): CurrentAssets | undefined => {
        return state.currentAssets;
    },
    getSalesReport: (state: GameVuexState): SalesReportEntry[] | undefined => {
        return state.salesReport;
    },
    getGameProduct: (state: GameVuexState, getters: any) => (gameProductId: string): GameProduct | undefined => {
        if(!state.currentCourse || !state.currentCourse.gameProducts) {
            return undefined;
        }

        const gameProduct = state.currentCourse.gameProducts.find((gameProduct) => gameProduct && gameProduct.id === gameProductId);
        if(!gameProduct) {
            return undefined;
        }

        return gameProduct;
    },
    getFormattedDate: (state: GameVuexState, getters: any) => (seconds: number, timezone: string): string | undefined => {
        const format = 'EEE MMMM d, yyyy h:mm a';
        return DateTime.fromSeconds(seconds, {zone: timezone}).toFormat(format);
    },
    getScoreboardData: (state: GameVuexState): ScoreboardData | undefined => {
        return state.scoreboardData;
    },
    getStockTickerData: (state: GameVuexState): any | undefined => {
        return state.stockTickerData;
    },
    getIsCurrentActiveRound: (state: GameVuexState, getters: any) => (activeRoundId: string): Boolean => {
        if(state.currentGameState && activeRoundId) {
            return state.currentGameState.roundId === activeRoundId
        }
        return false;
    }
};

const actions = {
    async initGame({ commit,  rootGetters }: any, payload: any) {
        const userType = rootGetters['getUserType'];
        const team: Team = rootGetters['getTeam'];
        commit('setCurrentGameCourse', team.semester?.course);

        if(userType === UserTypeEnum.Professor) {
            const teamResponse = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`
                    query ProfessorTeams($semesterId: ID!) {
                        professorTeams(semesterId: $semesterId) { 
                            id, 
                            name,
                            semester {
                                id,
                                course { id },
                                name,
                                year,
                                season,
                                startTime,
                                endTime,
                                numTeams,
                                numStudentsPerTeam,
                                timezone,
                                useFlowMode,
                                onboardCode,
                                teamSalesReturns { teamId, roundConfigId, assignedSalesReturnsPercentage },
                                roundTimings { roundConfigId, electionsOpen, electionsDeadline, secondSubmissionDeadline, gradeViewingDeadline, hasCalculatedMarket, hasGraded },
                                gameConfig {
                                    roundFeatures { advertising { cost, benefit }, salesReturns, incomeTax, borrowingCaps { debtRoundBorrowCap, bondRoundBorrowCap, totalPrincipalCap }, incomeStatementConfig { roundSetting, disabledFields}, balanceSheetConfig { roundSetting, disabledFields }, cashFlowConfig { roundSetting, disabledFields }, journalEntryTAccountConfig, requirePositiveCashBalanceBeforeElecting, accountsReceivable { outstandingPercent, badDebtPercent }, journalEntrySubmissionConfig { enabledJournalEntrySubmissions { journalEntryType, accountOptions } } },
                                    junkLoans { rate, term },
                                    stockConfig { teamStartingStockPrice, initialOfferingNumShares, totalSellableSharesPerTeam }
                                }
                                teams { id, name },
                                course {
                                    gameProducts { id, name }
                                    rounds {
                                        id,
                                        buildings { id, typesConfig { buildingType, purchaseCost, equipmentCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod, miniId } },
                                        equipments { id, typesConfig { equipmentType, purchaseCost, productionCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod, miniId } },
                                        storeFurnishings { id, typesConfig { furnishingType, purchaseCost, productionCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod, miniId } },
                                        lands { id, purchaseCost, buildingCapacity, miniId },
                                        debts { id, borrowingRate, borrowingTerm, miniId },
                                        products { id, gameProductId, productionCost, demandSlope, demandIntercept },
                                        isStockTradingEnabled,
                                        bonds {
                                            realInterestRateFactor, 
                                            bondOptions {
                                                id,
                                                miniId,
                                                nominalAmount, 
                                                nominalInterestRate, 
                                                paymentDueQuarterEnd, 
                                                numPaymentsPerYear, 
                                                term 
                                            } 
                                        }
                                    },
                                    defaultGameConfig {
                                        roundFeatures { advertising { cost, benefit }, salesReturns, incomeTax, borrowingCaps { debtRoundBorrowCap, bondRoundBorrowCap, totalPrincipalCap }, incomeStatementConfig { roundSetting, disabledFields }, balanceSheetConfig { roundSetting, disabledFields }, cashFlowConfig { roundSetting, disabledFields }, journalEntryTAccountConfig, requirePositiveCashBalanceBeforeElecting, accountsReceivable { outstandingPercent, badDebtPercent }, journalEntrySubmissionConfig { enabledJournalEntrySubmissions { journalEntryType, accountOptions } } },
                                        junkLoans { rate, term },
                                        stockConfig { teamStartingStockPrice, initialOfferingNumShares, totalSellableSharesPerTeam }
                                    }
                                },
                                isPaused
                            }
                        }
                    }
                `,
                variables: {
                    semesterId: payload.semesterId
                }
            });

            const teams = teamResponse.data.professorTeams;
            commit('setProfessorTeams', teams);
        }

        // Fetch the current game state for the given user credentials
        const gameStateResponse = await apolloClient.query({
            fetchPolicy: 'no-cache',
            query: gql`
                query GameState($semesterId: ID!) {
                    gameState(semesterId: $semesterId) {
                        roundIndex, 
                        roundId, 
                        phase
                    }
                }
            `,
            variables: {
                semesterId: payload.semesterId
            }
        });
        const gameState = gameStateResponse.data.gameState;
        commit('setCurrentGameState',  gameState);

        // Fetch and store all round elections for the team in this semester
        const electionsResponse = await apolloClient.query({
            fetchPolicy: 'no-cache',
            query: gql`
                query Elections($teamId: String!) {
                    elections(teamId: $teamId) { 
                        roundConfigId,
                        buildingPurchases { buildingConfigId, purchasePrice, type, depreciationMethod, capacity, usefulLife }, 
                        buildingSales { buildingAssetId, salePrice, originalPurchasePrice },
                        equipmentPurchases { equipmentConfigId, purchasePrice, type, depreciationMethod, capacity, usefulLife }, 
                        equipmentSales { equipmentAssetId, salePrice, originalPurchasePrice },
                        storeFurnishingPurchases { furnishingConfigId, purchasePrice, type, depreciationMethod, capacity, usefulLife }, 
                        storeFurnishingSales { furnishingAssetId, salePrice, originalPurchasePrice },
                        landPurchases { landConfigId, purchasePrice, capacity }, 
                        landSales { landAssetId, salePrice, originalPurchasePrice },
                        debtsBorrowed { debtConfigId, rate, term, borrowingAmount }, 
                        debtsRepayed { debtLiabilityId, rate, term, originalPrincipal, repaymentAmount },
                        productElections { productConfigId, gameProductId, quantityOffered, offerPrice, numUnitsToAdvertise },
                        stockSales { quantityOffered, stockPrice },
                        bondSales { bondConfigId, numBondsSold, nominalAmount },
                        submittedBy { firstname, lastname },
                        createdAt
                    }
                }
            `,
            variables: {
                teamId: payload.teamId
            }
        });
        const currentElections = electionsResponse.data.elections.filter((election: RoundElection) => election.roundConfigId === gameState.roundId);
        const currentElection = currentElections[0];
        commit('setRoundElections', currentElection);
    },
    async submitRoundElections({ commit,  rootGetters }: any, payload: RoundElectionInput) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation SubmitRoundElections($roundElectionInput: RoundElectionInput!) {
                        submitRoundElections(input: $roundElectionInput) {
                            id,
                            roundConfigId,
                            buildingPurchases { buildingConfigId, purchasePrice, type, depreciationMethod, capacity },
                            buildingSales { buildingAssetId, salePrice, originalPurchasePrice },
                            equipmentPurchases { equipmentConfigId, purchasePrice, type, depreciationMethod, capacity },
                            equipmentSales { equipmentAssetId, salePrice, originalPurchasePrice },
                            storeFurnishingPurchases { furnishingConfigId, purchasePrice, type, depreciationMethod, capacity },
                            storeFurnishingSales { furnishingAssetId, salePrice, originalPurchasePrice },
                            landPurchases { landConfigId, purchasePrice, capacity },
                            landSales { landAssetId, salePrice, originalPurchasePrice },
                            debtsBorrowed { debtConfigId, rate, term, borrowingAmount },
                            debtsRepayed { debtLiabilityId, rate, term, originalPrincipal, repaymentAmount },
                            productElections { productConfigId, gameProductId, quantityOffered, offerPrice, numUnitsToAdvertise },
                            stockSales { quantityOffered, stockPrice },
                            bondSales { bondConfigId, numBondsSold, nominalAmount },
                            submittedBy { firstname, lastname },
                            createdAt
                        }
                    }
                `,
                variables: {
                    roundElectionInput: cloneWithoutTypename(payload)
                }
            });

            return commit('setRoundElections', response.data.submitRoundElections);
        } catch(err) {
            return Promise.reject(err);
        }
    },
    async fetchCurrentAssets({ commit, rootGetters }: any, payload: any) {
        try {
            const response = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`
                    query CurrentAssets($teamId: String!) {
                        currentAssets(teamId: $teamId) {
                            currentCashBalance
                            lands { purchaseElectionId, saleElectionId, id, name, totalCapacity, isSellable, isSold, originalPurchasePrice, salePrice, landConfigId, buildings { id, isSold }, friendlyId, originalRoundPurchasedNum }
                            buildings { purchaseElectionId, saleElectionId, team {id}, id, name, totalCapacity, remainingUsefulLife, isSellable, isSold, originalPurchasePrice, salePrice, buildingConfigId, depreciationMethod, roundPurchasedIndex, equipments { id, isSold }, usefulLife, friendlyId, originalRoundPurchasedNum }
                            equipments { purchaseElectionId, saleElectionId, id, name, totalCapacity, remainingUsefulLife, isSellable, isSold, originalPurchasePrice, salePrice, equipmentConfigId, depreciationMethod, roundPurchasedIndex, usefulLife, friendlyId, originalRoundPurchasedNum },
                            storeFurnishings { purchaseElectionId, saleElectionId, id, name, totalCapacity, remainingUsefulLife, isSellable, isSold, originalPurchasePrice, salePrice, furnishingConfigId, depreciationMethod, roundPurchasedIndex, usefulLife, friendlyId, originalRoundPurchasedNum }
                            debts { id, name, borrowElectionId, originalRate, originalTerm, originalPrincipal, outstandingPrincipal, repayments { repaymentRoundId, debtLiabilityId, repaymentAmount }, repaymentRoundId, friendlyId, originalRoundBorrowedNum }
                            products { inventoryAmount, gameProduct { id, name }, team { id, name } },
                            stocks { quantityOffered, stockPrice },
                            bonds { id, team {id}, principal, nominalAmount, friendlyId, quantityIssued, roundIssued, term, nominalInterestRate, marketRate, unamortizedPremiumOrDiscount }
                        }
                    }
                `,
                variables: {
                    teamId: payload.teamId
                }
            });

            return commit('setCurrentAssets', response.data.currentAssets);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async fetchCurrentGameState({ commit, rootGetters }: any, payload: any) {
        try {
            const gameStateResponse = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`
                    query GameState($semesterId: ID!) {
                        gameState(semesterId: $semesterId) {
                            roundIndex,
                            roundId,
                            phase
                        }
                    }
                `,
                variables: {
                    semesterId: payload.semesterId
                }
            });
            const gameState = gameStateResponse.data.gameState;
            commit('setCurrentGameState',  gameState);
            return gameState;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async fetchSalesReport({ commit, rootGetters }: any, payload: any) {
        try {
            const response = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`
                    query SalesReport($roundConfigId: ID!, $teamId: ID!) {
                        salesReport(roundConfigId: $roundConfigId, teamId: $teamId) {
                            id, roundConfigId, roundIndex, team { id, name, colorCode }, gameProduct { id, name }, offerPrice, perceivedPrice, unitsSold, unitsOffered, isAdvertised, isInventory, previousRoundAR, cost
                        }
                    }
                `,
                variables: {
                    roundConfigId: payload.roundConfigId,
                    teamId: payload.teamId
                }
            });

            if(payload.omitCommit === true) {
                return response.data.salesReport;
            }

            return commit('setSalesReport', response.data.salesReport);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async fetchElectionByRoundId({ commit, rootGetters }: any, payload: any) {
        try {
            const response = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`
                    query Election($roundConfigId: ID!, $teamId: String!) {
                        election(roundConfigId: $roundConfigId, teamId: $teamId) {
                            id,
                            roundConfigId,
                            buildingPurchases { usefulLife, buildingConfigId, purchasePrice, type, depreciationMethod, capacity },
                            buildingSales { buildingAssetId, salePrice, originalPurchasePrice },
                            equipmentPurchases { usefulLife, equipmentConfigId, purchasePrice, type, depreciationMethod, capacity },
                            equipmentSales { equipmentAssetId, salePrice, originalPurchasePrice },
                            storeFurnishingPurchases { usefulLife, furnishingConfigId, purchasePrice, type, depreciationMethod, capacity },
                            storeFurnishingSales { furnishingAssetId, salePrice, originalPurchasePrice },
                            landPurchases { landConfigId, purchasePrice, capacity },
                            landSales { landAssetId, salePrice, originalPurchasePrice },
                            debtsBorrowed { debtConfigId, rate, term, borrowingAmount },
                            debtsRepayed { debtLiabilityId, rate, term, originalPrincipal, repaymentAmount },
                            productElections { productConfigId, gameProductId, quantityOffered, offerPrice, numUnitsToAdvertise },
                            stockSales { quantityOffered, stockPrice },
                            bondSales { bondConfigId, numBondsSold, nominalAmount },
                            submittedBy { firstname, lastname }
                        }
                    }
                `,
                variables: {
                    roundConfigId: payload.roundConfigId,
                    teamId: payload.teamId
                }
            });

            return response.data.election;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async fetchScoreboardData({ commit, rootGetters }: any, payload: any) {
        try {
            const response = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`
                    query ScoreboardData($semesterId: ID!) {
                        scoreboardData(semesterId: $semesterId) {
                            semesterName,
                            numRoundsCompleted,
                            numTotalRounds,
                            rounds {
                                roundId, roundNum, teamDatas { teamId, teamName, teamColorCode, currentRoundIncome, previousRoundsTotalNetIncome }
                            }
                        }
                    }
                `,
                variables: {
                    semesterId: payload.semesterId
                }
            });
            return commit('setScoreboardData', response.data.scoreboardData);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async fetchStockTickerData({ commit }: any, payload: any) {
        try {
            const response = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`
                    query StockTicker($semesterId: ID!, $roundConfigId: ID!) {
                        stockTicker(semesterId: $semesterId, roundConfigId: $roundConfigId) {
                            team {
                                id, stockSymbol
                            }
                            roundConfigId,
                            roundIndex,
                            stockPrice,
                            changeFromPreviousRound
                        }
                    }
                `,
                variables: {
                    semesterId: payload.semesterId,
                    roundConfigId: payload.roundConfigId
                }
            });
            return commit('setStockTickerData', response.data.stockTicker);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async fetchBeginningOfRoundStockPrice({ commit, rootGetters }: any, payload: any) {
        try {
            const response = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`
                    query GetCurrentStockPriceForTeam($roundConfigId: ID!, $teamId: ID!) {
                        getStockPriceForTeam(roundConfigId: $roundConfigId, teamId: $teamId) {
                            roundConfigId,
                            stockPrice
                        }
                    }
                `,
                variables: {
                    roundConfigId: payload.roundConfigId,
                    teamId: payload.teamId
                }
            });

            return response.data.getStockPriceForTeam.stockPrice;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async sendGameMessage({ commit,  rootGetters }: any, payload: any) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation SendMessage($sendMessageInput: SendMessageInput!) {
                        sendMessage(input: $sendMessageInput) {
                            id
                        }
                    }
                `,
                variables: {
                    sendMessageInput: cloneWithoutTypename(payload)
                }
            });

            return response.data.sendMessage;
        } catch(err) {
            return Promise.reject(err);
        }
    },
    async fetchGameMessages({ commit, rootGetters }: any, payload: any) {
        try {
            const response = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`
                    query GetMessages($teamId: ID!) {
                        getMessages(teamId: $teamId) {
                            id,
                            text,
                            timeSent,
                            teamIds,
                            messageType,
                            sender {
                                id,
                                firstname,
                                lastname,
                                email
                            },
                            priority,
                            hasBeenRead
                        }
                    }
                `,
                variables: {
                    teamId: payload.teamId
                }
            });

            return response.data.getMessages;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async updateLastNotificationTimestamp({ commit,  rootGetters }: any, payload: any) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation UpdateLastNotificationCheck($teamId: ID!) {
                        updateLastNotificationCheck(teamId: $teamId) {
                            id
                        }
                    }
                `,
                variables: {
                    teamId: payload.teamId
                }
            });

            return response.data.updateLastNotificationCheck;
        } catch (err) {
            return Promise.reject(err);
        }
    }
};

const mutations = {
    setCurrentGameCourse(state: GameVuexState, course: Course) {
        state.currentCourse = course;
    },
    setCurrentGameState(state: GameVuexState, gameState: GameState) {
        state.currentGameState = gameState;
    },
    setRoundElections(state: GameVuexState, roundElections: RoundElection) {
        state.roundElections = roundElections;
    },
    setCurrentAssets(state: GameVuexState, currentAssets: CurrentAssets) {
        state.currentAssets = currentAssets;
    },
    setSalesReport(state: GameVuexState, salesReport: SalesReportEntry[]) {
        state.salesReport = salesReport;
    },
    setScoreboardData(state: GameVuexState, data: ScoreboardData) {
        state.scoreboardData = data;
    },
    setStockTickerData(state: GameVuexState, data: any) {
        state.stockTickerData = data;
    },
    resetGameState(state: GameVuexState) {
        state.currentCourse = undefined;
        state.currentGameState = undefined;
        state.roundElections = undefined;
        state.currentAssets = undefined;
        state.salesReport = undefined;
        state.scoreboardData = undefined;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
