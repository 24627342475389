<template>
    <div class="md-layout">
        <div class="buildingBlock" v-if="getCurrentRoundConfig.buildings && getCurrentRoundConfig.buildings.length > 0 && currentBuildingPurchaseElections">
            <div class="wrapper">
                <span v-for="buildingConfig in getCurrentRoundConfig.buildings" v-bind:key="buildingConfig.id">
                    <md-card v-for="(buildingTypeConfig, typeIndex) in buildingConfig.typesConfig" v-bind:key="buildingTypeConfig.buildingType" :class="{ 'selected-border' : isBuildingLocallyPurchased(buildingConfig.id, typeIndex) }">
                        <div class="mini-id">
                            Asset ID: #{{buildingTypeConfig.miniId}}
                        </div>
                        <md-card-header>
                            <div class="md-title">Building Type: {{buildingTypeConfig.buildingType}}</div>
                        </md-card-header>
                        <md-card-content>
                            <div class="left-text cost">
                                <div class="election-config-label">Cost:</div>
                                <div class="election-config-value">{{buildingTypeConfig.purchaseCost | toCurrency}}</div>
                            </div>
                            <div class="left-text">
                                <div class="election-config-label">Useful Life</div>
                                <div class="election-config-value">{{buildingTypeConfig.usefulLife}} rounds</div>
                            </div>
                            <div class="left-text">
                                <div class="election-config-label">Salvage Value</div>
                                <div class="election-config-value">$0</div>
                            </div>
                            <div class="left-text">
                                <div class="election-config-label">Capacity</div>
                                <div class="election-config-value">{{buildingTypeConfig.equipmentCapacity}} equipment / furnishing units</div>
                            </div>
                            <md-field class="depreciation-field" v-if="!isBuildingLocallyPurchased(buildingConfig.id, typeIndex)">
                                <label>Depreciation Method</label>
                                <md-select v-model="selectedDepreciationMethods[buildingConfig.id][typeIndex]">
                                    <md-option v-for="depreciationMethod in buildingTypeConfig.depreciationMethods" v-bind:value="depreciationMethod" v-bind:key="depreciationMethod">
                                        {{ depreciationMethod }}
                                    </md-option>
                                </md-select>
                            </md-field>
                            <div v-if="isBuildingLocallyPurchased(buildingConfig.id, typeIndex)">
                                <div class="left-text">
                                    <div class="election-config-label">Depreciation Method</div>
                                    <div class="election-config-value">{{selectedDepreciationMethods[buildingConfig.id][typeIndex]}}</div>
                                </div>
                            </div>
                            <div>
                                <md-button v-if="!isBuildingLocallyPurchased(buildingConfig.id, typeIndex)" @click="purchaseBuilding(buildingConfig.id, typeIndex)" class="md-raised btn-primary">Purchase</md-button>
                                <md-button v-if="isBuildingLocallyPurchased(buildingConfig.id, typeIndex)" @click="cancelBuildingSale(buildingConfig.id, typeIndex)" class="md-raised btn-warning">Cancel Purchase</md-button>
                            </div>
                        </md-card-content>
                    </md-card>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import {
    MdCard,
    MdField,
    MdIcon
} from 'vue-material/dist/components'
import { mapGetters } from 'vuex'
export default {
    name: 'Building',
    data() {
        return {
            attrs: {
                currentBuildingPurchaseElections: this.buildingPurchaseElections,
                purchasedBuildings: []
            },
            selectedDepreciationMethods: {}
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentRoundConfig',
            'getBuildingTypeOptions',
        ]),
        currentBuildingPurchaseElections: {
            get() {
                return this.attrs.currentBuildingPurchaseElections;
            },
            set(value) {
                this.attrs.currentBuildingPurchaseElections = value;
                this.$emit(`update:building-purchase-elections`, value);
            },
        },
        purchasedBuildings: {
            get() {
                return this.attrs.purchasedBuildings;
            },
            set(value) {
                this.attrs.purchasedBuildings = value;
            }
        },
    },
    watch: {
        purchasedBuildings(newVal, oldVal) {
            this.currentBuildingPurchaseElections = newVal.map((purchasedBuilding) => {
                const buildingConfigId = purchasedBuilding.buildingConfigId;
                const typeIndex = purchasedBuilding.typeIndex;

                const roundConfig = this.getCurrentRoundConfig;
                const buildingConfig = roundConfig.buildings.find((_buildingConfig) => _buildingConfig.id === buildingConfigId);
                const typeConfig = buildingConfig.typesConfig[typeIndex];
                return {
                    buildingConfigId,
                    type: typeConfig.buildingType,
                    depreciationMethod: this.selectedDepreciationMethods[buildingConfigId][typeIndex],
                    purchasePrice: typeConfig.purchaseCost,
                    capacity: typeConfig.equipmentCapacity,
                    usefulLife: typeConfig.usefulLife,
                };
            });
        },
    },
    props: {
        buildingPurchaseElections: {
            type: Array,
        },
    },
    methods: {
        isBuildingLocallyPurchased(buildingConfigId, typeIndex) {
            return this.purchasedBuildings
                .map((purchasedBuilding) => `${purchasedBuilding.buildingConfigId}|${purchasedBuilding.typeIndex}`)
                .indexOf(`${buildingConfigId}|${typeIndex}`) >= 0;
        },
        purchaseBuilding(buildingConfigId, typeIndex) {
            const purchasedBuildingIds = this.purchasedBuildings
                .map((purchasedBuilding) => `${purchasedBuilding.buildingConfigId}|${purchasedBuilding.typeIndex}`);

            if(purchasedBuildingIds.indexOf(`${buildingConfigId}|${typeIndex}`) === -1) {
                this.purchasedBuildings.push({buildingConfigId, typeIndex});
            }
        },
        cancelBuildingSale(buildingConfigId, typeIndex) {
            const buildingIndex = this.purchasedBuildings
                .map((purchasedBuilding) => `${purchasedBuilding.buildingConfigId}|${purchasedBuilding.typeIndex}`)
                .indexOf(`${buildingConfigId}|${typeIndex}`);

            if(buildingIndex >= 0) {
                this.purchasedBuildings.splice(buildingIndex, 1);
            }
        },
        initElections(elections) {
            this.currentBuildingPurchaseElections = elections;
            if(!this.currentBuildingPurchaseElections) {
                this.currentBuildingPurchaseElections = [];
            }

            const roundConfig = this.getCurrentRoundConfig;
            for(let buildingPurchaseElection of this.currentBuildingPurchaseElections){
                const buildingConfigId = buildingPurchaseElection.buildingConfigId;
                const buildingConfig = roundConfig.buildings.find((buildingCfg) => buildingCfg.id === buildingConfigId);
                const typeIndex = buildingConfig.typesConfig.map((_type) => _type.buildingType).indexOf(buildingPurchaseElection.type)
                this.purchasedBuildings.push({buildingConfigId, typeIndex});
                this.selectedDepreciationMethods[buildingConfigId][typeIndex] = buildingPurchaseElection.depreciationMethod;
            }
        }
    },
    created() {
        const roundConfig = this.getCurrentRoundConfig;
        this.selectedDepreciationMethods = {};
        for(let buildingConfig of roundConfig.buildings) {
            this.selectedDepreciationMethods[buildingConfig.id] = buildingConfig.typesConfig.map((typeConfig) => typeConfig.defaultDepreciationMethod)
        }

        this.initElections(this.buildingPurchaseElections);
    }
}

Vue.use(MdCard);
Vue.use(MdField);
Vue.use(MdIcon);
</script>

<style scoped>
.md-layout-item {
    padding: 10px;
}

.wrapper {
    background-color: var(--main-box-color);
}

.inputWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.md-layout-item {
  padding: 10px;
}

.buildingBlock {
    margin-left: auto;
    margin-right: auto;
    min-width: 98%;
    max-width: 98%;
    padding: 0;
}

.md-card {
    width: 300px;
    margin: 20px;
    display: inline-block;
    vertical-align: top;
}

.md-button {
    margin: 1.5em;
}

.depreciation-field {
    margin-top: 30px;
}

.left-text {
    text-align: left;
}

.election-config-label {
    display: inline-block;
}

.election-config-value {
    display: inline-block;
    font-weight: bold;
    margin-left: 15px;
}

.cost {
    font-weight: bold;
    font-size: 20px;
}
</style>
