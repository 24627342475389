<template>
    <div class="current-assets">
        <div class="assets-top">
            <div class="cash-container">
                <md-badge v-if="currentCash < 0" class="warning-badge md-primary md-rounded" md-content="!" />
                <div v-bind:class="[{'capacity-error': currentCash < 0}, 'asset-value']">{{currentCash | toCurrency}}</div>
                <div v-bind:class="[{'capacity-error': currentCash < 0}, 'asset-label']">CASH</div>
            </div>
            <div class="loan-cap-container" v-if="loanCap > 0">
                <md-badge v-if="loanCap < loanUsed" class="warning-badge md-primary md-rounded" md-content="!" />
                <div v-bind:class="[{'capacity-error': loanCap < loanUsed}, 'asset-value']">{{loanUsed | toCurrency}} <span v-bind:class="[{'capacity-error': loanCap < loanUsed}, 'asset-label']"> / {{loanCap | toCurrency}}</span></div>
                <div v-bind:class="[{'capacity-error': loanCap < loanUsed}, 'asset-label']">CURRENT ROUND DEBT</div>
            </div>
            <div class="bond-cap-container" v-if="bondCap > 0">
                <md-badge v-if="bondCap < bondsSoldPrincipal" class="warning-badge md-primary md-rounded" md-content="!" />
                <div v-bind:class="[{'capacity-error': bondCap < bondsSoldPrincipal}, 'asset-value']">{{bondsSoldPrincipal | toCurrency}} <span v-bind:class="[{'capacity-error': bondCap < bondsSoldPrincipal}, 'asset-label']"> / {{bondCap | toCurrency}}</span></div>
                <div v-bind:class="[{'capacity-error': bondCap < bondsSoldPrincipal}, 'asset-label']">CURRENT ROUND BONDS</div>
            </div>
            <div class="total-borrowing-container" v-if="totalBorrowCap > 0">
                <md-badge v-if="totalBorrowCap < (loanUsed + bondsSoldPrincipal + priorLoanAmount + priorBondsSoldPrincipal)" class="warning-badge md-primary md-rounded" md-content="!" />
                <div v-bind:class="[{'capacity-error': totalBorrowCap < (loanUsed + bondsSoldPrincipal + priorLoanAmount + priorBondsSoldPrincipal)}, 'asset-value']">{{(loanUsed + bondsSoldPrincipal + priorLoanAmount + priorBondsSoldPrincipal) | toCurrency}} <span v-bind:class="[{'capacity-error': totalBorrowCap < (loanUsed + bondsSoldPrincipal + priorLoanAmount + priorBondsSoldPrincipal)}, 'asset-label']"> / {{totalBorrowCap | toCurrency}}</span></div>
                <div v-bind:class="[{'capacity-error': totalBorrowCap < (loanUsed + bondsSoldPrincipal + priorLoanAmount + priorBondsSoldPrincipal)}, 'asset-label']">TOTAL BORROWING</div>
            </div>
            <div class="market-container" v-if="isEquipmentEnabledForGame">
                <div class="production-container">
                    <md-badge v-if="totalEquipmentCapacity < nonInventoryUnitsOffered" class="warning-badge md-primary md-rounded" md-content="!" />
                    <div v-bind:class="[{'capacity-error': totalEquipmentCapacity < nonInventoryUnitsOffered}, 'asset-value']">{{nonInventoryUnitsOffered}}<span v-bind:class="[{'capacity-error': totalEquipmentCapacity < nonInventoryUnitsOffered}, 'asset-label']"> / {{totalEquipmentCapacity}}</span></div>
                    <div v-bind:class="[{'capacity-error': totalEquipmentCapacity < nonInventoryUnitsOffered}, 'asset-label']">PRODUCTION</div>
                </div>
                <div class="production-inventory-container" v-if="totalUnitsUsedFromInventory > 0">
                    <div class="asset-value"><span class="asset-label">PLUS</span> {{totalUnitsUsedFromInventory}}<span class="asset-label"> units</span></div>
                    <div class="asset-label">FROM INVENTORY</div>
                </div>
            </div>
            <div class="furnishings-capacity-container" v-if="isStoreFurnishingEnabledForGame">
                <md-tooltip v-if="totalFurnishingCapacity < totalInventory + nonInventoryUnitsOffered" md-direction="bottom">{{getFurnishingTooltipMessage()}}</md-tooltip>
                <md-badge v-if="totalFurnishingCapacity < totalInventory + nonInventoryUnitsOffered" class="warning-badge md-primary md-rounded" md-content="!" />
                <div v-bind:class="[{'capacity-error': totalFurnishingCapacity < totalInventory + nonInventoryUnitsOffered}, 'asset-value']">{{totalInventory + nonInventoryUnitsOffered}} <span v-bind:class="[{'capacity-error': totalFurnishingCapacity < totalInventory + nonInventoryUnitsOffered}, 'asset-label']"> / {{totalFurnishingCapacity}}</span></div>
                <div v-bind:class="[{'capacity-error': totalFurnishingCapacity < totalInventory + nonInventoryUnitsOffered}, 'asset-label']">FURNISHINGS CAPACITY</div>
            </div>
        </div>
        <div class="assets-bottom md-layout">
            <div class="relative-container md-layout-item">
                <transition name="bounce">
                    <div :key="numLandAssets" class="asset-label land">LAND {{numLandAssets}}</div>
                </transition>
            </div>
            <div class="relative-container md-layout-item">
                <md-badge v-if="totalLandCapacity < numBuildingAssets" class="warning-badge md-primary md-rounded" md-content="!" />
                <transition name="bounce">
                    <div :key="numBuildingAssets" v-bind:class="[{'capacity-error': totalLandCapacity < numBuildingAssets}, 'asset-label', 'building']">BUILDINGS {{numBuildingAssets}} / {{totalLandCapacity}}</div>
                </transition>
            </div>
            <div class="relative-container md-layout-item" v-if="isEquipmentEnabledForGame">
                <md-badge v-if="totalBuildingCapacity < numEquipmentAssets" class="warning-badge md-primary md-rounded" md-content="!" />
                <transition name="bounce">
                    <div :key="numEquipmentAssets" v-bind:class="[{'capacity-error': totalBuildingCapacity < numEquipmentAssets}, 'asset-label', 'equipment']">EQUIPMENT {{numEquipmentAssets}} / {{totalBuildingCapacity}}</div>
                </transition>
            </div>
            <div class="relative-container md-layout-item" v-if="isStoreFurnishingEnabledForGame">
                <md-badge v-if="totalBuildingCapacity < numStoreFurnishingAssets" class="warning-badge md-primary md-rounded" md-content="!" />
                <transition name="bounce">
                    <div :key="numStoreFurnishingAssets" v-bind:class="[{'capacity-error': totalBuildingCapacity < numStoreFurnishingAssets}, 'asset-label', 'store-furnishing']">FURNISHINGS {{numStoreFurnishingAssets}} / {{totalBuildingCapacity}}</div>
                </transition>
            </div>
            <div class="relative-container md-layout-item">
                <transition name="bounce">
                    <div :key="numDebtAssets" class="asset-label debt">LOANS {{numDebtAssets}}</div>
                </transition>
            </div>
        </div>
        <div class="inventory-bottom" v-if="showInventory">
            <div class="asset-label inventory-container inventory-container-label">
                <div class="inventory-container-label-top">IN STOCK</div>
                <div class="inventory-container-label-bottom">INVENTORY</div>
            </div>
            <div class="inventory-container inventory-container-product" v-for="gameProduct in getGameProducts()" v-bind:key="gameProduct.id">
                <div class="asset-label">{{gameProduct.name}}</div>
                <div class="asset-value">{{productInventoryAmount(gameProduct.id)}} <span class="asset-label">UNITS</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Vue from 'vue'
    import { MdBadge } from 'vue-material/dist/components'
    Vue.use(MdBadge);
    export default {
        name: "CurrentAssets",
        data() {
            const result = {
                attrs: {
                    isValid: false
                },
            };
            return result
        },
        props: {
            assets: {
                type: Object,
            },
            landSaleElections: {
                type: Array,
            },
            landPurchaseElections: {
                type: Array,
            },
            buildingSaleElections: {
                type: Array,
            },
            buildingPurchaseElections: {
                type: Array,
            },
            equipmentSaleElections: {
                type: Array,
            },
            equipmentPurchaseElections: {
                type: Array,
            },
            storeFurnishingSaleElections: {
                type: Array,
            },
            storeFurnishingPurchaseElections: {
                type: Array,
            },
            debtsBorrowedElections: {
                type: Array,
            },
            debtsRepayedElections: {
                type: Array,
            },
            productElections: {
                type: Array,
            },
            showInventory: {
                type: Boolean
            },
            loanCap: {
                type: Number
            },
            bondCap: {
                type: Number
            },
            isValid: {
                type: Boolean
            },
            shouldRequirePositiveCashBalanceBeforeElecting: {
                type: Boolean
            },
            productionCost: {
                type: Number
            },
            stockSaleElections: {
                type: Array
            },
            bondSaleElections: {
                type: Array
            },
            additionalStockSaleBenefit: {
                type: Number
            },
            totalBorrowCap: {
                type: Number
            },
            priorLoanAmount: {
                type: Number
            },
            priorBondsSoldPrincipal: {
                type: Number
            },
            isEquipmentEnabledForGame: {
                type: Boolean
            },
            isStoreFurnishingEnabledForGame: {
                type: Boolean
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentRoundConfig',
                'getGameProduct'
            ]),
            currentCash: {
                get() {
                    const landPurchaseTotal = this.landPurchaseElections.map((election) => election.purchasePrice)
                        .reduce((total, purchasePrice) => {
                            return total + purchasePrice;
                        }, 0);
                    const buildingPurchaseTotal = this.buildingPurchaseElections.map((election) => election.purchasePrice)
                        .reduce((total, purchasePrice) => {
                            return total + purchasePrice;
                        }, 0);
                    const storeFurnishingPurchaseTotal = this.storeFurnishingPurchaseElections.map((election) => election.purchasePrice)
                        .reduce((total, purchasePrice) => {
                            return total + purchasePrice;
                        }, 0);
                    const equipmentPurchaseTotal = this.equipmentPurchaseElections.map((election) => election.purchasePrice)
                        .reduce((total, purchasePrice) => {
                            return total + purchasePrice;
                        }, 0);
                    const debtRepayTotal = this.debtsRepayedElections.map((election) => election.repaymentAmount)
                        .reduce((total, repayment) => {
                            return total + repayment;
                        }, 0);
                    const totalPurchases = landPurchaseTotal + buildingPurchaseTotal + equipmentPurchaseTotal + storeFurnishingPurchaseTotal + debtRepayTotal;

                    const landSaleTotal = this.landSaleElections.map((election) => election.salePrice)
                        .reduce((total, salePrice) => {
                            return total + salePrice;
                        }, 0);
                    const buildingSaleTotal = this.buildingSaleElections.map((election) => election.salePrice)
                        .reduce((total, salePrice) => {
                            return total + salePrice;
                        }, 0);
                    const equipmentSaleTotal = this.equipmentSaleElections.map((election) => election.salePrice)
                        .reduce((total, salePrice) => {
                            return total + salePrice;
                        }, 0);
                    const storeFurnishingSaleTotal = this.storeFurnishingSaleElections.map((election) => election.salePrice)
                        .reduce((total, salePrice) => {
                            return total + salePrice;
                        }, 0);
                    const debtsBorrowedTotal = this.debtsBorrowedElections.map((election) => election.borrowingAmount)
                        .reduce((total, borrowingAmount) => {
                            return total + borrowingAmount;
                        }, 0);
                    const stockSalesTotal = this.stockSaleElections.map((election) => election.quantityOffered * election.stockPrice)
                        .reduce((total, salesTotal) => {
                            return total + salesTotal;
                        }, 0);
                    const bondSalesTotal = this.bondSaleElections.map((election) => election.numBondsSold * election.nominalAmount)
                        .reduce((total, salesTotal) => {
                            return total + salesTotal;
                        }, 0);
                    const totalSales = landSaleTotal + buildingSaleTotal + equipmentSaleTotal + storeFurnishingSaleTotal + debtsBorrowedTotal + stockSalesTotal + bondSalesTotal + this.additionalStockSaleBenefit;

                    return this.assets.currentCashBalance + totalSales - totalPurchases - this.productionCost;
                }
            },
            numLandAssets: {
                get() {
                    const numPurchases = this.landPurchaseElections.length;
                    const numSales = this.landSaleElections.length;
                    const numStock = this.assets.lands.filter((landAsset) => !landAsset.isSold).length;
                    return numStock + numPurchases - numSales;
                }
            },
            numBuildingAssets: {
                get() {
                    const numPurchases = this.buildingPurchaseElections.length;
                    const numSales = this.buildingSaleElections.length;
                    const numStock = this.assets.buildings.filter((buildingAsset) => !buildingAsset.isSold).length;
                    return numStock + numPurchases - numSales;
                }
            },
            numEquipmentAssets: {
                get() {
                    const numPurchases = this.equipmentPurchaseElections.length;
                    const numSales = this.equipmentSaleElections.length;
                    const numStock = this.assets.equipments.filter((equipmentAsset) => !equipmentAsset.isSold).length;
                    return numStock + numPurchases - numSales;
                }
            },
            numStoreFurnishingAssets: {
                get() {
                    const numPurchases = this.storeFurnishingPurchaseElections.length;
                    const numSales = this.storeFurnishingSaleElections.length;
                    const numStock = this.assets.storeFurnishings.filter((furnishingAsset) => !furnishingAsset.isSold).length;
                    return numStock + numPurchases - numSales;
                }
            },
            numDebtAssets: {
                get() {
                    const newDebt = this.debtsBorrowedElections.length;
                    const numRepayed = this.debtsRepayedElections.length;
                    const numStock = this.assets.debts.filter((debt) => !debt.isPaidOff).length;
                    return numStock + newDebt - numRepayed;
                }
            },
            isValidForSubmission: {
                get() {
                    return this.attrs.isValid;
                },
                set(value) {
                    this.attrs.isValid = value;
                    this.$emit(`update:is-valid`, value);
                }
            },
            totalLandCapacity: {
                get() {
                    const soldLandIds = this.landSaleElections.map((saleElection) => saleElection.landAssetId)
                    const soldCapacity = this.assets.lands
                        .filter((asset) => soldLandIds.indexOf(asset.id) >= 0)
                        .map((asset) => asset.totalCapacity)
                        .reduce((total, capacity) => {
                            return total + capacity;
                        }, 0);
                    const purchasedCapacity = this.landPurchaseElections
                        .map((purchaseElection) => purchaseElection.capacity)
                        .reduce((total, capacity) => {
                            return total + capacity;
                        }, 0);
                    const stockCapacity = this.assets.lands
                        .filter((asset) => asset.isSold === false)
                        .map((asset) => asset.totalCapacity)
                        .reduce((total, capacity) => {
                            return total + capacity;
                        }, 0);
                    return stockCapacity + purchasedCapacity - soldCapacity;
                }
            },
            totalBuildingCapacity: {
                get() {
                    const soldBuildingIds = this.buildingSaleElections.map((saleElection) => saleElection.buildingAssetId)
                    const soldCapacity = this.assets.buildings
                        .filter((asset) => soldBuildingIds.indexOf(asset.id) >= 0)
                        .map((asset) => asset.totalCapacity)
                        .reduce((total, capacity) => {
                            return total + capacity;
                        }, 0);
                    const purchasedCapacity = this.buildingPurchaseElections
                        .map((purchaseElection) => purchaseElection.capacity)
                        .reduce((total, capacity) => {
                            return total + capacity;
                        }, 0);
                    const stockCapacity = this.assets.buildings
                        .filter((asset) => asset.isSold === false)
                        .map((asset) => asset.totalCapacity)
                        .reduce((total, capacity) => {
                            return total + capacity;
                        }, 0);
                    return stockCapacity + purchasedCapacity - soldCapacity;
                }
            },
            totalEquipmentCapacity: {
                get() {
                    const soldEquipmentIds = this.equipmentSaleElections.map((saleElection) => saleElection.equipmentAssetId)
                    const soldCapacity = this.assets.equipments
                        .filter((asset) => soldEquipmentIds.indexOf(asset.id) >= 0)
                        .map((asset) => asset.totalCapacity)
                        .reduce((total, capacity) => {
                            return total + capacity;
                        }, 0);
                    const purchasedCapacity = this.equipmentPurchaseElections
                        .map((purchaseElection) => purchaseElection.capacity)
                        .reduce((total, capacity) => {
                            return total + capacity;
                        }, 0);
                    const stockCapacity = this.assets.equipments
                      .filter((asset) => asset.isSold === false)
                      .map((asset) => asset.totalCapacity)
                        .reduce((total, capacity) => {
                            return total + capacity;
                        }, 0);
                    return stockCapacity + purchasedCapacity - soldCapacity;
                }
            },
            totalFurnishingCapacity: {
                get() {
                    const soldFurnishingIds = this.storeFurnishingSaleElections.map((saleElection) => saleElection.furnishingAssetId)
                    const soldCapacity = this.assets.storeFurnishings
                        .filter((asset) => soldFurnishingIds.indexOf(asset.id) >= 0)
                        .map((asset) => asset.totalCapacity)
                        .reduce((total, capacity) => {
                            return total + capacity;
                        }, 0);
                    const purchasedCapacity = this.storeFurnishingPurchaseElections
                        .map((purchaseElection) => purchaseElection.capacity)
                        .reduce((total, capacity) => {
                            return total + capacity;
                        }, 0);
                    const stockCapacity = this.assets.storeFurnishings
                      .filter((asset) => asset.isSold === false)
                      .map((asset) => asset.totalCapacity)
                        .reduce((total, capacity) => {
                            return total + capacity;
                        }, 0);
                    return stockCapacity + purchasedCapacity - soldCapacity;
                }
            },
            nonInventoryUnitsOffered: {
                get() {
                    return this.productElections.map((election) => {
                            const numInStock = this.numInStock(election.gameProductId)
                            const inventoryUsed = this.inventoryUsedByProduct(election.gameProductId, numInStock);
                            return election.quantityOffered - inventoryUsed;
                        })
                        .reduce((total, numOffered) => {
                            total += parseInt(numOffered)
                            return total;
                        }, 0);
                }
            },
            totalInventory: {
                get() {
                    return this.getGameProducts().map((product) => {
                            return this.numInStock(product.id)
                        })
                        .reduce((total, inventory) => {
                            total += inventory
                            return total;
                        }, 0);
                }
            },
            totalUnitsUsedFromInventory: {
                get() {
                    // for each productElection, SUM:
                    // Min value between productElection.numOffered and product inventory amount
                    return this.productElections.map((election) => {
                            const numInStock = this.numInStock(election.gameProductId)
                            return Math.min(election.quantityOffered, numInStock);
                        })
                        .reduce((total, inventoryUsed) => {
                            total += inventoryUsed
                            return total;
                        }, 0);
                }
            },
            loanUsed: {
                get() {
                    return this.debtsBorrowedElections.map((election) => election.borrowingAmount)
                        .reduce((total, borrowingAmount) => {
                            return total + borrowingAmount;
                        }, 0);
                }
            },
            bondsSoldPrincipal: {
                get() {
                    return this.bondSaleElections.map((election) => election.numBondsSold * election.nominalAmount)
                        .reduce((total, salesTotal) => {
                            return total + salesTotal;
                        }, 0);
                }
            }
        },
        methods: {
            getFurnishingTooltipMessage() {
                // FurnishingTooltipMessage only shows when furnishing asset is negative (invalid).
                // and if its not production of units making it go negative, then their furnishings must have expired
                if(this.nonInventoryUnitsOffered == 0) {
                    return `Storage capacity has decreased. Total inventory now exceeds available storage capacity. You must buy additional storage before you can produce more units or offer your excess inventory of ${this.totalInventory} units`;
                }
                return "You must have available storage capacity for all units offered for sale";
            },
            productInventoryAmount(gameProductId) {
                const numInStock = this.numInStock(gameProductId)
                const inventoryUsed = this.inventoryUsedByProduct(gameProductId, numInStock);
                return numInStock - inventoryUsed;

            },
            numInStock(gameProductId) {
                return this.assets.products
                    .filter((report) => report.gameProduct.id === gameProductId)
                    .map((report) => report.inventoryAmount)
                    .reduce((total, inventoryAmount) => {
                        total += inventoryAmount
                        return total;
                    }, 0);
            },
            inventoryUsedByProduct(gameProductId, numStock) {
                return this.productElections
                    .filter((election) => election.gameProductId === gameProductId)
                    .map((election) => election.quantityOffered)
                    .reduce((total, quantityOffered) => {
                        const numElected = Math.min(quantityOffered, numStock);
                        total += numElected
                        return total;
                    }, 0);
            },
            getGameProducts() {
              return this.getCurrentRoundConfig.products.map((productConfig) => this.getGameProduct(productConfig.gameProductId))
            },
            checkValidity() {
                const isLoanCapValid = this.loanCap <= 0 || this.loanUsed <= this.loanCap;
                const isCashValid = this.shouldRequirePositiveCashBalanceBeforeElecting ? this.currentCash >= 0 : true;
                this.isValidForSubmission = isLoanCapValid && isCashValid &&
                  (
                    (!this.isEquipmentEnabledForGame || this.nonInventoryUnitsOffered <= this.totalEquipmentCapacity) &&
                    (!this.isStoreFurnishingEnabledForGame || this.totalInventory + this.nonInventoryUnitsOffered <= this.totalFurnishingCapacity) &&
                    this.numBuildingAssets <= this.totalLandCapacity &&
                    (!this.isEquipmentEnabledForGame || this.numEquipmentAssets <= this.totalBuildingCapacity) &&
                    (!this.isStoreFurnishingEnabledForGame || this.numStoreFurnishingAssets <= this.totalBuildingCapacity)
                  )
            }
        },
        watch: {
            currentCash(newVal, oldVal) {
                this.checkValidity();
            },
            nonInventoryUnitsOffered(newVal, oldVal) {
                this.checkValidity();
            },
            totalFurnishingCapacity() {
                this.checkValidity();
            }
        },
        created() {
            this.checkValidity();
        }
    }
</script>

<style scoped>
    .current-assets {
        padding-top: 64px;
    }

    .assets-top {
        height: 50px;
        background-color: var(--nav-bar-color);
        color: white;
        border-bottom: 1px solid var(--round-menu-color);
    }

    .assets-bottom, .inventory-bottom {
        background-color: var(--nav-bar-color);
        color: white;
        border-bottom: 1px solid var(--round-menu-color);
    }

    .assets-bottom {
        height: 30px;
    }

    .inventory-bottom {
        height: 50px;
    }

    .cash-container {
        display: inline-block;
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .loan-cap-container {
        display: inline-block;
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .bond-cap-container {
        display: inline-block;
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .total-borrowing-container {
        display: inline-block;
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .market-container {
        display: inline-block;
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .furnishings-capacity-container {
        display: inline-block;
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .inventory-container {
        display: inline-block;
        text-align: center;
    }

    .inventory-container-product {
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
        padding-left: 12px;
        padding-right: 12px;
    }

    .asset-value {
        color: var(--text-secondary);
        font-size: 18px;
        font-weight: bold;
    }

    .asset-label {
        font-weight: normal;
        font-size: 16px;
        color: var(--text-secondary-minor);
    }

    .assets-bottom > .relative-container {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .production-container {
        display: inline-block;
    }

    .production-inventory-container {
        display: inline-block;
        padding-left: 30px;
    }

    .inventory-container-label {
        padding-right: 40px;
        text-align: left;
    }

    .inventory-container-label-bottom {
        margin-top: -5px;
    }

    @media screen and (max-width: 550px){
        .cash-container {
            padding-left: 6px;
            padding-right: 6px;
        }

        .loan-cap-container {
            padding-left: 6px;
            padding-right: 6px;
        }

        .market-container {
            padding-left: 6px;
            padding-right: 6px;
        }
    }

    @media screen and (max-width: 650px){
        .asset-label {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 1232px){
        .assets-top {
            height: 100px;
        }
    }

    .relative-container {
        position: relative;
    }

    .relative-container > .warning-badge {
        margin-left: auto;
        margin-right: auto;
    }

    .capacity-error {
        color: var(--btn-warning-color) !important;
    }

    .warning-badge {
        position: absolute;
        top: -12px;
        left: 2px;
        width: 25px;
        height: 25px;
        background-color: var(--btn-warning-color) !important;
        color: var(--text-secondary) !important;
        font-size: 18px;
        font-weight: bold;
        z-index: 150;
    }

    .bounce-enter-active {
        animation: bounce-in 0.8s;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }
</style>
