<template>
    <div class="md-layout">
        <div class="bondBlock">
            <div class="wrapper">
                <md-card v-for="(bond, index) in getCurrentRoundConfig.bonds.bondOptions" v-bind:key="bond.id" :class="{ 'selected-border' : isBondLocallySold(bond.id) }">
                    <div class="mini-id">
                        Asset ID: #{{bond.miniId}}
                    </div>
                    <md-card-header>
                        <div class="md-title">Bond Choice {{index + 1}}</div>
                    </md-card-header>
                    <md-card-content>
                        <div class="inputWrapper md-gutter" v-if="!selectedBonds.includes(bond.id)">
                            <md-field>
                                <label>Number of Bonds to Sell</label>
                                <md-input 
                                    v-model="bondAmounts[index]" 
                                    @keypress="preventNegativeAndDecimal($event)"
                                    @click="selectBondAmount(index)"
                                    :ref="'bondAmounts' + index" 
                                    type="text"
                                    pattern="\d*">
                                </md-input>
                            </md-field>
                        </div>
                        <div v-if="inputError && !selectedBonds.includes(bond.id)" class="error">
                            {{inputError}}
                        </div>
                        <div v-if="isBondLocallySold(bond.id)" class="left-text">
                            <div class="election-config-label">Amount Selected to Sell</div>
                            <div class="election-config-value">{{bondAmounts[index]}}</div>
                        </div>
                        <div class="left-text">
                            <div class="election-config-label">Nominal Amount</div>
                            <div class="election-config-value">{{bond.nominalAmount | toCurrency}}</div>
                        </div>
                        <div class="left-text">
                            <div class="election-config-label">Interest rate per round</div>
                            <div class="election-config-value">{{ (bond.nominalInterestRate * 100).toFixed(2)}}%</div>
                        </div>
                        <div class="left-text">
                            <div class="election-config-label">Payment Due Quarter</div>
                            <div class="election-config-value">Q{{bond.paymentDueQuarterEnd}}</div>
                        </div>
                        <div class="left-text">
                            <div class="election-config-label">Number Payments per Year</div>
                            <div class="election-config-value">{{bond.numPaymentsPerYear}}</div>
                        </div>
                        <div class="left-text">
                            <div class="election-config-label">Term</div>
                            <div class="election-config-value">{{bond.term}} rounds</div>
                        </div>
                        <div>
                            <md-button v-if="!isBondLocallySold(bond.id) && parseFloat(bondAmounts[index]) > 0" @click="sellBond(bond.id, index)" class="md-raised btn-primary">Sell</md-button>
                            <md-button v-if="isBondLocallySold(bond.id)" @click="cancelSellBond(bond.id)" class="md-raised btn-warning">Cancel Bond(s) Sale</md-button>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import {
    MdCard,
    MdField,
    MdIcon
} from 'vue-material/dist/components'
import { mapGetters } from 'vuex';

export default {
    name: 'Bond',
    data() {
        return {
            attrs: {
                currentBondSaleElections: this.bondSaleElections,
                selectedBonds: [],
            },
            bondAmounts: [],
            inputError: ""
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentRoundConfig',
            'getTeam',
            'getCurrentCourse'
        ]),
        currentBondSaleElections: {
            get() {
                return this.attrs.currentBondSaleElections;
            },
            set(value) {
                this.attrs.currentBondSaleElections = value;
                this.$emit(`update:bond-sale-elections`, value);
            },
        },
        selectedBonds: {
            get() {
                return this.attrs.selectedBonds;
            },
            set(value) {
                this.attrs.selectedBonds = value;
            },
        },
    },
    watch: {
        selectedBonds(val) {
            this.currentBondSaleElections = val.map((bondConfigId) => {
                const roundConfig = this.getCurrentRoundConfig;
                const bondIndex = roundConfig.bonds.bondOptions.map((bondOption) => bondOption.id)
                    .indexOf(bondConfigId);
                const bondOptions = this.getCurrentRoundConfig.bonds.bondOptions[bondIndex];
                return {
                    bondConfigId,
                    numBondsSold: this.bondAmounts[bondIndex],
                    nominalAmount: bondOptions.nominalAmount
                };
            });
        }
    },
    methods: {
        preventNegativeAndDecimal(event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if(keyCode < 48 || keyCode > 57) {
              event.preventDefault();
            }
        },
        selectBondAmount(index) {
            const input = this.$refs['bondAmounts' + index][0].$el;
            input.focus();
            input.select();
        },
        isBondLocallySold(bondConfigId) {
            return this.selectedBonds.indexOf(bondConfigId) >= 0;
        },
        sellBond(bondConfigId, index) {
            const selectedBondOptions = this.getCurrentRoundConfig.bonds.bondOptions[index];
            const parsedNumBondElected = parseInt(this.bondAmounts[index]);

            if(isNaN(parsedNumBondElected) || !Number.isInteger(parsedNumBondElected)) {
                this.bondAmounts[index] = 0;
            } else {
                this.bondAmounts[index] = parsedNumBondElected;
            }

            const totalBondSalesPrincipal = this.currentBondSaleElections.reduce((result, bondElection) => {
                return (bondElection.numBondsSold * bondElection.nominalAmount) + result;
            }, 0);

            const newTotalBondSalesPrincipal = totalBondSalesPrincipal + (this.bondAmounts[index] * selectedBondOptions.nominalAmount);

            if(newTotalBondSalesPrincipal + this.priorBondsSoldPrincipal > this.totalBorrowCap) {
                this.inputError = "Your selection exceeds the maximum borrowing cap";
                return;
            }

            if(newTotalBondSalesPrincipal > this.bondCap) {
                this.inputError = "Your selection exceeds the borrowing cap for this round";
                return;
            }

            if(this.selectedBonds.indexOf(bondConfigId) === -1) {
                this.selectedBonds.push(bondConfigId);
                this.inputError = "";
            }
        },
        cancelSellBond(bondConfigId) {
            const bondIndex = this.selectedBonds.indexOf(bondConfigId);

            if(bondIndex >= 0) {
                this.selectedBonds.splice(bondIndex, 1);
            }
            this.inputError = "";
        },
        initSelectedBonds() {
            const roundConfig = this.getCurrentRoundConfig;
            if(this.currentBondSaleElections) {
                for(let bondSaleElection of this.currentBondSaleElections){
                    this.selectedBonds.push(bondSaleElection.bondConfigId);
                    const bondIndex = roundConfig.bonds.bondOptions.map((bondOption) => bondOption.id)
                        .indexOf(bondSaleElection.bondConfigId);
                    this.bondAmounts[bondIndex] = bondSaleElection.numBondsSold;
                }
            }
        }
    },
    props: {
        bondSaleElections: {
            type: Array
        },
        activeRoundId: {
            type: String
        },
        bondCap: {
            type: Number
        },
        totalBorrowCap: {
            type: Number
        },
        priorBondsSoldPrincipal: {
            type: Number
        }
    },
    created() {
        const roundConfig = this.getCurrentRoundConfig;
        this.bondAmounts = roundConfig.bonds.bondOptions.map((_) => {
            return 0;
        });

        this.initSelectedBonds();
    }
}

Vue.use(MdCard);
Vue.use(MdField);
Vue.use(MdIcon);
</script>

<style scoped>
    .md-layout-item {
        padding: 10px;
    }

    .wrapper {
        background-color: var(--main-box-color);
    }

    .md-layout-item {
        padding: 10px;
    }

    .bondBlock {
        margin-left: auto;
        margin-right: auto;
        min-width: 98%;
        max-width: 98%;
        padding: 0;
    }

    .md-card {
        width: 300px;
        margin: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .md-button {
        margin: 1.5em;
    }

    .left-text {
        text-align: left;
    }

    .election-config-label {
        display: inline-block;
    }

    .election-config-value {
        display: inline-block;
        font-weight: bold;
        margin-left: 15px;
    }

    .error {
        color: var(--warning-text-color);
    }
</style>
