<template>
    <div v-if="isPageReady" class="competitor-financials-container">
        <stock-ticker :activeRoundId="activeRoundId"></stock-ticker>
        <div class="countdown" v-if="shouldShowCountdown">
            <Countdown :deadline="currentDeadline" :countdown-id="'competitor-financials'"></Countdown>
            <div class="remaining-label">remaining</div>
        </div>
        <div class="page-title">Competitor Financials Round {{ roundNum }}</div>
        <div>Market Ended {{ marketEndFormatted }}</div>
        <div class="md-layout select-team-container">
            <div class="md-layout"></div>
            <div class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-90">
                <h3>Select Team</h3>
                <div class="custom-select-wrapper">
                    <select class="custom-select" name="basic-dropdown" v-model="selectedTeam">
                    <option v-for="(team, index) in selectableTeams" v-bind:key="team.id" :value="team">{{index + 1}}. {{team.name}}</option>
                    </select>
                </div>
            </div>
            <div class="md-layout"></div>
        </div>
        <div class="md-layout">
            <div class="md-layout"></div>
            <financial-ratios-table class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-90" :semester-id="semesterId" :round-config-id="activeRoundId" :team-id="selectedTeam.id"></financial-ratios-table>
            <div class="md-layout"></div>
        </div>
        <div class="md-layout">
            <div class="balance-sheet md-layout-item md-xl-size-33 md-large-size-33 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
                <balance-sheet :allow-submission-when-valid-game-config-and-game-state="false" :selected-team="selectedTeam" :active-round-id="activeRoundId"></balance-sheet>
            </div>
            <div class="income-statement md-layout-item md-xl-size-33 md-large-size-33 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
                <income-statement :allow-submission-when-valid-game-config-and-game-state="false" :selected-team="selectedTeam" :active-round-id="activeRoundId"></income-statement>
            </div>
            <div class="cash-flow md-layout-item md-xl-size-33 md-large-size-33 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
                <cash-flow :allow-submission-when-valid-game-config-and-game-state="false" :selected-team="selectedTeam" :active-round-id="activeRoundId"></cash-flow>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import IncomeStatement from './IncomeStatement.vue';
import CashFlow from './CashFlow.vue';
import BalanceSheet from './BalanceSheet.vue';
import StockTicker from './StockTicker.vue';
import {GameRoundPhase} from "@bit/accountonomics.types-lib.api-types/apiTypes";
import FinancialRatiosTable from './FinancialRatiosTable.vue';
import { DateTime } from 'luxon';
import Countdown from './Countdown';

export default {
    name: "CompetitorFinancials",
    components: {
        'income-statement': IncomeStatement,
        'cash-flow': CashFlow,
        'balance-sheet': BalanceSheet,
        'stock-ticker': StockTicker,
        'financial-ratios-table': FinancialRatiosTable,
        Countdown
    },
    data() {
        return {
            roundNum: null,
            marketEndFormatted: null,
            selectableTeams: null,
            semesterId: null,
            selectedTeam: null,
            isPageReady: false
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentCourse',
            'getFormattedDate',
            'getScoreboardData',
            'getCurrentGameState',
            'getNextDeadline'
        ]),
        currentDeadline() {
            return this.getNextDeadline;
        },
        shouldShowCountdown() {
            const nowSeconds = DateTime.local().toSeconds();
            const nextDeadlineSeconds = this.getNextDeadline;
            if(isNaN(nextDeadlineSeconds)) {
                return false;
            }
            // show countdown if time is less than 1 hour
            return nextDeadlineSeconds - nowSeconds <= 3599;
        }
    },
    props: {
        activeRoundId: {
            type: String
        }
    },
    watch: {
        activeRoundId(val) {
          if(val) {
            this.roundNum = this.getCurrentCourse.rounds.map((_round) => _round.id).indexOf(this.activeRoundId) + 1;
          }
        }
    },
    methods: {
        ...mapActions([
            'fetchScoreboardData',
            'getStudentTeam'
        ]),
        async init() {
            let roundIndex = this.getScoreboardData.rounds.findIndex((round) => round.roundId === this.activeRoundId);
            const roundData = this.getScoreboardData.rounds[roundIndex];
            this.selectableTeams = await Promise.all(roundData.teamDatas.map(async (teamData) => {
                const studentTeams = await this.getStudentTeam({
                    teamId: teamData.teamId
                });
                const semester = studentTeams.semester;
                return {
                    totalRoundIncome: teamData.currentRoundIncome,
                    id: teamData.teamId,
                    name: teamData.teamName,
                    semester
                }
            })
            .sort((a, b) => b.totalRoundIncome - a.totalRoundIncome))

            this.selectedTeam = this.selectableTeams[0];
            this.roundNum = this.getCurrentCourse.rounds.map((_round) => _round.id).indexOf(this.activeRoundId) + 1;
            const roundTimings = JSON.parse(JSON.stringify(this.selectedTeam.semester.roundTimings));
            const roundTiming = roundTimings[this.roundNum - 1];

            this.marketEndFormatted = this.getFormattedDate(roundTiming.electionsDeadline, this.selectedTeam.semester.timezone);
        }
    },
    async created() {
        this.loggedInTeamId = this.$route.params.teamId;
        this.semesterId = this.$route.params.semesterId;
        await this.fetchScoreboardData({
            semesterId: this.semesterId
        });

        if(this.activeRoundId) {
            await this.init();
        }
        this.isPageReady = true;
    }
}
</script>

<style scoped>
    .custom-select {
        position: relative;
        display: flex;
        flex-direction: column;
        border: 1px solid black;
        width: 100%;
    }

    .custom-select select {
        padding-left: 10px;
        background-color: transparent;
        font-size: 18px;
        outline: none;
        appearance: none;
        cursor: pointer;
    }

    .custom-select-wrapper {
        position: relative;
        user-select: none;
        width: 100%;
    }

    .page-title {
        font-weight: bold;
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .competitor-financials-container {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    .select-team-container {
        padding-bottom: 20px;
    }
</style>
