<template>
    <div class="form-container">
<!--        <div v-if="paymentIntentKey">Payment Intent: {{JSON.stringify(paymentIntentKey)}}</div>-->
        <md-field>
            <label>Name on Card</label>
            <md-input v-model="name" type="text"></md-input>
        </md-field>
        <div class="card" ref="card"></div>
        <md-field>
            <label>Address Line 1</label>
            <md-input v-model="address1" type="text"></md-input>
        </md-field>
        <md-field>
            <label>Address Line 2</label>
            <md-input v-model="address2" type="text"></md-input>
        </md-field>
        <md-field>
            <label>City</label>
            <md-input v-model="city" type="text"></md-input>
        </md-field>
        <md-field>
            <label>State</label>
            <md-input v-model="state" type="text"></md-input>
        </md-field>
    </div>
</template>

<script>
import Vue from 'vue';

export default {
    name: 'StripeCardElement',
    data() {
        return {
            attrs: {
                currentStripe: null,
                currentCard: null,
                paymentIntent: null,
                paymentError: null
            },
            cardToken: null,
            paymentIntentKey: null,
            name: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            url: process.env.VUE_APP_LAMBDA_URL
        }
    },
    props: {
        paymentIntent: Object,
        paymentError: String,
        amount: Number
    },
    watch: {
        async amount(val) {
            if(val && val > 0 && val !== this.amount) {
                await this.generatePaymentIntent();
            }
        }
    },
    computed: {
      currentPaymentIntent: {
        get() {
          return this.attrs.paymentIntent;
        },
        set(value) {
          this.attrs.paymentIntent = value;
          this.$emit(`update:payment-intent`, value);
        }
      },
      currentPaymentError: {
        get() {
          return this.attrs.paymentError;
        },
        set(value) {
          this.attrs.paymentError = value;
          this.$emit(`update:payment-error`, value);
        }
      }
    },
    methods: {
      async confirmPaymentIntent() {
        if(!this.currentPaymentIntent || !this.currentPaymentIntent.client_secret) {
          throw("Payment intent does not exist in order to make payment");
        }
        const params = {
          payment_method: {
            card: this.currentCard,
            billing_details: {
              name: this.name,
            },
          },
        }
        try {
          const result = await this.currentStripe.confirmCardPayment(this.currentPaymentIntent.client_secret, params);
          if(result.paymentIntent) {
            this.currentPaymentIntent = result.paymentIntent;
          } else {
            this.currentPaymentError = "Could not confirm paymentIntent: " + JSON.stringify(result);
          }
          return result;
        } catch (err) {
          this.currentPaymentError = "Could not confirm paymentIntent: " + JSON.stringify(err);
        }
      },
      async generatePaymentIntent() {
        try {
          const response = await fetch(`${this.url}/paymentIntent`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Request-Method': 'POST'
            },
            body: JSON.stringify({amount: this.amount})
          });
          const responseJson = await response.json();
          if(response.ok) {
            this.currentPaymentIntent = responseJson
          } else {
            this.currentPaymentIntent = null;
          }
        } catch(err) {
          this.currentPaymentIntent = null;
        }
      }
    },
    async created() {
        this.currentStripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
        var elements = this.currentStripe.elements({
            fonts: [{
                cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
            }]
        });
        this.currentCard = elements.create('card', {
            iconStyle: 'solid',
                style: {
                base: {
                    iconColor: '#000000',
                    color: '#000000',
                    fontWeight: 500,
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                    color: '#000000',
                    },
                    '::placeholder': {
                    color: '#000000',
                    },
                },
                invalid: {
                    iconColor: '#ff5252',
                    color: '#ff5252',
                }
            },
        });
        Vue.nextTick(() => {
            this.currentCard.mount(this.$refs["card"]);
        });

        if(this.amount && this.amount > 0) {
          await this.generatePaymentIntent();
        }
    }
}

</script>

<style scoped>
.card.StripeElement--empty {
    opacity: 0.5;
}
</style>
