import gql from 'graphql-tag';
import { apolloClient } from '../../main';
import { ReportVuexState } from "@/types/types";
import { IncomeStatement, BalanceSheet, CashFlow, IncomeStatementInput, BalanceSheetInput, CashFlowInput } from "@bit/accountonomics.types-lib.api-types";

const state: ReportVuexState = {
  currentIncomeStatement: undefined,
  currentBalanceSheet: undefined,
  currentCashFlowStatement: undefined,
  currentJournalEntriesAndTAccounts: undefined
};

const getters = {
  getCurrentIncomeStatement: (state: ReportVuexState): IncomeStatement | undefined => {
    return state.currentIncomeStatement;
  },
  getCurrentBalanceSheet: (state: ReportVuexState): BalanceSheet | undefined => {
    return state.currentBalanceSheet;
  },
  getCurrentCashFlowStatement: (state: ReportVuexState): CashFlow | undefined => {
    return state.currentCashFlowStatement;
  },
  getCurrentJournalEntriesAndTAccounts: (state: ReportVuexState): any | undefined => {
    return state.currentJournalEntriesAndTAccounts;
  }
};

const actions = {
  async fetchIncomeStatement({ commit }: any, payload: any) {
    const response = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
      query IncomeStatement($roundConfigId: ID!, $teamId: String!) {
        incomeStatement(roundConfigId: $roundConfigId, teamId: $teamId) {
          revenue,
          returns,
          net,
          costOfGoodsSold,
          grossProfit,
          adExpense,
          depreciationExpense,
          interest,
          gainOnAssetSales,
          badDebtExpense,
          total,
          incomeTax,
          netIncome,
          correctAnswers {fieldName, correctAnswer},
          incorrectAnswers {fieldName, correctAnswer},
          disabledFieldAnswers {fieldName, correctAnswer},
          submittedBy { firstname, lastname },
          createdAt
        }
      }
      `,
      variables: {
        roundConfigId: payload.roundConfigId,
        teamId: payload.teamId
      }
    });

    return commit('setCurrentIncomeStatement', response.data.incomeStatement);
  },
  async fetchBalanceSheet({ commit }: any, payload: any) {
    const response = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
      query BalanceSheet($roundConfigId: ID!, $teamId: String!) {
        balanceSheet(roundConfigId: $roundConfigId, teamId: $teamId) {
          cash,
          accountsReceivable,
          inventory,
          equipment,
          equipmentDepreciation,
          furnishings,
          furnishingsDepreciation,
          buildings,
          buildingsDepreciation,
          land,
          totalAssets,
          loanPayable,
          totalLiabilities,
          commonStock,
          retainedEarnings,
          totalLiabilitiesAndEquity,
          bondsPayable,
          bondsPremiumOrDiscount,
          correctAnswers {fieldName, correctAnswer},
          incorrectAnswers {fieldName, correctAnswer},
          disabledFieldAnswers {fieldName, correctAnswer},
          submittedBy { firstname, lastname },
          createdAt
        }
      }
      `,
      variables: {
        roundConfigId: payload.roundConfigId,
        teamId: payload.teamId
      }
    });

    return commit('setCurrentBalanceSheet', response.data.balanceSheet);
  },
  async fetchCashFlowStatement({ commit }: any, payload: any) {
    const response = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
      query CashFlow($roundConfigId: ID, $teamId: String!) {
        cashFlow(roundConfigId: $roundConfigId, teamId: $teamId) {
          operatingActivitiesSales,
          operatingActivitiesPurchasedInventory,
          operatingActivitiesPaidAdvertising,
          operatingActivitiesSalesReturns,
          operatingActivitiesIncomeTaxesPaid,
          operatingActivitiesInterestPaid,
          operatingActivitiesTotal,
          investingActivitiesInflowEquipment,
          investingActivitiesInflowFurnishings,
          investingActivitiesInflowBuildings,
          investingActivitiesInflowLand,
          investingActivitiesOutflowEquipment,
          investingActivitiesOutflowFurnishings,
          investingActivitiesOutflowBuildings,
          investingActivitiesOutflowLand,
          investingActivitiesTotal,
          financingActivitiesCapital,
          bondBorrowing,
          financingActivitiesLoanBorrow,
          bondRepayment,
          financingActivitiesLoanRepay,
          financingActivitiesTotal,
          netCash,
          beginningOfRound,
          endOfRound,
          correctAnswers {fieldName, correctAnswer},
          incorrectAnswers {fieldName, correctAnswer},
          disabledFieldAnswers {fieldName, correctAnswer},
          submittedBy { firstname, lastname },
          createdAt
        }
      }
      `,
      variables: {
        roundConfigId: payload.roundConfigId,
        teamId: payload.teamId
      }
    });

    return commit('setCurrentCashFlowStatement', response.data.cashFlow);
  },
  async fetchJournalEntrySubmissions({ commit }: any, payload: any) {
    const response = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
      query JournalEntrySubmissions($roundConfigId: ID!, $teamId: ID!) {
        journalEntrySubmissions(roundConfigId: $roundConfigId, teamId: $teamId) {
          journalEntryTypeEnum,
          journalEntryTypeFriendlyName,
          availableAccounts {
            friendlyName,
            enum
          },
          debitsOrCredits {
            accountEnum,
            accountFriendlyName,
            balanceType,
            value
          },
          submittedBy { firstname, lastname },
          correctDebitsOrCredits {
            accountEnum,
            accountFriendlyName,
            balanceType,
            value
          },
          incorrectDebitsOrCredits {
            props
          },
          numCorect,
          numTotal,
          isCorrect,
          createdAt,
          problemText
        }
      }
      `,
      variables: {
        roundConfigId: payload.roundConfigId,
        teamId: payload.teamId
      }
    });

    return response.data.journalEntrySubmissions;
  },
  async fetchGradingReport({ commit, rootGetters }: any, payload: any) {
    const response = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
      query GradingReport($roundConfigId: ID!, $teamId: String!) {
        gradingReport(roundConfigId: $roundConfigId, teamId: $teamId) {
          roundConfigId,
          teamId,
          incomeStatementNumCorrect,
          incomeStatementTotal,
          balanceSheetNumCorrect,
          balanceSheetTotal,
          cashFlowNumCorrect,
          cashFlowTotal,
          journalEntryNumCorrect,
          journalEntryTotal,
          combinedNumCorrect,
          combinedTotal
        }
      }
      `,
      variables: {
        roundConfigId: payload.roundConfigId,
        teamId: payload.teamId
      }
    });

    return response.data.gradingReport;
  },
  async fetchRemainingInventoryCosts({ commit, rootGetters }: any, payload: any) {
    const response = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
      query RemainingInventoryCosts($teamId: String!, $roundConfigId: ID!) {
        remainingInventoryCosts(teamId: $teamId, roundConfigId: $roundConfigId) {
          roundIndex,
          roundConfigId,
          inventoryCosts {
            gameProductId,
            gameProductName,
            remainingUnits,
            cost
          }
        }
      }
      `,
      variables: {
        teamId: payload.teamId,
        roundConfigId: payload.roundConfigId
      }
    });

    return response.data.remainingInventoryCosts;
  },
  async submitIncomeStatement({ commit,  rootGetters }: any, payload: IncomeStatementInput) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
            mutation SubmitIncomeStatement($incomeStatementInput: IncomeStatementInput!) {
              submitIncomeStatement(input: $incomeStatementInput) {
                id,
                revenue,
                returns,
                net,
                costOfGoodsSold,
                grossProfit,
                adExpense,
                depreciationExpense,
                interest,
                gainOnAssetSales,
                badDebtExpense,
                total,
                incomeTax,
                netIncome
            }
          }
        `,
        variables: {
          incomeStatementInput: payload
        }
      });

      return commit('setCurrentIncomeStatement', response.data.submitIncomeStatement);
    } catch(err) {
      return Promise.reject(err);
    }
  },
  async submitBalanceSheet({ commit,  rootGetters }: any, payload: BalanceSheetInput) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
            mutation SubmitBalanceSheet($balanceSheetInput: BalanceSheetInput!) {
              submitBalanceSheet(input: $balanceSheetInput) {
                id,
                cash,
                accountsReceivable,
                inventory,
                equipment,
                equipmentDepreciation,
                furnishings,
                furnishingsDepreciation,
                buildings,
                buildingsDepreciation,
                land,
                totalAssets,
                loanPayable,
                totalLiabilities,
                commonStock,
                retainedEarnings,
                bondsPayable,
                bondsPremiumOrDiscount,
                totalLiabilitiesAndEquity
            }
          }
        `,
        variables: {
          balanceSheetInput: payload
        }
      });

      return commit('setCurrentBalanceSheet', response.data.submitBalanceSheet);
    } catch(err) {
      return Promise.reject(err);
    }
  },
  async submitCashFlowStatement({ commit,  rootGetters }: any, payload: CashFlowInput) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
            mutation SubmitCashFlow($cashFlowInput: CashFlowInput!) {
              submitCashFlow(input: $cashFlowInput) {
                id,
                operatingActivitiesSales,
                operatingActivitiesPurchasedInventory,
                operatingActivitiesPaidAdvertising,
                operatingActivitiesSalesReturns,
                operatingActivitiesIncomeTaxesPaid,
                operatingActivitiesInterestPaid,
                operatingActivitiesTotal,
                investingActivitiesInflowEquipment,
                investingActivitiesInflowFurnishings,
                investingActivitiesInflowBuildings,
                investingActivitiesInflowLand,
                investingActivitiesOutflowEquipment,
                investingActivitiesOutflowFurnishings,
                investingActivitiesOutflowBuildings,
                investingActivitiesOutflowLand,
                investingActivitiesTotal,
                financingActivitiesCapital,
                bondBorrowing,
                financingActivitiesLoanBorrow,
                bondRepayment,
                financingActivitiesLoanRepay,
                financingActivitiesTotal,
                netCash,
                beginningOfRound,
                endOfRound
            }
          }
        `,
        variables: {
          cashFlowInput: payload
        }
      });

      return commit('setCurrentCashFlowStatement', response.data.submitCashFlow);
    } catch(err) {
      return Promise.reject(err);
    }
  },
  async submitJournalEntry({ commit,  rootGetters }: any, payload: any) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation SubmitJournalEntry($journalEntrySubmissionInput: JournalEntrySubmissionInput!) {
            submitJournalEntry(input: $journalEntrySubmissionInput) {
              journalEntryTypeEnum,
              journalEntryTypeFriendlyName,
              debitsOrCredits {
                accountEnum,
                accountFriendlyName,
                balanceType,
                value
              },
              submittedBy { firstname, lastname },
              correctDebitsOrCredits {
                accountEnum,
                accountFriendlyName,
                balanceType,
                value
              },
              incorrectDebitsOrCredits {
                props
              },
              numCorect,
              numTotal,
              isCorrect,
              createdAt
            }
          }
        `,
        variables: {
          journalEntrySubmissionInput: payload
        }
      });

      return response.data.submitJournalEntry;
    } catch(err) {
      return Promise.reject(err);
    }
  },

  async fetchJournalEntriesAndTAccounts({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
        query JournalEntriesAndTAccounts($roundConfigId: ID!, $teamId: ID!) {
          journalEntriesAndTAccounts(roundConfigId: $roundConfigId, teamId: $teamId) {
            journalEntries {
              id,
              friendlyName,
              miniId,
              entries {
                id,
                roundConfigId,
                roundIndex,
                team {
                  id
                },
                amount,
                account,
                journalEntryId,
                miniId,
                journalEntryTypeEnum,
                friendlyName,
                normalBalance
              }
            },
            tAccounts {
              account,
              friendlyName,
              normalBalance,
              type,
              beginningBalance,
              endingBalance,
              roundConfigId,
              team {
                id
              },
              entries {
                id,
                roundConfigId,
                roundIndex,
                team {
                  id
                },
                amount,
                account,
                journalEntryId,
                miniId,
                journalEntryTypeEnum,
                friendlyName,
                normalBalance
              }
            }
          }
        }
        `,
        variables: {
          roundConfigId: payload.roundConfigId,
          teamId: payload.teamId
        }
      });

      return commit('setCurrentJournalEntriesAndTAccounts', response.data.journalEntriesAndTAccounts);
    } catch(err) {
      return Promise.reject(err);
    }
  },
  async fetchFinancialRatios({ commit, rootGetters }: any, payload: any) {
    const response = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
      query FinancialRatios($semesterId: ID!, $teamId: ID, $roundConfigId: ID!) {
        financialRatios(semesterId: $semesterId, teamId: $teamId, roundConfigId: $roundConfigId) {
          team {
            id,
            name
          }
          earningsPerShare,
          priceEarnings,
          debtToEquity,
          returnOnEquity,
          returnOnAssets
        }
      }
      `,
      variables: {
        semesterId: payload.semesterId,
        teamId: payload.teamId,
        roundConfigId: payload.roundConfigId
      }
    });

    return response.data.financialRatios;
  }
};

const mutations = {
  setCurrentIncomeStatement(state: ReportVuexState, incomeStatement: IncomeStatement) {
    state.currentIncomeStatement = incomeStatement;
  },
  setCurrentBalanceSheet(state: ReportVuexState, balanceSheet: BalanceSheet) {
    state.currentBalanceSheet = balanceSheet;
  },
  setCurrentCashFlowStatement(state: ReportVuexState, cashFlow: CashFlow) {
    state.currentCashFlowStatement = cashFlow;
  },
  setCurrentJournalEntriesAndTAccounts(state: ReportVuexState, journalEntriesAndTAccounts: any) {
    state.currentJournalEntriesAndTAccounts = journalEntriesAndTAccounts;
  },
  resetReportState(state: ReportVuexState) {
    state.currentIncomeStatement = undefined;
    state.currentBalanceSheet = undefined;
    state.currentCashFlowStatement = undefined;
    state.currentJournalEntriesAndTAccounts = undefined;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
