<template>
  <div class="container">
    <div class="main-box">
      <h1 class="text-center">New Semester</h1>
      <div class="new-semester-form-container">
        <div class="md-layout">
          <div class="md-layout-item"></div>
          <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
            <label>Semester Name</label>
            <md-input v-model="newSemesterName"></md-input>
            <span v-if="inputError === 'name'" class="error form-error">Please enter a semester name</span>
          </md-field>
          <div class="md-layout-item"></div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item"></div>
          <div class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
            <div class="md-layout-item">
              <md-field>
                <label for="course">Select a Course Template</label>
                <md-select v-bind:class="{ 'select-course-error':inputError === 'course'}" v-model="selectedCourseTemplateId" name="dropdown">
                  <md-optgroup label="Public Course Templates">
                    <md-option v-for="publicCourseTemplate in publicCourseTemplates" v-bind:key="publicCourseTemplate.id" :value="publicCourseTemplate.id">{{publicCourseTemplate.name}}</md-option>
                  </md-optgroup>

                  <md-optgroup label="My Course Templates">
                    <md-option v-for="myCourseTemplate in myCourseTemplates" v-bind:key="myCourseTemplate.id" :value="myCourseTemplate.id">{{myCourseTemplate.name}}</md-option>
                  </md-optgroup>
                </md-select>
              </md-field>
            </div>
            <div v-if="inputError === 'course'" class="error">Please select a course</div>
          </div>
          <div class="md-layout-item"></div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item"></div>
          <div class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
            <h3 class="label">Start Date</h3>
            <md-datepicker v-model="newSemesterStart" :md-disabled-dates="disabledDates" md-immediately/>
          </div>
          <div class="md-layout-item"></div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item"></div>
            <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
              <label>Number of Teams <span v-if="this.selectedCourseTemplate">(min {{minNumTeams}} max {{maxNumTeams}})</span></label>
              <md-input v-model="newSemesterNumTeams" type="number" :min="minNumTeams" :max="maxNumTeams"></md-input>
              <span v-if="inputError === 'numTeams'" class="error form-error">Please enter the number of teams</span>
              <span v-if="inputError === 'minMaxNumTeams'" class="error form-error">The value must be within the range of {{minNumTeams}} and {{maxNumTeams}}</span>
            </md-field>
          <div class="md-layout-item"></div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item"></div>
            <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
              <label>Students per Team <span v-if="this.selectedCourseTemplate">(min {{minStudentsPerTeam}} max {{maxStudentsPerTeam}})</span></label>
              <md-input v-model="newSemesterNumStudentsPerTeam" type="number" :min="minStudentsPerTeam" :max="maxStudentsPerTeam"></md-input>
              <span v-if="inputError === 'numStudentsPerTeam'" class="error form-error">Please enter the number of students per team</span>
              <span v-if="inputError === 'minMaxNumStudentsPerTeam'" class="error form-error">The value must be with the range of {{minStudentsPerTeam}} and {{maxStudentsPerTeam}}</span>
            </md-field>
          <div class="md-layout-item"></div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item"></div>
            <div class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
              <h3 class="label">Use Flow Mode</h3>
              <div class="radio-options">
                <md-radio v-model="newSemesterUseFlowMode" :value="true" class="md-primary">Yes</md-radio>
                <md-radio v-model="newSemesterUseFlowMode" :value="false" class="md-primary">No</md-radio>
              </div>
            </div>
          <div class="md-layout-item"></div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item"></div>
          <md-button :disabled="httpWait" @click="submitNewSemester()" class="md-raised btn-primary" :class="{ 'btn-disabled' : httpWait }">
              Submit
              <md-progress-spinner v-if="httpWait" class="btn-spin" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
          </md-button>
          <div class="md-layout-item"></div>
        </div>
        <span class="error">{{serverError}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { MdField } from 'vue-material/dist/components'
import { MdButton } from 'vue-material/dist/components'
import { MdDatepicker } from 'vue-material/dist/components'
import { MdDialog } from 'vue-material/dist/components'
import { MdMenu } from 'vue-material/dist/components'
import { MdList } from 'vue-material/dist/components'
import { MdRadio } from 'vue-material/dist/components'
import { MdSubheader } from 'vue-material/dist/components'
import { mapActions } from 'vuex'
import { DateTime } from "luxon";

Vue.use(MdField);
Vue.use(MdButton);
Vue.use(MdDatepicker);
Vue.use(MdDialog);
Vue.use(MdMenu);
Vue.use(MdList);
Vue.use(MdRadio);
Vue.use(MdSubheader);

export default {
  name: 'NewSemester',
  data() {
    let startDate = DateTime.local().set({weekday: 1}).startOf('day');
    const now = DateTime.local();
    if(startDate < now) {
      startDate = startDate.plus({days: 7});
    }
    return {
      disabledDates: date => {
        const day = date.getDay()
        return day !== 1 || DateTime.fromMillis(date.getTime()) < now;
      },
      newSemesterName: "",
      selectedCourseTemplate: null,
      newSemesterYear: "",
      newSemesterStart: startDate.toJSDate(),
      newSemesterUseFlowMode: false,
      newSemesterNumTeams: "",
      newSemesterNumStudentsPerTeam: "",
      minNumTeams: 0,
      maxNumTeams: 100,
      minStudentsPerTeam: 0,
      maxStudentsPerTeam: 100,
      serverError: "",
      inputError: "",
      httpWait: false,
      courseTemplates: [],
      publicCourseTemplates: [],
      myCourseTemplates: [],
      selectedCourseTemplateId: null
    }
  },
  props: {
    showCreateSemester: Boolean,
    alertMessage: String,
    professorId: String
  },
  watch: {
    selectedCourseTemplateId: function(val) {
      if(val) {
        this.selectedCourseTemplate = this.courseTemplates.find(courseTemplate => courseTemplate.id === val);
      }
    },
    selectedCourseTemplate: function(val) {
      if(val) {
        this.minNumTeams = val.minTeams;
        this.maxNumTeams = val.maxTeams;
        this.minStudentsPerTeam = val.minStudentsPerTeam;
        this.maxStudentsPerTeam = val.maxStudentsPerTeam;
      }
    }
  },
  methods: {
    ...mapActions([
      'addSemester', 
      'fetchCourseList',
      'fetchCourseTemplates'
    ]),
    async submitNewSemester() {
      this.httpWait = true;
      this.newSemesterYear = this.newSemesterStart.getFullYear();
      if(this.validateForm()) {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        try {
          await this.addSemester({
            name: this.newSemesterName,
            year: this.newSemesterYear,
            season: this.newSemesterSeason,
            startTime: (this.newSemesterStart.getTime() / 1000),
            useFlowMode: this.newSemesterUseFlowMode,
            timezone,
            numTeams: parseInt(this.newSemesterNumTeams),
            numStudentsPerTeam: parseInt(this.newSemesterNumStudentsPerTeam),
            courseTemplateId: this.selectedCourseTemplate.id
          });
          this.$emit("update:dismissAlert", false);
          this.$emit("update:showFormSuccess", true);
          this.$emit("update:showCreateSemester", false);
          this.$emit("update:alert-message", "Successfully created a game!");
          this.httpWait = false;
        } catch(err) {
          this.serverError = "Something went wrong. Please try again";
          this.httpWait = false;
          return err;
        }
      }
      this.httpWait = false;
    },
    validateForm() {
      if(!this.newSemesterName) {
        this.inputError = "name";
      } else if(!this.selectedCourseTemplate) {
        this.inputError = "course";
      } else if(!this.newSemesterNumTeams) {
        this.inputError = "numTeams";
      } else if(!this.newSemesterNumStudentsPerTeam) {
        this.inputError = "numStudentsPerTeam";
      } else if(this.newSemesterNumTeams < this.minNumTeams || this.newSemesterNumTeams > this.maxNumTeams) {
        this.inputError = "minMaxNumTeams";
      } else if(this.newSemesterNumStudentsPerTeam < this.minStudentsPerTeam || this.newSemesterNumStudentsPerTeam > this.maxStudentsPerTeam) {
        this.inputError = "minMaxNumStudentsPerTeam";
      } else {
        this.inputError = "";
        return true;
      }
    }
  },
  async created() {
    this.fetchCourseList();
    this.useFlowMode = !!localStorage.getItem('accountonomics.flowMode');
    this.courseTemplates = await this.fetchCourseTemplates({
      professorId: this.professorId
    });
    this.courseTemplates.filter(template => template.isLive);
    this.publicCourseTemplates = this.courseTemplates.filter(template => template.isSystemTemplate);
    this.myCourseTemplates = this.courseTemplates.filter(template => !template.isSystemTemplate);
  }
}
</script>

<style scoped>
  h1 {
    padding: 1em;
  }
  .container {
    margin: 1em;
  }
  .main-box {
    background-color: var(--main-box-color);
  }
  .text-center {
    text-align: center;
  }
  .btn-group {
    padding-bottom: 1em;
  }
  .new-semester-form-container {
    padding: 1em;
  }
  .md-card {
    width: 320px;
    margin: 4px;
    display: inline-block;
    vertical-align: top;
  }
  .highlightSelected {
    background-color: #448aff;
  }
  .error {
    color: #ff1744;
  }
  .form-error {
    display: block!important;
    left: 0;
    height: 20px;
    position: absolute;
    bottom: -22px;
    font-size: 12px;
    transition: .3s cubic-bezier(.4,0,.2,1);
  }
  .label {
    text-align: left;
  }
  .select-course-error {
    border-color: var(--warning-text-color);
  }
  .radio-options {
    text-align: left;
  }
</style>
