<template>
  <div id="app">
   <div v-if="showColorPicker" id="color-picker">
    <div id="color-picker-move">Click and drag to move</div>
      <div class="color-picker-item">
        Main Background:
        <input ref="main-background-color" value="#e7ddc6" type="color">
      </div>
      <div class="color-picker-item">
        Navbar:
        <input ref="nav-bar-color" value="#000000" type="color">
      </div>
      <div class="color-picker-item">
        Main Box:
        <input ref="main-box-color" value="#ededed" type="color">
      </div>
      <div class="color-picker-item">
        Inner Box:
        <input ref="inner-box-color" value="#ffffff" type="color">
      </div>
      <div class="color-picker-item">
        Inner Cards:
        <input ref="inner-card-color" value="#ffffff" type="color">
      </div>
      <div class="color-picker-item">
        Table Title Background:
        <input ref="table-title-bg-color" value="#000000" type="color">
      </div>
      <div class="color-picker-item">
        Table Cells:
        <input ref="table-cell-color" value="#ffffff" type="color">
      </div>
      <div class="color-picker-item">
        Box Shadow:
        <input ref="box-shadow-color" value="#888888" type="color">
      </div>
      <div class="color-picker-item">
        Team Menu:
        <input ref="team-menu-color" value="#d2c8ad" type="color">
      </div>
      <div class="color-picker-item">
        Round Menu:
        <input ref="round-menu-color" value="#d2c8ad" type="color">
      </div>
      <div class="color-picker-item">
        Secondary Text:
        <input ref="secondary-text-color" value="#ededed" type="color">
      </div>
      <div class="color-picker-item">
        Button Primary:
        <input ref="btn-primary-color" value="#123f98" type="color">
      </div>
      <div class="color-picker-item">
        Button Secondary:
        <input ref="btn-secondary-color" value="#ffffff" type="color">
      </div>
    </div>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap" rel="stylesheet">
    <router-view>
    </router-view>
  </div>
</template>

<script>
class App extends Vue {}
import { Component, Vue } from 'vue-property-decorator';
import {mapGetters} from 'vuex'
export default {
  data() {
    return {
      showColorPicker: false,
      counter: 0
    }
  },
  watch: {
    showColorPicker(val) {
      if(val === true) {
        window.removeEventListener('keydown', this.checkForKeycode);
        setTimeout(() => {
          this.$nextTick(() => {
            let mainBackgroundColor = this.$refs['main-background-color'];
            let navBarColor = this.$refs['nav-bar-color'];
            let mainBoxColor = this.$refs['main-box-color'];
            let innerBoxColor = this.$refs['inner-box-color'];
            let innerCardColor = this.$refs['inner-card-color'];
            let tableTitleBackgroundColor = this.$refs['table-title-bg-color'];
            let tableCellColor = this.$refs['table-cell-color'];
            let boxShadowColor = this.$refs['box-shadow-color'];
            let teamMenuColor = this.$refs['team-menu-color'];
            let roundMenuColor = this.$refs['round-menu-color'];
            let secondaryTextColor = this.$refs['secondary-text-color'];
            let btnPrimaryColor = this.$refs['btn-primary-color'];
            let btnSecondaryColor = this.$refs['btn-secondary-color'];
            mainBackgroundColor.addEventListener("change", this.setColor);
            navBarColor.addEventListener("change", this.setColor);
            mainBoxColor.addEventListener("change", this.setColor);
            innerBoxColor.addEventListener("change", this.setColor);
            innerCardColor.addEventListener("change", this.setColor);
            tableTitleBackgroundColor.addEventListener("change", this.setColor);
            tableCellColor.addEventListener("change", this.setColor);
            boxShadowColor.addEventListener("change", this.setColor);
            teamMenuColor.addEventListener("change", this.setColor);
            roundMenuColor.addEventListener("change", this.setColor);
            secondaryTextColor.addEventListener("change", this.setColor);
            btnPrimaryColor.addEventListener("change", this.setColor);
            btnSecondaryColor.addEventListener("change", this.setColor);
            this.moveColorPicker(document.getElementById("color-picker"));
          });
        }, 1000)
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', this.checkForKeycode);
  },
  computed: {
    ...mapGetters([
      'getAppClientVersion',
    ]),
  },
  created() {
    window.nerkleInfo = {
      buildNumber: this.getAppClientVersion,
      timeToMakeRequest: 1200000, // 20 min
      secUntilShowLogoutDialog: 300 // 5 min  
    };
  },
  methods: {
    checkForKeycode(event) {
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      if(keyCode === 220) {
        this.counter ++;
      }
      if(this.counter === 5) {
        this.showColorPicker = true;
      }
    },
    moveColorPicker(elmnt) {
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      } else {
        elmnt.onmousedown = dragMouseDown;
      }
    
      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
      }
    
      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }
    
      function closeDragElement() {
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },
    setColor() {
      document.documentElement.style.setProperty("--main-bg-color", this.$refs['main-background-color'].value);
      document.documentElement.style.setProperty("--nav-bar-color", this.$refs['nav-bar-color'].value);
      document.documentElement.style.setProperty("--main-box-color", this.$refs['main-box-color'].value);
      document.documentElement.style.setProperty("--inner-box-color", this.$refs['inner-box-color'].value);
      document.documentElement.style.setProperty("--inner-card-color", this.$refs['inner-card-color'].value);
      document.documentElement.style.setProperty("--table-title-bg-color", this.$refs['table-title-bg-color'].value);
      document.documentElement.style.setProperty("--table-cell-color", this.$refs['table-cell-color'].value);
      document.documentElement.style.setProperty("--box-shadow-color", this.$refs['box-shadow-color'].value);
      document.documentElement.style.setProperty("--team-menu-color", this.$refs['team-menu-color'].value);
      document.documentElement.style.setProperty("--round-menu-color", this.$refs['round-menu-color'].value);
      document.documentElement.style.setProperty("--text-secondary", this.$refs['secondary-text-color'].value);
      document.documentElement.style.setProperty("--btn-primary-color", this.$refs['btn-primary-color'].value);
      document.documentElement.style.setProperty("--btn-secondary-color", this.$refs['btn-secondary-color'].value);
    }
  }
}
</script>

<style>
#app {
  font-family: 'Baloo 2';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;
  background-color: var(--main-bg-color);
  overflow: scroll;
}

#color-picker {
  position: absolute;
  z-index: 9;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  text-align: center;
}

.color-picker-item {
  padding-bottom: 20px;
}

#color-picker-move {
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #2196F3;
  color: #fff;
}
</style>
